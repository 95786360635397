import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import Stores from './Stores'
// import AddStore from './AddStore'
import AddStore from './AddStore_'
import ShowEditStore from './ShowEditStore'
import { useSelector } from 'react-redux'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import queryString from 'query-string'
import AuthRequired from '../common/AuthRequired'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export const StoresMain = ({ match, classFromParent, openSwipeable, location }) => {
  const subProp = useMemo(() => match.params.sub, [match])
  const values = useMemo(() => queryString.parse(location.search), [location])
  const classes = useStyles()
  const {
    values: { isRestaurantAdmin, isStoreAdmin },
  } = useMerchantHomeContext()

  const stores = useSelector(({ stores }) => stores)
  const merchantStores = useSelector(({ merchantStores }) => merchantStores.stores)
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)
  const isStoreEditable = merchantStores.length > 0 && merchantStores.some((item) => item.storeId === +values.storeid)
  const canRestAdminSee = isRestaurantAdmin && isRestaurant
  const canAdminEdit = (isRestaurantAdmin && !isRestaurant) || isStoreAdmin

  const getContent = () => {
    switch (subProp) {
      case 'add':
        if (canRestAdminSee) {
          return renderAddScreen()
        } else {
          return renderAuthRequired()
        }
      case 'delivery-options':
      case 'store-details':
      case 'bankinfo':
      case 'notifications':
      case 'working-hours':
      case 'payment-contact-info':
        return renderEditStore()
      default:
        if (canRestAdminSee) {
          return renderStoresScreen()
        } else {
          return renderAuthRequired()
        }
    }
  }

  const renderAddScreen = () => <AddStore classFromParent={classFromParent} openSwipeable={openSwipeable} />
  const renderStoresScreen = () => <Stores />
  const renderAuthRequired = () => <AuthRequired />

  const renderEditStore = () => {
    // prevent user to enter link with unauthorized store id
    if (!canAdminEdit || !isStoreEditable) {
      return renderAuthRequired()
    }
    return <ShowEditStore subProp={subProp} />
  }

  // to prevent 404 image display before merchantStores is loaded
  // if (!isStoreAdmin && !canRestAdminSee) return null

  return (
    <div>
      {getContent()}
      <Backdrop className={classes.backdrop} open={stores.loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

StoresMain.propTypes = {
  match: PropTypes.object,
  classFromParent: PropTypes.object,
  openSwipeable: PropTypes.bool,
}

export default withRouter(StoresMain)
