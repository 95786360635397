import React from 'react'
import AppStoresLink from './WebFooter/AppStoresLink'
import FooterLinks from './WebFooter/FooterLinks'
import { aboutTookfresh, getHelps } from '../../../constants/footer/web-footer'
import Subscribe from './WebFooter/Subscribe'
import SocialMediaLinks from './WebFooter/SocialMediaLinks'
import BottomLinks from './WebFooter/BottomLinks'
import { Container, Grid } from '@material-ui/core'

const WebFooter = ({ isTablet }) => {
  let tabletView = isTablet === 'true' ? true : false
  return (
    <Container maxWidth='lg'>
      <Grid
        container
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: tabletView ? 'column' : 'row',
        }}
      >
        <AppStoresLink isTablet={isTablet} tabletView={tabletView} />
        <FooterLinks data={tabletView ? getHelps : aboutTookfresh} isTablet={isTablet} />
        <FooterLinks data={tabletView ? aboutTookfresh : getHelps} isTablet={isTablet} />
        {tabletView ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <BottomLinks tabletView={tabletView} isTablet={isTablet} />
            <SocialMediaLinks />
            <Subscribe tabletView={tabletView} isTablet={isTablet} />
          </div>
        ) : (
          <>
            <Subscribe />
            <SocialMediaLinks />
            <BottomLinks />
          </>
        )}
      </Grid>
    </Container>
  )
}

export default WebFooter
