import React from 'react'
import PropTypes from 'prop-types'

import CartItem from '../cartItem'
import { deliveryTypes } from '../../../../constants'
import WebPromo from '../WebPromo'
import { checkDeliveryDiscount, checkSubtotalDiscount } from '../../../../helpers/promotion/delivery-discount'

const CartPrice = ({ itemCount, cart }) => {
  return (
    <>
      {!!cart?.promotion && !!cart?.discountPrice && <WebPromo promotion={cart?.promotion} />}
      <CartItem
        title={`Subtotal - ${itemCount} item${itemCount > 1 ? 's' : ''}`}
        price={(+cart?.finalSubtotalPrice)?.toFixed(2)}
        oldPrice={checkSubtotalDiscount(cart)}
      />
      {/* <CartItem title='Tip' price={(+cart?.driverTipPrice?.value)?.toFixed(2)} /> */}
      {/* <CartItem title='Service Fee' price='*' /> */}
      {cart?.type === deliveryTypes.DELIVERY && (
        <CartItem
          title='Delivery Fee'
          price={(+cart?.deliveryFee)?.toFixed(2)}
          oldPrice={checkDeliveryDiscount(cart)}
        />
      )}
      <CartItem title='Taxes' price={cart?.tax?.toFixed(2)} />
      <CartItem title='Total' price={cart?.totalPrice?.toFixed(2)} total />
    </>
  )
}

CartPrice.propTypes = {
  itemCount: PropTypes.number,
  cart: PropTypes.object,
}

export default React.memo(CartPrice)
