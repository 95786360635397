import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { Button, makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import clsx from 'clsx'
import StickyHeader from '../../../common/StickyHeader'
import { StoreData, AddItems, MenuDetails } from './your-cart'
import Prices from '../../../common/Prices'
import Information from '../../../common/Information'
import EmtpyCart from './your-cart/EmtpyCart'
import Promo from './check-out/Promo'
import { checkDeliveryDiscount, checkSubtotalDiscount } from '../../../../helpers/promotion/delivery-discount'
import { deliveryTypes } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  footer: {
    width: '100%',
    paddingRight: theme.spacing(2),
    bottom: 10,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    backgroundColor: '#69e781',
    borderRadius: 10,
    color: '#000',
    textTransform: 'capitalize',
  },
}))

const YourCart = ({
  logo,
  handleClose,
  storeDetail,
  selectedCategories,
  itemCounter,
  intl,
  loading,
  goToStore,
  deleteMenuItem,
  userLoginCheck,
  handleQuantityChange,
}) => {
  const classes = useStyles()
  //Global states
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const hideDeliveryFee = cart?.type === deliveryTypes.TAKEOUT

  return (
    <React.Fragment>
      <StickyHeader callback={handleClose} title='your-cart' />
      <StoreData storeDetail={storeDetail} logo={logo} selectedCategories={selectedCategories} />
      <AddItems itemCounter={itemCounter} goToStore={goToStore} />
      {cart.orderMenus.length === 0 ? (
        <EmtpyCart title={getLabel('still-empty')} subTitle={getLabel('basket-no-item')} />
      ) : (
        cart.orderMenus.map((menu, index) => (
          <MenuDetails
            menu={menu}
            index={index}
            loading={loading}
            handleQuantityChange={handleQuantityChange}
            deleteMenuItem={deleteMenuItem}
            key={index}
          />
        ))
      )}
      {/* <AddPromo /> */}
      {/* WE CAN IMPLEMENT GHOST LOADER WHILE LOADING AS WE DID IN QUICKCART */}
      {!!cart?.promotion && !!cart?.discountPrice && !loading && <Promo promotion={cart?.promotion} />}
      {!loading && (
        <Prices
          title={'subtotal-label'}
          price={+cart?.finalSubtotalPrice}
          oldPrice={checkSubtotalDiscount(cart)}
          variant='h6'
        />
      )}

      {/* <Prices title={'small-order-fee'} price={} variant='body2' /> */}
      {/* <Prices title={'service-fee'} price={} variant='body2' /> */}
      {!hideDeliveryFee && !loading && (
        <Prices
          title='delivery-fee'
          oldPrice={checkDeliveryDiscount(cart)}
          price={cart?.deliveryFee || 0}
          variant='body2'
        />
      )}
      <Prices title={'taxes-label'} price={cart.tax || 0} variant='body2' />
      <Prices title={'total-label'} price={cart.totalPrice || 0} variant='h6' />
      <Information text={'commission-text'} align={'center'} />

      <div className={classes.grow}></div>
      <div className={classes.grow} />
      <div className={clsx('sticky-bottom', classes.footer)}>
        <Button className={classes.button} onClick={userLoginCheck} disabled={loading || cart.orderMenus.length === 0}>
          {getLabel('next-label')}
        </Button>
      </div>
    </React.Fragment>
  )
}

YourCart.propTypes = {
  intl: PropTypes.object,
  logo: PropTypes.object,
  handleClose: PropTypes.func,
  storeDetail: PropTypes.object,
  selectedCategories: PropTypes.array,
  itemCounter: PropTypes.number,
  goToStore: PropTypes.func,
  loading: PropTypes.bool,
  deleteMenuItem: PropTypes.func,
  userLoginCheck: PropTypes.func,
}

export default injectIntl(YourCart)
