import React from 'react'
import clsx from 'clsx'
import IntersectionVisible from 'react-intersection-visible'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import TopNavbar from '../../top_navbar'
import Footer from '../../Footers'
import StoreItem from '../../search/store_item'

import backIcon from '../../../../images/black-back.svg'

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexColumn,
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  headerContainer: {
    ...flexColumn,
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    lineHeight: 1.32,
    color: '#333333',
    textAlign: 'center',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    minHeight: 600,
  },
  subContainer: {
    flexGrow: 1,
    minHeight: 300,
  },
  gridContainer: {
    paddingBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
}))

const WebCampaigns = ({ history, stores, currentCampaign, getStores, favoriteHandler, favoriteStores, pricey }) => {
  const classes = useStyles()
  return (
    <div className={clsx('grow desktop full-width', [classes.root])}>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={true} />
      </Container>
      <div className={classes.headerContainer}>
        <Container maxWidth='lg' className={classes.header}>
          <IconButton color='inherit' aria-label='close' onClick={() => history.goBack()}>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography className={classes.headerText}>{currentCampaign?.name}</Typography>
        </Container>
      </div>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <Container maxWidth='lg'>
            <Grid container spacing={2} className={classes.gridContainer}>
              {stores.map(
                (store, index) =>
                  store !== [] && (
                    <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                      <IntersectionVisible onShow={() => index === stores.length - 4 && getStores()}>
                        <StoreItem
                          search={false}
                          data={store}
                          history={history}
                          onPress={favoriteHandler}
                          favorites={favoriteStores}
                          price={pricey}
                        />
                      </IntersectionVisible>
                    </Grid>
                  )
              )}
            </Grid>
          </Container>
        </div>
        <div className='grow' />
        <Footer invisible={true} />
      </div>
    </div>
  )
}

WebCampaigns.propTypes = {
  history: PropTypes.object,
  stores: PropTypes.array,
  currentCampaign: PropTypes.object,
  getStores: PropTypes.func,
  favoriteHandler: PropTypes.func,
  favoriteStores: PropTypes.array,
  pricey: PropTypes.number,
}

export default WebCampaigns
