import React from 'react'
import { connect } from 'react-redux'
import { customerActions } from '../../../../actions'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { GreenButton } from '../../../utils/tools'
import { phoneRegex } from '../../../utils/regex_collections'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import axios from 'axios'
import Menu from '@material-ui/core/Menu'
import InputMask from 'react-input-mask'
import MenuItem from '@material-ui/core/MenuItem'
import CountryCodeData from '../../../../constants/country_phone_list.json'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { customerService } from '../../../../services'
import { generalConstants, customerConstants } from '../../../../constants'
import backIcon from '../../../../images/black-back.svg'
import doneImage from '../../../../images/verification-done.svg'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1,
  },
  text: {
    fontWeight: 500,
    textAlign: 'center',
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    margin: theme.spacing(),
  },
  dialog: {
    width: 240,
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  countryCode: {
    marginRight: theme.spacing(),
    width: 100,
  },
  resend: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  digitField: {
    backgroundColor: '#d6d6d6',
    borderRadius: 40,
    height: 60,
    width: 60,
    maxWidth: 60,
    position: 'relative',
    fontSize: 30,
    padding: 10,
  },
  filled: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  took: {
    fontWeight: 'bold',
  },
  mobileTopBar: {
    marginBottom: 80,
    borderBottomColor: 'white',
  },
  sendSms: {
    textAlign: 'center',
    fontSize: 16,
    color: '#707070',
  },
  numberLabel: {
    marginBottom: 60,
    textAlign: 'center',
    fontSize: 16,
    color: '#707070',
  },
  orLogin: {
    fontSize: 14,
    color: '#707070',
    marginRight: 5,
    marginTop: 2,
    opacity: 0.8,
    fontWeight: 300,
  },
  socialNetwork: {
    fontSize: 16,
    color: '#69e781',
    fontWeight: 500,
  },
  digitAreaGrid: {
    marginBottom: 70,
    fontSize: 16,
    color: '#707070',
  },
  notReceive: {
    fontSize: 16,
    fontWeight: 300,
    opacity: 0.8,
    marginBottom: -5,
  },
  dialogContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 390,
  },
  dialogContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 380,
  },
  dialogContentText: {
    fontWeight: 'bold',
    fontSize: 34,
    textAlign: 'center',
    color: 'black',
  },
  dialogContentText2: {
    fontWeight: 'normal',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
  dialogActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogButton: {
    fontSize: 15,
    textTransform: 'capitalize',
    width: '100%',
    height: 48,
    borderRadius: 10,
    backgroundColor: '#69e781',
    color: 'white',
  },
})

class VerifyPhone extends React.Component {
  constructor(props) {
    super(props)

    this.firstInput = React.createRef()
    this.secondInput = React.createRef()
    this.thirdInput = React.createRef()
    this.fourthInput = React.createRef()

    this.state = {
      showDoneDialog: false,
      showErrorDialog: false,
      codePart: '',
      phonePart: '',
      anchorEl: null,
      activeStep: 0,
      firstDigit: '',
      secondDigit: '',
      thirdDigit: '',
      fourthDigit: '',
      regexp: /^[0-9\b]+$/,
    }

    this.getGeoInfo = this.getGeoInfo.bind(this)
  }

  getGeoInfo() {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data

        this.setState({
          country: data.country_name,
          codePart: data.country_calling_code,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.customer) {
  //     let phoneArray = props.currentPhone.split("-")

  //     return {
  //       codePart: phoneArray.shift(),
  //       phonePart: phoneArray.join(' '),
  //     }
  //   }
  //   return null
  // }

  componentDidMount() {
    if (this.props.customer) {
      CountryCodeData.filter((code) =>
        this.props.currentPhone?.includes(code.dial_code)
          ? this.setState({ codePart: code.dial_code }, () =>
              this.setState({ phonePart: this.props.currentPhone.slice(this.state.codePart.length) })
            )
          : ''
      )
    }
    return null
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSubmit(e) {
    const { currentPhone, customer, storeUpdatedPhone, requestVerificationCode } = this.props
    const { phonePart, codePart } = this.state
    e.preventDefault()
    const data = {
      firstName: customer.customerInfo.firstName,
      lastName: customer.customerInfo.lastName,
      phone: `${codePart}${phonePart}`,
      email: customer.customerInfo.email,
    }
    if (`${codePart}${phonePart}` !== currentPhone) {
      customerService.updateUser(data).then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.setState({ activeStep: 1 })
          requestVerificationCode({ phone: data.phone })
          storeUpdatedPhone(`${codePart}${phonePart}`, res.data.phoneVerified)
        }
      })
    } else {
      this.setState({ activeStep: 1 })
      requestVerificationCode({ phone: data.phone })
    }
  }

  sendVerificationCode(token) {
    customerService.verifyPhoneCode(token).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          const customerData = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER))
          const verifiedCustomer = { ...customerData, phoneVerified: true }
          localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(verifiedCustomer))
          this.setState({ showDoneDialog: true }, () => this.props.verifyPhone(res.data))
        } else {
          return this.setState({ showErrorDialog: true }, () => this.props.errorPhoneVerification(res.data))
        }
      },
      (err) => {
        // console.log('VerifyPhone -> sendVerificationCode -> err', err.response)
      }
    )
  }

  handleZeroPad(num) {
    const zeroPad = String(num).padStart(4, '0')
    return zeroPad
  }

  handleVerificationCode() {
    const { firstDigit, secondDigit, thirdDigit, fourthDigit } = this.state
    const tokenStr = firstDigit.concat(secondDigit, thirdDigit, fourthDigit)
    this.sendVerificationCode(this.handleZeroPad(tokenStr))
  }

  countryCodeSelected(evt) {
    this.setState({ anchorEl: null })

    if (evt.target.value) {
      this.setState({
        codePart: '+' + evt.target.value,
      })
    }
  }

  handleFirstDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ firstDigit: d })
    } else if (this.state.regexp.test(d)) {
      this.setState({ firstDigit: d })
      this.secondInput.current.focus()
    }
  }

  handleSecondDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ secondDigit: d })
      this.firstInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ secondDigit: d })
      this.thirdInput.current.focus()
    }
  }

  handleThirdDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ thirdDigit: d })
      this.secondInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ thirdDigit: d })
      this.fourthInput.current.focus()
    }
  }

  handleFourthDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ fourthDigit: d })
      this.thirdInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ fourthDigit: d }, () => this.handleVerificationCode())
    }
  }

  render() {
    const { classes, currentPhone, verified, requestVerificationCode } = this.props
    const {
      showDoneDialog,
      codePart,
      phonePart,
      anchorEl,
      activeStep,
      firstDigit,
      secondDigit,
      thirdDigit,
      fourthDigit,
      showErrorDialog,
    } = this.state

    let countryCodeMenu = (
      <Menu
        id='country-code-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.countryCodeSelected.bind(this)}
      >
        {CountryCodeData.map((data) => (
          <MenuItem
            key={data.dial_code + data.code}
            value={data.dial_code}
            onClick={this.countryCodeSelected.bind(this)}
          >
            {`${data.dial_code} ${data.name}`}
          </MenuItem>
        ))}
      </Menu>
    )

    return (
      <div className='column'>
        <div className='row'>
          <div className={clsx('mobile-top-bar', [classes.mobileTopBar])}>
            <IconButton
              size='small'
              aria-label='close'
              className={classes.back}
              onClick={() => this.props.handleClose()}
            >
              <img src={backIcon} alt='Back' />
            </IconButton>
            <div className='row'>
              <Typography variant='h6' className={classes.took}>
                Took
              </Typography>
              <Typography variant='h6'>Fresh</Typography>
            </div>
          </div>
        </div>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          {activeStep === 0 && (
            <div className={classes.paper}>
              <Typography variant='h4' gutterBottom className={classes.title}>
                {currentPhone && `${codePart}${phonePart}` === currentPhone
                  ? this.getLabel('verify-your')
                  : this.getLabel('update-your')}
              </Typography>
              <Typography variant='h4' gutterBottom className={classes.title} style={{ marginBottom: 30 }}>
                {this.getLabel('phone-number')}
              </Typography>
              <Typography variant='body1' gutterBottom className={classes.sendSms}>
                {this.getLabel('sent-sms-code')}
              </Typography>
              <Typography variant='body1' gutterBottom className={classes.numberLabel}>
                {`${this.getLabel('number-label')}   ${codePart}${phonePart}`}
              </Typography>
              <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <div className='row'>
                  <TextField
                    size='small'
                    className={classes.countryCode}
                    variant='outlined'
                    fullWidth
                    id='code'
                    autoComplete='code'
                    value={codePart}
                    onClick={(event) => {
                      this.setState({ anchorEl: event.target })
                    }}
                    inputProps={{
                      style: { cursor: 'pointer', fontSize: 16, fontWeight: 'bold', color: '#707070' },
                    }}
                  />
                  {countryCodeMenu}
                  <InputMask
                    mask='9999999999'
                    maskChar=' '
                    value={phonePart}
                    onChange={(event) =>
                      this.setState({
                        phonePart: event.target.value,
                        phoneFocused: true,
                        phoneInvalid: !phoneRegex.test(event.target.value),
                      })
                    }
                  >
                    {() => (
                      <TextField
                        size='small'
                        autoComplete='phone'
                        variant='outlined'
                        required
                        fullWidth
                        id='phone'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: { fontSize: 16, fontWeight: 'normal', color: '#c4c4c4' },
                        }}
                        label={this.getLabel('phone-number')}
                        error={this.state.phoneFocused && this.state.phoneInvalid}
                        helperText={
                          this.state.phoneFocused && this.state.phoneInvalid ? this.getLabel('invalid-phone') : ' '
                        }
                      />
                    )}
                  </InputMask>
                </div>
                <div className='row' style={{ justifyContent: 'center' }}>
                  <Typography gutterBottom className={classes.orLogin}>
                    {this.getLabel('or-login')}
                  </Typography>
                  <Typography className={classes.socialNetwork}>{this.getLabel('social-network')}</Typography>
                </div>
                {`${codePart}${phonePart}` === currentPhone ? (
                  <GreenButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    disabled={verified}
                    className={classes.button}
                    // onClick={() => this.setState({ activeStep: 1 })}
                  >
                    {this.getLabel('send-label')}
                  </GreenButton>
                ) : (
                  <GreenButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    disabled={this.state.phoneInvalid}
                    className={classes.button}
                    // onClick={() => this.setState({ showDoneDialog: true })}
                  >
                    {this.getLabel('update-label')}
                  </GreenButton>
                )}
              </form>
            </div>
          )}
          {activeStep === 1 && (
            <div className={classes.paper}>
              <Typography variant='h4' gutterBottom className={classes.title} style={{ marginBottom: 20 }}>
                {this.getLabel('phone-verification')}
              </Typography>
              <Typography variant='subtitle1' gutterBottom style={{ marginBottom: 50 }}>
                {this.getLabel('enter-otp')}
              </Typography>
              <Grid container className={clsx('full-width', [classes.digitAreaGrid])}>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='first'
                    inputRef={this.firstInput}
                    autoFocus
                    value={firstDigit}
                    onChange={this.handleFirstDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: firstDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='second'
                    inputRef={this.secondInput}
                    value={secondDigit}
                    onChange={this.handleSecondDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: secondDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='third'
                    inputRef={this.thirdInput}
                    value={thirdDigit}
                    onChange={this.handleThirdDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: thirdDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='fourth'
                    inputRef={this.fourthInput}
                    value={fourthDigit}
                    onChange={this.handleFourthDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: fourthDigit,
                      }),
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant='subtitle2' gutterBottom className={classes.notReceive}>
                {this.getLabel('not-received-code')}
              </Typography>
              <Button className={classes.resend} onClick={() => requestVerificationCode()}>
                {this.getLabel('resend-code')}
              </Button>
            </div>
          )}
        </Container>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showDoneDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <img src={doneImage} alt='doneImage' />
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('verification-done')}
              </DialogContentText>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('phone-approved')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.props.handleClose()} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showErrorDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('check-credentials')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.setState({ showErrorDialog: false })} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { customer } = state

  return {
    customer,
    currentPhone: customer.customerInfo.phone,
    verified: customer.customerInfo.phoneVerified,
  }
}

const actionCreators = {
  resetPassword: customerActions.resetPassword,
  requestVerificationCode: customerActions.requestPhoneVerificationCode,
  verifyPhone: customerActions.storePhoneVerification,
  errorPhoneVerification: customerActions.errorPhoneVerification,
  storeUpdatedPhone: customerActions.storeUpdatedPhone,
}

VerifyPhone.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(connect(mapState, actionCreators)(VerifyPhone)))
