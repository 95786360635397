import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import facebookLogo from '../../images/facebook_logo.svg'
import { FACEBOOK_APP_ID } from '../../constants/general.constants'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, Redirect } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { customerActions } from '../../actions'

const styles = (theme) => ({
  facebookButton: {
    color: '#fff',
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    padding: 8,
    textTransform: 'none',
    width: '100%',
    marginBottom: theme.spacing(),
    backgroundColor: '#3a589b',
    '&:hover': {
      backgroundColor: '#3a589b',
    },
  },
})

const LoginButton = ({ facebookResponse, classes, label }) => {
  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      fields='name,email,picture'
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          variant='contained'
          className={classes.facebookButton}
          startIcon={<img src={facebookLogo} alt='Facebook' />}
        >
          {label}
        </Button>
      )}
      callback={facebookResponse}
    />
  )
}

class FacebookAuth extends Component {
  facebookResponse = (response) => {
    const token = response.accessToken
    if (token) this.props.social('FACEBOOK', token)
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  render() {
    const { classes, signedIn } = this.props
    return (
      <div style={{ width: '100%' }}>
        {signedIn ? (
          <Redirect to='/customer' />
        ) : (
          <LoginButton
            facebookResponse={this.facebookResponse}
            classes={classes}
            label={this.getLabel('continue-facebook')}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    signedIn: state.customer.signedIn,
  }
}

const actionCreators = {
  social: customerActions.social,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(FacebookAuth))))
