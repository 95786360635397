import React from 'react'
import '../styles/customer_global.scss'
import priceIcon from '../images/price.svg'
import starIcon from '../images/star.svg'

export const generalConstants = {
  mobileTopBarHeight: 40,
  mobileBottomBarHeight: 83,
  siteWidth: 1400,
  columnWidth: 400,
  rowsPerPage: 10,
  minPageHeight: 500,
  TOOKFRESH: 'TKFRSH',
  SEARCHED_ADDRESSES: 'TKFRSH_SEARCH_ADDRESSES',
  SEARCHED_KEYWORDS: 'TKFRSH_SEARCH_KEYWORDS',

  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',

  USA: 'United States',

  MAIN_MENU: 'MAIN_MENU',

  STATUS_OK: 200,

  MAX_IMAGE_SIZE: 5242880,
}

export const deliveryTimes = {
  ASAP: 'ASAP',
  SCHEDULED: 'SCHEDULED',
}

export const campaignTypes = {
  FREE_DELIVERY: 'FREE_DELIVERY',
}

export const deliveryTypes = {
  DELIVERY: 'DELIVERY',
  TAKEOUT: 'TAKEOUT',
}

export const deliverySubTypes = {
  DELIVER_DOOR: 'DELIVER2DOOR',
  MEET_DOOR: 'MEET@DOOR',
  MEET_OUTSIDE: 'MEETOUTSIDE',
}

export const days = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
}

export const servingTypes = {
  WEIGHT: 'WEIGHT',
  PORTION: 'PORTION',
}

export const orderStatuses = {
  // 24-12-2021
  ORDER_RECEIVED: 'ORDER_RECEIVED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  PAYMENT_PROCESS: 'PAYMENT_PROCESS',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  CANCELED: 'CANCELED',
  //senpex will change this 2 status
  DRIVER_PICKED_UP: 'DRIVER_PICKED_UP',
  DELIVERED: 'DELIVERED',
  ORDER_REJECTED: 'ORDER_REJECTED',
}

export const paymentMethods = {
  OTHER: 'OTHER',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  CASH: 'CASH',
  STRIPE: 'STRIPE',
  BANK_TRANSFER: 'BANK_TRANSFER',
  AMEX: 'AMEX',
}

export const paginationValues = {
  totalElements: 0,
  totalPages: 0,
  last: true,
  first: true,
  numberOfElements: 0,
  size: 15,
  number: 0,
  empty: false,
}

export const rowsPerPageOptions = [5, 10, 15, 25]

export const roles = {
  WEB_USER: 'WEB_USER',
  REST_ADMIN: 'RESTAURANT_ADMIN',
  STORE_ADMIN: 'STORE_ADMIN',
  STORE_USER: 'STORE_USER',
}

export const initialMenu = {
  name: '',
  description: '',
  price: '',
  servingSize: 1,
  servingType: servingTypes.PORTION,
  dietaries: [],
  categories: [],
  // order_: 0,
  // stores: [],
}

export const initialDetails = {
  deliveryType: '',
  deliverySubType: '',
  instruction: '',
  appSuite: '',
  addressName: '',
}

export const bottomNavItems = {
  FOOD: 'food',
  PICKUP: 'pickup',
  SEARCH: 'search',
  ORDERS: 'orders',
  ACCOUNT: 'account',
}

export const priceRanges = [1, 2, 3, 4, 5]

export const GOOGLE_CLIENT_ID = '334350219319-9a290g90gero4g6b6qtdhkcunjgu4g05.apps.googleusercontent.com'
export const OAUTH20_CLIENT_ID = '226176166839-o799o8n045oqgs9jrc50a85kgnc9gn48.apps.googleusercontent.com'

export const FACEBOOK_APP_ID = '327781391691739'

export const distanceMarks = [
  {
    value: 5,
    label: (
      <div className='column'>
        <span className='price-range-label'>5</span>
        <span className='price-range-label'>mi</span>
      </div>
    ),
  },
  {
    value: 10,
    label: (
      <div className='column'>
        <span className='price-range-label'>10</span>
        <span className='price-range-label'>mi</span>
      </div>
    ),
  },
  {
    value: 15,
    label: (
      <div className='column'>
        <span className='price-range-label'>15</span>
        <span className='price-range-label'>mi</span>
      </div>
    ),
  },
  {
    value: 20,
    label: (
      <div className='column'>
        <span className='price-range-label'>20</span>
        <span className='price-range-label'>mi</span>
      </div>
    ),
  },
  {
    value: 25,
    label: (
      <div className='column'>
        <span className='price-range-label'>25</span>
        <span className='price-range-label'>mi</span>
      </div>
    ),
  },
]

export const priceMarks = [
  {
    value: 1,
    label: (
      <div className='column' style={{ marginLeft: 8 }}>
        <img src={priceIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className='column'>
        <img src={priceIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X2</span>
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div className='column'>
        <img src={priceIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X3</span>
      </div>
    ),
  },
  {
    value: 4,
    label: (
      <div className='column' style={{ marginLeft: -8 }}>
        <img src={priceIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X4</span>
      </div>
    ),
  },
  {
    value: 5,
    label: (
      <div className='column' style={{ marginLeft: -8 }}>
        <img src={priceIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X5</span>
      </div>
    ),
  },
]
export const starMarks = [
  {
    value: -1,
    label: (
      <div className='column' style={{ marginLeft: 8 }}>
        <img src={starIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
      </div>
    ),
  },
  {
    value: -2,
    label: (
      <div className='column'>
        <img src={starIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X2</span>
      </div>
    ),
  },
  {
    value: -3,
    label: (
      <div className='column'>
        <img src={starIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X3</span>
      </div>
    ),
  },
  {
    value: -4,
    label: (
      <div className='column' style={{ marginLeft: -8 }}>
        <img src={starIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X4</span>
      </div>
    ),
  },
  {
    value: -5,
    label: (
      <div className='column' style={{ marginLeft: -8 }}>
        <img src={starIcon} alt='' height='18' width='18' style={{ marginBottom: 4 }} />
        <span className='price-range-label'>X5</span>
      </div>
    ),
  },
]

export const starMarksMoreFilters = [
  {
    value: -1,
    label: (
      <div className='row' style={{ marginLeft: 8 }}>
        <img src={starIcon} alt='' height='10' width='10' />
      </div>
    ),
  },
  {
    value: -2,
    label: (
      <div className='row' style={{ alignItems: 'center' }}>
        <span className='price-range-label'>2</span>
        <img src={starIcon} alt='' height='10' width='10' style={{ marginBottom: 2 }} />
      </div>
    ),
  },
  {
    value: -3,
    label: (
      <div className='row' style={{ alignItems: 'center' }}>
        <span className='price-range-label'>3</span>
        <img src={starIcon} alt='' height='10' width='10' style={{ marginBottom: 2 }} />
      </div>
    ),
  },
  {
    value: -4,
    label: (
      <div className='row' style={{ alignItems: 'center' }}>
        <span className='price-range-label'>4</span>
        <img src={starIcon} alt='' height='10' width='10' style={{ marginBottom: 2 }} />
      </div>
    ),
  },
  {
    value: -5,
    label: (
      <div className='row' style={{ alignItems: 'center' }}>
        <span className='price-range-label'>5</span>
        <img src={starIcon} alt='' height='10' width='10' style={{ marginBottom: 2 }} />
      </div>
    ),
  },
]

export const entityTypes = {
  store: 'STORE',
}

export const initialPagableList = {
  content: [],
  pageable: {
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    pageNumber: 0,
    pageSize: 5,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalElements: 0,
  totalPages: 0,
  last: true,
  first: true,
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  numberOfElements: 0,
  size: 25,
  number: 0,
  empty: false,
}

export const sortBy = {
  NAME: 'store_nickname_s',
  MOST_POPULAR: 'popular',
  NEAREST: 'nearest',
  TOP_RATED: 'rating',
  COST_HIGH_TO_LOW: 'pricey_i',
  COST_LOW_TO_HIGH: 'pricey_i',
  ASC: 'asc',
  DESC: 'desc',
}

export const gooelAddressTemplate = {
  street_number: '',
  route: '',
  locality: '',
  administrative_area_level_2: '',
  administrative_area_level_1: '',
  country: '',
  postal_code: '',
  latitude: '',
  longitude: '',
  formattedAddress: '',
  deliveryOption: '',
  deliveryInstruction: '',
}

export const dateConstant = {
  dateFormat: 'MM.DD.YYYY HH:mm',
}

export const initialStoreState = {
  nickname: '',
  storeNumber: '',
  acceptSameDayOrder: false,
  minOrderPrice: '',
  maxDeliveryDistance: '',
  deliveryFee: null,
  deliveryTime: '',
  active: false,
  deliveryBy: null,
  tierInformations: null,
  notificationType: [],
  orderConfirmationPhone: '',
  orderConfirmationEmail: '',
}

export const initialStoreInformation = {
  email: '',
  phone: '',
  address: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    formattedAddress: '',
  },
  workingHours: [],
}

export const initialTierInformation = {
  1: {
    isActive: true,
    deliveryFee: '',
    minOrder: '',
    maxDistance: '',
  },
  2: {
    isActive: false,
    deliveryFee: '',
    minOrder: '',
    maxDistance: '',
  },
  3: {
    isActive: false,
    deliveryFee: '',
    minOrder: '',
    maxDistance: '',
  },
}

export const initialServiceHours = {
  DELIVERY: {
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  },
  TAKEOUT: {
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  },
}

export const initialFocusedState = {
  nickName: false,
  phone: false,
  email: false,
  minOrderPrice: false,
  maxDeliveryDistance: false,
  deliveryFee: false,
  deliveryTime: false,
  orderConfirmationPhone: false,
  orderConfirmationEmail: false,
}

export const ownBusinessInvalidStates = {
  1: {
    isActive: true,
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
  2: {
    isActive: false,
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
  3: {
    isActive: false,
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
}

export const ownBusinessFocusedStates = {
  1: {
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
  2: {
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
  3: {
    deliveryFee: false,
    minOrder: false,
    maxDistance: false,
  },
}

export const initialInvalidState = {
  nickName: false,
  phone: false,
  email: false,
  minOrderPrice: false,
  maxDeliveryDistance: false,
  deliveryFee: false,
  deliveryTime: false,
  orderConfirmationPhone: false,
  orderConfirmationEmail: false,
}

export const addEmployeeIsFocused = {
  firstName: false,
  lastName: false,
  email: false,
}
export const addEmployeeIsInvalid = {
  firstName: false,
  lastName: false,
  email: false,
}

export const addEmployeeUser = {
  firstName: '',
  lastName: '',
  email: '',
  role: null,
}

export const employeeRoles = [
  {
    name: 'Store Admin',
    label: 'STORE_ADMIN',
    whoCanAdd: [roles.REST_ADMIN],
  },
  {
    name: 'Store User',
    label: 'STORE_USER',
    whoCanAdd: [roles.REST_ADMIN, roles.STORE_ADMIN],
  },
]

export const initialQueryState = {
  latitude: null,
  longitude: null,
  orderTime: null,
  orderType: null,
  unit: 'M',
  distance: null,
  customOffset: 0,
  size: 5,
  searchText: '',
}
export const categorySearchQueryState = {
  latitude: null,
  longitude: null,
  orderTime: null,
  orderType: null,
  unit: 'M',
  distance: null,
  customOffset: 0,
  size: 5,
  categoryIds: [],
}
export const initialMemberState = {
  restaurantName: '',
  restaurantAddress: '',
  email: '',
  mobilePhone: '',
}

export const beMemberIsFocused = {
  restaurantName: false,
  // restaurantAddress: false,
  email: false,
  mobilePhone: false,
}
export const beMemberIsInvalid = {
  restaurantName: false,
  // restaurantAddress: false,
  email: false,
  mobilePhone: false,
}

export const daysOfWeek = [
  generalConstants.MONDAY,
  generalConstants.TUESDAY,
  generalConstants.WEDNESDAY,
  generalConstants.THURSDAY,
  generalConstants.FRIDAY,
  generalConstants.SATURDAY,
  generalConstants.SUNDAY,
]

export const sliderFilters = ['price', 'rating', 'distance']

export const sortFilterLabels = ['store_nickname_s', 'rating', 'high_low', 'low_high']

export const initialAddressQuery = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  default_: true,
  deliveryInstruction: '',
  deliveryOption: deliverySubTypes.DELIVER_DOOR,
  formattedAddress: '',
  latitude: '',
  longitude: '',
  name: '',
  state: '',
  stateName: '',
  zipCode: '',
}

export const bankInfoArr = ['DIRECT_DEPOSIT', 'CHECK']
