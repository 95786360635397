import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { BlackActionButton, BlackButton, RedActionButton, RedButton } from '../../../utils/tools'
import CollapseIcon from '@material-ui/icons/KeyboardArrowUp'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
  },
  edit: {
    marginRight: theme.spacing(6),
  },
  smallButton: {
    margin: theme.spacing(),
    padding: 2,
  },
}))

const SectionTitle = ({
  editting,
  edittingName,
  updateSelection,
  expand,
  showConfirm,
  handleConfirmClose,
  handleDelete,
  section,
  intl,
  setShowConfirm,
  setEditting,
  setEdittingName,
  setExpand,
}) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className='centered-row'>
      <BlackActionButton
        onClick={() => {
          setEditting((prev) => !prev)
          setEdittingName(section.name)
        }}
      >
        {editting ? getLabel('cancel-label') : getLabel('edit-label')}
      </BlackActionButton>

      <TextField
        className={clsx(classes.title, {
          [classes.edit]: !editting,
        })}
        id={`name${section.id}`}
        value={editting ? edittingName : section.name}
        size='small'
        InputProps={{
          readOnly: !editting,
          disableUnderline: !editting,
          endAdornment: editting && (
            <InputAdornment position='end'>
              <Tooltip title={getLabel('cancel-label')}>
                <IconButton size='small' onClick={() => setEditting(false)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={getLabel('save-label')}>
                <IconButton size='small' onClick={updateSelection} disabled={edittingName === section.name}>
                  <SaveIcon color={edittingName !== section.name ? 'primary' : 'inherit'} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          // editting ? setEdittingName(event.target.value) : update(event.target.value)
          setEdittingName(event.target.value)
        }}
      />

      <div className='grow' />

      {editting && <RedActionButton onClick={() => setShowConfirm(true)}>{getLabel('delete-label')}</RedActionButton>}

      <IconButton
        edge='end'
        color='inherit'
        className={classes.smallButton}
        aria-label='service-hours'
        onClick={() => setExpand((prev) => !prev)}
      >
        {expand ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>

      <Dialog
        open={showConfirm}
        onClose={handleConfirmClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='delete-dialog-title'>{getLabel('are-you-sure')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{getLabel('about-delete-section')}</DialogContentText>
          <DialogContentText id='alert-dialog-description'>{section.name}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <BlackButton onClick={handleConfirmClose}>{getLabel('cancel-label')}</BlackButton>
          <RedButton onClick={handleDelete}>{getLabel('delete-label')}</RedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

SectionTitle.propTypes = {
  editting: PropTypes.bool,
  edittingName: PropTypes.string,
  updateSelection: PropTypes.func,
  expand: PropTypes.bool,
  showConfirm: PropTypes.bool,
  handleConfirmClose: PropTypes.func,
  handleDelete: PropTypes.func,
  section: PropTypes.object,
  intl: PropTypes.object,
  setShowConfirm: PropTypes.func,
  setEditting: PropTypes.func,
  setEdittingName: PropTypes.func,
  setExpand: PropTypes.func,
}

export default injectIntl(SectionTitle)
