import React from 'react'
import PropTypes from 'prop-types'
import RowContainer from './RowContainer'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

export const OrderRating = ({ index, order, rateOrder }) => {
  const handleOrderRating = () => rateOrder(order)

  if (index !== 0) return null
  return (
    <RowContainer>
      <Button fullWidth disabled={order.ratingStatus} onClick={handleOrderRating}>
        <FormattedMessage id='rate-your-order' />
      </Button>
    </RowContainer>
  )
}

OrderRating.propTypes = {
  index: PropTypes.number,
  order: PropTypes.object,
  rateOrder: PropTypes.func,
}

export default OrderRating
