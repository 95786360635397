import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { GreenRadio } from '../../../../utils/tools'
import { emailRegex } from '../../../../utils/regex_collections'

import { valueConstants, labelConstants, notificationConstants, emailConstants } from '../../../businessConstants'

const OrderConfirmationEmail = ({
  classes,
  orderConfirmation,
  handleOrderConfirmation,
  values,
  error,
  handleChange,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth>
        {emailConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={
              <GreenRadio checked={orderConfirmation === item} onChange={handleOrderConfirmation} value={item} />
            }
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <TextField
        size='small'
        value={values.orderConfirmationEmail}
        variant='outlined'
        fullWidth
        id={valueConstants.notificationEmail}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants.orderConfirmationEmail })}
        onChange={handleChange(valueConstants.orderConfirmationEmail)}
        required
        error={
          error && orderConfirmation === notificationConstants.otherEmail
            ? values.orderConfirmationEmail.length === 0
              ? true
              : emailRegex.test(values.orderConfirmationEmail)
              ? false
              : true
            : false
        }
        disabled={orderConfirmation !== notificationConstants.otherEmail}
        inputProps={{
          maxLength: 100,
        }}
      />
    </Grid>
  )
}

OrderConfirmationEmail.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  orderConfirmation: PropTypes.string,
  handleOrderConfirmation: PropTypes.func,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default OrderConfirmationEmail
