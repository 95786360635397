import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { merchantActions } from '../../../actions'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import BeMemberDialog from './BeMemberDialog'
import ForgotPasswordDialog from './forgotPasswordDialog'
import EmailField from './signin/EmailField'
import PasswordField from './signin/PasswordField'
import LinksAndButton from './signin/LinksAndButton'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
  textField: {
    marginTop: theme.spacing(3),
  },
}))

const MerchantSignin = () => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()

  const signIn = (email, password) => dispatch(merchantActions.signin(email, password))
  const signedIn = useSelector(({ merchant }) => merchant.signedIn)

  const [showPasswordDialog, setPasswordDialog] = useState(false)
  const [showBeMemberDialog, setShowBeMemberDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState({
    email: false,
    password: false,
  })
  const [values, setValues] = useState({
    email: '',
    password: '',
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
    setIsFocused({ ...isFocused, [name]: true })
  }

  const onFormSubmit = (event) => {
    event.preventDefault()
    const { email, password } = values
    if (email && password) {
      signIn(email, password)
    }
  }

  const togglePaswordDialog = () => setPasswordDialog(!showPasswordDialog)

  const toggleBeMemberDialog = () => setShowBeMemberDialog(!showBeMemberDialog)

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  if (signedIn) {
    return <Redirect to='/merchant' />
  }
  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography variant='h4'>{f({ id: 'merchant-signin' })}</Typography>
        <EmailField email={values.email} isFocused={isFocused.email} classes={classes} handleChange={handleChange} />
        <PasswordField
          showPassword={showPassword}
          password={values.password}
          handleChange={handleChange}
          isFocused={isFocused.password}
          togglePasswordVisibility={togglePasswordVisibility}
        />
        <LinksAndButton
          togglePaswordDialog={togglePaswordDialog}
          toggleBeMemberDialog={toggleBeMemberDialog}
          onFormSubmit={onFormSubmit}
        />
      </div>
      <ForgotPasswordDialog
        showForgotPasswordDialog={showPasswordDialog}
        handleForgotPasswordClose={togglePaswordDialog}
      />
      <BeMemberDialog isOpen={showBeMemberDialog} handleClose={toggleBeMemberDialog} />
    </Container>
  )
}

export default MerchantSignin
