import React from 'react'
import PropTypes from 'prop-types'
import logoText from '../../../../images/logo_text_black.svg'
import close from '../../../../images/close.svg'
import backIcon from '../../../../images/black-back.svg'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  top: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  leftBackButton: {
    top: theme.spacing(2),
    left: theme.spacing(2),
    position: 'absolute',
  },
  rightBackButton: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
  },
}))

export const Header = ({ handleClose }) => {
  const classes = useStyles()
  return (
    <div className={clsx('centered-row', [classes.top])}>
      <IconButton size='small' className={clsx('mobile', [classes.leftBackButton])} onClick={handleClose}>
        <img src={backIcon} alt='' />
      </IconButton>
      <img src={logoText} alt='TookFresh' height='45' className='mobile-logo' />
      <IconButton
        color='inherit'
        className={clsx('desktop', [classes.rightBackButton])}
        onClick={handleClose}
        aria-label='close'
      >
        <img src={close} height='22' width='22' alt='Back' />
      </IconButton>
    </div>
  )
}

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
}
