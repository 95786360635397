import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { useIntl } from 'react-intl'

import { getCroppedImg, getRotatedImage } from './helpers/canvasUtils'
import { styles } from './helpers/styles'
import { generalConstants } from '../../../../constants/general.constants'
import { alertActions } from '../../../../actions/alert.actions'
import Spinner from '../spinner/spinner'
import { merchantStoreActions } from '../../../../actions/merchant.store.actions'
import placeHolder from '../../../../images/logo-bg-green.svg'
import StyledTooltip from './helpers/StyledTooltip'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'

const useStyles = makeStyles(styles)

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const MenuImageUpload = ({ handleCroppedArea, item, sectionId, imageSelected, buttonsClicked }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const isLoading = useSelector(({ merchantStores }) => merchantStores.loading)

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const storeId = selectedStore.storeId

  const error = () => dispatch(alertActions.error('Max file size 5mb'))
  const clear = () => dispatch(alertActions.clear())

  const saveImage = () => dispatch(merchantStoreActions.saveMenuImage(storeId, sectionId, item.id, file))

  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    imageSelected && setImageSrc(null)
  }, [imageSelected])

  useEffect(() => {
    setPreview(false)
  }, [croppedAreaPixels])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = async () => {
    buttonsClicked()
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)
      const blobBin = atob(croppedImage.split(',')[1])
      const array = []
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
      const fileImage = URL.createObjectURL(file)
      setFile(file)
      handleCroppedArea(fileImage)
      setPreview(true)
    } catch (e) {
      console.error(e)
    }
  }

  const toDefault = () => {
    buttonsClicked()
    setRotation(0)
    setZoom(1)
    handleCroppedArea(null)
    setImageSrc(null)
    setPreview(false)
  }

  const onSave = () => {
    toDefault()
    saveImage()
  }

  const onCancel = () => {
    toDefault()
    setImageSrc(null)
  }

  const onError = () => {
    error()
    setTimeout(() => clear(), 5000)
  }

  const onFileChange = async (e) => {
    buttonsClicked()
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.size > generalConstants.MAX_IMAGE_SIZE) {
        return onError()
      }
      let imageDataUrl = await readFile(file)

      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setImageSrc(imageDataUrl)
    }
  }

  const tooltipTitleHandler = () => {
    if (item.images.length >= 3) {
      return 'Maximum image count reached'
    } else if (!preview) {
      return 'Please preview first'
    } else {
      return ''
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.menuImageCropMain}>
        <div className={classes.menuImageCropContainer}>
          {imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              // aspect={2.25} // THIS WILL BE HANDLED IN DIFFERENT BRANCH
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          ) : (
            <div className={classes.placeHolderContainer}>
              <img src={placeHolder} alt='placeholder' />
            </div>
          )}
        </div>

        <div className={classes.cropRightPartMain}>
          <div className={classes.sliderContainer}>
            <Typography variant='overline' classes={{ root: classes.sliderLabel }} style={{ marginRight: 19 }}>
              {f({ id: 'zoom-label' })}
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby='Zoom'
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
              disabled={!imageSrc}
            />
          </div>
          <div className={classes.sliderContainer}>
            <Typography variant='overline' classes={{ root: classes.sliderLabel }}>
              {f({ id: 'rotation-label' })}
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby='Rotation'
              classes={{ root: classes.slider }}
              onChange={(e, rotation) => setRotation(rotation)}
              disabled={!imageSrc}
            />
          </div>
          <div className={classes.cropActionButtons}>
            {!imageSrc ? (
              <>
                <label htmlFor='file-upload' className={classes.menuImageChooseButton}>
                  <Typography className={classes.buttonText} data-content='menu-upload'>
                    {f({ id: 'choose-image' })}
                  </Typography>
                </label>
                <input
                  id='file-upload'
                  type='file'
                  onChange={onFileChange}
                  accept='image/*'
                  className={classes.input}
                />
              </>
            ) : (
              <StyledTooltip title={tooltipTitleHandler()}>
                <span>
                  <Button
                    onClick={onSave}
                    variant='contained'
                    color='primary'
                    classes={{ root: classes.cropButton }}
                    style={{ minWidth: 130 }}
                    data-content='Save'
                    disableRipple
                    disabled={!preview || item.images.length >= 3}
                  >
                    {f({ id: 'save-label' })}
                  </Button>
                </span>
              </StyledTooltip>
            )}
            {imageSrc && (
              <>
                <Button
                  onClick={showCroppedImage}
                  variant='contained'
                  color='primary'
                  classes={{ root: classes.cropButton }}
                  style={{ minWidth: 130 }}
                  data-content='Preview'
                  disableRipple
                >
                  {f({ id: 'preview-label' })}
                </Button>
                <Button
                  onClick={onCancel}
                  variant='contained'
                  color='primary'
                  classes={{ root: classes.cropButton }}
                  style={{ minWidth: 130 }}
                  data-content='Delete'
                  disableRipple
                >
                  {f({ id: 'cancel-label' })}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <Spinner />
      </Backdrop>
    </div>
  )
}

export default MenuImageUpload
