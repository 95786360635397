import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import { FormattedMessage } from 'react-intl'
import { a11yProps } from '../../../../helpers/orders/a11y'
import Footer from '../../Footers'
import clsx from 'clsx'
import useStyles from './styles'
import TabScreen from './mobile-orders/TabScreen'

const MobileOrders = ({
  mobileTabValue,
  handleTabChange,
  upcomingOrders,
  previousOrders,
  handleOrderRating,
  handleOrderReceipt,
}) => {
  const classes = useStyles()

  return (
    <div className={clsx('mobile', classes.mobileRoot)}>
      <div className={classes.header}>
        <Tabs
          className={classes.tabs}
          value={mobileTabValue}
          onChange={handleTabChange}
          indicatorColor='primary'
          variant='fullWidth'
          aria-label='full width order tabs'
        >
          <Tab className={classes.label} label={<FormattedMessage id='previous-label' />} {...a11yProps(0)} />
          <Tab className={classes.label} label={<FormattedMessage id='upcoming-label' />} {...a11yProps(1)} />
        </Tabs>
        <Divider light className={classes.divider} />
      </div>
      <div className={classes.mobileContainer}>
        <TabScreen
          mobileTabValue={mobileTabValue}
          orders={previousOrders}
          i={0}
          handleOrderRating={handleOrderRating}
          handleOrderReceipt={handleOrderReceipt}
        />
        <TabScreen
          mobileTabValue={mobileTabValue}
          orders={upcomingOrders}
          i={1}
          handleOrderRating={handleOrderRating}
          handleOrderReceipt={handleOrderReceipt}
        />
      </div>
      <div className='sticky-bottom'>
        <Footer />
      </div>
    </div>
  )
}

MobileOrders.propTypes = {
  mobileTabValue: PropTypes.number,
  handleTabChange: PropTypes.func,
  upcomingOrders: PropTypes.array,
  previousOrders: PropTypes.array,
  handleOrderRating: PropTypes.func,
  handleOrderReceipt: PropTypes.func,
}

export default MobileOrders
