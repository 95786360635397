import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import notFavoritedIcon from '../../../images/gray-favorite.svg'
import favoritedIcon from '../../../images/green-favorite.svg'
import rate from '../../../images/white-rate.svg'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import priceFilter from '../../../helpers/price-icons'
import backgroundPlaceholder from '../../../images/restaurant-back.jpeg'
import { getImage } from '../../../helpers/get-image'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 275,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: 6,
    height: '100%',
    minHeight: 315,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      minHeight: 221,
      minWidth: 230,
    },
    "&[component='carousel']": {
      minWidth: 397.33,
      maxWidth: 397.33,
    },
  },
  titleContainer: {
    height: 32,
    marginTop: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 9,
    [theme.breakpoints.down('md')]: {
      height: 19,
      marginBottom: 2,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.29,
    },
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  media: {
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
    width: '100%',
    height: 205,
    backgroundColor: '#f4f4f4',
    "&[store-open='false']": {
      opacity: '0.5',
    },
  },
  favorite: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 2,
    right: 10,
    zIndex: 10,
    filter: 'drop-shadow(1px 1px 10px black)',
    "&[store-open='false']": {
      opacity: '0.8',
    },
  },
  rating: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    width: 60,
    height: 30,
    zIndex: 100,
    "&[store-open='false']": {
      opacity: '0.8',
    },
  },
  rateIcon: {
    marginLeft: 5,
  },
  storeRating: {
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    color: '#fff',
    marginLeft: 3,
    marginTop: 5,
  },
  icons: {
    width: 15,
    height: 15,
  },
  queryBuilderIcon: {
    height: 15,
    width: 15,
    marginRight: 5,
    color: 'grey',
  },
  image: {
    height: 13,
    width: 13,
    marginRight: 5,
    opacity: 0.5,
  },
  cardContent: {
    minHeight: 110,
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      minHeight: 72,
    },
  },
  deliveryTime: {
    fontSize: 13,
    lineHeight: 0.92,
    letterSpacing: 0.28,
    textAlign: 'left',
    opacity: 0.9,
    color: '#707070',
    "&[store-open='false']": {
      color: 'rgb(97, 26, 21)',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: 0.67,
      marginTop: 1,
    },
  },
  descriptionContainer: {
    height: 18,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 22,
    [theme.breakpoints.down('md')]: {
      height: 16,
      marginBottom: 10.1,
    },
  },
  descriptionContainerSearch: {
    height: 19,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -3,
    marginBottom: 28,
    [theme.breakpoints.down('md')]: {
      height: 16,
      marginBottom: 10.1,
    },
  },
  description: {
    fontSize: 13,
    lineHeight: 1.85,
    textAlign: 'left',
    color: 'rgba(51, 51, 51, 0.5)',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: 1.25,
    },
  },
}))

export default function StoreItem(props) {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  const rating = props.data.ratingSummary
  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    props.favorites?.some((favorite) => favorite.entityId === props.data.id)
      ? setIsFavorite(true)
      : setIsFavorite(false)
  }, [props.favorites, props.data])
  let storeOpen = props.data.open ? 'true' : 'false'
  return (
    <Card className={classes.root} conponent={props.component}>
      {rating ? (
        <div store-open={storeOpen} className={clsx('centered-row', classes.rating)}>
          <img src={rate} width='20' className={classes.rateIcon} alt='' />
          <Typography className={classes.storeRating}>{rating.averageRating.toFixed(1)}</Typography>
        </div>
      ) : null}
      <IconButton store-open={storeOpen} className={classes.favorite} onClick={() => props.onPress(props.data.id)}>
        <img src={isFavorite ? favoritedIcon : notFavoritedIcon} alt='' />
      </IconButton>
      {props.data && (
        <CardActionArea onClick={() => props.history.push(`${path}/customer/store/${props.data.id}`)}>
          <CardMedia
            className={classes.media}
            store-open={storeOpen}
            image={getImage(props.data.restaurant?.background, 'thumb') || backgroundPlaceholder} //thumb looks enough but can be replaced with medium if needed
            title={props.data.nickname}
          />

          <CardContent className={classes.cardContent}>
            <Box flexGrow={1} className={classes.titleContainer} style={{ overflow: 'hidden' }}>
              <div className='centered-row'>
                <Typography gutterBottom className={classes.title} display='block' noWrap={true}>
                  {props.data?.nickname}
                </Typography>
                <div className='grow' />
                <div style={{ marginTop: 2 }}>{priceFilter(props.data.pricey, 15, false)}</div>
              </div>
            </Box>
            <Box
              flexGrow={1}
              className={props.search ? classes.descriptionContainerSearch : classes.descriptionContainer}
              style={{ overflow: 'hidden' }}
            >
              <Typography className={classes.description} noWrap={true}>
                {props.data.restaurant?.description}
              </Typography>
            </Box>
            <div className='centered-row'>
              {props.data.open ? (
                <>
                  <QueryBuilderIcon className={classes.queryBuilderIcon} />
                  <Typography className={classes.deliveryTime}>
                    {props.search ? `${props.data?.deliveryTime} min` : `${props.data?.deliveryTime} mins`}
                  </Typography>
                </>
              ) : (
                <div store-open={storeOpen} className={classes.deliveryTime}>
                  {format({ id: 'closed-label' })}
                </div>
              )}
              <div className='grow' />
              {/* <img src={delivery} alt='delivery' className={classes.image} />
              <Typography variant='body2' color='textSecondary' component='p' style={{ marginTop: 2 }}>
                {props.data?.deliveryFee} delivery fee
              </Typography> */}
            </div>
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  )
}

StoreItem.propTypes = {
  data: PropTypes.object.isRequired,
}
