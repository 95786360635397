import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SettingsMain from '../settings/SettingsMain'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import StoresMain from '../stores/StoresMain'
import MenusMain from '../menus/MenusMain'
import EmployeesMain from '../employees/EmployeesMain'
import MerchantNoMatch from '../common/MerchantNoMatch'
import OrdersMain from '../orders/OrdersMain'
import { isMobile } from 'react-device-detect'
import RestInfoMain from '../restaurant-info/RestInfoMain'
import CampaignsMain from '../campaigns/CampaignsMain'
import ReviewsMain from '../reviews/ReviewsMain'

const useStyles = makeStyles((theme) => ({
  page: {
    flexGrow: 1,
  },
  content: {
    maxWidth: !isMobile && 1400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: !isMobile && theme.spacing(),
    paddingLeft: !isMobile && theme.spacing(3),
  },
}))

export const MerchantRoutes = ({ classesFromParent, openSwipeable }) => {
  const classes = useStyles()
  const { path } = useRouteMatch()

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <Switch>
          <Route exact path={`${path}/menus/:sub?`}>
            <MenusMain />
          </Route>
          <Route exact path={`${path}/stores/:sub?`}>
            <StoresMain classFromParent={classesFromParent} openSwipeable={openSwipeable} />
          </Route>
          <Route exact path={`${path}/employees/:sub?`}>
            <EmployeesMain />
          </Route>
          <Route exact path={`${path}/orders/:sub?`}>
            <OrdersMain />
          </Route>
          <Route exact path={`${path}/settings/:sub?`} component={SettingsMain} />
          <Route path={`${path}/reviews/:sub?`} component={ReviewsMain} />
          <Route path={`${path}/restaurant-info`} component={RestInfoMain} />
          <Route path={`${path}/campaigns/:sub?`} component={CampaignsMain} />
          <Route path={`${path}/*`} component={MerchantNoMatch} />
        </Switch>
      </div>
    </div>
  )
}

MerchantRoutes.propTypes = {
  classesFromParent: PropTypes.object,
  openSwipeable: PropTypes.bool,
}

export default MerchantRoutes
