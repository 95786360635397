import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CardContent, makeStyles, Typography } from '@material-ui/core'
import deliveryTime from '../../../images/delivery-time.svg'
import priceFilter from '../../../helpers/price-icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingTop: 2,
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  deliveryBlock: {
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.29,
    },
  },
  icon: {
    width: 13.9,
    height: 13.9,
  },
  close: {
    color: 'rgb(97, 26, 21)',
  },
}))

export const StoreDetails = ({ data }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  return (
    <CardContent className={classes.content}>
      <div className={clsx('centered-row', classes.deliveryBlock)} style={{ marginTop: 5 }}>
        <Typography className={clsx('ellipsis', classes.title)}>{data.nickname}</Typography>
        <div className='centered-row'>{priceFilter(data.pricey, 15, false)}</div>
      </div>
      <div className='grow' />

      {/* <div className='centered-row'>
              <Typography variant='body2' color='textSecondary' component='p'>
                {data.deliveryTime}
              </Typography>
              <div className='grow' />
              <Typography variant='body2' color='textSecondary' component='p'>
                {data.deliveryFee}
              </Typography>
            </div> */}
      <div className={clsx('centered-row', classes.deliveryBlock)} style={{ paddingBottom: 8 }}>
        {data.open ? (
          data.deliveryTime !== -1 && (
            <div className='centered-row'>
              <img
                src={deliveryTime}
                alt='Delivery time'
                width='13.9'
                height='13.9'
                className={classes.icon}
                style={{ opacity: 0.5, marginRight: 5 }}
              />
              <Typography variant='body2' color='textSecondary' component='p'>
                {`${data.deliveryTime} mins`}
              </Typography>
            </div>
          )
        ) : (
          <Typography variant='body2' component='p' className={classes.close}>
            {format({ id: 'closed-label' })}
          </Typography>
        )}

        {/* <div className='centered-row'>
              <img
                src={deliveryFee}
                alt='Delivery fee'
                width='20'
                className={classes.icon}
                style={{ opacity: 0.5, marginRight: 5 }}
              />
              <Typography variant='body2' color='textSecondary' component='p'>
                {data.deliveryFee} delivery fee
              </Typography>
            </div> */}
      </div>
    </CardContent>
  )
}

StoreDetails.propTypes = {
  data: PropTypes.object,
}

export default StoreDetails
