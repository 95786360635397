import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1.5px solid #c4c4c4',
    borderRadius: 10,
    height: 48,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  selected: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const PaymentMethods = ({ selectPaymentMethod, paymentSelection }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const buttonsArr = ['Card', 'Paypal', 'ApplePay', 'GooglePay']
  return (
    <Grid container spacing={2} direction='row' justify='center' alignItems='center'>
      {buttonsArr.map((item, index) => (
        <Tooltip key={index} arrow placement='top' title={item !== 'Card' ? f({ id: 'activated-soon' }) : ''}>
          <Grid item xs={3}>
            <Button
              className={clsx({
                [classes.button]: true,
                [classes.selected]: paymentSelection === item,
              })}
              disabled={item !== 'Card'}
              onClick={() => selectPaymentMethod(item)}
            >
              {item}
            </Button>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  )
}

PaymentMethods.propTypes = {
  selectPaymentMethod: PropTypes.func,
  paymentSelection: PropTypes.string,
}

export default PaymentMethods
