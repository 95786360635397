import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import backIcon from '../../../images/black-back.svg'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import InputBase from '@material-ui/core/InputBase'
import { GreenButton } from '../../utils/tools'
import InputMask from 'react-input-mask'
import Loading from '../../common/loading'
import { connect } from 'react-redux'
import { alertActions } from '../../../actions'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 320,
    padding: 0,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  addButton: {
    textTransform: 'capitalize',
    color: '#69e781',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 263,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    //color: '#ffffff',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 73,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 48,
    marginTop: 67,
  },
  StripeElement: {
    padding: '10px 13px 1px 12.2px',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    border: 'solid 0.5px #c4c4c4',
    height: 44,
  },
  StripeZipCodeElement: {
    padding: '0px 13px 0px 12.2px',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    border: 'solid 0.5px #c4c4c4',
    height: 44,
  },
  cardNumberContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 73,
    width: 377,
  },
  cvvContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 73,
    width: 96,
  },
  expirationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 73,
    width: 231,
  },
  zipcodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 73,
    width: 242,
  },
  cvvElement: {
    width: 96,
    height: 44,
  },
  cardNumberElement: {
    width: 377,
    height: 44,
  },
  expirationElement: {
    width: 231,
    height: 44,
  },
  zipCodeElement: {
    width: 242,
    height: 44,
  },
  subHeaderContainer: {
    width: '100%',
    height: 21,
    padding: 0,
    margin: '0px 0px 8px 0px',
  },
  subHeader: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
  },
  zipCodeInput: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '18px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
})

class CustomizedWebAddCard extends React.Component {
  state = { open: false, loading: false, postalCode: '' }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleClose() {
    this.setState({ open: false }, () => {
      this.props.handleClose()
      this.props.handleCardsNum()
    })
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    const { stripe, elements } = this.props

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    this.setState({ loading: true })
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      //   postalCode: this.state.postalCode,
      billing_details: {
        name: 'Jenny Rosen',
      },
    })

    if (result.error) {
      this.props.errorMessage(result.error.message)
      this.setState({ loading: false })
    } else {
      customerService.savePaymentMethod(result.paymentMethod.id).then(
        (res) => {
          if ((res.status = generalConstants.STATUS_OK)) {
            this.props.handleClose()
            this.setState({ loading: false })
            setTimeout(this.props.handleDoneDialog(), 500)
          }
        },
        (err) => {
          this.props.errorMessage(err.response?.data?.error_message)
          this.setState({ loading: false })
        }
      )
    }
  }

  render() {
    const { classes, handleClose } = this.props
    const { loading, postalCode } = this.state
    return (
      <div className={classes.root}>
        <div className='mobile-top-bar'>
          <IconButton size='small' className={classes.back} onClick={() => handleClose()}>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography variant='h6'>{this.getLabel('add-payment')}</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <form onSubmit={this.handleSubmit.bind(this)}>
            <div className={classes.lineContainer} style={{ marginBottom: 12 }}>
              <div className={classes.cardNumberContainer}>
                <div className={classes.subHeaderContainer}>
                  <Typography className={classes.subHeader}>{this.getLabel('card-number')}</Typography>
                </div>
                <div className={classes.cardNumberElement}>
                  <CardNumberElement options={CARD_ELEMENT_OPTIONS} className={classes.StripeElement} />
                </div>
              </div>
              <div className={classes.cvvContainer}>
                <div className={classes.subHeaderContainer}>
                  <Typography className={classes.subHeader}>{this.getLabel('cvv-label')}</Typography>
                </div>
                <div className={classes.cvvElement}>
                  <CardCvcElement options={CARD_ELEMENT_OPTIONS} className={classes.StripeElement} />
                </div>
              </div>
            </div>
            <div className={classes.lineContainer}>
              <div className={classes.expirationContainer}>
                <div className={classes.subHeaderContainer}>
                  <Typography className={classes.subHeader}>{this.getLabel('expiration-label')}</Typography>
                </div>
                <div className={classes.expirationElement}>
                  <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className={classes.StripeElement} />
                </div>
              </div>
              <div className={classes.zipcodeContainer}>
                <div className={classes.subHeaderContainer}>
                  <Typography className={classes.subHeader}>{this.getLabel('zipcode-label')}</Typography>
                </div>
                <div className={classes.zipCodeElement}>
                  <InputMask
                    mask='99999'
                    maskChar=' '
                    value={postalCode}
                    onChange={(event) =>
                      this.setState({
                        postalCode: event.target.value,
                      })
                    }
                  >
                    {() => (
                      <InputBase
                        fullWidth
                        className={classes.StripeZipCodeElement}
                        classes={{ input: classes.zipCodeInput }}
                        placeholder='12345'
                      />
                    )}
                  </InputMask>
                </div>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <GreenButton className={classes.addButton} type='submit'>
                {this.getLabel('add-card')}
              </GreenButton>
            </div>
          </form>
          <Loading open={loading} />
        </div>
      </div>
    )
  }
}

CustomizedWebAddCard.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const actionItemCreators = {
  errorMessage: alertActions.error,
}

export default withStyles(styles)(injectIntl(withRouter(connect(null, actionItemCreators)(CustomizedWebAddCard))))
