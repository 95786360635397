import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, Fab, Grid, makeStyles, ThemeProvider, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { deliveryTypes, initialQuery } from '../../../../constants'
import { injectIntl } from 'react-intl'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { orderActions, searchActions } from '../../../../actions'
import Chip from '@material-ui/core/Chip'

const filterButtonTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#F3F3F3',
    },
    secondary: {
      main: '#69e781',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  filterBar: {
    padding: 4,
    backgroundColor: '#fff',
    borderBottom: '1px solid #f7f7f7',
  },
  margin: {
    margin: theme.spacing(),
    textTransform: 'capitalize',
    wordBreak: 'keep-all',
    boxShadow: 'none',
    '&:focus': {
      boxShadow: 'none',
    },
  },
  filter: {
    fontSize: 12,
  },
  drop: {
    height: 16,
    width: 16,
  },
}))

const Filters = ({ intl, showDialog, handleDialogOpen, resetItems, loading }) => {
  const classes = useStyles()

  const searchQuery = useSelector(({ search }) => search.searchQuery)
  //Actions
  const dispatch = useDispatch()
  const resetSearch = (text) => dispatch(searchActions.resetSearch())
  const setDeliveryType = (text) => dispatch(searchActions.setDeliveryType(text))
  const setDeliverySubType = (subType) => dispatch(orderActions.setDeliverySubTypes(subType))
  const setInstructions = (instructions) => dispatch(orderActions.setInstructions(instructions))
  const toggleOpenFilter = () => dispatch(searchActions.toggleOpenFilter())

  const handleDeliveryTypeChange = () => {
    searchQuery.deliveryType === deliveryTypes.DELIVERY ? handlePickup() : handleDelivery()
    resetItems()
  }

  const handlePickup = () => {
    setDeliveryType(deliveryTypes.TAKEOUT)
    setDeliverySubType('')
    setInstructions('')
  }
  const handleDelivery = () => {
    setDeliveryType(deliveryTypes.DELIVERY)
  }

  const resetFilters = () => {
    resetSearch()
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  // const priceFilterCondition = useMemo(() => showDialog.priceRange || searchQuery.pricey !== initialQuery.pricey, [
  //   showDialog.priceRange,
  //   searchQuery.pricey,
  // ])

  const distanceFilterCondition = useMemo(
    () => showDialog.distanceRange || searchQuery.distance !== initialQuery.distance,
    [showDialog.distanceRange, searchQuery.distance]
  )
  const dietaryFilterCondition = useMemo(
    () => showDialog.dietary || searchQuery.dietaryIds.length !== initialQuery.dietaryIds.length,
    [showDialog.dietary, searchQuery.dietaryIds]
  )
  const sortFilterCondition = useMemo(
    () => showDialog.sortBy || searchQuery.orderByKey !== initialQuery.orderByKey,
    [showDialog.sortBy, searchQuery.orderByKey]
  )

  const openCondition = searchQuery?.onlyOpen

  return (
    <Grid item xs={12} className={classes.filterBar}>
      <div className='scrolling-wrapper-flexbox'>
        <div>
          <ThemeProvider theme={filterButtonTheme}>
            <Fab
              variant='extended'
              color={searchQuery.deliveryType === deliveryTypes.TAKEOUT ? 'secondary' : 'primary'}
              size='small'
              className={classes.margin}
              onClick={handleDeliveryTypeChange}
            >
              <Typography noWrap className={classes.filter}>
                {getLabel('pick-up')}
              </Typography>
            </Fab>
          </ThemeProvider>
        </div>

        <div>
          <ThemeProvider theme={filterButtonTheme}>
            <Fab
              variant='extended'
              color={distanceFilterCondition ? 'secondary' : 'primary'}
              size='small'
              className={classes.margin}
              onClick={() => handleDialogOpen('distanceRange')}
            >
              <Typography noWrap className={classes.filter}>
                {searchQuery.distance} mi
              </Typography>
              <KeyboardArrowDown className={classes.drop} />
            </Fab>
          </ThemeProvider>
        </div>

        <div>
          <ThemeProvider theme={filterButtonTheme}>
            <Fab
              variant='extended'
              color={dietaryFilterCondition ? 'secondary' : 'primary'}
              size='small'
              className={classes.margin}
              onClick={() => handleDialogOpen('dietary')}
            >
              <Typography noWrap className={classes.filter}>
                {getLabel('dietary-label')}
              </Typography>
              <KeyboardArrowDown className={classes.drop} />
            </Fab>
          </ThemeProvider>
        </div>

        <div>
          <ThemeProvider theme={filterButtonTheme}>
            <Fab
              variant='extended'
              color={openCondition ? 'secondary' : 'primary'}
              size='small'
              onClick={toggleOpenFilter}
              disabled={loading}
              className={classes.margin}
            >
              <Typography noWrap className={classes.filter}>
                {getLabel('only-open-label')}
              </Typography>
            </Fab>
          </ThemeProvider>
        </div>
        <div>
          <ThemeProvider theme={filterButtonTheme}>
            <Fab
              variant='extended'
              color={sortFilterCondition ? 'secondary' : 'primary'}
              size='small'
              className={classes.margin}
              onClick={() => handleDialogOpen('sortBy')}
            >
              <Typography noWrap className={classes.filter}>
                {getLabel('sort-label')}
              </Typography>
              <KeyboardArrowDown className={classes.drop} />
            </Fab>
          </ThemeProvider>
        </div>
        <div>
          <Chip
            clickable
            variant='outlined'
            className={classes.margin}
            color='primary'
            label='Reset'
            onClick={resetFilters}
          />
        </div>
      </div>
    </Grid>
  )
}

Filters.propTypes = {
  intl: PropTypes.object,
  showDialog: PropTypes.object,
  handleDialogOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default injectIntl(Filters)
