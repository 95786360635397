import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import yesIcon from '../../../../../images/yes.svg'

const innerHeight = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
  },
  row: {
    height: 70,
    borderBottom: '1px solid #f7f7f7',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingBottom: theme.spacing(5),
    overflowY: 'scroll',
    height: innerHeight - 280,
    scrollBehavior: 'smooth',
    // flex: "1 50 auto",
  },
  primary: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(),
    width: 16,
    height: 16,
  },
  noAddressText: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    color: '#c4c4c4',
  },
}))

const SavedAddresses = ({ addresses, selectAddress, handleDeleteAddress }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {addresses?.length > 0 ? (
          addresses
            .sort((a, b) => b.default_ - a.default_)
            .map((address, index) => (
              <div className={classes.row} key={index}>
                <div className={classes.subRow} onClick={(e) => selectAddress(e, address)}>
                  <div className='column'>
                    <Typography variant='body1' className={classes.name}>
                      {`${address.name}`}
                    </Typography>
                    <Typography variant='body1' color={address.default_ ? 'textPrimary' : 'textSecondary'}>
                      {`${address.addressLine1} ${address.addressLine2}`}
                    </Typography>
                    <Typography
                      variant='body1'
                      color={address.default_ ? 'textPrimary' : 'textSecondary'}
                    >{`${address.city}, ${address.state} ${address.zipCode}`}</Typography>
                  </div>
                  <div className='grow' />
                  <div className='centered-row'>
                    {address.default_ && (
                      // <Typography className={classes.primary}>{getLabel('default-label')}</Typography>
                      <img src={yesIcon} alt='yes' className={classes.primary} />
                    )}
                  </div>
                </div>
                <div className='column' style={{ width: 30 }}>
                  <IconButton onClick={() => handleDeleteAddress(address)} className={classes.deleteButton}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))
        ) : (
          <Typography className={classes.noAddressText}>{f({ id: 'no-saved-addresses' })}</Typography>
        )}
      </div>
    </div>
  )
}

SavedAddresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  selectAddress: PropTypes.func.isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
}

export default SavedAddresses
