import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { history } from '../../../helpers/history'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles({
  root: {
    width: '100%',
    minWidth: 275,
    backgroundColor: '#ffffff',
    // borderRadius: 6,
    boxShadow: 'none',
  },
  media: {
    height: 250,
  },
})

export default function LocalFavorite(props) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => history.push(`${path}/customer/store/${props.data.id}`)}>
        {props.data.restaurant.background ? (
          <CardMedia
            className={classes.media}
            image={
              props.data.restaurant.background.path +
              '/big/' +
              props.data.restaurant.background.filename +
              '.' +
              props.data.restaurant.background.extension
            }
            title={props.data.nickname}
          />
        ) : (
          <div className={classes.media}></div>
        )}
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {props.data.nickname}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {props.data.restaurant.description}
          </Typography>
          <div className='centered-row'>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.data.deliveryFee} Delivey Fee
            </Typography>
            <div style={{ margin: '0px 3px 7px 3px' }}>
              <Typography variant='h6' color='textSecondary' component='p'>
                .
              </Typography>
            </div>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.data.deliveryTime}
            </Typography>
            <div className='grow' />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

LocalFavorite.propTypes = {
  data: PropTypes.object.isRequired,
}
