import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import TopNavbar from '../../top_navbar'
import plates from '../../../../images/plates.png'
import deliveryPickup from '../../../../images/delivery-pickup.png'
import LocalFavorite from '../local_favorite'
import AddressPopup from '../../../common/addressPopup/addressPopup'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Footer from '../../Footers'
import clsx from 'clsx'
import { useToggle } from '../../../../hooks/useToggle'
import BeCustomer from '../../../common/BeCustomer'

const BeMemberDialog = lazy(() => import('../../../merchant/authentication/BeMemberDialog'))

const useStyles = makeStyles((theme) => ({
  addressBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addressColumn: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 578,
    },
  },
  favorite: {
    fontSize: 80,
    [theme.breakpoints.down('md')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('xs')]: {
      color: '#fff',
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
  },
  inputMain: {
    minWidth: 400,
    maxWidth: 400,
    position: 'relative',
    zIndex: 100,
  },
  inputContainer: {
    maxWidth: 400,
    minWidth: 400,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: 5,
    flexGrow: 1,
    borderBottomLeftRadius: 5,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    height: 50,
  },
  adornment: {
    minWidth: 50,
    maxWidth: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  platesBanner: {
    position: 'relative',
    height: 500,
    [theme.breakpoints.up('lg')]: {
      height: 700,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  plates: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${plates})`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    marginBottom: 20,
  },
  local: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(3),
  },
  pickupDelivery: {
    marginTop: 40,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 108,
    },
  },
  pickDeliveryDesc: {
    fontSize: 20,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.45,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  deliveryImage: {
    marginTop: 40,
    backgroundImage: `url(${deliveryPickup})`,
    position: 'relative',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}))

const DesktopPage = ({ intl, locals }) => {
  const classes = useStyles()

  const [showBeMemberDialog, setShowBeMemberDialog] = useToggle()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={false} />
        <Grid container className='full-width'>
          <Grid item xs={12} md={6} lg={5} className={classes.addressBanner}>
            <div className={classes.addressColumn}>
              <Typography className={classes.favorite}>{getLabel('favorable-restaurants')}</Typography>
              <div className={clsx('centered-row', classes.inputMain)}>
                <div className={classes.inputContainer}>
                  <AddressPopup welcomePage />
                </div>
                <div className={classes.adornment}>
                  <LocationOnIcon />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={7} className={classes.platesBanner}>
            <div className={classes.plates} />
          </Grid>
        </Grid>
        {locals?.length > 0 && (
          <Grid container className='full-width' style={{ minHeight: 660 }}>
            <Grid item xs={12} className={classes.local}>
              <Typography className={classes.title}>{getLabel('local-favorites')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {locals.map((local) => (
                  <Grid item sm={4} xs={12} key={local.id}>
                    <LocalFavorite data={local} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container className='full-width'>
          <Grid item xs={4}>
            <BeCustomer title='become-driver' description='become-driver-desc' buttonTitle='start-earning' />
          </Grid>
          <Grid item xs={4}>
            <BeCustomer title='become-partner' description='become-partner-desc' buttonTitle='signup-store' />
          </Grid>
          <Grid item xs={4}>
            <BeCustomer title='try-app' description='try-app-desc' buttonTitle='get-app' />
          </Grid>
          <Grid item xs={6} className={classes.pickupDelivery}>
            <div className='column'>
              <Typography className={classes.title}>{getLabel('pickup-delivery')}</Typography>
              <Typography className={classes.pickDeliveryDesc}>{getLabel('pickup-delivery-desc')}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.deliveryImage}></Grid>
        </Grid>
      </Container>
      <div style={{ width: '100%' }}>
        <Footer />
      </div>
      <Suspense fallback={<div></div>}>
        <BeMemberDialog isOpen={showBeMemberDialog} handleClose={setShowBeMemberDialog} />
      </Suspense>
    </>
  )
}

DesktopPage.propTypes = {
  locals: PropTypes.array,
  intl: PropTypes.object,
}

export default injectIntl(DesktopPage)
