import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import becomeDriver from '../../images/become-driver.svg'
import becomePartner from '../../images/become-partner.svg'
import tryApp from '../../images/try-app.svg'

const useStyles = makeStyles((theme) => ({
  col: {
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgCol: {
    height: 300,
    minHeight: 200,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  desc: {
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#707070',
    marginBottom: 20,
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    marginBottom: 20,
  },
  action: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
  },
}))

const relatedImages = {
  'become-driver': becomeDriver,
  'become-partner': becomePartner,
  'try-app': tryApp,
}
const BeCustomer = ({ title, description, buttonTitle }) => {
  const classes = useStyles()

  const getRelatedImage = (txt) => relatedImages[txt]
  return (
    <div className={classes.col}>
      <div className={classes.imgCol}>
        <img src={getRelatedImage(title)} alt={title} />
      </div>
      <Typography className={classes.title}>
        <FormattedMessage id={title} />
      </Typography>
      <Typography className={classes.desc} align='center'>
        <FormattedMessage id={description} />
      </Typography>
      <Button className={classes.action} endIcon={<ArrowForward />}>
        <FormattedMessage id={buttonTitle} />
      </Button>
    </div>
  )
}

BeCustomer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  // callback: PropTypes.func.isRequired,
}

export default React.memo(BeCustomer)
