import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import HomeMarkerComponent from './HomeMarker'
import MarkerComponent from './Marker'
import deliveryTime from '../../../../images/delivery-time.svg'
import rate from '../../../../images/rate.svg'
import addressIcon from '../../../../images/store-address-more.svg'
import clsx from 'clsx'
import priceFilter from '../../../../helpers/price-icons'
import { getImage } from '../../../../helpers/get-image'

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY

function StoreMoreHeader({ store, classes, center, zoom, onMapChange, searchQuery }) {
  return (
    <Grid container>
      <Grid item xs={12} className={classes.banner} style={{ position: 'relative' }}>
        <div className={clsx('column', [classes.banner])}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={center}
            zoom={zoom}
            options={{ fullscreenControl: false, zoomControl: false }}
            onChange={onMapChange}
          >
            <HomeMarkerComponent lat={searchQuery.address.latitude} lng={searchQuery.address.longitude} />
            <MarkerComponent
              lat={store.storeInformation.address.latitude}
              lng={store.storeInformation.address.longitude}
              data={store}
            />
          </GoogleMapReact>
        </div>
        <div style={{ position: 'absolute', bottom: 0 }}>
          <div className={clsx('row', [classes.logoRow])}>
            {store?.restaurant?.logo && (
              <Box boxShadow={2} className={classes.paper}>
                <img src={getImage(store?.restaurant?.logo, 'thumb')} alt='restaurant-logo' height='122' width='122' />
              </Box>
            )}
            <div className={clsx('column', [classes.storeDetails])}>
              {store.ratingSummary && (
                <div className='centered-row'>
                  <img src={rate} alt='Rate' width='20' className={classes.icon} />
                  <Typography className={classes.storeDetail}>
                    {store.ratingSummary.averageRating?.toFixed(1)} ({store.ratingSummary.totalRatingCount})
                  </Typography>
                </div>
              )}
              <div className='centered-row'>
                <img src={deliveryTime} alt='Delivery time' width='20' className={classes.icon} />
                <Typography className={classes.storeDetail}>{store.deliveryTime} mins</Typography>
              </div>
              {/* <div className='centered-row'>
                <img src={deliveryFee} alt='Delivery fee' width='20' className={classes.icon} />
                <Typography className={classes.storeDetail}>{store.deliveryFee}</Typography>
              </div> */}
              <div className='centered-row' style={{ marginLeft: -2.6 }}>
                {priceFilter(store.pricey, 20, false)}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.nameRow}>
        <Typography className={clsx(classes.storeName, { [classes.storeNameLong]: store.nickname.length > 27 })} noWrap>
          {store.nickname}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.addressRow}>
        <div className='row'>
          <img src={addressIcon} alt='' width='13' height='16' style={{ marginRight: 5 }} />
          <Typography variant='h6'>{store.storeInformation.address.formattedAddress}</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

StoreMoreHeader.propTypes = {
  searchQuery: PropTypes.object,
  onMapChange: PropTypes.func,
  zoom: PropTypes.number,
  center: PropTypes.object,
  classes: PropTypes.object,
  store: PropTypes.object,
}

export default StoreMoreHeader
