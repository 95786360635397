import React from 'react'
import ImageHeader from './mobile-order-detail/ImageHeader'
import StatusDetail from './mobile-order-detail/StatusDetail'
import OrderDetails from './mobile-order-detail/OrderDetails'
import DeliveryDetails from './mobile-order-detail/DeliveryDetails'
import PriceRow from './mobile-order-detail/PriceRow'
import Grid from '@material-ui/core/Grid'
import { width } from '../../../../../helpers'
import OrderRating from './mobile-order-detail/OrderRating'
import PropTypes from 'prop-types'
import RowContainer from './mobile-order-detail/RowContainer'
import { deliveryTypes } from '../../../../../constants'

const MobileOrderDetail = ({ index, orders, rateOrder, handleOrderReceipt }) => (
  <div className='column'>
    {orders.map((order, i) => (
      <Grid key={i} container width={width}>
        <ImageHeader order={order} />
        <StatusDetail order={order} index={index} />
        <OrderRating index={index} order={order} rateOrder={rateOrder} />
        <OrderDetails order={order} />
        {order.orderType === deliveryTypes.DELIVERY && (
          <RowContainer>
            <DeliveryDetails order={order} />
          </RowContainer>
        )}
        <PriceRow order={order} handleOrderReceipt={handleOrderReceipt} />
      </Grid>
    ))}
  </div>
)

MobileOrderDetail.propTypes = {
  index: PropTypes.number,
  orders: PropTypes.array,
  rateOrder: PropTypes.func,
  handleOrderReceipt: PropTypes.func,
}

export default MobileOrderDetail
