import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { categoryActions, dietaryActions } from '../../../actions'
import queryString from 'query-string'
import { initialMenu } from '../../../constants'
import { makeStyles } from '@material-ui/core'
import MenuTextFields from './common/MenuTextFields'
import Categories from './common/Categories'
import Dietaries from './common/Dietaries'
import ServingType from './common/ServingType'
import MenuSize from './common/MenuSize'
import MenuPrice from './common/MenuPrice'
import clsx from 'clsx'
import ActionButtons from './common/ActionButtons'
import { categoryChangeHandler, dietaryChangeHandler } from '../../../helpers/merchant/menu-section-item'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
  },
  actionButtonsContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,
  },
}))

const AddMenuSectionItem = ({ location, history, intl }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  const values = useMemo(() => queryString.parse(location.search), [location.search])
  //Global states
  const categories = useSelector(({ categories }) => categories.data)
  const dietaries = useSelector(({ dietaries }) => dietaries.data)
  const sections = useSelector(({ merchantStores }) => merchantStores.sections)
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  //Local states
  const [sectionName, setSectionName] = useState('')
  const [menu, setMenu] = useState(initialMenu)
  //Actions
  const dispatch = useDispatch()
  const getCategories = () => dispatch(categoryActions.getAll())
  const getDietaries = () => dispatch(dietaryActions.getAll())
  const createNewMenu = (storeId, sectionId, menu) =>
    dispatch(merchantStoreActions.createMenu(storeId, sectionId, menu))

  useEffect(() => {
    getCategories()
    getDietaries()
    const name = sections?.find((i) => i.id === +values.sectionid).name
    setSectionName(name)
    // eslint-disable-next-line
  }, [])

  const onFormSubmit = (event) => {
    event.preventDefault()
    let menu_ = menu
    const storeId = selectedStore.storeId
    createNewMenu(storeId, values.sectionid, menu_)
    history.goBack()
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    let newState = { ...menu, [name]: value }
    setMenu(newState)
  }

  const handleCategoryChange = (event) => {
    const newState = categoryChangeHandler(event, menu)
    setMenu(newState)
  }

  const handleDietaryChange = (event) => {
    const newState = dietaryChangeHandler(event, menu)
    setMenu(newState)
  }

  const handleTypeChange = (event) => {
    let val = event.target.value
    let newState = { ...menu, servingType: val }
    setMenu(newState)
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <div className='page-header' style={{ marginTop: 70 }}>
        <Typography variant='h4' style={{ margin: 10 }}>
          {getLabel('add-food-under')} {sectionName}
        </Typography>
      </div>
      <form onSubmit={onFormSubmit}>
        <div className='row'>
          <Grid container spacing={2} className='full-width'>
            <MenuTextFields menu={menu} handleChange={handleChange} />
            <Categories categories={categories} menu={menu} handleChange={handleCategoryChange} />
            <Dietaries dietaries={dietaries} menu={menu} handleDietaryChange={handleDietaryChange} />
            <ServingType menu={menu} handleTypeChange={handleTypeChange} />
            <MenuSize menu={menu} handleChange={handleChange} />
            <MenuPrice menu={menu} handleChange={handleChange} />
          </Grid>
        </div>
        <div className={classes.actionButtonsContainer}>
          <ActionButtons menu={menu} history={history} />
        </div>
      </form>
    </div>
  )
}

AddMenuSectionItem.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
}

export default withRouter(injectIntl(AddMenuSectionItem))
