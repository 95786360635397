import { customerConstants } from '../constants'

let localCustomerData = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER))

const initialState = {
  loading: false,
  error: null,
  signedIn: false,
  gettingAddress: false, // This state is added to manage address popup appearance
  customerInfo: localCustomerData ? localCustomerData : {},
  latitude: '',
  longitude: '',
  savedAddresses: [],
  // favoriteStores: initialPagableList,
  favoriteStores: [],
  defaultCard: null,
}

export function customer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
        gettingAddress: true,
      }
    case customerConstants.SIGNIN_SUCCESS:
      return {
        ...state,
        signedIn: true,
        loading: false,
        customerInfo: action.customer,
        error: null,
      }
    case customerConstants.CHANGE_ADDRESS_STATE:
      return {
        ...state,
        gettingAddress: false,
      }
    case customerConstants.SIGNIN_FAILURE:
      return {
        ...state,
        signedIn: false,
        customerInfo: {},
        gettingAddress: false,
        error: action.error,
        loading: false,
      }
    case customerConstants.SIGNOUT:
      return {
        ...state,
        signedIn: false,
        customerInfo: {},
        savedAddresses: [],
        // favoriteStores: initialPagableList,
        favoriteStores: [],
      }
    case customerConstants.SET_GEOLOCATION:
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
      }
    case customerConstants.GETALL_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case customerConstants.GETALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        savedAddresses: action.addresses,
      }
    case customerConstants.GETALL_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case customerConstants.CREATE_ADDRESS_SUCCESS:
      if (action.address.default_) {
        let oldDefault = state.savedAddresses.find((i) => i.default_)
        if (oldDefault) oldDefault.default_ = false
      }
      return {
        ...state,
        loading: false,
        error: null,
        savedAddresses: [...state.savedAddresses, action.address],
      }
    case customerConstants.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        savedAddresses: [...state.savedAddresses].filter((x) => x.id !== action.id),
      }
    case customerConstants.UPDATE_ADDRESS_SUCCESS:
      let newAddressArr = state.savedAddresses.filter((address) => address.id !== action.address.id)
      if (action.address.default_) {
        let oldDefault = newAddressArr.find((i) => i.default_)
        if (oldDefault) oldDefault.default_ = false
      }
      return {
        ...state,
        loading: false,
        error: null,
        savedAddresses: [...newAddressArr, action.address],
      }
    case customerConstants.SAVE_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        favoriteStores: [...state.favoriteStores, action.data],
      }
    case customerConstants.SAVE_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case customerConstants.SAVE_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case customerConstants.GETALL_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        favoriteStores: action.stores,
      }
    case customerConstants.GETALL_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case customerConstants.GETALL_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case customerConstants.DELETE_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        favoriteStores: state.favoriteStores.filter((store) => store.id !== +action.storeId),
        // favoriteStores: {
        //   ...state.favoriteStores,
        //   content: [...state.favoriteStores.content].filter((x) => x.id !== action.storeId),
        //   numberOfElements: state.favoriteStores.numberOfElements - 1,
        // },
      }
    case customerConstants.DELETE_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case customerConstants.DELETE_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case customerConstants.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          emailVerified: true,
        },
      }
    case customerConstants.PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          phoneVerified: true,
        },
      }
    case customerConstants.EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          email: action.payload.email,
          emailVerified: action.payload.emailVerified,
        },
      }
    case customerConstants.PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          phone: action.payload.phone,
          phoneVerified: action.payload.phoneVerified,
        },
      }
    case customerConstants.NAME_UPDATE_SUCCESS:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      }
    case customerConstants.DEFAULT_CARD_CHANGE:
      return {
        ...state,
        defaultCard: action.payload,
      }
    default:
      return state
  }
}
