import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import MobileMenuItem from '../mobile_menu_item'
import IntersectionVisible from 'react-intersection-visible'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  scrollView: {
    marginBottom: 60,
  },
  container: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
}))

const Content = React.forwardRef(
  (
    {
      handleScrollMenus,
      storeId,
      isMenuNotEmpty,
      onMenuItemShow,
      onMenuItemHide,
      handleMenuItemClick,
      cart,
      menus,
      myRef,
      sections,
    },
    ref
  ) => {
    const classes = useStyles()

    const getAlreadyOrderedCount = (id) => {
      let totalCount = 0
      const relatedMenus = menus.filter((i) => i.menuId === id)
      relatedMenus.forEach((menu) => {
        totalCount += menu.quantity
      })
      return totalCount
    }

    return (
      <Grid
        container
        // className={classes.container}
        onScroll={handleScrollMenus}
        className={clsx('vertical-scrolling-wrapper-flexbox', isMenuNotEmpty && classes.scrollView)}
      >
        {sections?.map((section) => {
          return section?.menus
            .filter((m) => m.active)
            ?.map((menu, index) => {
              return (
                <Grid
                  item
                  className={classes.menuItem}
                  key={menu.id}
                  ref={index === 0 ? myRef?.current[`${section.id}_ref`] : null}
                >
                  <IntersectionVisible
                    onShow={() => onMenuItemShow(menu, section.id)}
                    onHide={() => onMenuItemHide(menu, section.id)}
                  >
                    <MobileMenuItem
                      data={menu}
                      handleClick={() => handleMenuItemClick(menu)}
                      menuItemstoreId={cart.storeId === storeId}
                      alreadyOrderedCount={getAlreadyOrderedCount(menu.id) || 0}
                    />
                  </IntersectionVisible>
                </Grid>
              )
            })
        })}
      </Grid>
    )
  }
)

Content.propTypes = {
  handleScrollMenus: PropTypes.func,
  storeId: PropTypes.number,
  isMenuNotEmpty: PropTypes.bool,
  onMenuItemShow: PropTypes.func,
  onMenuItemHide: PropTypes.func,
  handleMenuItemClick: PropTypes.func,
  cart: PropTypes.object,
  menus: PropTypes.array,
  myRef: PropTypes.object,
}

export default Content
