import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Footer from '../Footers'
import { searchService } from '../../../services'
import { generalConstants } from '../../../constants'
import { isThereAnyDifference } from '../../../helpers/mobile-food/filter-change'
import { fetchMoreCheck } from '../../../helpers/search'
import { favoriteStoreCheck } from '../../../helpers/favorite-store'
import Loading from '../../common/loading'
import { showDialogInitialState } from '../../../constants/mobile-food/mobile-food'
import { stateMaker } from '../../../helpers/mobile-food/state-maker'
import { Header } from './mobile-food/Header'
import Filters from './mobile-food/Filters'
import CategoryBar from './mobile-food/CategoryBar'
import Content from './mobile-food/Content'
import MFDialogs from './mobile-food/MFDialogs'
import AddressDialog from '../../common/AddressDialog'
import { customerActions, campaignActions } from '../../../actions'

const topHeight = 200
const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  top: {
    height: topHeight,
  },
  sectionTitleRow: {
    padding: theme.spacing(),
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.58,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#69e781',
  },
  seeAll: {
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.58,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
}))

export const MobileFood = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  //Global states
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const addressLoading = useSelector(({ customer }) => customer.gettingAddress)
  const signinError = useSelector(({ customer }) => customer.error)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const favoriteStores = useSelector(({ customer }) => customer.favoriteStores, shallowEqual)
  const globalLoading = useSelector(({ customer }) => customer.loading)
  const campaigns = useSelector(({ campaigns }) => campaigns.data, shallowEqual)
  const campaignLoading = useSelector(({ campaigns }) => campaigns.loading)

  //Actions
  const saveFavorite = (id) => dispatch(customerActions.saveFavoriteStore(id))
  const deleteFavorite = (id) => dispatch(customerActions.deleteFavoriteStore(id))
  const getCampaigns = () => dispatch(campaignActions.getAllCampaigns())

  const changed = useMemo(() => {
    return isThereAnyDifference(searchQuery)
  }, [JSON.stringify(searchQuery)])

  //Local states
  const [showDialog, setShowDialog] = useState(showDialogInitialState)
  const [searchResult, setSearchResult] = useState()
  const [showLogin, setShowLogin] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false) //setMainFilters
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState()
  const [customOffset, setCustomOffset] = useState(0)
  const [showAddressDialog, setShowAddressDialog] = useState(false)
  const [incrementFetch, setIncrementFetch] = useState(0)
  const mountedRef = useRef(false)

  useEffect(() => {
    const newState = stateMaker(searchQuery)
    setQuery(newState)
    setCustomOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchQuery)])

  useEffect(() => {
    if (searchQuery.address && (query || changed || incrementFetch)) {
      mountedRef.current = true
      doSearch(mountedRef.current)
    }
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query), incrementFetch])

  useEffect(() => {
    if (!addressLoading && !searchQuery.address) {
      toggleAddressDialog()
    }
    if (signinError) {
      setLoading(false)
      // setShowLogin(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchQuery.address), addressLoading, signinError])

  useEffect(() => {
    searchQuery.address && getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchQuery.address)])

  const doSearch = (sub) => {
    setLoading(true)
    const searchParams = { ...query, customOffset }
    searchService.search(searchParams).then(
      (res) => {
        if (sub && res.status === generalConstants.STATUS_OK) {
          customOffset === 0 ? setSearchResult(res.data.stores) : setSearchResult([...searchResult, ...res.data.stores])
          setCustomOffset(res.data.customOffset)
        }
      },
      (err) => console.log('err--- doSearch', err.response)
    )
    setLoading(false)
  }

  const handleDialogClose = (text) => {
    setShowDialog({ ...showDialog, [text]: false })
  }

  const handleDialogOpen = (text) => {
    setShowDialog({ ...showDialog, [text]: true })
  }

  const handleMainFilterDialogClose = (value) => {
    //value is true after setFilters triggered.
    value && setFiltersApplied(value)
    // if filtersApplied is set to true, needs to be set to false immediately in order to
    // apply button works correctly
    setShowDialog({ ...showDialog, filter: false })
    setReset(false)
    setFiltersApplied(false)
  }

  const favoriteHandler = (id) => {
    if (!signedIn) {
      setShowLogin(true)
      return
    }
    const { includes, storeId } = favoriteStoreCheck(id, favoriteStores)
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  const fetchMoreData = () => {
    const { size } = query
    const fetchMore = fetchMoreCheck(size, customOffset, searchResult)
    if (!fetchMore) return
    setIncrementFetch((prev) => prev + 1)
    // doSearch()
  }

  const handleLoginDialogClose = () => setShowLogin(false)

  const handleSignin = () => {
    setShowLogin(false)
    // history.push(`${path}/customer/food`)
  }

  const resetItems = () => {
    setSearchResult()
    setCustomOffset(0)
  }

  const toggleAddressDialog = () => setShowAddressDialog((prev) => !prev)

  if (!addressLoading && !searchQuery.address) {
    return <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
  }
  return (
    <div className={classes.root}>
      <Loading open={loading || globalLoading || campaignLoading} />
      <Grid container className={classes.top}>
        <Header handleDialogOpen={handleDialogOpen} />
        <Filters showDialog={showDialog} handleDialogOpen={handleDialogOpen} resetItems={resetItems} />
        <CategoryBar resetItems={resetItems} />
      </Grid>
      <Content
        changed={changed}
        campaignTypes={campaigns}
        searchResult={searchResult}
        fetchMoreData={fetchMoreData}
        favoriteHandler={favoriteHandler}
        favoriteStores={favoriteStores}
      />
      <div className='sticky-bottom'>
        <Footer />
      </div>
      <MFDialogs
        showDialog={showDialog}
        handleDialogClose={handleDialogClose}
        toggleAddressDialog={toggleAddressDialog}
        showAddressDialog={showAddressDialog}
        resetItems={resetItems}
        handleMainFilterDialogClose={handleMainFilterDialogClose}
        showLogin={showLogin}
        handleLoginDialogClose={handleLoginDialogClose}
        handleSignin={handleSignin}
      />
    </div>
  )
}

export default MobileFood
