const obj = {
  store_nickname_s: {
    value: 'store_nickname_s',
    order: 'asc',
    combined: 'store_nickname_sasc',
  },
  rating: {
    value: 'rating',
    order: 'desc',
    combined: 'ratingdesc',
  },
  high_low: {
    value: 'pricey_i',
    order: 'desc',
    combined: 'pricey_idesc',
  },
  low_high: {
    value: 'pricey_i',
    order: 'asc',
    combined: 'pricey_iasc',
  },
}

const parameters = (param) => obj[param]

export { parameters }
