import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import IntersectionVisible from 'react-intersection-visible'
import MobileStoreItem from '../../mobile_store_item'
import { useSelector } from 'react-redux'
import MobileNoResult from '../../../../common/MobileNoResult'

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  item: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
  scrollView: {
    marginBottom: 60,
  },
}))

const AvailableStores = ({ searchResult, category, favoriteHandler, favoriteStores, fetchMore, showNoResult }) => {
  const classes = useStyles()
  const orderMenus = useSelector(({ orders }) => orders.cart.orderMenus)
  return (
    <div className={classes.content}>
      <div
        className={clsx(
          'vertical-scrolling-wrapper-flexbox',
          searchResult.length > 0 && orderMenus.length > 0 && classes.scrollView
        )}
      >
        {showNoResult && (!searchResult || searchResult.length === 0) && <MobileNoResult />}
        {searchResult?.map((store, index) => (
          <div className={classes.item} key={store.id}>
            <IntersectionVisible onShow={() => index === searchResult.length - 1 && fetchMore()}>
              <MobileStoreItem data={store} category={category} onPress={favoriteHandler} favorites={favoriteStores} />
            </IntersectionVisible>
          </div>
        ))}
      </div>
    </div>
  )
}

AvailableStores.propTypes = {
  searchResult: PropTypes.array.isRequired,
  category: PropTypes.object,
  favoriteHandler: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  favoriteStores: PropTypes.array.isRequired,
}

export default AvailableStores
