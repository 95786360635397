import React from 'react'
import ContentLoader from 'react-content-loader'

const CommissionTextCL = () => {
  return (
    <ContentLoader viewBox='0 0 300 100'>
      <rect x='25' y='15' rx='4' ry='4' width='240' height='10' />
      <rect x='40' y='35' rx='4' ry='4' width='210' height='10' />
    </ContentLoader>
  )
}

export default CommissionTextCL
