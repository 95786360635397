import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import RowContainer from './RowContainer'

const PriceRow = ({ order, handleOrderReceipt }) => {
  const classes = useStyles()

  const showReceiptHandler = () => handleOrderReceipt(order)

  return (
    <RowContainer>
      <Grid item xs={8} container justify='space-evenly' direction='column' style={{ marginRight: 10 }}>
        <Grid container justify='space-between' direction='row'>
          <Typography variant='caption'>
            <FormattedMessage id='tax-label' />
          </Typography>
          <Typography variant='caption'>${order.taxPrice}</Typography>
        </Grid>

        <Grid container justify='space-between' direction='row'>
          <Typography variant='body1' className={classes.total}>
            <FormattedMessage id='total-label' />
          </Typography>
          <Typography variant='body1' className={classes.total}>
            ${order.totalPrice}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} container justify='center'>
        <Button
          className={classes.receiptButton}
          variant='contained'
          color='primary'
          size='large'
          onClick={showReceiptHandler}
        >
          <FormattedMessage id='receipt-label' />
        </Button>
      </Grid>
    </RowContainer>
  )
}

PriceRow.propTypes = {
  order: PropTypes.object,
  handleOrderReceipt: PropTypes.func,
}

export default React.memo(PriceRow)
