import { orderConstants, deliveryTypes, deliverySubTypes, paymentMethods } from '../constants'
import { deepCheck } from '../helpers/redux/addMenu/deepCheck'
import { isEqual } from 'lodash'

const initialState = {
  data: [],
  savedOrder: null,
  clientSecretKey: null,
  loading: false,
  error: null,
  cart: {
    storeId: null,
    storeName: '',
    userId: null,
    paymentMethod: paymentMethods.VISA,
    type: deliveryTypes.DELIVERY,
    deliveryFee: 0,
    subType: deliverySubTypes.MEET_DOOR,
    instructions: '',
    discount: 0,
    note: '',
    orderAddress: {
      country: '',
      state: '',
      city: '',
      zipCode: '',
      addressLine1: '',
      addressLine2: '',
    },
    orderMenus: [],
    tax: 0,
    subTotal: 0,
    totalPrice: 0,
    driverTipPrice: {
      value: 0,
      rate: 0,
      isCustom: false,
    },
    senpexApiToken: null,
    senpexInsertedId: null,
    distanceSuccess: null,
    senpexSuccess: null,
    originalDeliveryFee: 0,
    discountPrice: 0,
    promotion: null,
    finalSubtotalPrice: 0,
  },
  startedOrder: null,
}

export function orders(state = initialState, action) {
  switch (action.type) {
    case orderConstants.SAVE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case orderConstants.SAVE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        savedOrder: action.order,
      }
    case orderConstants.ADD_MENU:
      let menuItemToAdd = action.orderMenu
      let exists = deepCheck(menuItemToAdd, state.cart.orderMenus)
      let menus = state.cart.orderMenus
      let relatedItem = null
      let otherMenuItems = null
      if (!exists[0]) {
        menus.push(menuItemToAdd)
      } else {
        otherMenuItems = menus.filter((menu) => !isEqual(menu, exists[1]))
        relatedItem = exists[1]
        relatedItem.quantity += menuItemToAdd.quantity
        menus = [...otherMenuItems, relatedItem]
      }
      return {
        ...state,
        cart: {
          ...state.cart,
          orderMenus: menus,
          storeId: action.id,
          storeName: action.nickname,
        },
      }
    case orderConstants.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        savedOrder: null,
      }
    case orderConstants.SELECT_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          orderMenus: action.orderMenu,
          storeId: action.store.id,
          storeName: action.store.nickname,
        },
      }
    case orderConstants.REMOVE_MENU:
      return {
        ...state,
        cart: {
          ...state.cart,
          orderMenus: [...state.cart.orderMenus]
            .slice(0, action.index)
            .concat([...state.cart.orderMenus].slice(action.index + 1, [...state.cart.orderMenus].length)),
        },
      }
    case orderConstants.REMOVE_MENU_ITEM:
      let newArray = state.cart.orderMenus.filter((i, index) => index !== action.index)
      return {
        ...state,
        cart: {
          ...state.cart,
          orderMenus: newArray,
        },
      }
    case orderConstants.CLEAR_ALL_MENUS:
      // CHECK THE LATEST INITIAL STATE
      return {
        ...state,
        cart: {
          ...state.cart,
          storeId: null,
          storeName: '',
          deliveryFee: '',
          note: '',
          orderMenus: [],
          tax: 0,
        },
      }
    case orderConstants.CLEAR_CART:
      const { type, subType, orderMenus, ...rest } = initialState.cart
      return {
        ...state,
        cart: {
          ...state.cart,
          orderMenus: [],
          ...rest,
        },
        clientSecretKey: null,
        savedOrder: null,
      }
    case orderConstants.SET_ORDER_ADDRESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          orderAddress: {
            country: action.address.country,
            state: action.address.state,
            city: action.address.city,
            zipCode: action.address.zipCode,
            addressLine1: `${action.address.addressLine1}`,
            // addressLine1: `${action.address.street_number} ${action.address.route}`,
            addressLine2: `${action.address.addressLine2}`,
          },
        },
      }
    case orderConstants.SET_DELIVERY_SUBTYPE:
      return {
        ...state,
        cart: {
          ...state.cart,
          subType: action.subType,
        },
      }
    case orderConstants.SET_ORDER_TYPE:
      return {
        ...state,
        cart: {
          ...state.cart,
          type: action.orderType,
        },
      }
    case orderConstants.SET_INSTRUCTIONS:
      return {
        ...state,
        cart: {
          ...state.cart,
          instructions: action.instructions,
        },
      }
    case orderConstants.GETALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case orderConstants.GETALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.orders,
      }
    case orderConstants.GETALL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case orderConstants.ADD_SIGNEDIN_USERID:
      return {
        ...state,
        cart: {
          ...state.cart,
          userId: action.customerId,
        },
      }
    case orderConstants.CREATE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case orderConstants.CREATE_PAYMENT_INTENT_SUCCESS:
      // DO WE NEED THIS STATE IN REDUX STORE
      return {
        ...state,
        loading: false,
        clientSecretKey: action.secretKey,
      }
    case orderConstants.CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        loading: false,
        clientSecretKey: null,
        error: action.error,
      }

    case orderConstants.SET_ORDERFEES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case orderConstants.SET_ORDERFEES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        cart: {
          ...state.cart,
          tax: action.orderDetail.order.taxPrice,
          totalPrice: action.orderDetail.order.totalPrice,
          subTotal: action.orderDetail.order.subTotal,
          deliveryFee: action.orderDetail.order.deliveryFee,
          senpexApiToken: action.orderDetail.order.senpexApiToken,
          senpexInsertedId: action.orderDetail.order.senpexInsertedId,
          distanceSuccess: action.orderDetail.distanceStatus.success,
          senpexSuccess: action.orderDetail.senpexStatus.success,
          originalDeliveryFee: action.orderDetail.order.originalDeliveryFee,
          discountPrice: action.orderDetail.order.discountPrice,
          promotion: action.orderDetail.order.promotion,
          finalSubtotalPrice: action.orderDetail.order.finalSubtotalPrice,
        },
      }
    case orderConstants.CLEAR_ORDERFEES:
      return {
        ...state,
        cart: {
          ...state.cart,
          tax: 0,
          totalPrice: 0,
          subTotal: 0,
          deliveryFee: 0,
          senpexApiToken: null,
          senpexInsertedId: null,
          distanceSuccess: null,
          senpexSuccess: null,
          driverTipPrice: {
            value: 0,
            rate: 0,
            isCustom: false,
          },
          originalDeliveryFee: 0,
          discountPrice: 0,
          promotion: null,
        },
      }
    case orderConstants.SET_ORDERFEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case orderConstants.SET_TIP:
      return {
        ...state,
        cart: {
          ...state.cart,
          driverTipPrice: action.query,
        },
      }
    default:
      return state
  }
}
