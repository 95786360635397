/* eslint-disable no-unused-expressions */
import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { searchActions } from '../../../actions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
// import KeyboardArrowDown from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import searchIcon from '../../../images/white-search.svg'
import moreFilters from '../../../images/more-filter.svg'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { GreenButton, GreenCheckbox } from '../../utils/tools'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { distanceMarks } from '../../../helpers/more-filters'
import { initialQuery, starMarksMoreFilters } from '../../../constants'
import { isChanged } from '../../../helpers/moreFilters/isChanged'
import arrowDown from '../../../images/arrow-down.svg'
import TfSwitch from '../../common/TfSwitch'
import { marksPrice } from '../../../helpers/searchFilter/marks-price'

const path = process.env.PUBLIC_URL

const rootHeight = 48

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 7,
    height: rootHeight,
    backgroundColor: '#fff',
  },
  whenInput: {
    width: 140,
  },
  whereInput: {
    flexGrow: 1,
    paddingLeft: 6,
  },
  searchInput: {
    flexGrow: 1,
    paddingLeft: 6,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
    color: '#000',
    '&::placeholder': {
      color: '#c4c4c4',
    },
  },
  howInput: {
    width: 120,
    paddingLeft: 6,
  },
  button: {
    textTransform: 'capitalize',
    backgroundColor: '#f7f7f7',
    color: '#707070',
    height: rootHeight,
    borderRadius: 8,
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  searchText: {
    flexGrow: 1,
  },
  searchButton: {
    minWidth: rootHeight,
    width: rootHeight,
    height: rootHeight,
    borderRadius: 7,
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  groupTitle: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
  },
  formLabel: {
    '&.MuiFormLabel-root': {
      zIndex: 3000,
    },
  },
  sliderGroupTitle: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
    paddingLeft: 12,
  },
  groupItem: {
    fontSize: 11,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#212121',
  },
  check: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  slider: {
    opacity: '1',
    '& .MuiSlider-thumb': {
      width: 16,
      height: 16,
    },
    '&.MuiSlider-vertical .MuiSlider-rail': {
      width: '5px',
      borderRadius: 5,
    },
    '&.MuiSlider-vertical .MuiSlider-track': {
      width: '5px',
      borderRadius: 5,
    },
    '& .MuiSlider-mark': {
      left: '52%',
      backgroundColor: '#fff',
      "&[data-index='0']": {
        bottom: '97% !important',
      },
      "&[data-index='3']": {
        bottom: '1% !important',
      },
    },
  },
})

class MoreFilters extends React.Component {
  constructor(props) {
    super(props)
    this.whereRef = createRef()

    this.state = {
      showMorePopover: false,
      anchorEl: null,
      searchText: '',
      dietaryIds: [],
      rating: initialQuery.rating,
      pricey: initialQuery.pricey,
      distance: initialQuery.distance,
      onlyOpen: false,
    }

    this.applyMoreFilterChange = this.applyMoreFilterChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount() {
    this.setQuery()
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.props

    if (prevProps.searchQuery.searchText !== searchQuery.searchText && searchQuery.searchText.length === 0) {
      this.setState({ searchText: '' })
    }
  }

  setQuery = () => {
    const { searchText, dietaryIds, rating, pricey, distance, onlyOpen } = this.props.searchQuery
    this.setState({ searchText, dietaryIds, rating, pricey, distance, onlyOpen })
  }

  resetFilters = () => {
    this.setState({
      searchText: initialQuery.searchText,
      dietaryIds: initialQuery.dietaryIds,
      rating: initialQuery.rating,
      pricey: initialQuery.pricey,
      distance: initialQuery.distance,
      onlyOpen: initialQuery.onlyOpen,
    })
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleMoreChange(event) {
    this.setState({ showMorePopover: true, anchorEl: event.currentTarget })
  }

  handleDietaryChange = (id) => {
    const { dietaryIds } = this.state
    if (dietaryIds.some((d) => d === id)) {
      this.setState({ dietaryIds: dietaryIds.filter((d) => d !== id) })
    } else {
      this.setState({ dietaryIds: dietaryIds.concat(id) })
    }
  }

  handleSearch = () => {
    this.props.setSearchTextFilter(this.state.searchText)
    // this.setState({ searchText: '' })
    this.props.history.push(`${path}/customer/search`)
  }

  handleRatingChange = (event, newValue) => {
    this.state.rating !== newValue * -1 && this.setState({ rating: newValue * -1 })
  }

  handlePriceyChange = (event, newValue) => {
    this.state.pricey !== newValue && this.setState({ pricey: newValue })
  }

  handleDistanceChange = (event, newValue) => {
    this.state.distance !== newValue && this.setState({ distance: newValue })
  }

  applyMoreFilterChange = () => {
    const { dietaryIds, rating, pricey, distance, onlyOpen } = this.state
    this.setState({ showMorePopover: false, anchorEl: null })
    this.props.setMoreFilters(dietaryIds, rating, pricey, distance, onlyOpen)
    this.props.history.push(`${path}/customer/search`)
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      this.handleSearch()
    }
  }

  handleSwitch = () => this.setState({ onlyOpen: !this.state.onlyOpen })

  render() {
    const { classes, showMoreFilters } = this.props
    const { showMorePopover, anchorEl, rating, pricey, distance, dietaryIds, onlyOpen } = this.state
    const query = { rating, pricey, distance, dietaryIds, onlyOpen }

    return (
      <div className={classes.root}>
        {showMoreFilters && (
          <div>
            <Button
              className={classes.button}
              onClick={(event) =>
                this.setState({
                  anchorEl: event.currentTarget,
                  showMorePopover: true,
                })
              }
              startIcon={<img src={moreFilters} alt='more-filter' height='25' width='25' />}
              endIcon={<img src={arrowDown} width={9.4} height={6.1} style={{ marginRight: 8.5, marginLeft: 8.5 }} />}
            >
              {this.getLabel('more-filters')}
            </Button>
            <Popover
              id='more-popover'
              open={showMorePopover}
              anchorEl={anchorEl}
              style={{ width: 500 }}
              onClose={() => this.setState({ showMorePopover: false, anchorEl: null })}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container style={{ padding: 10 }}>
                <Grid item xs={12} className='row'>
                  <div className='column' style={{ width: 140 }}>
                    <FormControl component='fieldset' className={classes.formControl}>
                      <FormLabel component='legend' className={classes.formLabel}>
                        <Typography variant='subtitle1' className={classes.groupTitle}>
                          {this.getLabel('dietary-label')}
                        </Typography>
                      </FormLabel>
                      <FormGroup style={{ paddingTop: 18 }}>
                        {this.props.dietaries &&
                          this.props.dietaries.map((dietary) => (
                            <FormControlLabel
                              className={classes.groupItem}
                              key={dietary.id}
                              control={
                                <GreenCheckbox
                                  className={classes.check}
                                  checked={dietaryIds.some((d) => d === dietary.id)}
                                  onChange={() => this.handleDietaryChange(dietary.id)}
                                  name={dietary.id.toString()}
                                />
                              }
                              label={
                                <Typography variant='body2' color='textSecondary'>
                                  {dietary.name}
                                </Typography>
                              }
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div className='column' style={{ width: 120 }}>
                    <FormControl component='fieldset' className={classes.formControl}>
                      <FormLabel component='legend' className='row'>
                        <Typography variant='subtitle1' className={classes.sliderGroupTitle}>
                          {this.getLabel('rating-label')}
                        </Typography>
                      </FormLabel>
                      <FormGroup style={{ paddingTop: 30 }}>
                        <Slider
                          orientation='vertical'
                          value={+rating * -1}
                          onChange={this.handleRatingChange}
                          aria-labelledby='discrete-slider'
                          style={{ height: 160 }}
                          step={0.5}
                          min={-5}
                          max={-2}
                          marks={starMarksMoreFilters.slice(1)}
                          className={classes.slider}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div className='column' style={{ width: 120 }}>
                    <FormControl component='fieldset' className={classes.formControl}>
                      <FormLabel component='legend' className='row'>
                        <Typography variant='subtitle1' className={classes.sliderGroupTitle}>
                          {this.getLabel('price-label')}
                        </Typography>
                      </FormLabel>
                      <FormGroup style={{ paddingTop: 30 }}>
                        <Slider
                          orientation='vertical'
                          value={pricey}
                          onChange={this.handlePriceyChange}
                          aria-labelledby='discrete-slider'
                          style={{ height: 160 }}
                          step={1}
                          min={1}
                          max={4}
                          marks={marksPrice()}
                          className={classes.slider}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div className='column' style={{ width: 120 }}>
                    <FormControl component='fieldset' className={classes.formControl}>
                      <FormLabel component='legend' className='row'>
                        <Typography variant='subtitle1' className={classes.sliderGroupTitle}>
                          {this.getLabel('distance-label')}
                        </Typography>
                      </FormLabel>
                      <FormGroup style={{ paddingTop: 30 }}>
                        <Slider
                          orientation='vertical'
                          value={distance}
                          onChange={this.handleDistanceChange}
                          aria-labelledby='discrete-slider'
                          style={{ height: 160 }}
                          step={5}
                          min={5}
                          max={35}
                          marks={distanceMarks}
                          className={classes.slider}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} className='row' style={{ paddingTop: 15, display: 'flex', alignItems: 'center' }}>
                  <TfSwitch
                    value={onlyOpen}
                    firstLabel='all-label'
                    secondLabel='only-open-stores'
                    handleSwitch={this.handleSwitch}
                  />
                  <div className='grow' />
                  <Button onClick={this.resetFilters} style={{ marginRight: 10 }} disabled={!isChanged(query)}>
                    {this.getLabel('reset-label')}
                  </Button>
                  <GreenButton onClick={this.applyMoreFilterChange}>{this.getLabel('apply-label')}</GreenButton>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
        <TextField
          id='search-text'
          value={this.state.searchText}
          className={classes.searchText}
          onChange={(event) => this.setState({ searchText: event.target.value })}
          onKeyDown={this.keyPress.bind(this)}
          placeholder={this.getLabel('search-cuisine-item')}
          size='small'
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.searchInput,
            },
            endAdornment: this.state.searchText && (
              <InputAdornment position='start'>
                <IconButton
                  size='small'
                  onClick={() => {
                    this.setState({ searchText: '' }, () => this.handleSearch())
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <GreenButton className={classes.searchButton} onClick={this.handleSearch}>
          <img src={searchIcon} alt='' height='18' width='18' />
        </GreenButton>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { dietaries, categories, search, customer } = state

  return {
    dietaries: dietaries.data,
    categories: categories.data,
    searchQuery: search.searchQuery,
    address: customer.currentAddress,
  }
}

const actionCreators = {
  setMoreFilters: searchActions.setMoreFilters,
  setSearchTextFilter: searchActions.setSearchTextFilter,
}

MoreFilters.propTypes = {
  showMoreFilters: PropTypes.bool,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(MoreFilters))))
