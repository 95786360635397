import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { orderActions, searchActions } from '../../../../actions'
import { GreenButton } from '../../../utils/tools'
import { deliveryTypes, deliverySubTypes } from '../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from './delivery-type/CloseIcon'
import ModalTitle from './delivery-type/ModalTitle'
import PickupRow from './delivery-type/PickupRow'
import DeliveryRow from './delivery-type/DeliveryRow'
import DeliverToDoor from './delivery-type/DeliverToDoor'
import MeetAtDoor from './delivery-type/MeetAtDoor'
import MeetOutside from './delivery-type/MeetOutside'
import DeliveryInstructions from './delivery-type/DeliveryInstructions'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    "&[web='true']": {
      padding: theme.spacing(),
      paddingTop: theme.spacing(2),
    },
  },
  selected: {
    color: '#000',
    fontWeight: 600,
  },
  row: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  subRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  subRow2: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 15,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
  addInstruction: {
    padding: theme.spacing(),
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'none',
  },
  selectedInstruction: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
  field: {
    padding: theme.spacing(),
  },
  applyButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  applyButton: {
    backgroundColor: '#69e781',
    width: 100,
    height: 30,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500,
    color: 'white',
    '&:focus, &:hover, &active': {
      backgroundColor: '#69e781',
    },
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: '#e7e7e7',
    },
  },
}))

export const DeliveryTypeFilter = ({
  intl,
  handleValueChange,
  handleClose,
  howDialogData,
  web,
  handleUpdateAddress,
}) => {
  const classes = useStyles()
  const history = useHistory()

  // Global states
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliverySubType = useSelector(({ orders }) => orders.cart.subType)
  const instructionss = useSelector(({ orders }) => orders.cart.instructions)
  const signedIn = useSelector(({ customer }) => customer.signedIn)

  // Local states
  const [typ, setTyp] = useState(howDialogData ? howDialogData.deliveryTyp : deliveryType)
  const [subTyp, setSubTyp] = useState(howDialogData ? howDialogData.subTyp : deliverySubType)
  const [instructions, setInstructions] = useState(howDialogData ? howDialogData.instr : instructionss)
  // Actions
  const dispatch = useDispatch()
  const setDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setDeliverySubType = (subType) => dispatch(orderActions.setDeliverySubTypes(subType))
  const setInstructionss = (instructions) => dispatch(orderActions.setInstructions(instructions))

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleDeliveryTypeChange = () => {
    if (handleValueChange) {
      handleValueChange(typ, subTyp, instructions)
    } else {
      setDeliveryType(typ)
      setDeliverySubType(subTyp)
      setInstructionss(instructions)
      web && signedIn && handleUpdateAddress(subTyp, instructions)
      web && !history.location.pathname.includes('/customer/search') && history.push(`${path}/customer/search`)
    }
    handleDeliverTypeClose()
  }

  // when passing a function to a child component in which React.memo is used,
  // it is suggested to wrap function with useCallback. By doing so, Reac.memo works properly
  const handlePickup = useCallback(() => {
    setTyp(deliveryTypes.TAKEOUT)
    setSubTyp('')
    setInstructions('')
  }, [])

  const handleDelivery = useCallback(() => setTyp(deliveryTypes.DELIVERY), [])

  const handleDeliverTypeClose = useCallback(() => {
    setTyp('')
    setSubTyp('')
    setInstructions('')
    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeliverDoor = useCallback(() => setSubTyp(deliverySubTypes.DELIVER_DOOR), [])

  const handleMeetDoor = useCallback(() => setSubTyp(deliverySubTypes.MEET_DOOR), [])

  const handleMeetOutside = useCallback(() => setSubTyp(deliverySubTypes.MEET_OUTSIDE), [])

  const handleInstructions = useCallback((e) => setInstructions(e.target.value), [])

  const disableButton = typ === deliveryTypes.DELIVERY && subTyp === ''

  return (
    <Grid className={classes.root} container web={web}>
      {!web && <CloseIcon handleClose={handleDeliverTypeClose} />}
      <ModalTitle title={getLabel('delivery-type')} web={web} />
      <PickupRow
        typ={typ}
        row={classes.row}
        icon={classes.icon}
        button={classes.button}
        selectedButton={classes.selectedButton}
        handlePickup={handlePickup}
        label={getLabel('pick-up')}
      />
      <DeliveryRow
        typ={typ}
        row={classes.row}
        icon={classes.icon}
        button={classes.button}
        selectedButton={classes.selectedButton}
        handleDelivery={handleDelivery}
        label={getLabel('delivery-label')}
      />
      <Grid item xs={12} className={classes.row} style={{ padding: 20 }}>
        <Grid container>
          <DeliverToDoor
            typ={typ}
            subTyp={subTyp}
            subRow={classes.subRow}
            icon={classes.icon}
            button={classes.button}
            selectedButton={classes.selectedButton}
            handleDeliverDoor={handleDeliverDoor}
            label={getLabel('deliver-door')}
          />
          <MeetAtDoor
            typ={typ}
            subTyp={subTyp}
            subRow={classes.subRow}
            icon={classes.icon}
            button={classes.button}
            selectedButton={classes.selectedButton}
            handleMeetDoor={handleMeetDoor}
            label={getLabel('meet-door')}
          />
          <MeetOutside
            typ={typ}
            subTyp={subTyp}
            subRow2={classes.subRow2}
            icon={classes.icon}
            button={classes.button}
            selectedButton={classes.selectedButton}
            handleMeetOutside={handleMeetOutside}
            label={getLabel('meet-outside')}
          />
        </Grid>
      </Grid>
      <DeliveryInstructions
        typ={typ}
        instructions={instructions}
        subRow={classes.subRow}
        icon={classes.icon}
        field={classes.field}
        addInstruction={classes.addInstruction}
        selectedInstruction={classes.selectedInstruction}
        handleInstructions={handleInstructions}
        label={getLabel('add-instructions')}
        placeholder={getLabel('add-instruction-placeholder')}
      />
      {web ? (
        <div className={classes.applyButtonContainer}>
          <Button
            onClick={handleDeliveryTypeChange}
            disableRipple
            className={classes.applyButton}
            disabled={disableButton}
          >
            {getLabel('apply-label')}
          </Button>
        </div>
      ) : (
        <Grid item xs={12}>
          <GreenButton fullWidth onClick={handleDeliveryTypeChange} disabled={disableButton}>
            {getLabel('view-label')}
          </GreenButton>
        </Grid>
      )}
    </Grid>
  )
}

DeliveryTypeFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func,
  intl: PropTypes.object,
  howDialogData: PropTypes.object || null,
  web: PropTypes.string,
  handleUpdateAddress: PropTypes.func,
}

export default injectIntl(DeliveryTypeFilter)
