import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getImage } from '../../../helpers/get-image'
import imagePlaceholder from '../../../images/background-placeholder.png'
import { CardMedia, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  media: {
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
    width: '100%',
    height: 208,
    backgroundColor: '#f4f4f4',
    "&[store-open='false']": {
      opacity: '0.5',
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.29,
    },
  },
}))

export const StoreBackground = ({ background, data }) => {
  const classes = useStyles()
  const image = useMemo(() => (background ? getImage(background, 'thumb') : imagePlaceholder), [background])
  let storeOpen = data.open ? 'true' : 'false'

  return (
    <CardMedia store-open={storeOpen} component={'img'} className={classes.media} image={image} title={data.nickname} />
  )
}

StoreBackground.propTypes = {
  background: PropTypes.object,
  data: PropTypes.object,
}

export default StoreBackground
