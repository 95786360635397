//Import libs
import React, { useState, useEffect, createRef, useRef, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

//Import components
import Sections from './webStore/sections'
import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import MenuItemDialog from './MenuItemDialog'
import WebConfirmationDialog from '../../common/web-confirmation-dialog'
import Loading from '../../common/loading'
import NoMatch from '../no_match'
import DeliveryDetails from '../delivery_banner/DeliveryDetails'

// import WarnBlock from './webStore/warnBlock'
import StoreBackground from './webStore/storeBackground'
import StoreTabs from './webStore/storeTabs'
import StoreMoreDialog from './webStore/StoreMoreDialog'

//Import actions, services and helpers
import { storeActions, orderActions } from '../../../actions'
import { Transition } from '../../utils/tools'
import useRouter from '../../../helpers/useRouter'
import { getOCStatusQuery } from '../../../helpers/store/getOCStatusQuery'
import { storeService } from '../../../services/store.service'
import { generalConstants } from '../../../constants'
import { useCurrentWidth } from '../../../hooks/useCurrentWidth'
import { handleMaxWidth } from '../../../helpers/store/handleMaxWidth'

//Material ui imports
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Paper, Dialog, Slide, useScrollTrigger, AppBar, withWidth } from '@material-ui/core'

//Image imports
import imagePlaceholder from '../../../images/image-placeholder.svg'
import { getImage } from '../../../helpers/get-image'
// import deliveryFee from '../../../images/white-delivery-fee.svg'

// const StoreMoreDialog = lazy(() => import('./webStore/StoreMoreDialog'))

const path = process.env.PUBLIC_URL

function HideOnScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 480,
  })

  return (
    <Slide appear={true} direction='down' in={trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#fff',
    minHeight: '100vh',
    flexGrow: 1,
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
  },
  sectionDesc: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: 'rgba(51, 51, 51, 0.5)',
  },
  paper: {
    width: 200,
    height: 200,
    backgroundColor: '#fff',
    marginTop: -100,
  },
  stickyPaper: {
    width: 100,
    height: 100,
    backgroundColor: '#fff',
  },
  sections: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  stickySections: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
  },
  stickyAppbar: {
    backgroundColor: '#fff',
  },
  deliveryDetails: {
    // border: '1.5px solid #c4c4c4',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.16)',

    borderRadius: 10,
    padding: 1,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 585,
  },
  confirmationDialog: {
    width: '638px',
    height: ' 503px',
    padding: '20px 20px 34px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deliveryDetailsContainer: {
    width: '100%',
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#f7f7f7',
  },
}))

const windowHeight = window.innerHeight
let _isMounted = false

const Store = (props) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const { query } = useRouter()
  const idProp = useMemo(() => query.id, [query])

  //Redux
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const store = useSelector(({ stores }) => stores.customerCurrentStore, shallowEqual)
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const width = useCurrentWidth()
  const max_width = handleMaxWidth(width, searchQuery?.deliveryTime)

  //Actions
  const dispatch = useDispatch()
  const clearMenus = () => dispatch(orderActions.clearMenus())
  const addMenu = (id, nickname, order) => dispatch(orderActions.addMenuToOrder(id, nickname, order))
  const setCurrentStore = (data) => dispatch(storeActions.setCurrentStore(data))

  //States
  const [showMenuDialog, setShowMenuDialog] = useState(false)
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false)
  const [newOrderItem, setNewOrderItem] = useState(null)
  const [selected, setSelected] = useState(null)
  const [sections, setSections] = useState(null)
  const [selectedSection, setSelectedSection] = useState('none')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [openStoreMoreDialog, setOpenStoreMoreDialog] = useState(false)
  const [ocStatus, setOCStatus] = useState(false)

  //References
  const heightRef = useRef()
  const refs = useMemo(
    () =>
      sections?.reduce((refsObj, section) => {
        refsObj[section.name] = createRef()
        return refsObj
      }, {}),
    [sections]
  )

  useEffect(() => {
    if (sections) {
      if (refs[sections[0].current] && refs[sections[0].current] !== null) return
    }
  }, [refs, sections])

  useEffect(() => {
    sections && setSelectedSection(sections[0].name)
  }, [sections])

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      getStores(idProp)
    }
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    getOCStatus()
  }, [searchQuery.deliveryType, searchQuery.deliveryTimeDetail])

  const getStores = async (id) => {
    storeService
      .findStore(id)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setCurrentStore(res.data)
          getSections(id)
        }
      })
      .catch((error) => {
        setError(true)
        setLoading(false)
      })
  }

  const getOCStatus = async () => {
    let query = getOCStatusQuery(searchQuery, idProp)
    const response = await storeService.getStoreOCStatus(query)
    let status = response[0]?.open
    setOCStatus(status)
  }

  const getSections = async (id) => {
    await storeService
      .getAllSectionsUi(id)
      .then((res) => {
        res.length > 0 && setSections(res)
        setLoading(false)
      })
      .catch((err) => {
        // setError(true)
        setLoading(false)
      })
  }

  //Scroll to section with related tab selection
  const handleSelectionChange = (newValue) => {
    window.scroll({
      top: refs[newValue].current.offsetTop - 80,
      behavior: 'smooth',
      block: 'center',
    })
  }

  //Opens dialog to add selected menu item to cart
  const handleMenuItemClick = (item) => {
    setShowMenuDialog(true)
    setSelected(item)
  }

  const closeMenuDialog = () => {
    setShowMenuDialog(false)
  }

  //If cart is included menuItems from another store new order dialog is opened
  const openNewOrderDialog = (item) => {
    setShowNewOrderDialog(true)
    setNewOrderItem(item)
  }

  const closeNewOrderDialog = () => {
    setShowNewOrderDialog(false)
    setNewOrderItem(null)
  }

  //Adding related menuItem to cart
  const addMenuToCart = () => {
    clearMenus()
    addMenu(store?.id, store?.nickname, newOrderItem)
    setShowNewOrderDialog(false)
    setNewOrderItem(null)
  }

  const goToSignIn = () => props.history.push(`${path}/customer/signin`, 'fromStore')

  const handleStoreMoreDialogOpen = () => setOpenStoreMoreDialog(true)

  const handleStoreMoreDialogClose = () => setOpenStoreMoreDialog(false)

  const storeBackground = useMemo(
    () => (
      <StoreBackground
        store={store}
        sections={sections}
        format={f}
        setSelectedSection={setSelectedSection}
        openStoreMoreDialog={handleStoreMoreDialogOpen}
        id={idProp}
        goToSignIn={goToSignIn}
        ocStatus={ocStatus}
      />
    ),
    [store, sections, setSelectedSection, idProp]
  )

  const storeTabs = useMemo(
    () => (
      <StoreTabs selectedSection={selectedSection} sections={sections} handleSelectionChange={handleSelectionChange} />
    ),
    [sections, selectedSection]
  )

  const logo = getImage(store?.restaurant?.logo, 'thumb') || imagePlaceholder

  if (loading) return <Loading open={loading} />
  if (error) return <NoMatch />
  if (store) {
    return (
      <div className={classes.root}>
        <Container maxWidth='lg'>
          <TopNavbar cartVisible={true} deliveryDetails={width > 1150 ? true : false} component='store' />
        </Container>
        {width <= 1150 && (
          <div className={classes.deliveryDetailsContainer}>
            <div className={classes.deliveryDetails}>
              <DeliveryDetails navbar={true} component='store' max_width={max_width} />
            </div>
          </div>
        )}
        <Container maxWidth='lg' style={{ minHeight: 1000 }}>
          <HideOnScroll {...props}>
            <AppBar className={classes.stickyAppbar} elevation={0}>
              <Container maxWidth='lg' ref={heightRef}>
                <Grid container>
                  <Grid item xs={12} className={classes.stickySections}>
                    <Paper className={classes.stickyPaper}>
                      <img style={{ height: 100, width: 100 }} alt={store?.nickname} src={logo} />
                    </Paper>
                    {storeTabs}
                  </Grid>
                </Grid>
              </Container>
            </AppBar>
          </HideOnScroll>
          {store && (
            <div className='column'>
              {storeBackground}
              <Grid container>
                <Grid item xs={12} className={classes.sections}>
                  <Paper className={classes.paper}>
                    <img alt={store.nickname} src={logo} style={{ height: 200, width: 200 }} />
                  </Paper>
                  {storeTabs}
                </Grid>
              </Grid>
            </div>
          )}
          {/* <WarnBlock store={store} /> */}
          {sections?.length > 0 &&
            sections?.map((section, index) => {
              return (
                <Sections
                  key={index}
                  refs={refs}
                  section={section}
                  windowHeight={windowHeight}
                  classes={classes}
                  setSelectedSection={setSelectedSection}
                  store={store}
                  handleMenuItemClick={handleMenuItemClick}
                  cart={cart}
                  selectedSection={selectedSection}
                />
              )
            })}
        </Container>
        <div className='grow' />
        <Footer />
        {/* Dialogs should be used with suspense and lazy methods to descrease render time and will be implemented soon*/}
        <MenuItemDialog
          web
          open={showMenuDialog}
          data={selected}
          onClose={closeMenuDialog}
          onCreateNewOrder={openNewOrderDialog}
          storeId={idProp}
          storeOpened={ocStatus}
        />
        <Dialog
          classes={{ paper: classes.confirmationDialog }}
          open={showNewOrderDialog}
          onClose={closeNewOrderDialog}
          TransitionComponent={Transition}
        >
          <WebConfirmationDialog
            close={closeNewOrderDialog}
            header={f({ id: 'new-order-alert-title' })}
            text={f({ id: 'new-order-alert-text-1' })}
            text2={f({ id: 'new-order-alert-text-2' })}
            function1={closeNewOrderDialog}
            function2={addMenuToCart}
            img={'newOrderImage'}
            button1={f({ id: 'cancel-label' })}
            button2={f({ id: 'create-new-order' })}
            id={false}
          />
        </Dialog>
        <StoreMoreDialog open={openStoreMoreDialog} onClose={handleStoreMoreDialogClose} store={store} />
      </div>
    )
  }
}

export default withRouter(withWidth()(Store))
