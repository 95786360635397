import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/MoreVert'
import Fade from '@material-ui/core/Fade'

import { customerActions, searchActions, orderActions } from '../../../../../actions'
import { handleAddressQuery } from '../../../../../helpers/address/handleAddressQuery'
import { customerService } from '../../../../../services'

import { Transition } from '../../../../utils/tools'
import { generalConstants, deliveryTypes } from '../../../../../constants'

import WebConfirmationDialog from '../../../../common/web-confirmation-dialog'

const path = process.env.PUBLIC_URL

const customFont = {
  fontStyle: 'italic',
  color: '#707070',
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  name: {
    fontSize: 22,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
  },
  default: {
    marginLeft: theme.spacing(2),
    marginTop: 4,
  },
  edit: {
    color: '#212121',
  },
  deleteAddressDialog: {
    width: '638px',
    height: ' 503px',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deliveryType: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    minHeight: 20,
    alignItems: 'center',
    marginTop: theme.spacing(),
  },
  bulletList: {
    margin: 0,
    marginTop: 1,
    paddingLeft: theme.spacing(3),
    color: '#707070',
  },
  subType: {
    ...customFont,
  },
  instruction: {
    ...customFont,
    marginLeft: theme.spacing(),
  },
}))

const InnerAddressItem = ({ data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { formatMessage: f } = useIntl()

  const setUserAddress = (address) => dispatch(searchActions.setAddress(address))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))
  const updateAddress = (data) => dispatch(customerActions.updateAddressFromAddressPopUp(data))
  const deleteAddress = (data) => dispatch(customerActions.deleteAddress(data))

  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteAddressDialog, setDeleteAddressDialog] = useState(false)

  const handleEditClose = () => {
    setAnchorEl(null)
  }

  const selectAddress = () => {
    let query = handleAddressQuery(data)
    if (query.default_) {
      setUserAddress(query)
      setAnchorEl(null)
      handleHowDetails(query)
      history.push(`${path}/customer/search`)
    } else {
      setAsDefaultAddress()
    }
  }

  const setAsDefaultAddress = () => {
    let address = data
    address.default_ = true
    customerService.updateAddress(data.id, address).then((res) => {
      let addressQuery = handleAddressQuery(res.data)
      if (res.status === generalConstants.STATUS_OK) {
        updateAddress(res.data)
        setAnchorEl(null)
        setUserAddress(addressQuery)
        handleHowDetails(addressQuery)
        history.push(`${path}/customer/search`)
      }
      // else {
      //   error(res.message?.toString())
      // }
    })
    // .catch(err => error(err.message?.toString()))
  }

  const handleHowDetails = (query) => {
    setUserInstructions(query.deliveryInstruction)
    setUserDeliverySubTypes(query.deliveryOption)
    if (query.deliveryOption === '') {
      setUserDeliveryType(deliveryTypes.TAKEOUT)
    } else {
      setUserDeliveryType(deliveryTypes.DELIVERY)
    }
  }

  const closeDeleteAddressDialog = () => {
    setDeleteAddressDialog(false)
    setAnchorEl(null)
  }

  const handleAddressDelete = (id) => {
    deleteAddress(id)
    closeDeleteAddressDialog()
  }

  const show = Boolean(anchorEl)

  const deliveryType = data.deliveryOption === '' ? 'Pickup' : 'Delivery'

  const getLabel = {
    DELIVER2DOOR: f({ id: 'deliver-door' }),
    'MEET@DOOR': f({ id: 'meet-door' }),
    MEETOUTSIDE: f({ id: 'meet-outside' }),
  }

  return (
    <>
      <Card variant='outlined'>
        <CardContent className={classes.root}>
          <div className='row'>
            <Typography className={classes.name} color='textSecondary' gutterBottom>
              {data.name}
            </Typography>
            {data.default_ && (
              <Chip
                className={classes.default}
                size='small'
                label={f({ id: 'default-label' })}
                variant='outlined'
                color='primary'
              />
            )}
            <div className='grow' />
            <IconButton
              className={classes.edit}
              aria-label='edit'
              component='span'
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <EditIcon />
            </IconButton>
            <Menu
              id='edit-menu'
              anchorEl={anchorEl}
              keepMounted
              open={show}
              onClose={handleEditClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => selectAddress()}>{f({ id: 'use-this-address' })}</MenuItem>
              <MenuItem onClick={() => setDeleteAddressDialog(true)}>{f({ id: 'delete-label' })}</MenuItem>
            </Menu>
          </div>
          <Typography variant='body1' component='p'>
            {`${data.addressLine1} ${data.addressLine2} ${data.city}, ${data.state} ${data.zipCode || ''}`}
          </Typography>
          <div className={classes.deliveryType}>
            <Typography variant='body1' component='p'>
              {deliveryType}
            </Typography>
            {Object.keys(getLabel).includes(data?.deliveryOption) && (
              <ul className={classes.bulletList}>
                <li>
                  <Typography variant='body1' component='p' className={classes.subType}>
                    {getLabel[data.deliveryOption]}
                  </Typography>
                </li>
              </ul>
            )}
          </div>
          <div className={classes.deliveryType}>
            <Typography variant='body1' component='p'>
              {f({ id: 'instructions-label' })}:
            </Typography>
            <Typography noWrap variant='body1' component='p' className={classes.instruction}>
              {data.deliveryInstruction}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Dialog
        classes={{ paper: classes.deleteAddressDialog }}
        open={deleteAddressDialog}
        onClose={closeDeleteAddressDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closeDeleteAddressDialog}
          header={f({ id: 'are-you-sure' })}
          text={f({ id: 'about-delete-address' })}
          text2={data.formattedAddress}
          function1={closeDeleteAddressDialog}
          function2={handleAddressDelete}
          img={'deleteAddressImage'}
          button1={f({ id: 'cancel-label' })}
          button2={f({ id: 'delete-label' })}
          id={data.id}
        />
      </Dialog>
    </>
  )
}

InnerAddressItem.propTypes = {
  data: PropTypes.object,
}

export default InnerAddressItem
