/* eslint-disable eqeqeq */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import backIcon from '../../../images/black-back.svg'
import Footer from '../Footers'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import StoreCard from '../../common/StoreCard'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    //flexWrap: 'nowrap',
  },
  item: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
})

class PickupListView extends React.Component {
  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  render() {
    const { classes, stores, orderMenus, onPress, favorites } = this.props

    return (
      <div className={clsx('column', [classes.root])}>
        <div className='mobile-top-bar'>
          <IconButton size='small' className={classes.back} onClick={() => this.props.handleClose()} aria-label='close'>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography variant='h6'>{this.getLabel('near-me')}</Typography>
        </div>
        <div className={classes.content} style={{ paddingBottom: orderMenus.length > 0 ? 120 : 60 }}>
          <Grid
            container
            className='vertical-scrolling-wrapper-flexbox'
            style={{ minHeight: '100%', paddingBottom: 10 }}
          >
            {stores.map((store) => {
              return (
                <div className={classes.item} key={store.id}>
                  <StoreCard data={store} onPress={onPress} favorites={favorites} />
                </div>
              )
            })}
          </Grid>
        </div>
        <div className='sticky-bottom'>
          <Footer />
        </div>
      </div>
    )
  }
}

PickupListView.propTypes = {
  stores: PropTypes.array,
  handleClose: PropTypes.func,
}

const mapStateToProps = (state) => {
  const { orders } = state
  return {
    orderMenus: orders.cart.orderMenus,
  }
}

const actionCreators = {}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(PickupListView))))
