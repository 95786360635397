import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import backIcon from '../../images/black-back.svg'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
}))

const MobileTopHeader = ({ callback, title }) => {
  const classes = useStyles()
  return (
    <div className='mobile-top-bar'>
      <IconButton size='small' className={classes.back} onClick={callback}>
        <img src={backIcon} alt='Back' />
      </IconButton>
      <Typography variant='h6'>
        <FormattedMessage id={title} />
      </Typography>
    </div>
  )
}

MobileTopHeader.propTypes = {
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}

export default React.memo(MobileTopHeader)
