import React from 'react'
import PropTypes from 'prop-types'
import { Box, CardMedia, makeStyles } from '@material-ui/core'
import placeholder from '../../../images/image-placeholder.svg'
import { getImage } from '../../../helpers/get-image'

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: 120,
    backgroundColor: '#f4f4f4',
    borderRadius: 6,
    "&[store-open='false']": {
      opacity: '0.5',
    },
  },
}))

const StoreImage = ({ data }) => {
  const classes = useStyles()

  const storeImage_ = data.restaurant?.background && getImage(data.restaurant?.background, 'thumb')

  return (
    <Box width={'30%'}>
      {data.restaurant.background ? (
        <CardMedia
          className={classes.media}
          component='img'
          alt={data.nickname}
          image={storeImage_}
          title={data.nickname}
          store-open={data.open.toString()}
        />
      ) : (
        <div className={classes.media} store-open={data.open.toString()}>
          <img src={placeholder} width='120' alt='' className={{ height: 120 }} />
        </div>
      )}
    </Box>
  )
}

StoreImage.propTypes = {
  data: PropTypes.object,
}

export default StoreImage
