import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import { FavoriteButton, StoreDetails, StoreImage, StoreRating } from './store-card-small'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    height: 120,
    position: 'relative',
    marginBottom: theme.spacing(),
    display: 'flex',
  },
}))

const StoreCardSmall = ({ data, isFavorite, onPress, show, favorite, direction, history, big }) => {
  const classes = useStyles()

  const cardActionHandler = () => {
    direction && history.push(direction)
  }

  return (
    <Card className={classes.cardRoot} variant='outlined'>
      <FavoriteButton data={data} onPress={onPress} show={show} isFavorite={isFavorite} />
      <CardActionArea style={{ display: 'inherit' }} onClick={cardActionHandler}>
        <StoreRating data={data} big={big} />
        <StoreImage data={data} />
        <StoreDetails data={data} search={show} favorite={favorite} />
      </CardActionArea>
    </Card>
  )
}

StoreCardSmall.propTypes = {
  show: PropTypes.bool,
  favorite: PropTypes.bool,
  data: PropTypes.object,
  onPress: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(StoreCardSmall)
