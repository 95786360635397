import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { StoreRating } from './store-card-small'
import { FavoriteButton, StoreBackground, StoreDetails } from './store-card'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: 10,
    height: 321,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 221,
    },
  },
  rootFood: {
    width: '100%',
    minWidth: 521,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: 10,
    height: 321,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 221,
    },
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
}))

const StoreCard = ({ favorites, food, data, onPress, history }) => {
  const classes = useStyles()
  const { background } = data.restaurant
  const isFavorite = favorites.some((favorite) => favorite.entityId === data.id)

  const cardActionHandler = () => {
    history.push(`${path}/customer/store/${data.id}`)
  }

  return (
    <Card className={food ? classes.rootFood : classes.root} variant='outlined' style={{ border: 0 }}>
      <StoreRating data={data} />
      <FavoriteButton data={data} onPress={onPress} isFavorite={isFavorite} />
      <CardActionArea className={classes.actionArea} onClick={cardActionHandler}>
        <StoreBackground data={data} background={background} />
        <StoreDetails data={data} />
      </CardActionArea>
    </Card>
  )
}

StoreCard.propTypes = {
  favorites: PropTypes.array,
  food: PropTypes.bool,
  data: PropTypes.object,
  onPress: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(StoreCard)
