import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import SectionTitle from './menu-section/SectionTitle'
import SectionBody from './menu-section/SectionBody'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import { useToggle } from '../../../hooks/useToggle'

const useStyles = makeStyles((theme) => ({
  section: {
    padding: 8,
  },
  divider: {
    marginBottom: 10,
  },
  inputtest: {
    paddingTop: 12,
  },
}))

const MenuSection = ({ section }) => {
  const classes = useStyles()
  // Global states
  const storeid = useSelector(({ merchantStores }) => merchantStores.selectedStore?.storeId)
  // Local states
  const [expand, setExpand] = useState(true)
  const [editting, setEditting] = useState(false)
  const [edittingName, setEdittingName] = useState(section.name)
  const [showConfirm, setShowConfirm] = useToggle()

  const dispatch = useDispatch()
  const deleteSection = (storeId, sectionId) => dispatch(merchantStoreActions.deleteSection(storeId, sectionId))
  const updateSection = (id, sectionId, data) => dispatch(merchantStoreActions.updateSection(id, sectionId, data))

  const handleDelete = () => {
    setShowConfirm()
    deleteSection(storeid, section.id)
  }

  const updateSelection = () => {
    let data = {
      name: edittingName,
      description: section.description,
      order_: section.order_,
    }
    updateSection(storeid, section.id, data)
    setEditting(false)
    setEdittingName('')
  }

  return (
    <div className={classes.section}>
      <SectionTitle
        editting={editting}
        edittingName={edittingName}
        updateSelection={updateSelection}
        expand={expand}
        showConfirm={showConfirm}
        handleConfirmClose={setShowConfirm}
        handleDelete={handleDelete}
        section={section}
        setShowConfirm={setShowConfirm}
        setEditting={setEditting}
        setEdittingName={setEdittingName}
        setExpand={setExpand}
      />
      <Divider className={classes.divider} />
      <SectionBody expand={expand} section={section} />
    </div>
  )
}

MenuSection.propTypes = {
  section: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default MenuSection
