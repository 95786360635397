import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import SearchAddress from '../../../utils/address_search'
import SavedAddresses from './new-initial-step/SavedAddresses'

import { customerActions, searchActions, orderActions } from '../../../../actions'
import { generalConstants, deliveryTypes } from '../../../../constants'
import { handleAddressQuery } from '../../../../helpers/address/handleAddressQuery'
import { customerService } from '../../../../services'
import { parseGoogleAPIAddress } from '../../../utils/common'

import resultImage from '../../../../images/set-address-result.svg'
import addressSearchIcon from '../../../../images/address-search.svg'
import arrowIcon from '../../../../images/finder-location-arrow.svg'
import currentLocationIcon from '../../../../images/current-location.svg'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const innerWidth = window.innerWidth

const useStyles = makeStyles((theme) => ({
  addressRow: {
    height: 40,
    borderTop: '1px solid #f7f7f7',
    borderBottom: '1px solid #f7f7f7',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  stepOneSubRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'point',
  },
  stepOneIcon: {
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
}))

const NewInitialStep = ({
  handleDeliveryAddress,
  hideCurrentAddress,
  showCurrentAddress,
  currentLocation,
  showCurrentLocation,
  selectAddressAsCurrent,
  setStep,
  handleClose,
  signedIn,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const addresses = useSelector(({ customer }) => customer.savedAddresses)

  const deleteAddress = async (id) => dispatch(customerActions.deleteAddress(id))
  const setUserAddress = (address) => dispatch(searchActions.setAddress(address))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))
  const updateAddress = (data) => dispatch(customerActions.updateAddressFromAddressPopUp(data))

  const formatCurrentAddress = (address) => {
    let parsedAddress = parseGoogleAPIAddress(address)
    parsedAddress.name = parsedAddress.city
    selectAddressAsCurrent(parsedAddress)
  }

  const handleDeleteAddress = async (addr) => {
    await deleteAddress(addr.id)
  }

  const selectAddress = (e, data) => {
    e.persist()
    let query = handleAddressQuery(data)
    if (query.default_) {
      setUserAddress(query)
      handleHowDetails(query)
      setStep(0)
      handleClose()
    } else {
      setAsDefaultAddress(data)
    }
  }

  const setAsDefaultAddress = (data) => {
    let address = data
    address.default_ = true
    customerService
      .updateAddress(data.id, address)
      .then((res) => {
        let addressQuery = handleAddressQuery(res.data)
        if (res.status === generalConstants.STATUS_OK) {
          updateAddress(res.data)
          setUserAddress(addressQuery)
          handleHowDetails(addressQuery)
          setStep(0)
          handleClose()
        } else {
          console.log(res)
        }
      })
      .catch((err) => console.log(err))
  }

  const handleHowDetails = (query) => {
    setUserInstructions(query.deliveryInstruction)
    setUserDeliverySubTypes(query.deliveryOption)
    if (query.deliveryOption === '') {
      setUserDeliveryType(deliveryTypes.TAKEOUT)
    } else {
      setUserDeliveryType(deliveryTypes.DELIVERY)
    }
  }

  return (
    <div className='column grow '>
      <div
        style={{
          width: innerWidth,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        <img src={resultImage} alt='location' />
      </div>
      <div className={clsx('centered-row', [classes.addressRow])}>
        <SearchAddress
          startAdornment={addressSearchIcon}
          endAdornment={arrowIcon}
          placeholder={f({ id: 'search-label' })}
          onChange={handleDeliveryAddress}
          typesRef={[]}
          hideCurrent={hideCurrentAddress}
          showCurrent={showCurrentAddress}
        />
      </div>
      {currentLocation && showCurrentLocation && (
        <div className={classes.stepOneSubRow} onClick={() => formatCurrentAddress(currentLocation)}>
          <img className={classes.stepOneIcon} src={currentLocationIcon} alt='current' height='20' width='20' />
          <div className='column full-width' style={{ borderBottom: '1px solid #f7f7f7' }}>
            <Typography variant='body1'>{f({ id: 'current-location' })}</Typography>
            <Typography variant='body1'>{currentLocation.formatted_address}</Typography>
            <Typography variant='body1'>{f({ id: 'deliver-door' })}</Typography>
          </div>
        </div>
      )}
      {signedIn && (
        <SavedAddresses addresses={addresses} selectAddress={selectAddress} handleDeleteAddress={handleDeleteAddress} />
      )}
    </div>
  )
}

NewInitialStep.propTypes = {
  handleDeliveryAddress: PropTypes.func,
  hideCurrentAddress: PropTypes.func,
  showCurrentAddress: PropTypes.func,
  currentLocation: PropTypes.object,
  showCurrentLocation: PropTypes.bool,
  selectAddressAsCurrent: PropTypes.func,
  setStep: PropTypes.func,
  handleClose: PropTypes.func,
  signedIn: PropTypes.bool,
}

export default NewInitialStep
