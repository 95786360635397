import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import { directDepositFields, valueConstants } from '../../Helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
    padding: 0,
    alignItems: 'flex-start',
    margin: 0,
  },
  field: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}))

const DirectDepositFields = ({ values, handleChange, error }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const handlePaste = (e) => e.preventDefault()

  return (
    <Container maxWidth='sm' className={classes.root}>
      <Grid container spacing={2}>
        {directDepositFields.map((item, index) => (
          <Grid key={index} item md={6} sm={6} xs={12}>
            <TextField
              size='small'
              value={values[item.value] || ''}
              className={classes.field}
              variant='outlined'
              fullWidth
              id={item.value}
              InputLabelProps={{ shrink: true }}
              label={f({ id: item.label })}
              required={true}
              onPaste={item.value === valueConstants.confirmAccountNumber ? handlePaste : null}
              error={
                error
                  ? item.value === valueConstants.accountNumber && values.accountNumber.length === 0
                    ? true
                    : item.value === valueConstants.confirmAccountNumber &&
                      values.confirmAccountNumber !== values.accountNumber
                    ? true
                    : item.value === valueConstants.taxIdNumber && values[item.value].length !== 9
                    ? true
                    : item.value === valueConstants.routingNumber && values[item.value].length !== 10
                    ? true
                    : false
                  : false
              }
              onChange={handleChange(item.value)}
              inputProps={{
                type: 'number',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

DirectDepositFields.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
}

export default DirectDepositFields
