import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import MarkerComponent from '../mobile-store-more/Marker'
import GoogleMapReact from 'google-map-react'
import StoreMoreContent from '../mobile-store-more/StoreMoreContent'

import addressIcon from '../../../../images/store-address-more.svg'
import closeIcon from '../../../../images/close.svg'
import rate from '../../../../images/rate.svg'
import deliveryTime from '../../../../images/delivery-time.svg'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 524,
    // height: 650,
    height: 'auto',
    padding: 0,
    borderRadius: 8,
    overflow: 'hidden',
  },
  mapContainer: {
    width: '100%',
    height: 210,
    backgroundColor: 'pink',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  storeName: {
    marginTop: theme.spacing(),
    fontSize: 28,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    color: '#000',
    marginBottom: theme.spacing(),
  },
  day: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#000',
  },
  close: {
    position: 'absolute',
    right: 10,
    top: 5,
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
    opacity: '.5',
    '&:hover': {
      background: 'rgba(0,0,0,0.03);',
      opacity: '1',
    },
    '&:active, &:focus': {
      background: 'rgba(0,0,0,0.08);',
    },
  },
  nameAndIcons: {
    paddingLeft: theme.spacing(2),
  },
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  storeDetail: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#333333',
    marginLeft: 5,
    marginRight: 5,
  },
  rateIcon: {
    marginRight: theme.spacing(2),
  },
  iconContainer: {
    marginBottom: theme.spacing(),
  },
  storeAddress: {
    paddingLeft: theme.spacing(2),
  },
}))

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY

const StoreMoreDialog = ({ open, onClose, store }) => {
  const classes = useStyles()

  let address = store.storeInformation.address
  const initialCenter = {
    lat: parseFloat(address.latitude),
    lng: parseFloat(address.longitude),
  }

  const [center, setCenter] = useState(initialCenter)

  const onMapChange = (properties) => {
    setCenter(properties.center)
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Card className={classes.mapContainer}>
        <IconButton className={classes.close} onClick={onClose} disableRipple>
          <img src={closeIcon} width='20px' height='20px' alt='Close Icon' />
        </IconButton>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
          center={center}
          zoom={14}
          options={{ fullscreenControl: false, zoomControl: false }}
          onChange={onMapChange}
        >
          <MarkerComponent lat={address.latitude} lng={address.longitude} />
        </GoogleMapReact>
      </Card>
      <div className={clsx('column', [classes.nameAndIcons])}>
        <Typography className={classes.storeName} noWrap>
          {store.nickname}
        </Typography>
        <div className={clsx('row', [classes.iconContainer])}>
          {store.ratingSummary && (
            <div className={clsx('centered-row', [classes.rateIcon])}>
              <img src={rate} alt='Rate' width='20' className={classes.icon} style={{ marginBottom: 3 }} />
              <Typography className={classes.storeDetail}>
                {store.ratingSummary.averageRating?.toFixed(1)} ({store.ratingSummary.totalRatingCount})
              </Typography>
            </div>
          )}
          <div className='centered-row'>
            <img src={deliveryTime} alt='Delivery time' width='17' className={classes.icon} />
            <Typography className={classes.storeDetail}>{store.deliveryTime} mins</Typography>
          </div>
        </div>
      </div>
      <div className={clsx('row', [classes.storeAddress])}>
        <img src={addressIcon} alt='' width='13' height='16' style={{ marginRight: 5 }} />
        <Typography variant='h6'>{address.formattedAddress}</Typography>
      </div>
      <div className={classes.contentContainer}>
        <StoreMoreContent classes={classes} workingHours={store?.storeInformation.workingHours} store={store} web />
      </div>
    </Dialog>
  )
}

StoreMoreDialog.propTypes = {
  open: PropTypes.bool,
  store: PropTypes.object,
  onClose: PropTypes.func,
}

export default React.memo(StoreMoreDialog)
