import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography, IconButton } from '@material-ui/core'
import closeIconButton from '../../images/close-icon.svg'
import deleteCardImage from '../../images/delete-card-image.svg'
import deleteAddressImage from '../../images/delete-address-image.svg'
import newOrderImage from '../../images/new-order-image.svg'
import deleteFavoriteImage from '../../images/delete-favorite-image.svg'
import noResponse from '../../images/no_response.svg'
import { GreenButton } from '../utils/tools'

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  dialogIconButtonContainer: {
    width: '100%',
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 12,
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 222.3,
    marginTop: 0,
    marginBottom: 24,
  },
  headerContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 45,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 19.7,
  },
  headerContainerVerification: {
    width: '60%',
    height: 'auto',
    minHeight: 45,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 19.7,
  },
  header: {
    width: '100%',
    height: 'auto',
    color: '#333333',
    fontSize: 34,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  textContainer: {
    width: 'auto',
    height: 'auto',
    minHeight: 51,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginTop: 0,
    marginBottom: 34,
  },
  text: {
    width: 'auto',
    height: 'auto',
    color: 'black',
    textAlign: 'left',
    fontSize: 16,
    marginBottom: 5,
  },
  buttonContainer: {
    width: '100%',
    height: 21,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  button: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#707070',
    fontSize: 16,
    textAlign: 'center',
    marginRight: 15,
  },
  buttonRed: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#ea4335',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonGreen: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#69e781',
    fontSize: 16,
    textAlign: 'center',
  },
  greenButton: {
    width: 263,
    height: 48,
    textTransform: 'capitalize',
    color: 'white',
  },
})

const WebConfirmationDialog = ({
  classes,
  close,
  header,
  text,
  text2,
  function1,
  function2,
  img,
  button1,
  button2,
  id,
}) => {
  const imageHandler = () => {
    switch (img) {
      case 'deleteCardImage':
        return deleteCardImage
      case 'noResponse':
        return noResponse
      case 'deleteAddressImage':
        return deleteAddressImage
      case 'newOrderImage':
        return newOrderImage
      case 'deleteFavoriteImage':
        return deleteFavoriteImage
      default:
        return null
    }
  }

  const dialogImage = imageHandler()

  return (
    <div className={classes.root}>
      <div className={classes.dialogIconButtonContainer}>
        <IconButton onClick={() => close()} style={{ backgroundColor: 'transparent' }}>
          <img src={closeIconButton} alt='close-icon' height='20px' width='20px' />
        </IconButton>
      </div>
      <div className={classes.imgContainer}>
        <img src={dialogImage} alt='img' width='298.3' height='222.3' />
      </div>
      <div className={button2 ? classes.headerContainer : classes.headerContainerVerification}>
        <Typography className={classes.header}>{header}</Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text}>{text}</Typography>
        {text2 && <Typography className={classes.text}>{text2}</Typography>}
      </div>
      {button2 ? (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} style={{ backgroundColor: 'transparent' }} onClick={() => function1()}>
            {button1}
          </Button>
          <Button
            className={button2 === 'Delete' ? classes.buttonRed : classes.buttonGreen}
            style={{ backgroundColor: 'transparent' }}
            onClick={!id ? function2 : () => function2(id)}
          >
            {button2}
          </Button>
        </div>
      ) : (
        <div
          style={{
            marginTop: -20,
            width: '100%',
            height: '48',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <GreenButton className={classes.greenButton} onClick={() => function1()}>
            {button1}
          </GreenButton>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(WebConfirmationDialog)
