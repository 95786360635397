import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { storesHeader } from '../../../helpers/merchant/table-header'
import { useSelector } from 'react-redux'
import { rowsPerPageOptions } from '../../../constants'
import { RedCheckbox } from '../../utils/tools'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    margin: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  cell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  row: {
    // cursor: 'pointer',
  },
}))

const StoresTable = ({
  intl,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  selected,
  handleSelectClick,
  page,
}) => {
  const classes = useStyles()

  const stores = useSelector(({ merchantStores }) => merchantStores.stores)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby='Stores' size='medium' aria-label='stores table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {storesHeader.map((header, i) => (
                <TableCell key={header.label}>{getLabel(header.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((store, i) => {
              return (
                <TableRow key={store.storeNickName} className={classes.row} hover>
                  <TableCell className={classes.cell}>
                    <RedCheckbox checked={selected === store.storeId} onChange={(e) => handleSelectClick(e, store)} />
                  </TableCell>
                  <TableCell className={classes.cell}>{store.storeNickName}</TableCell>
                  <TableCell className={classes.cell}>{store.storeNumber}</TableCell>
                  <TableCell className={classes.cell}>{store.storeEmail}</TableCell>
                  <TableCell className={classes.cell}>{store.storeCity}</TableCell>
                  <TableCell className={classes.cell}>{store.storeState}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={stores.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

StoresTable.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(StoresTable)
