const showDialogInitialState = {
  filter: false,
  priceRange: false,
  distanceRange: false,
  deliveryDetails: false,
  dietary: false,
  sortBy: false,
}

const queryInitialState = {
  size: 20,
  // minOrderPrice: 54,
  // customOffset: 0,
  categoryIds: [],
  dietaryIds: [],
  // pricey: null,
  rating: null,
  distance: null,
  orderByKey: null,
  latitude: null,
  longitude: null,
  orderTime: null,
  orderType: null,
  unit: 'M',
}

export { showDialogInitialState, queryInitialState }
