import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import useStyles from './styles'
import { FormattedMessage } from 'react-intl'
import RowContainer from './RowContainer'

const StatusDetail = ({ order, index }) => {
  const classes = useStyles()
  return (
    <RowContainer>
      <Grid item className={classes.orderStatus}>
        <Typography variant='h6' component='p' className={classes.order}>
          <FormattedMessage id={index === 0 ? 'order-done' : 'order-upcoming'} />
        </Typography>
        <Typography variant='h6' component='p' className={classes.orderDeliveryDetail}>
          {index === 0
            ? `${order.deliveryDate} at ${order.deliveryHour} Order Number ${order.orderNumber}`
            : `Order Number ${order.orderNumber}`}
        </Typography>
      </Grid>
      <Grid item className={classes.iconBlock}>
        <CheckIcon color='primary' fontSize='medium' />
      </Grid>
    </RowContainer>
  )
}

StatusDetail.propTypes = {
  order: PropTypes.object,
  index: PropTypes.number,
}

export default React.memo(StatusDetail)
