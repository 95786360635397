import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { Button, Typography, IconButton } from '@material-ui/core'
import closeIconButton from '../../images/close-icon.svg'
import deleteCardImage from '../../images/delete-card-image.svg'
import deleteAddressImage from '../../images/delete-address-image.svg'
import { GreenButton } from '../utils/tools'
import newOrderImage from '../../images/new-order-image.svg'
import noResponse from '../../images/no_response.svg'
import newPaymentMethod from '../../images/new-payment-method-image.svg'
import deleteFavoriteImage from '../../images/delete-favorite-image.svg'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 200,
    marginTop: 0,
    marginBottom: 5,
  },
  headerContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 85,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  header: {
    width: '100%',
    height: 'auto',
    color: 'black',
    fontSize: 34,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  textContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 20,
  },
  textContainer2: {
    width: '100%',
    height: 'auto',
    minHeight: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  text: {
    width: '100%',
    height: 'auto',
    color: 'black',
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 5,
  },
  buttonContainer: {
    width: '100%',
    height: 21,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  button: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#707070',
    fontSize: 16,
    textAlign: 'center',
    marginRight: 15,
  },
  buttonRed: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#ea4335',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonGreen: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#69e781',
    fontSize: 16,
    textAlign: 'center',
  },
  greenButton: {
    width: 263,
    height: 48,
    textTransform: 'capitalize',
    color: 'white',
  },
})

const MobileDialog = ({
  classes,
  intl,
  close,
  closeIcon,
  header,
  text,
  text2,
  function1,
  function2,
  sizeW,
  sizeH,
  img,
  greenButton,
  button1,
  button2,
  id,
}) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const imageHandler = () => {
    switch (img) {
      case 'deleteCardImage':
        return deleteCardImage
      case 'deleteAddressImage':
        return deleteAddressImage
      case 'newOrderImage':
        return newOrderImage
      case 'noResponse':
        return noResponse
      case 'newPaymentMethod':
        return newPaymentMethod
      case 'deleteFavoriteImage':
        return deleteFavoriteImage
      default:
        return null
    }
  }

  const dialogImage = imageHandler()

  return (
    <div className={classes.root}>
      {closeIcon && (
        <div className={classes.dialogIconButtonContainer}>
          <IconButton onClick={() => close()} style={{ backgroundColor: 'transparent' }}>
            <img src={closeIconButton} alt='close-icon' height='20px' width='20px' />
          </IconButton>
        </div>
      )}
      <div className={classes.imgContainer}>
        <img src={dialogImage} alt='img' width={sizeW} height={sizeH} />
      </div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>{header}</Typography>
      </div>
      <div className={greenButton ? classes.textContainer2 : classes.textContainer}>
        <Typography className={classes.text}>{text}</Typography>
        {text2 && <Typography className={classes.text}>{text2}</Typography>}
      </div>
      {greenButton ? (
        <GreenButton className={classes.greenButton} onClick={() => close()}>
          {getLabel('continue-label')}
        </GreenButton>
      ) : (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} style={{ backgroundColor: 'transparent' }} onClick={() => function1()}>
            {button1}
          </Button>
          <Button
            className={button2 === 'Delete' ? classes.buttonRed : classes.buttonGreen}
            style={{ backgroundColor: 'transparent' }}
            onClick={!id ? function2 : () => function2(id)}
          >
            {button2}
          </Button>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(injectIntl(MobileDialog))
