/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { Scrollbars } from 'react-custom-scrollbars'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { orderActions } from '../../../actions'
import { showWarnBox } from '../../../helpers/cart/showWarnBox'
import { scrollHeight } from '../../../helpers/quickCart/scrollHeight'
import GreenSelect from '../../common/GreenSelect'
import { createNewOrder } from '../../../helpers/cart/create-new-order'

import EmptyQuickCart from './empty_quick_cart'
import SelectedMenu from './WebCart/SelectedMenu'
import CartMenuItemCL from '../../contentLoaders/CartMenuItemCL'
import CartPricesCL from '../../contentLoaders/CartPricesCL'
import CommissionTextCL from '../../contentLoaders/CommissionTextCL'
import CheckoutButtonCL from '../../contentLoaders/CheckoutButtonCL'
import CheckoutButton from './QuickCart/CheckoutButton'
import CartPrice from './QuickCart/CartPrice'
import Names from './QuickCart/Names'
import useStyles from './QuickCart/styles'
import { createRequestBody } from '../../../helpers/order-menu/request-body'

const QuickCart = ({ onClose, onCheckout }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const removeMenu = (index) => dispatch(orderActions.removeMenuFromOrder(index))
  const setQuantity = (store, order) => dispatch(orderActions.selectMenuQuantity(store, order))
  const previewOrder = (query) => dispatch(orderActions.setOrderFees(query))
  const deleteOrder = (id) => dispatch(orderActions.deleteOrder(id))
  const clearMenus = () => dispatch(orderActions.clearMenus())

  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const store = useSelector(({ stores }) => stores.customerCurrentStore, shallowEqual)
  const query = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const customerInfo = useSelector(({ customer }) => customer.customerInfo, shallowEqual)
  const orders = useSelector(({ orders }) => orders, shallowEqual)

  useEffect(() => {
    let active = true
    if (active) {
      if (cart.orderMenus?.length > 0) {
        // const params = { query, cart, customerInfo }
        const previewQuery = createRequestBody(query, customerInfo, cart)
        previewOrder(previewQuery)
      }
    }
    return () => {
      active = false
    }
  }, [JSON.stringify(cart?.orderMenus), query, customerInfo])

  const handleQuantity = (e, index) => {
    const { newOrder } = createNewOrder(e, index, cart)
    setQuantity(store, newOrder)
  }

  const deleteMenuItem = (index) => {
    const { cart, savedOrder } = orders
    removeMenu(index)
    if (cart?.orderMenus?.length === 1) {
      clearMenus()
      if (savedOrder?.id) {
        deleteOrder(savedOrder?.id)
      }
    }
  }

  const openCart = () => {
    onClose()
    onCheckout()
  }

  let itemCount = cart?.orderMenus?.reduce((total, menuItem) => total + menuItem.quantity, 0)

  const warnActive = showWarnBox(cart)
  const disableCondition = orders?.loading || warnActive === 'visible'

  return (
    <div className={classes.root}>
      {itemCount === 0 ? (
        <div className={classes.emptyCartContainer}>
          <div style={{ width: '70%' }}>
            <EmptyQuickCart callbackParent={onClose} />
          </div>
        </div>
      ) : (
        <div className={clsx('column', [classes.cartLeftPart])}>
          <Grid container>
            <Names customerInfo={customerInfo} cart={cart} />
            <Grid item xs={12} className={classes.selectContainerGrid}>
              <Scrollbars style={{ height: scrollHeight(signedIn, warnActive) }}>
                <Container className={classes.selectContainer}>
                  {cart?.orderMenus?.map((om, index) =>
                    orders?.loading ? (
                      <CartMenuItemCL key={index} />
                    ) : (
                      <Grid container key={index} className={classes.selectGrid}>
                        <Grid xs={2} item>
                          <GreenSelect
                            loading={orders?.loading}
                            menu={om}
                            index={index}
                            handleQuantityChange={handleQuantity}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <SelectedMenu
                            menu={om}
                            index={index}
                            loading={orders?.loading}
                            deleteMenuItem={deleteMenuItem}
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                </Container>
                <div className={classes.cartItem}>
                  {orders?.loading ? <CartPricesCL /> : <CartPrice itemCount={itemCount} cart={cart} />}
                </div>
                <Grid container direction='row'>
                  <Grid item xs={12}>
                    {orders?.loading ? (
                      <CommissionTextCL />
                    ) : (
                      <Typography className={classes.commissionText}>{f({ id: 'commission-text' })}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Scrollbars>
            </Grid>
          </Grid>
          {warnActive === 'visible' && !orders?.loading && (
            <div className={classes.warnBox} content-visible={warnActive}>
              {f({ id: 'warn-text' })}
            </div>
          )}
          {orders?.loading ? (
            <CheckoutButtonCL />
          ) : (
            <CheckoutButton
              openCart={openCart}
              disableCondition={disableCondition}
              itemCount={itemCount}
              totalPrice={cart?.totalPrice}
            />
          )}
        </div>
      )}
    </div>
  )
}

QuickCart.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
}

export default QuickCart
