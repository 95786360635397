import React from 'react'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import IntersectionVisible from 'react-intersection-visible'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import IconButton from '@material-ui/core/IconButton'
import AttributeTitle from '../mobile-menu-item/AttributeTitle'
import Attributes from '../mobile-menu-item/Attributes'
import bglogo from '../../../../images/addMenu-placeholder.svg'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import { getDietaryIcon } from '../../../../helpers/dietary-icon'

import { servingTypes } from '../../../../constants'
import { getImage } from '../../../../helpers/get-image'

const MenuDialogContent = ({
  data,
  classes,
  attributes,
  menuAttributes,
  web,
  handleAttributes,
  checked,
  handleInstruction,
  quantity,
  incrementQty,
  decrementQty,
  toggleShowTitle,
  getLabel,
  specialInstructions,
  servingSize,
}) => {
  const image = getImage(data?.images[0], 'thumb') || bglogo

  const text =
    data?.servingType === servingTypes.PORTION ? (+data?.servingSize * quantity > 1 ? 'people' : 'person') : 'oz'

  const dietaryExist = data?.dietaries.length > 0

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={image} alt='menu-item' height='200' />
      </Grid>
      <Grid item container direction='column' className={classes.paper}>
        <Grid item>
          <IntersectionVisible onHide={() => toggleShowTitle(true)} onShow={() => toggleShowTitle(false)}>
            <Typography variant='h5' component='h5' className={classes.title} style={{ fontSize: web ? 22 : null }}>
              {data?.name}
            </Typography>
          </IntersectionVisible>
          {dietaryExist && (
            <div className='centered-row'>
              {data?.dietaries.map((dietary, index) => (
                <Tooltip key={index} title={dietary?.name} placement='bottom' arrow>
                  <img
                    alt=''
                    src={getDietaryIcon(dietary?.name)}
                    width='20'
                    height='20'
                    className={classes.dietaryIcon}
                  />
                </Tooltip>
              ))}
            </div>
          )}
          <Typography variant='body1' component='h6' className={classes.desc}>
            {data?.description}
          </Typography>
        </Grid>
        <Grid item container direction='column' className={classes.attributes}>
          {attributes?.map((attribute, i) => (
            <Grid item key={i}>
              <AttributeTitle attribute={attribute} classes={classes} menuAttributes={menuAttributes} web={web} />
              {attribute.values.map((value, ind) => (
                <Grid container item direction='column' key={ind}>
                  <Attributes
                    attribute={attribute}
                    value={value}
                    handleAttributes={handleAttributes}
                    menuAttributes={menuAttributes}
                    checked={checked}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <TextField
            multiline
            fullWidth
            rows={3}
            className={classes.instruction}
            label={getLabel('special-instructions')}
            variant='outlined'
            value={specialInstructions}
            helperText={`${specialInstructions.length}/250`}
            onChange={(event) => handleInstruction(event.target.value)}
            inputProps={{
              maxLength: 250,
            }}
            FormHelperTextProps={{
              className: clsx(classes.helperText, {
                [classes.helperTextRed]: specialInstructions.length === 250,
              }),
            }}
            InputProps={{
              classes: {
                input: classes.special,
              },
            }}
          />
        </Grid>
        <Grid item container alignItems='center' justify='center' className='centered-row'>
          <IconButton aria-label='decrement-qty' disabled={quantity === 1} onClick={decrementQty}>
            <RemoveCircleOutlineIcon />
          </IconButton>
          <Box
            height={35}
            width={75}
            border={2}
            borderRadius={8}
            borderColor={'#c4c4c4'}
            justifyContent='center'
            alignItems='center'
            display='flex'
            flexDirection='column'
            style={{ userSelect: 'none' }}
          >
            {`${quantity}`}
          </Box>
          <IconButton aria-label='increment-qty' onClick={incrementQty}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
        <Grid item container alignItems='center' justify='center' className='centered-row'>
          <Typography variant='body1' component='h6' className={classes.desc}>
            {`for ${servingSize * quantity}  ${text}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MenuDialogContent
