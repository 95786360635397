import { deliveryTypes, deliveryTimes } from './general.constants'

export const searchConstants = {
  RESET_SEARCH: 'RESET_SEARCH',

  SET_CATEGORY: 'SET_CATEGORY',
  ADD_CATEGORY_FILTER: 'ADD_CATEGORY_FILTER',
  REMOVE_CATEGORY_FILTER: 'REMOVE_CATEGORY_FILTER',
  ADD_DIETARY_FILTER: 'ADD_DIETARY_FILTER',
  REMOVE_DIETARY_FILTER: 'REMOVE_DIETARY_FILTER',
  SET_PRICEY_VALUE: 'SET_PRICEY_VALUE',
  REMOVE_PRICEY_FILTER: 'REMOVE_PRICEY_FILTER',
  SET_RATING_VALUE: 'SET_RATING_VALUE',
  REMOVE_RATING_FILTER: 'REMOVE_RATING_FILTER',
  SET_DISTANCE_VALUE: 'SET_DISTANCE_VALUE',
  REMOVE_DISTANCE_FILTER: 'REMOVE_DISTANCE_FILTER',
  SET_SEARCH_SORTBY_VALUE: 'SET_SEARCH_SORTBY_VALUE',
  REMOVE_SORTBY_FILTER: 'REMOVE_SORTBY_FILTER',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  REMOVE_SEARCH_TEXT: 'REMOVE_SEARCH_TEXT',
  SET_MORE_FILTERS: 'SET_MORE_FILTERS',
  SET_MORE_FILTERS_MOBILE: 'SET_MORE_FILTERS_MOBILE',
  SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_DELIVERYTIME: 'SET_DELIVERYTIME',
  SET_DELIVERYTIME_DETAIL: 'SET_DELIVERYTIME_DETAIL',
  SET_DIETARY_FILTERS: 'SET_DIETARY_FILTERS',
  SET_CATEGORY_FILTER: 'SET_CATEGORY_FILTER',
  DO_SEARCH_REQUEST: 'DO_SEARCH_REQUEST',
  DO_SEARCH_FAILURE: 'DO_SEARCH_FAILURE',
  DO_SEARCH_SUCCESS: 'DO_SEARCH_SUCCESS',
  SET_SEARCH_SORTBY_ORDER: 'SET_SEARCH_SORTBY_ORDER',
  TOGGLE_OPEN_FILTER: 'TOGGLE_OPEN_FILTER',
}

export const initialQuery = {
  latitude: '',
  longitude: '',
  distance: 10,
  searchText: '',
  name: '',
  sectionName: '',
  dietaryIds: [],
  categoryIds: [],
  priceBegin: '',
  priceEnd: '',
  orderByValue: 'asc',
  page: 0,
  size: 100,
  orderByKey: 'store_nickname_s',
  unit: 'K',
  address: null,
  deliveryTime: deliveryTimes.ASAP,
  deliveryTimeDetail: null,
  deliveryType: deliveryTypes.DELIVERY,
  district_id: 1,
  pricey: 2,
  rating: null,
  onlyOpen: false,
}
