import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}))

const Prices = ({ title, price, variant, oldPrice, pr = 1, pl = 1 }) => {
  const classes = useStyles()
  return (
    <Box mt={1} pb={1} pr={pr} pl={pl} display='flex' flexDirection='row' alignItems='center'>
      <Box width='50%'>
        <Typography variant={variant} color='textSecondary'>
          <FormattedMessage id={title} />
        </Typography>
      </Box>
      <Box width='50%' textAlign='right' className={classes.row}>
        {oldPrice && (
          <Typography
            variant={variant}
            color='textSecondary'
            style={{ paddingRight: 10, textDecorationLine: 'line-through' }}
          >
            $ {(+oldPrice)?.toFixed(2)}
          </Typography>
        )}
        <Typography variant={variant} style={{ color: '#000' }}>
          $ {(+price)?.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  )
}

Prices.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  oldPrice: PropTypes.number,
}

export default React.memo(Prices)
