import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Grow, IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { BlackButton } from '../../utils/tools'
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import MenuSection from './MenuSection'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import MerchantNoResult from '../common/MerchantNoResult'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
    marginLeft: theme.spacing(2),
  },
  new: {
    width: 300,
    marginRight: theme.spacing(),
  },
  noResultContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

const Menus = ({ intl }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  //Global states
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const sections = useSelector(({ merchantStores }) => merchantStores.sections)
  //Local State
  const [newSection, setNewSection] = useState('')
  const [adding, setAdding] = useState(false)
  // Actions
  const dispatch = useDispatch()
  const getSections = (id) => dispatch(merchantStoreActions.getAllSections(id))
  const createSection = (id, data) => dispatch(merchantStoreActions.createSection(id, data))

  useEffect(() => {
    selectedStore && getSections(selectedStore?.storeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const createNewSection = () => {
    let data = {
      name: newSection,
      description: '...',
      order_: sections.length,
    }
    createSection(selectedStore?.storeId, data)
    resetStates()
  }

  const resetStates = () => {
    setAdding((prev) => !prev)
    setNewSection('')
  }

  // if (!selectedStore || sections.length === 0) return null

  return (
    <div
      className={clsx('grow', classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Grid container style={{ marginTop: 70 }}>
        <Grid item xs={12} className='centered-row'>
          <div className='grow' />
          <Grow in={adding}>
            <TextField
              id='new-section'
              value={newSection}
              onChange={(event) => setNewSection(event.target.value)}
              className={classes.new}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={getLabel('cancel-label')}>
                      <IconButton color='inherit' size='small' onClick={resetStates} aria-label='close'>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grow>
          {adding ? (
            <BlackButton disabled={newSection.length === 0} onClick={createNewSection}>
              {getLabel('save-section')}
            </BlackButton>
          ) : (
            <BlackButton onClick={() => setAdding((prev) => !prev)}>{getLabel('add-section')}</BlackButton>
          )}
        </Grid>
        {sections.length > 0 ? (
          sections
            .sort((a, b) => a.order_ < b.order_)
            .map((section) => (
              <Grid item xs={12} className={classes.row} key={section.id}>
                <MenuSection section={section} />
              </Grid>
            ))
        ) : (
          <div className={classes.noResultContainer}>
            <MerchantNoResult text='no-section' />
          </div>
        )}
      </Grid>
    </div>
  )
}

Menus.propTypes = {
  intl: PropTypes.object,
}

export default withRouter(injectIntl(Menus))
