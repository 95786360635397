import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { CustomFormatCurrency } from '../../../utils/tools'

const MenuPrice = ({ menu, intl, handleChange }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item sm={12} xs={12}>
      <TextField
        variant='outlined'
        required
        style={{ width: 200 }}
        id='price'
        name='price'
        value={menu.price}
        label={getLabel('price-label')}
        onChange={handleChange}
        InputProps={{
          inputComponent: CustomFormatCurrency,
        }}
      />
    </Grid>
  )
}

MenuPrice.propTypes = {
  menu: PropTypes.object,
  handleChange: PropTypes.func,
}

export default injectIntl(MenuPrice)
