import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import backIcon from '../../../images/black-back.svg'
import StoreMoreHeader from './mobile-store-more/StoreMoreHeader'
import StoreMoreContent from './mobile-store-more/StoreMoreContent'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  banner: {
    width: '100%',
    backgroundColor: 'gray',
    height: 250,
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoRow: {
    marginBottom: 0,
    height: 122,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },
  paper: {
    width: 122,
    height: 122,
    backgroundColor: '#fff',
    marginTop: 10,
  },
  sections: {
    height: 36,
  },
  storeDetails: {
    paddingLeft: theme.spacing(),
    justifyContent: 'space-evenly',
  },
  icon: {
    // filter: 'drop-shadow(1px 1px 1px #333333)',
  },
  storeDetail: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#333333',
    // textShadow: '1px 1px 1px #333333',
    marginLeft: 5,
    marginRight: 5,
  },
  more: {
    textTransform: 'capitalize',
    height: 34,
    marginTop: 'auto',
    marginBottom: theme.spacing(),
  },
  nameRow: {
    paddingTop: 15,
    height: 75,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    backgroundColor: '#fff',
  },
  addressRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    backgroundColor: '#fff',
  },
  categoryRow: {
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },
  storeName: {
    fontSize: 28,
    // fontSize: '2.75vw',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    color: '#000',
  },
  storeNameLong: {
    fontSize: 24,
  },
  menuItem: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
  day: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#000',
  },
  scrollView: {
    marginBottom: theme.spacing(2),
  },
  header: {
    height: 40,
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}))

export const MobileStoreMore = ({ store, handleClose }) => {
  const classes = useStyles()
  const initialCenter = {
    lat: parseFloat(store.storeInformation.address.latitude),
    lng: parseFloat(store.storeInformation.address.longitude),
  }

  const [center, setCenter] = useState(initialCenter)
  // const [isInitial, setIsInitial] = useState(true)
  // const [zoom, setZoom] = useState(16)
  // const [setBounds] = useState(null)

  const searchQuery = useSelector(({ search }) => search.searchQuery)

  const onMapChange = (properties) => {
    setCenter(properties.center)
    // setBounds(properties.bounds)

    // if (
    //   this.state.isInitial &&
    //   this.checkIfPointInBounds(storeAddress, properties.bounds) &&
    //   this.checkIfPointInBounds(homeAddress, properties.bounds)
    // ) {
    //   this.setState({
    //     isInitial: false,
    //   })
    // } else if (
    //   this.state.isInitial &&
    //   (!this.checkIfPointInBounds(storeAddress, properties.bounds) ||
    //     !this.checkIfPointInBounds(homeAddress, properties.bounds))
    // ) {
    //   this.setState({
    //     zoom: this.state.zoom - 1,
    //   })
    // }
  }

  // const getInitialZoomLevel = (properties) => {
  //   let storeAddress = store.storeInformation.address
  //   let homeAddress = searchQuery.address

  //   if (parseInt(properties.zoom) > 8) {
  //     if (
  //       checkIfPointInBounds(storeAddress, properties.bounds) &&
  //       checkIfPointInBounds(homeAddress, properties.bounds)
  //     ) {
  //       return
  //     }
  //     setZoom(properties.zoom - 1)
  //   }
  // }

  // const checkIfPointInBounds = (point, bounds) => {
  //   return (
  //     bounds.ne.lat >= point.latitude &&
  //     point.latitude >= bounds.se.lat &&
  //     bounds.ne.lng <= point.longitude &&
  //     point.longitude <= bounds.nw.lng
  //   )
  // }

  return (
    <div className={classes.root}>
      <div className='flex-container flex-start centered-row'>
        <IconButton onClick={handleClose} aria-label='close'>
          <img src={backIcon} alt='Back' />
        </IconButton>
        {store && (
          <Typography variant='h6' className={classes.headerText}>
            {store.nickname}
          </Typography>
        )}
      </div>
      {store && (
        <StoreMoreHeader
          store={store}
          classes={classes}
          center={center}
          zoom={16}
          onMapChange={onMapChange}
          searchQuery={searchQuery}
        />
      )}

      <StoreMoreContent classes={classes} workingHours={store?.storeInformation.workingHours} store={store} />
    </div>
  )
}

MobileStoreMore.propTypes = {
  store: PropTypes.object,
  handleClose: PropTypes.func,
}

export default React.memo(MobileStoreMore)
