import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button, Grid, IconButton, makeStyles } from '@material-ui/core'
import asapIcon from '../../../../images/asap-green-top.svg'
import scheduleIcon from '../../../../images/schedule-green.svg'
import downIcon from '../../../../images/down-arrow-green.svg'
import { ReactComponent as TopFilterIcon } from '../../../../images/top-filter.svg'
import { useSelector } from 'react-redux'
import { deliveryTimes } from '../../../../constants'
import clsx from 'clsx'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  detailsBar: {
    padding: 4,
    backgroundColor: '#fff',
    alignItems: 'center',
    borderBottom: '1px solid #f7f7f7',
    position: 'relative',
  },
  deliveryDtailsButton: {
    textTransform: 'capitalize',
  },
  topFilter: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 0,
  },
  isSticky: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
    backgroundColor: '#ffffff !important',
    animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export const Header = ({ handleDialogOpen }) => {
  const classes = useStyles()
  const [isStickyHeader, setIsSticky] = useState(false)

  const searchQuery = useSelector(({ search }) => search.searchQuery)

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  const isSticky = (e) => {
    const scrollTop = window.scrollY
    if (scrollTop >= 100) {
      !isStickyHeader && setIsSticky((prev) => !prev)
    } else {
      isStickyHeader && setIsSticky((prev) => !prev)
    }
  }

  const getDeliveryDetailsLabel = () => {
    let deliveryTimeLabel = ''
    let deliveryAddressLabel = ''

    if (searchQuery.deliveryTime === deliveryTimes.ASAP) {
      deliveryTimeLabel = 'ASAP'
    } else {
      deliveryTimeLabel = moment(searchQuery.deliveryTimeDetail).format('ddd, MMM Do')
    }

    if (searchQuery.address) {
      if (searchQuery.address.addressLine1) {
        deliveryAddressLabel = searchQuery.address.addressLine1
      } else if (searchQuery.address.city) {
        deliveryAddressLabel = searchQuery.address.city
      }
    }

    return deliveryTimeLabel + ' | ' + deliveryAddressLabel
  }

  return (
    <Grid
      item
      xs={12}
      className={clsx('flex-container flex-center', [classes.detailsBar, { [classes.isSticky]: isStickyHeader }])}
    >
      <Button
        className={classes.deliveryDtailsButton}
        startIcon={
          <img
            src={searchQuery.deliveryTime === deliveryTimes.ASAP ? asapIcon : scheduleIcon}
            height='16'
            width='14'
            alt=''
          />
        }
        endIcon={<img src={downIcon} alt='down' height='10' width='10' />}
        color='primary'
        onClick={() => handleDialogOpen('deliveryDetails')}
      >
        {getDeliveryDetailsLabel()}
      </Button>
      <IconButton className={classes.topFilter} onClick={() => handleDialogOpen('filter')}>
        <TopFilterIcon />
      </IconButton>
    </Grid>
  )
}

Header.propTypes = {
  handleDialogOpen: PropTypes.func.isRequired,
}

export default injectIntl(Header)
