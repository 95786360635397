import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import { attributesList } from '../../../../helpers/attributes/attributesList'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { servingTypes } from '../../../../constants'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    padding: '5px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
    overflowX: 'hidden',
  },
  attributes: {
    marginLeft: theme.spacing(),
  },
  attributesContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  menuName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'normal',
    color: '#0d0d0d',
    overflowX: 'hidden',
    "&[data-content='attr']": {
      fontSize: 14,
      color: '#707070',
    },
  },
  priceDelete: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  orderMenuPrice: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    fontWeight: 'bold',
    fontSize: 16,
    "&[data-content='attr']": {
      fontWeight: 'normal',
      fontSize: 14,
      color: '#707070',
      paddingRight: 28,
    },
  },
  delete: {
    // marginRight: -3,
    //"&[data-content='attr']": {
    //  fontSize: 10,
    //  color: "#707070",
    //   '& svg': {
    //     fontSize: 20,
    //     opacity: '0.6',
    //     marginRight: 1
    //   },
    //},
  },
  specialInstructions: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    overflow: 'hidden',
    fontStyle: 'italic',
    fontSize: 14,
    color: '#707070',
    maxWidth: 200,
  },
  detailContainer: {
    width: '42px',
    // paddingLeft: 44.5,
    minHeight: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  detailText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#000000',
  },
}))

const SelectedMenu = ({ menu, index, loading, deleteMenuItem }) => {
  const classes = useStyles()
  const attributes = attributesList(menu)
  const [selectedIndex, setSelectedIndex] = useState('')

  const text =
    menu?.servingType === servingTypes.PORTION ? (+menu?.servingSize * menu?.quantity > 1 ? 'people' : 'person') : 'oz'

  const deleteHandler = () => deleteMenuItem(index)

  const handleCollapse = (i) => {
    setSelectedIndex(selectedIndex === i ? '' : i)
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={9} className={classes.menuName}>
          {menu.name}
        </Grid>
        <Grid item xs={3} className={classes.priceDelete}>
          <div className={classes.orderMenuPrice}>${(menu.price * menu.quantity).toFixed(2)}</div>
          <IconButton
            className={classes.delete}
            aria-label='delete'
            size='small'
            onClick={deleteHandler}
            disabled={loading}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.servingSize}>
          <Typography variant='caption'>{` for ${+menu?.servingSize * menu?.quantity} ${text}`}</Typography>
        </Grid>
      </Grid>
      {attributes.length > 0 && (
        <>
          <div onClick={() => handleCollapse(index)} className={classes.detailContainer}>
            <Typography className={classes.detailText}>Detail</Typography>
            {index === selectedIndex ? (
              <ExpandMoreIcon style={{ marginBottom: 2 }} />
            ) : (
              <NavigateNextIcon style={{ marginBottom: 2 }} />
            )}
          </div>

          <Collapse in={index === selectedIndex} timeout='auto' unmountOnExit style={{ paddingLeft: 0 }}>
            <div className={classes.attributesContainer}>
              {attributes.map((attr, index) => (
                <Grid container key={index} className={clsx(classes.container, classes.attributes)}>
                  <Grid item xs={9} className={classes.menuName} data-content={'attr'}>
                    {attr.name}
                  </Grid>
                  <Grid item xs={3} className={classes.priceDelete}>
                    {/* <div className={classes.orderMenuPrice} data-content={'attr'}>
                (${attr.price.toFixed(2)})
              </div> */}
                  </Grid>
                </Grid>
              ))}
            </div>
          </Collapse>
        </>
      )}
      {menu.specialInstructions && <div className={classes.specialInstructions}>{menu.specialInstructions}</div>}
    </div>
  )
}

export default SelectedMenu
