import React from 'react'
import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@material-ui/core'

const MultiSelect = ({ value, attribute, handleAttributes, menuAttributes, checked }) => {
  const relatedCheckedCount =
    Object.keys(checked).length > 0 && checked[attribute.id]?.length === attribute.maximumCount
  const relatedCheckedItems = Object.keys(checked).length > 0 && checked[attribute.id]?.includes(value.id)

  return (
    <React.Fragment>
      <Grid item xs={10} container direction='row'>
        <FormControl component='fieldset'>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => handleAttributes(attribute, value)}
                color='primary'
                disabled={relatedCheckedCount && !relatedCheckedItems}
                value={value.name}
              />
            }
            label={`${value.name}`}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={2} direction='row' alignItems='center' justify='center'>
        <Typography variant='body1'>{value.price !== 0 ? `$${value.price.toFixed(2)}` : null}</Typography>
      </Grid>
    </React.Fragment>
  )
}

export default MultiSelect
