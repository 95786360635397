import React from 'react'
import PropTypes from 'prop-types'
import { days, deliveryTypes } from '../../../../constants'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { handleWorkingDays } from '../../../../helpers/mobileStore/WorkingDays'

function StoreMoreContent({ classes, workingHours, store, intl, web }) {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  const workingDays = handleWorkingDays(workingHours)

  const getWorkingHours = (wd, dt) => {
    if (!store) return null

    let hours = store.storeInformation.workingHours.filter((wh) => wh.orderType === dt && wh.day === wd)

    return (
      <Grid container className='full-width'>
        <Grid item xs={7}>
          <div className='column'>
            {hours.map((hour) => (
              <Typography key={hour.id} className={classes.workingHour}>
                {`${hour.fromTime} - ${hour.toTime}`}
              </Typography>
            ))}
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className='column'>
            {hours.length > 0 && (
              <Typography className={classes.deliveryType}>
                {getLabel(`${dt.toString().toLowerCase()}-label`)}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.content}>
      <div className={clsx('vertical-scrolling-wrapper-flexbox', classes.scrollView)}>
        <Grid container>
          {workingDays?.map((workingDay, i) => (
            <Grid key={i} item xs={web ? 6 : 12} className='column' style={{ marginTop: web ? 13 : 20 }}>
              <Typography className={classes.day}>{getLabel(`${workingDay.toLowerCase()}-label`)}</Typography>
              {getWorkingHours(days[`${workingDay}`], deliveryTypes.DELIVERY)}
              {getWorkingHours(days[`${workingDay}`], deliveryTypes.TAKEOUT)}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

StoreMoreContent.propTypes = {
  classes: PropTypes.object,
  getLabel: PropTypes.func,
  workingHours: PropTypes.array,
  store: PropTypes.object,
  web: PropTypes.bool,
}

export default injectIntl(StoreMoreContent)
