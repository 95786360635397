import React from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { Grid, IconButton, Typography } from '@material-ui/core'
import backIcon from '../../../../images/black-back.svg'
import closeIcon from '../../../../images/close.svg'
import receipt from '../../../../images/receipt-image.svg'
import greenLogo from '../../../../images/logo-green.svg'
import { isMobileOnly } from 'react-device-detect'
import useStyles from './styles'
import DeliveryDetails from './mobile-orders/mobile-order-detail/DeliveryDetails'
import { deliveryTypes, orderStatuses } from '../../../../constants'
import { isEmpty } from 'lodash'
import AttributeDetails from '../../../common/AttributeDetails'
import { QuantityBox } from '../../../common/QuantityBox'
import Scrollbars from 'react-custom-scrollbars'
import { checkDeliveryDiscount, checkSubtotalDiscount } from '../../../../helpers/promotion/delivery-discount'
import Promo from '../../cart/mobile-cart/check-out/Promo'
import Prices from '../../../common/Prices'
import moment from 'moment'

const Receipt = ({ handleClose, order }) => {
  const classes = useStyles()
  const isTakeOut = order?.orderType === deliveryTypes.TAKEOUT

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.rootReceiptWeb}>
      {isMobileOnly ? (
        <div className={clsx('mobile-top-bar', classes.receiptHeader)}>
          <IconButton size='small' className={classes.back} onClick={handleClose}>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography variant='h6' className={classes.receiptText}>
            <FormattedMessage id='receipt-label' />
          </Typography>
        </div>
      ) : (
        <div className={classes.iconContainer}>
          <IconButton aria-label='close' className={classes.close} onClick={handleClose}>
            <img src={closeIcon} alt='Close' width='30px' height='30px' className={classes.closeImage} />
          </IconButton>
        </div>
      )}
      <Scrollbars style={{ height: isMobileOnly ? '100%' : 640, widht: '100%' }}>
        <div className={clsx('centered-row', classes.receiptRow)}>
          <Typography variant='h5' className={classes.priceTitle}>
            $ {order.totalPrice}
          </Typography>
          <img src={greenLogo} alt='logo' width='84px' height='84px' className={classes.logo} />
        </div>

        <div className={clsx('centered-row', classes.greetingsContainer)}>
          <Typography variant='body1' className={classes.regularText}>
            <FormattedMessage id='receipt-greeting' />
            {order.orderAddress.firstName}
          </Typography>
        </div>

        <div className={classes.receiptImage}>
          <img src={receipt} alt='receipt' />
        </div>

        <div className={clsx('centered-column', classes.dateContainer)}>
          <div className='centered-column'>
            <Typography variant='body1' className={clsx(classes.regularText, classes.bold)}>
              <FormattedMessage id='order-date' />
            </Typography>
            <div className='centered-row'>
              <Typography variant='body1' className={classes.regularText}>
                {moment(order.creationDate).format('MMMM D, YYYY HH:mm')}
              </Typography>
            </div>
          </div>
          {order.status.name === orderStatuses.DELIVERED && (
            <div className='centered-column'>
              <Typography variant='body1' className={clsx(classes.regularText, classes.bold)}>
                <FormattedMessage id='delivery-date' />
              </Typography>
              <div className='centered-row'>
                <Typography variant='body1' className={classes.regularText}>
                  {moment(order.deliveryDate).format('LL')}
                </Typography>{' '}
                <Typography variant='body1' className={classes.regularText} style={{ paddingLeft: 5 }}>
                  {order.deliveryHour}
                </Typography>
              </div>
            </div>
          )}
        </div>

        {order.orderType === deliveryTypes.DELIVERY && (
          <div className={classes.deliveryContainer}>
            <Grid item xs={8} className='centered-row'>
              <DeliveryDetails order={order} />
            </Grid>
            <Grid item xs={4} className='centered-row'>
              {/* <Typography variant='body1' className={classes.regularText}>
              5 miles | 32 mins
            </Typography> */}
            </Grid>
          </div>
        )}
        <div style={{ paddingBottom: 80 }} className={classes.mobileContainer}>
          <div className={classes.menuContainer}>
            <div className='centered-row'>
              <Typography variant='body1' className={classes.storeName} noWrap>
                {order.store.nickname}
              </Typography>
            </div>

            <div className='column'>
              {order?.orderDetails.map((orderDetail, i) => (
                <div key={i}>
                  <Grid container direction='row'>
                    <Grid item xs={1} className={classes.box}>
                      <QuantityBox>{orderDetail?.quantity}</QuantityBox>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body1' className={classes.menuTitle} noWrap>
                        {orderDetail?.orderMenu?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.receiptPriceContainer}>
                      <Typography variant='body1' className={classes.menuTitle} noWrap>
                        ${orderDetail?.totalRegularPrice?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!isEmpty(orderDetail?.attributes) && <AttributeDetails i={i} orderDetail={orderDetail} mobile />}
                </div>
              ))}
              {!!order?.promotion && !!order?.discountPrice && <Promo promotion={order?.promotion} pr={0} pl={0} />}
              <Prices
                title={'subtotal-label'}
                price={+order?.finalSubtotalPrice}
                oldPrice={checkSubtotalDiscount(order)}
                variant='body1'
                pr={0}
                pl={0}
              />
              {!isTakeOut && (
                <Prices
                  title='delivery-fee'
                  price={order?.deliveryFee}
                  oldPrice={checkDeliveryDiscount(order)}
                  variant={'body1'}
                  pr={0}
                  pl={0}
                />
              )}
              {!isTakeOut && !!order?.driverTipPrice && (
                <Prices title='tip-label' price={order?.driverTipPrice} variant={'body1'} pr={0} pl={0} />
              )}
            </div>
          </div>
        </div>
      </Scrollbars>
      <div
        style={{ backgroundColor: 'white' }}
        className={isMobileOnly ? classes.receiptBottomContainer : classes.receiptBottomContainer}
      >
        <div className='column' style={{ paddingRight: 12 }}>
          <div className='centered-row' style={{ marginBottom: 10, marginTop: 13.5 }}>
            <div className={clsx(classes.flexText, 'right-column')}>
              <Typography variant='body1' className={clsx(classes.regularText, classes.caption)}>
                <FormattedMessage id='taxes-label' />
              </Typography>
            </div>
            <div className={clsx(classes.flexPrice, 'right-column')}>
              <Typography variant='body1' className={clsx(classes.regularText, classes.caption)}>
                ${order.taxPrice}
              </Typography>
            </div>
          </div>
          <div className='centered-row'>
            <div className={clsx(classes.flexText, 'right-column')}>
              <Typography variant='body1' className={clsx(classes.regularText, classes.body, classes.bold)}>
                <FormattedMessage id='total-label' />
              </Typography>
            </div>
            <div className={clsx(classes.flexPrice, 'right-column')}>
              <Typography variant='body1' className={clsx(classes.regularText, classes.body, classes.bold)}>
                ${order.totalPrice}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Receipt)
