import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Dialog,
  IconButton,
  Container,
  Grid,
  Card,
  Divider,
  Typography,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Box,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'
import MenuImageUpload from '../MenuImageUpload'
import StoreMenuSample from '../sampleVisuals/StoreMenuSample'
import StoreMenuDialogSample from '../sampleVisuals/StoreMenuDialogSample'
import { merchantStoreActions } from '../../../../../actions/merchant.store.actions'
import { getImage } from '../../../../../helpers/get-image'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
  },
  imageUploadContainer: {
    width: '100%',
    height: 240,
  },
  dialogIconButtonContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  appBar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: 'none',
  },
  dialogTitle: {
    flex: 1,
    fontSize: 18,
  },
  imgCard: {
    minWidth: 225,
    maxWidth: 225,
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    userSelect: 'none',
    transform: 'scale(1)',
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      transform: 'scale(1.05)',
      transition: `all 200ms ${theme.transitions.easing.easeIn}`,
    },
  },
  miniIconButtonsContainer: {
    width: 225,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
    // alignItems: 'center',
    marginTop: 5,
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  miniIconButtons: {
    height: 30,
    width: 30,
  },
  saveIconButton: {
    color: '#69e781',
    fontSize: 25,
    transform: 'scale(1)',
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      transition: `all 200ms ${theme.transitions.easing.easeIn}`,
      transform: 'scale(1.2)',
    },
  },
  deleteIconButton: {
    color: '#E63946',
    fontSize: 25,
    transform: 'scale(1)',
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      transition: `all 200ms ${theme.transitions.easing.easeIn}`,
      transform: 'scale(1.2)',
    },
  },
  divider: {
    minWidth: '100%',
  },
  samplePartContainer: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 15,
  },
  storeMenuSampleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  imagesContainer: {
    width: '100%',
    minHeight: 180,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-evenly',
    // backgroundColor: 'red',
  },
  warnBox: {
    height: 80,
    width: 160,
    padding: '5px 10px',
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    userSelect: 'none',
  },
  warnBoxDelete: {
    color: '#E63946',
    textTransform: 'capitalize',
  },
  warnBoxCancel: {
    color: 'black',
    textTransform: 'capitalize',
  },
  warnBoxText: {
    textAlign: 'center',
    color: '#707070',
    fontSize: 15,
  },
  iconButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const ManuImageDialog = ({ open, handleClose, item, sectionId }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const deleteImage = (storeId, sectionId, itemId, imageId) =>
    dispatch(merchantStoreActions.deleteMenuImage(storeId, sectionId, itemId, imageId))

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const storeId = selectedStore.storeId

  const [croppedArea, setCroppedArea] = useState(null)
  const [selected, setSelected] = useState(false)
  const [id, setId] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [indexValue, setIndexValue] = useState(null)
  const [disabled, setDisabled] = useState(null)

  const handleCroppedArea = (image) => {
    setCroppedArea(image)
  }

  const handleImageSelect = (path, id) => {
    setId(id)
    setCroppedArea(path)
    setSelected(true)
  }

  const onDelete = (storeId, sectionId, itemId, imageId) => {
    setDisabled(null)
    setIndexValue(null)
    if (imageId === id) {
      setCroppedArea(null)
    }
    deleteImage(storeId, sectionId, itemId, imageId)
  }

  const onCancel = () => {
    setDisabled(null)
    setIndexValue(null)
  }

  const onDeleteIntent = (e) => {
    setIndexValue(e.currentTarget.getAttribute('indexvalue'))
    setDisabled(true)
  }

  const onCloseDialog = () => {
    setDisabled(null)
    setIndexValue(null)
    handleClose()
  }

  const handleSelectTab = (value) => {
    onCancel()
    setSelectedTab(value)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color='inherit' onClick={onCloseDialog} aria-label='Close'>
            <CloseIcon />
          </IconButton>
          <Typography color='inherit' className={classes.dialogTitle}>
            {f({ id: 'menu-image-selection' })}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth='lg' className={classes.paper}>
        <div className={classes.imageUploadContainer}>
          <MenuImageUpload
            handleCroppedArea={handleCroppedArea}
            item={item}
            sectionId={sectionId}
            imageSelected={selected}
            buttonsClicked={onCancel}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.samplePartContainer}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Tabs
                value={selectedTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={(event, newValue) => handleSelectTab(newValue)}
                aria-label='samples visual tab'
              >
                <Tab label={f({ id: 'menu-sample' })} />
                <Tab label={f({ id: 'menu-dialog-sample' })} />
              </Tabs>
              <TabPanel value={selectedTab} index={0}>
                <StoreMenuSample img={croppedArea} item={item} />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <StoreMenuDialogSample img={croppedArea} item={item} />
              </TabPanel>
            </Grid>
            <Grid item xs={6} className={classes.storeMenuSampleContainer}>
              <Grid container spacing={1} className={classes.imagesContainer}>
                {item?.images?.map((img, index) => (
                  <Grid item xs={6} key={index}>
                    <Card className={clsx([classes.imgCard], [classes.wiggle])}>
                      <img
                        className={classes.img}
                        src={getImage(img, 'thumb')}
                        alt={`${img.filename}`}
                        height='100px'
                        width='225px'
                      />
                    </Card>
                    <div className={classes.miniIconButtonsContainer}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        }}
                      >
                        {/* <IconButton
                            className={classes.miniIconButtons}
                            onClick={() =>
                              handleImageSelect(`${img.path}/big/${img.filename}.${img.extension}`, img.id)
                            }
                            disabled={!!disabled}
                          >
                            <CheckCircleIcon className={classes.saveIconButton} />
                          </IconButton> */}
                        <IconButton
                          className={classes.miniIconButtons}
                          onClick={(e) => onDeleteIntent(e)}
                          indexvalue={index}
                          disabled={!!disabled}
                        >
                          <CancelIcon className={classes.deleteIconButton} />
                        </IconButton>
                      </div>
                      <Card
                        style={{
                          opacity: indexValue && +indexValue === index ? 1 : 0,
                        }}
                        className={classes.warnBox}
                      >
                        <Typography className={classes.warnBoxText}>{f({ id: 'sure-to-delete-image' })}</Typography>
                        <div>
                          <Button
                            disableRipple
                            style={{ backgroundColor: 'transparent' }}
                            className={classes.warnBoxDelete}
                            onClick={() => onDelete(storeId, sectionId, item.id, img.id)}
                          >
                            {f({ id: 'delete-label' })}
                          </Button>
                          <Button
                            disableRipple
                            style={{ backgroundColor: 'transparent' }}
                            className={classes.warnBoxCancel}
                            onClick={onCancel}
                          >
                            {f({ id: 'cancel-label' })}
                          </Button>
                        </div>
                      </Card>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Dialog>
  )
}

export default ManuImageDialog
