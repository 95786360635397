import { dateConstant, deliveryTimes } from '../../constants'
import { queryInitialState } from '../../constants/mobile-food/mobile-food'
import moment from 'moment'

export const stateMaker = (searchQuery) => {
  const orderTime =
    searchQuery.deliveryTime === deliveryTimes.ASAP
      ? moment().format(dateConstant.dateFormat)
      : moment(searchQuery.deliveryTimeDetail).format(dateConstant.dateFormat)

  const newState = {
    ...queryInitialState,
    categoryIds: searchQuery.categoryIds,
    dietaryIds: searchQuery.dietaryIds,
    pricey: searchQuery.pricey,
    rating: searchQuery.rating,
    distance: searchQuery.distance,
    orderByKey: searchQuery.orderByKey,
    orderByValue: searchQuery.orderByValue,
    latitude: searchQuery.address?.latitude,
    longitude: searchQuery.address?.longitude,
    orderTime: orderTime,
    orderType: searchQuery.deliveryType,
    onlyOpen: searchQuery.onlyOpen,
  }
  return newState
}
