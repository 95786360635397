import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core'
import Footer from '../Footers'
import CategorySearchResult from './search-main/CategorySearchResult'
import TextSearchResult from './text_search_results'
import { setMainMenu } from '../../../actions/general.actions'
import { bottomNavItems, dateConstant, deliveryTimes, generalConstants, initialQueryState } from '../../../constants'
import { searchActions } from '../../../actions'
import moment from 'moment'
import { searchService } from '../../../services'
import { fetchMoreCheck, recentlySearchedWords } from '../../../helpers/search'
import { useDispatch, useSelector } from 'react-redux'
import InitialStep from './search-main/InitialStep'
import FirstStep from './search-main/FirstStep'
import Loading from '../../common/loading'
import AddressDialog from '../../common/AddressDialog'

// const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: height,
    // maxHeight: height,
    flexWrap: 'nowrap',
  },
}))

export const MobileSearchMain = ({ location }) => {
  const classes = useStyles()
  //Global states
  const categories = useSelector(({ categories }) => categories.data)
  const searchQuery = useSelector(({ search }) => search.searchQuery)

  //Local states
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [step, setStep] = useState(0)
  const [showLogin, setShowLogin] = useState(false)
  const [searchResult, setSearchResult] = useState()
  const [query, setQuery] = useState(initialQueryState)
  const [loading, setLoading] = useState(false)
  const [showAddressDialog, setShowAddressDialog] = useState(false)

  //Actions
  const dispatch = useDispatch()
  const setSearchTextFilter = (word) => dispatch(searchActions.setSearchTextFilter(word))

  useEffect(() => {
    dispatch(setMainMenu(bottomNavItems.SEARCH))
    !searchQuery.address ? toggleAddressDialog() : queryMaker()
    if (location.state) {
      setSelectedCategory(location.state.category)
      setStep(location.state.step)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    query.searchText && doSearch()
  }, [query.searchText])

  const queryMaker = () => {
    const orderTime =
      searchQuery.deliveryTime === deliveryTimes.ASAP
        ? moment().format(dateConstant.dateFormat)
        : moment(searchQuery.deliveryTimeDetail).format(dateConstant.dateFormat)
    let newState = {
      ...query,
      latitude: searchQuery.address.latitude,
      longitude: searchQuery.address.longitude,
      orderTime: orderTime,
      orderType: searchQuery.deliveryType,
      unit: 'M',
      distance: searchQuery.distance,
    }
    setQuery(newState)
  }

  const doSearch = async () => {
    setLoading(true)
    const res = await searchService.search(query)
    if (res.status === generalConstants.STATUS_OK) {
      let newSearchResultState = query.customOffset === 0 ? res.data.stores : [...searchResult, ...res.data.stores]
      let newQueryState = { ...query, customOffset: res.data.customOffset }
      setSearchResult(newSearchResultState)
      setQuery(newQueryState)
    }
    setLoading(false)
  }

  const handleSearch = async (category) => {
    const searchedAddress = await JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
    if (!searchedAddress) {
      toggleAddressDialog()
    } else {
      setSelectedCategory(category)
      setStep(3)
    }
  }

  const handleSearchClose = () => setStep(0)

  // const handleCategorySearchClose = () => {
  //   setShowCategorySearchDialog(false)
  // }

  const goBackHandler = () => setStep(1)

  const signInBackButtonHandler = () => setShowLogin(false)

  const showSignin = () => setShowLogin(true)

  const keywordSearch = async (keyword) => {
    const searchedAddress = await JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
    if (searchedAddress) {
      setSearchResult()
      recentlySearchedWords(keyword)
      setSearchTextFilter(keyword)
      let newState = { ...query, searchText: keyword, customOffset: 0 }
      setQuery(newState)
      setStep(2)
    } else {
      toggleAddressDialog()
    }
  }

  const fetchMore = () => {
    const { size, customOffset } = query
    const fetchMore = fetchMoreCheck(size, customOffset, searchResult)
    if (!fetchMore) return
    doSearch()
  }

  const goBackToInitialState = () => setStep(0)

  const toggleAddressDialog = () => setShowAddressDialog((prev) => !prev)

  if (!searchQuery.address) {
    return <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
  }

  return (
    <div className={classes.root}>
      <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
      <Loading open={loading} />
      {step === 0 && <InitialStep setStep={setStep} categories={categories} handleSearch={handleSearch} />}
      {step === 1 && <FirstStep handleClose={handleSearchClose} keywordSearch={keywordSearch} />}
      {step === 2 && (
        <TextSearchResult
          goBack={goBackHandler}
          showSignin={showSignin}
          fetchMore={fetchMore}
          searchResult={searchResult}
        />
      )}
      {step === 3 && (
        <CategorySearchResult category={selectedCategory} goBack={goBackToInitialState} showSignin={showSignin} />
      )}
      <div className='sticky-bottom' style={{ zIndex: 100 }}>
        <Footer showLogin={showLogin} goBackHandler={signInBackButtonHandler} invisible={step === 3} />
      </div>
    </div>
  )
}

MobileSearchMain.propTypes = {
  location: PropTypes.object,
}

export default withRouter(MobileSearchMain)
