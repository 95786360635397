import { merchantConstants, customerConstants, generalConstants } from '../constants'

const _merchantHeader = {
  data: {},
  headers: {
    'Content-Type': 'application/json',
  },
}

export function merchantHeader() {
  let merchant = JSON.parse(localStorage.getItem(merchantConstants.MERCHANT))

  if (merchant && merchant.token) {
    _merchantHeader.headers['Authorization'] = merchant.token
  }

  return _merchantHeader
}

const _merchantImageHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export function merchantImageHeader() {
  let merchant = JSON.parse(localStorage.getItem(merchantConstants.MERCHANT))

  if (merchant && merchant.token) {
    _merchantImageHeader.headers['Authorization'] = merchant.token
  }

  return _merchantImageHeader
}

const _uiHeader = {
  data: {},
  headers: {
    'Content-Type': 'application/json',
  },
}

export function uiHeader() {
  let searchedAddress = JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
  _uiHeader.headers['X-Latitude'] = searchedAddress && searchedAddress[0].latitude
  _uiHeader.headers['X-Longitude'] = searchedAddress && searchedAddress[0].longitude
  _uiHeader.headers['X-Timezone-Offset'] = searchedAddress && searchedAddress[0].timeZoneOffset
  return _uiHeader
}

const _authCustomerHeader = {
  data: {},
  headers: {
    'Content-Type': 'application/json',
  },
}

export function authCustomerHeader() {
  let customer = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER))

  if (customer && customer.token) {
    _authCustomerHeader.headers['Authorization'] = customer.token
    let searchedAddress = JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
    _authCustomerHeader.headers['X-Latitude'] = searchedAddress && searchedAddress[0].latitude
    _authCustomerHeader.headers['X-Longitude'] = searchedAddress && searchedAddress[0].longitude
    _authCustomerHeader.headers['X-Timezone-Offset'] = searchedAddress && searchedAddress[0].timeZoneOffset
  }
  return _authCustomerHeader
}

export const width = window.innerWidth

export const height = window.innerHeight
