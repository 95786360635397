import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { secondTier, valueConstants } from '../../Helpers'
import TierField from '../../Fields/TierField_'

const SecondTier = ({ classes, handleChange, thirdTierState, addTier, removeTier, values }) => {
  const { formatMessage: f } = useIntl()

  let disableCondition =
    values.secondTierDeliveryFee === '' ||
    values.secondTierMinOrder === '' ||
    values.secondTierMaxDistance === '' ||
    +values.secondTierMinOrder <= +values.firstTierMinOrder ||
    +values.secondTierMaxDistance <= +values.firstTierMaxDistance ||
    +values.secondTierDeliveryFee <= +values.firstTierDeliveryFee

  return (
    <div className={classes.tier}>
      <Grid container spacing={2} className='full-width'>
        {secondTier.map((tier, index) => (
          <Grid key={index} item md={4} sm={6} xs={12} className={classes.gridItem}>
            <TierField
              prop={tier.value}
              gridVal={12}
              handleChange={handleChange}
              startAdornment_={
                tier.value === valueConstants.secondTierDeliveryFee || tier.value === valueConstants.secondTierMinOrder
              }
              endAdornment_={tier.value === valueConstants.secondTierMaxDistance}
              values={values}
              disabled={thirdTierState}
            />
          </Grid>
        ))}
      </Grid>
      {!thirdTierState && (
        <div className={classes.buttonContainer}>
          <Button
            disableRipple
            variant='contained'
            color='primary'
            onClick={addTier}
            add-button='true'
            className={classes.button}
            disabled={disableCondition}
          >
            {f({ id: 'add-label' })}
          </Button>
          <Button disableRipple variant='contained' color='secondary' onClick={removeTier} className={classes.button}>
            {f({ id: 'remove-label' })}
          </Button>
        </div>
      )}
    </div>
  )
}

SecondTier.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  removeTier: PropTypes.func,
  addTier: PropTypes.func,
  thirdTierState: PropTypes.bool,
}

export default SecondTier
