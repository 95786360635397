import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { restaurantActions, merchantRestaurantActions } from '../../../actions'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import UploadImage from '../common/imageUpload/UploadImage'
import { restaurantService } from '../../../services/restaurant.service'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    flexGrow: 1,
    marginTop: 50,
  },
  justified: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function withContextHook(Component) {
  return function WrappedComponent(props) {
    const contextHook = useMerchantHomeContext()
    return <Component {...props} contextHook={contextHook} />
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 0,
      logoFile: null,
      backgroundFile: null,
      showAttributeDialog: false,
    }

    this.onDropLogo = this.onDropLogo.bind(this)
    this.onDropBackground = this.onDropBackground.bind(this)
  }

  componentDidMount() {
    const { restaurantId } = this.props
    this.getImages(restaurantId)
  }

  getImages = (id) => {
    const { saveBackgroundSuccess, saveLogoSuccess } = this.props
    restaurantService.getImageData(id).then((res) => {
      saveBackgroundSuccess(res.data.background)
      saveLogoSuccess(res.data.logo)
    })
  }

  onDropLogo(pictureFiles) {
    this.setState({ logoFile: pictureFiles[0] })
  }

  onDropBackground(pictureFiles) {
    this.setState({ backgroundFile: pictureFiles[0] })
  }

  saveLogo = () => {
    if (this.state.logoFile) this.props.saveLogo(this.props.restaurant.id, this.state.logoFile)
  }

  saveBackground = () => {
    if (this.state.backgroundFile) this.props.saveBackground(this.props.restaurantId, this.state.backgroundFile)
  }

  deleteLogo = () => {
    this.props.deleteLogo(this.props.restaurant.id)
  }

  deleteBackground = () => {
    this.props.deleteBackground(this.props.restaurant.id)
  }

  handleAttributeDialogClose = () => {
    this.setState({ showAttributeDialog: false })
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  render() {
    const { classes, contextHook } = this.props
    const { selectedTab } = this.state
    const {
      values: { classesFromParent, openSwipeable },
    } = contextHook

    return (
      <div
        className={clsx(classes.root, classesFromParent.pageContent, {
          [classesFromParent.pageContentShift]: openSwipeable,
        })}
      >
        {/* <div className='page-header'>
          <Typography variant='h4'>{this.getLabel('settings-label')}</Typography>
        </div> */}
        <Paper className={classes.paper}>
          <Tabs
            value={selectedTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(event, newValue) => this.setState({ selectedTab: newValue })}
            aria-label='settings tab'
          >
            <Tab label={this.getLabel('logo-label')} />
            <Tab label={this.getLabel('background-label')} />
            {/* <Tab label={this.getLabel('menu-attributes')} /> */}
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <Grid container>
              {/* <Grid item xs={12} className={classes.justified}>
                {restaurant.logo ? (
                  <Paper>
                    <img
                      alt='logo'
                      src={`${restaurant.logo.path}/big/${restaurant.logo.filename}.${restaurant.logo.extension}`}
                    />
                  </Paper>
                ) : (
                  <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    accept='accept=image/*'
                    buttonText='Choose images'
                    onChange={this.onDropLogo}
                    imgExtension={['.jpg', '.png']}
                    maxFileSize={5242880}
                    singleImage={true}
                  />
                )}
              </Grid>
              {logoFile && (
                <Grid item xs={12} className='centered-column'>
                  <GreenButton variant='contained' onClick={() => this.setState({ showLogoDialog: true })}>
                    {this.getLabel('save-logo')}
                  </GreenButton>
                </Grid>
              )}
              {restaurant.logo && (
                <Grid item xs={12} className='centered-column'>
                  <RedButton variant='contained' onClick={this.deleteLogo}>
                    {this.getLabel('delete-label')}
                  </RedButton>
                </Grid>
              )} */}
            </Grid>
            <UploadImage index={0} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Grid container>
              <UploadImage index={1} />
              {/* <Grid item xs={12} className={classes.justified}>
                {restaurant.background ? (
                  <Paper>
                    <img
                      alt='background'
                      src={`${restaurant.background.path}/big/${restaurant.background.filename}.${restaurant.background.extension}`}
                    />
                  </Paper>
                ) : (
                  <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    accept='accept=image/*'
                    buttonText='Choose images'
                    onChange={this.onDropBackground}
                    imgExtension={['.jpg', '.png']}
                    maxFileSize={5242880}
                    singleImage={true}
                  />
                )}
              </Grid>
              {backgroundFile && (
                <Grid item xs={12} className='centered-column'>
                  <GreenButton variant='contained' onClick={this.saveBackground}>
                    {this.getLabel('save-background')}
                  </GreenButton>
                </Grid>
              )}
              {restaurant.background && (
                <Grid item xs={12} className='centered-column'>
                  <RedButton variant='contained' onClick={this.deleteBackground}>
                    {this.getLabel('delete-label')}
                  </RedButton>
                </Grid>
              )} */}
            </Grid>
          </TabPanel>
          {/* <TabPanel value={selectedTab} index={2}>
            <BlackButton onClick={() => this.setState({ showAttributeDialog: true })}>
              {this.getLabel('add-new')}
            </BlackButton>
          </TabPanel> */}
        </Paper>
        {/* <Dialog open={showAttributeDialog} onClose={this.handleAttributeDialogClose} TransitionComponent={Transition}>
          <DialogTitle className={classes.dialogTitle}>{this.getLabel('add-address')}</DialogTitle>
          <DialogContent>
            <AddAttribute onClose={this.handleAttributeDialogClose} />
          </DialogContent>
        </Dialog> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { restaurant, merchantStores } = state
  return {
    restaurant: restaurant.data,
    restaurantId: merchantStores.selectedRestaurant?.restaurantId,
  }
}

const actionCreators = {
  saveLogo: restaurantActions.saveLogo,
  deleteLogo: restaurantActions.deleteLogo,
  saveBackground: restaurantActions.saveBackground,
  deleteBackground: restaurantActions.deleteBackground,
  saveBackgroundSuccess: merchantRestaurantActions.successBackgroundSave,
  saveLogoSuccess: merchantRestaurantActions.successLogoSave,
}

export default withStyles(styles)(
  withRouter(injectIntl(connect(mapStateToProps, actionCreators)(withContextHook(Settings))))
)
