import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  email: {
    fontSize: 11,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

export const UserEmail = React.memo(() => {
  const classes = useStyles()

  const merchant = useSelector(({ merchant }) => merchant.merchantInfo)
  return (
    <Grid container>
      <Typography variant='h6' className={classes.email}>
        {merchant.email}
      </Typography>
    </Grid>
  )
})
