import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { IconButton, makeStyles } from '@material-ui/core'
import notFavoritedIcon from '../../../images/gray-favorite.svg'
import favoritedIcon from '../../../images/green-favorite.svg'

const useStyles = makeStyles((theme) => ({
  favorite: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    filter: 'drop-shadow(1px 1px 5px black)',
    "&[store-open='false']": {
      opacity: '0.8',
    },
  },
}))

const FavoriteButton = ({ isFavorite, onPress, data }) => {
  const classes = useStyles()
  let storeOpen = data.open ? 'true' : 'false'

  return (
    <IconButton store-open={storeOpen} className={classes.favorite} onClick={() => onPress(data.id)}>
      <img src={isFavorite ? favoritedIcon : notFavoritedIcon} height='19.6' width='19.6' alt='favorite' />
    </IconButton>
  )
}

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  onPress: PropTypes.func,
  data: PropTypes.object,
}

export default withRouter(FavoriteButton)
