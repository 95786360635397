import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Container, CssBaseline, IconButton, Typography, Backdrop, ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { initialMemberState, beMemberIsFocused, beMemberIsInvalid } from '../../../constants/general.constants'
import { GreenButton } from '../../utils/tools'
import InputField from '../common/InputField'
import { emailRegex, phoneRegex } from '../../utils/regex_collections'
import SearchAddress from '../../utils/address_search'
import addressSearchIcon from '../../../images/address-search.svg'
import { parseGoogleAPIAddress } from '../../utils/common'
import { Header } from './be-member/Header'
import { generalConstants } from '../../../constants'
import { alertActions } from '../../../actions/alert.actions'
import Spinner from '../common/spinner/spinner'
import PhoneInputField from './be-member/PhoneInputField'

import { restaurantService } from '../../../services/restaurant.service'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 0),
  },
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  address: {
    marginTop: theme.spacing(),
    borderRadius: 10,
    border: '1px solid rgb(195, 195, 195)',
    height: 37.63,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    '&:hover': {
      border: `1px solid black`,
    },
    "&[is-active='true']": {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
  phoneContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
  },
}))

const BeMember = ({ handleClose }) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const success = () => dispatch(alertActions.success(f({ id: 'request-received' })))
  const error = () => dispatch(alertActions.error(f({ id: 'request-not-received' })))
  const clear = () => dispatch(alertActions.clear())

  const classes = useStyles()
  const [member, setMember] = useState(initialMemberState)
  const [address, setAddress] = useState('')
  const [isFocused, setIsFocused] = useState(beMemberIsFocused)
  const [isInvalid, setIsInvalid] = useState(beMemberIsInvalid)
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState('false')

  const handleSubmit = (e) => {
    e.preventDefault()
    setOpen(true)
    let newAddress = { ...address, addressLine2: member.aptSuite }
    const data = {
      name: member.restaurantName,
      address: newAddress,
      email: member.email,
      phone: `+${member.mobilePhone}`,
    }
    restaurantService.registerRequest(data).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        success()
        handleClose()
      } else {
        error()
      }
      setOpen(false)
      setTimeout(() => clear(), 5000)
    })
  }

  const handleAddressChange = (newAddress) => {
    let parsedAddress = parseGoogleAPIAddress(newAddress)
    setAddress(parsedAddress)
  }

  const validitityCheck = (name, value) => {
    switch (name) {
      case 'email':
        return !emailRegex.test(value)
      case 'mobilePhone':
        return !phoneRegex.test(value)
      default:
        return !value
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const invalidCondition = validitityCheck(name, value)
    setIsFocused({ ...isFocused, [name]: true })
    setIsInvalid({ ...isInvalid, [name]: invalidCondition })
    setMember({ ...member, [name]: value })
  }

  const handlePhone = (phone) => {
    const invalidCondition = validitityCheck('mobilePhone', phone.substring(1))
    setIsFocused({ ...isFocused, mobilePhone: true })
    setIsInvalid({ ...isInvalid, mobilePhone: invalidCondition })
    setMember({ ...member, mobilePhone: phone })
  }

  const handleAddressDelete = () => {
    setAddress('')
  }

  const handleBorderColor = () => setActive('true')
  const handleBorderColorBack = () => setActive('false')

  return (
    <div className='column'>
      <Header handleClose={handleClose} />
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant='h4' gutterBottom className={classes.title}>
            {f({ id: 'become-member' })}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <InputField propss={{ isFocused, isInvalid, state: member, handleChange, field: 'restaurantName' }} />
            <InputField propss={{ isFocused, isInvalid, state: member, handleChange, field: 'email' }} />
            <div className={classes.phoneContainer}>
              <PhoneInputField propss={{ isFocused, isInvalid, state: member, handlePhone, field: 'mobilePhone' }} />
            </div>
            <ClickAwayListener onClickAway={handleBorderColorBack}>
              <div className={classes.address} onClick={handleBorderColor} is-active={active}>
                <SearchAddress
                  outlined={true}
                  value={address?.formattedAddress || ''}
                  startAdornment={addressSearchIcon}
                  placeholder={f({ id: 'restaurantAddress-label' })}
                  onChange={handleAddressChange}
                  typesRef={['address']}
                  component='beMember'
                />
                {address?.formattedAddress && (
                  <IconButton size='small' aria-label='del' onClick={handleAddressDelete}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </ClickAwayListener>
            <InputField propss={{ isFocused, isInvalid, state: member, handleChange, field: 'aptSuite' }} />
            <GreenButton
              variant='contained'
              color='primary'
              type='submit'
              fullWidth
              disabled={
                !member.email ||
                isInvalid.email ||
                !member.restaurantName ||
                isInvalid.restaurantName ||
                !member.mobilePhone ||
                isInvalid.mobilePhone
              }
              className={classes.button}
            >
              {f({ id: 'request-registration' })}
            </GreenButton>
          </form>
        </div>
      </Container>
      <Backdrop className={classes.backdrop} open={open}>
        <Spinner />
      </Backdrop>
    </div>
  )
}

BeMember.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default BeMember

// const getGeoInfo = () => {
//   axios
//     .get('https://ipapi.co/json/')
//     .then((response) => {
//       let data = response.data
//       setCode(data.country_calling_code)
//     })
//     .catch((error) => {
//       // console.log(error)
//     })
// }
