import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import IconButton from '@material-ui/core/IconButton'
import backIcon from '../../../images/black-back.svg'
import { isMobileOnly } from 'react-device-detect'
import Loading from '../../common/loading'
import clsx from 'clsx'
import { TemplateHeader } from '../../../helpers/templateHeader'

const height = window.innerHeight
const path = process.env.PUBLIC_URL

const useTemplateStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  full: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  mobileContent: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
    // marginTop: theme.spacing(),
  },
  headerContainer: {
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    lineHeight: 1.32,
    color: '#333333',
    textAlign: 'center',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileBack: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  footer: {
    zIndex: 10,
  },
}))

const Template = () => {
  let history = useHistory()
  let match = useRouteMatch(`${path}/customer/:relativePath`)
  let header = TemplateHeader(match)
  const classes = useTemplateStyles()

  const [loading] = useState(false)

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
      <Loading open={loading} />
      {!isMobileOnly ? (
        <div className={clsx('grow desktop full-width', [classes.root])}>
          <Container maxWidth='lg'>
            <TopNavbar cartVisible={true} />
          </Container>
          <div className={classes.headerContainer}>
            <Container maxWidth='lg' className={classes.header}>
              <IconButton color='inherit' aria-label='close' onClick={() => history.goBack()}>
                <img src={backIcon} alt='Back' />
              </IconButton>
              <Typography className={classes.headerText}>{header}</Typography>
            </Container>
          </div>
          <Container maxWidth='lg' style={{ minHeight: 600, flexGrow: 1 }}>
            <div></div>
          </Container>
          <div className='grow' />
          <Footer invisible={true} />
        </div>
      ) : (
        <div className={clsx('mobile', classes.mobileRoot)}>
          <div className='mobile-top-bar'>
            <IconButton size='small' className={classes.back} onClick={() => history.goBack()}>
              <img src={backIcon} alt='Back' />
            </IconButton>
            <Typography variant='h6'>{header}</Typography>
          </div>
          <div className={classes.mobileContent}></div>
          <div className={clsx('sticky-bottom', classes.footer)}>
            <Footer />
          </div>
        </div>
      )}
    </div>
  )
}

export default Template
