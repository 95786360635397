export const TemplateHeader = (match) => {
  let header = match.params.relativePath
  switch (header) {
    case 'read-blog':
      return 'Read Our Blog'
    case 'buy-gift-card':
      return 'Buy Gift Card'
    case 'create-business-account':
      return 'Create Business Account'
    case 'add-your-restaurant':
      return 'Add Your Restaurant'
    case 'signup-to-delivery':
      return 'Signup to Delivery'
    case 'read-faq':
      return 'Read FAQs'
    case 'view-cities':
      return 'View All Cities'
    case 'view-countries':
      return 'View All Countries'
    case 'restaurants-near-me':
      return 'Restaurants Near Me'
    case 'support-center':
      return 'Supports Center'
    case 'privacy-policy':
      return 'Privacy Policy'
    case 'terms-and-conditions':
      return 'Terms and Conditions'
    default:
      return ''
  }
}
