import axios from 'axios'
import { merchantHeader, uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const storeService = {
  create,
  update,
  getAll,
  getStores,
  deleteStore,
  customerStoreSearch,
  findLocals,
  findStore,
  getStoreBasedRoles,
  getEmployeesByStore,
  deleteEmployee,
  inviteEmloyee,
  getAllSections,
  getAllSectionsUi,
  getStoreOrders,
  findStoreMerchant,
  getStoreOCStatus,
}

function create(restaurantId, store) {
  return axios.post(`${API_URL}/restaurant/${restaurantId}/store`, store, merchantHeader()).then((res) => {
    return res
  })
}

function update(restaurantId, store, storeId) {
  return axios.put(`${API_URL}/restaurant/${restaurantId}/store/${storeId}`, store, merchantHeader()).then((res) => {
    return res
  })
}

function getAll(restaurantId, page, size) {
  return axios
    .get(`${API_URL}/restaurant/${restaurantId}/store?page=${page}&size=${size}`, merchantHeader())
    .then((stores) => {
      return stores.data
    })
}

function getStoreBasedRoles(restaurantId) {
  return axios.get(`${API_URL}/team/restaurant/${restaurantId}/store`, merchantHeader()).then((roles) => {
    return roles.data
  })
}

function getStores(page = 0, size = 10) {
  return axios.get(`${API_URL}/store?page=${page}&size=${size}`, merchantHeader()).then((stores) => {
    return stores.data
  })
}

function getEmployeesByStore(storeId) {
  return axios.get(`${API_URL}/team/store/${storeId}`, merchantHeader()).then((employees) => {
    return employees
  })
}

function getAllSections(storeId) {
  return axios.get(`${API_URL}/store/${storeId}/section`, merchantHeader()).then((sections) => {
    return sections.data
  })
}

function getAllSectionsUi(storeId) {
  return axios.get(`${API_URL}/store/${storeId}/section/ui`, uiHeader()).then((sections) => {
    return sections.data
  })
}

function deleteStore(restaurantId, storeId) {
  return axios.delete(`${API_URL}/restaurant/${restaurantId}/store/${storeId}`, merchantHeader()).then((res) => {
    return res.status
  })
}

function deleteEmployee(employeeId) {
  return axios.delete(`${API_URL}/team/${employeeId}`, merchantHeader()).then((res) => {
    return res
  })
}

function inviteEmloyee(data) {
  return axios.post(`${API_URL}/team/invite/verification`, data, merchantHeader()).then((res) => {
    return res
  })
}

function customerStoreSearch(query) {
  const queryString = require('query-string')
  const stringified = queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  return axios.get(`${API_URL}/find/search/ui?${stringified}`, uiHeader()).then((stores) => {
    return stores
  })
}

function findLocals(query) {
  return axios.get(`${API_URL}/find/search/ui?page=0&size=100`, uiHeader()).then((stores) => {
    return stores
  })
}

function findStore(id) {
  return axios.get(`${API_URL}/store/${id}/ui`, uiHeader())
}

function findStoreMerchant(id) {
  return axios.get(`${API_URL}/store/${id}`, merchantHeader())
}

function getStoreOrders(query) {
  const { page, storeId, startDate, endDate, size } = query
  return axios
    .get(
      `${API_URL}/store/${storeId}/order?page=${page}&size=${size}&start_date=${startDate}&end_date=${endDate}`,
      merchantHeader()
    )
    .then((res) => {
      return res
    })
}

function getStoreOCStatus(query) {
  const queryString = require('query-string')
  const stringified = queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  return axios.get(`${API_URL}/store/oc-status/ui?${stringified}`, uiHeader()).then((stores) => {
    return stores.data
  })
}
