import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core'
import { useMerchantHomeContext } from '../../../../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    marginLeft: theme.spacing(2),
  },
}))

export const SelectRestaurant = (props) => {
  const classes = useStyles()
  const {
    setters: { handleRestaurantChange },
  } = useMerchantHomeContext()
  const restaurants = useSelector(({ merchantStores }) => merchantStores.business)
  const selectedRestaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='age-native-simple'>Restaurant</InputLabel>
      <Select
        native
        value={selectedRestaurant?.restaurantId}
        onChange={handleRestaurantChange}
        inputProps={{
          name: 'restaurant',
          id: 'restaurant-native-simple',
        }}
      >
        {/* <option aria-label='Restaurant' value='' /> */}
        {restaurants.map((restaurant) => (
          <option key={restaurant.restaurantId} value={restaurant.restaurantId}>
            {restaurant.restaurantName}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

SelectRestaurant.propTypes = {
  handleChange: PropTypes.func,
}
