import { gooelAddressTemplate } from '../../constants'

export const parseGoogleAPIAddress = (address) => {
  let addressData = { ...gooelAddressTemplate }

  if (address.address_components) {
    for (const typ of address.address_components) {
      if (typ.types.some((t) => t === 'street_number')) {
        addressData.street_number = typ.long_name
      } else if (typ.types.some((t) => t === 'route')) {
        addressData.route = typ.long_name
      } else if (typ.types.some((t) => t === 'locality')) {
        addressData.locality = typ.long_name
      } else if (typ.types.some((t) => t === 'administrative_area_level_2')) {
        addressData.administrative_area_level_2 = typ.long_name
      } else if (typ.types.some((t) => t === 'administrative_area_level_1')) {
        addressData.administrative_area_level_1 = typ.short_name
        addressData.stateName = typ.long_name
      } else if (typ.types.some((t) => t === 'country')) {
        addressData.country = typ.short_name
      } else if (typ.types.some((t) => t === 'postal_code')) {
        addressData.postal_code = typ.short_name
      }
    }
  }

  if (address.geometry) {
    if (address.geometry.location) {
      //This check is added for the case that location.lat/lng has number type for some reason
      if (typeof address.geometry.location.lat === 'number') {
        addressData.latitude = address.geometry.location.lat
        addressData.longitude = address.geometry.location.lng
      } else {
        addressData.latitude = address.geometry.location.lat()
        addressData.longitude = address.geometry.location.lng()
      }
    }
  }

  if (address.utc_offset_minutes) {
    addressData.timeZoneOffset = address.utc_offset_minutes
  }

  addressData.formattedAddress = address.formatted_address

  let formattedAddressData = {
    latitude: addressData.latitude,
    longitude: addressData.longitude,
    name: '',
    country: addressData.country,
    city: addressData.locality,
    state: addressData.administrative_area_level_1,
    stateName: addressData.stateName,
    zipCode: addressData.postal_code,
    addressLine1: `${addressData.street_number} ${addressData.route}`.trim(),
    addressLine2: '',
    formattedAddress: addressData.formattedAddress,
    default_: true,
    timeZoneOffset: addressData.timeZoneOffset,
  }

  return formattedAddressData
}
