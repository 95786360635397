import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers'
import throttle from 'lodash/throttle'

import { loadState, saveState } from './localStorage'

const persistentState = loadState()

const loggerMiddleware = createLogger()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  persistentState,
  composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
)

store.subscribe(
  throttle(() => {
    saveState({
      customer: store.getState().customer,
      signup: store.getState().signup,
      alert: store.getState().alert,
      general: store.getState().general,
      merchant: store.getState().merchant,
      restaurant: store.getState().restaurant,
      merchantAuth: store.getState().merchantAuth,
      merchantStores: store.getState().merchantStores,
      stores: store.getState().stores,
      menus: store.getState().menus,
      categories: store.getState().categories,
      dietaries: store.getState().dietaries,
      orders: store.getState().orders,
      search: store.getState().search,
      attributes: store.getState().attributes,
      campaigns: store.getState().campaigns,
    })
  }, 1000)
)
