import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter, Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { searchActions, storeActions } from '../../../actions'
import StoreItem from './store_item'
import { generalConstants, deliveryTimes, dateConstant } from '../../../constants'
import { searchService, customerService } from '../../../services'
import NoResult from './no_result'
import { favoriteStateMaker } from '../../../helpers/favorite-store'
import IntersectionVisible from 'react-intersection-visible'
import moment from 'moment'

const path = process.env.PUBLIC_URL

const styles = () => ({
  dialogPaperEmptyBasket: {
    height: '50vh',
    width: '35vh',
    borderRadius: 20,
  },
  noResultContainer: {
    width: '100%',
    height: 350,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 50,
  },
})

class ScrollComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      stores: [],
      favoriteStores: [],
      loading: false,
      query: {
        size: 15,
        customOffset: 0,
        categoryIds: [],
        dietaryIds: [],
        rating: null,
        distance: null,
        orderByKey: null,
        latitude: null,
        longitude: null,
        orderTime: null,
        orderType: null,
        unit: 'M',
        searchText: '',
        pricey: null,
        onlyOpen: false,
      },
    }
  }

  componentDidMount() {
    const { searchQuery } = this.props
    let orderTime = this.getOrderTime()

    this.setState(
      {
        query: {
          ...this.state.query,
          categoryIds: searchQuery.categoryIds,
          dietaryIds: searchQuery.dietaryIds,
          rating: searchQuery.rating,
          distance: searchQuery.distance,
          searchText: searchQuery.searchText,
          orderByKey: searchQuery.orderByKey,
          orderByValue: searchQuery.orderByValue,
          latitude: searchQuery.address?.latitude,
          longitude: searchQuery.address?.longitude,
          orderTime,
          orderType: searchQuery.deliveryType,
          onlyOpen: searchQuery.onlyOpen,
          pricey: searchQuery.pricey,
        },
      },
      () => this.fetchData()
    )
    this.getFavorites()
  }

  fetchData() {
    const { stores, query } = this.state
    const fetchMore = this.fetchMoreCheck(query.customOffset, stores)
    if (!fetchMore) return
    this.doSearchNext()
  }

  fetchMoreCheck(offset, stores) {
    const { query } = this.state
    let fetchMore = true
    if (offset !== 0 && query.size > offset) {
      fetchMore = false
    }
    if (stores.length % query.size !== 0) {
      fetchMore = false
    }
    return fetchMore
  }

  doSearchNext() {
    const { query, stores } = this.state
    this.setState({ loading: true })
    searchService
      .search(query)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.setState({
            stores: query.customOffset === 0 ? res.data.stores : [...stores, ...res.data.stores],
            query: { ...query, customOffset: res.data.customOffset },
            loading: false,
          })
        }
      })
      .catch(() => this.setState({ loading: false }))
  }

  getOrderTime = () => {
    const { searchQuery } = this.props
    const orderTime =
      searchQuery.deliveryTime === deliveryTimes.ASAP
        ? moment().format(dateConstant.dateFormat)
        : moment(searchQuery.deliveryTimeDetail).format(dateConstant.dateFormat)
    return orderTime
  }

  async componentDidUpdate(prevProps, prevState) {
    const { searchQuery, storeCount } = this.props
    const { stores } = this.state
    let orderTime = this.getOrderTime()

    if (prevProps.searchQuery !== searchQuery) {
      this.setState(
        {
          stores: [],
          query: {
            ...this.state.query,
            categoryIds: searchQuery.categoryIds,
            dietaryIds: searchQuery.dietaryIds,
            orderByKey: searchQuery.orderByKey,
            orderByValue: searchQuery.orderByValue,
            searchText: searchQuery.searchText,
            rating: searchQuery.rating,
            distance: searchQuery.distance,
            latitude: searchQuery.address?.latitude,
            longitude: searchQuery.address?.longitude,
            orderType: searchQuery.deliveryType,
            customOffset: 0,
            onlyOpen: searchQuery.onlyOpen,
            pricey: searchQuery.pricey,
            orderTime,
          },
        },
        () => this.fetchData()
      )
    }
    if (prevProps.signedIn !== this.props.signedIn) {
      if (this.props.signedIn) {
        this.getFavorites()
      } else {
        this.setState({ favoriteStores: [] })
      }
    }
    if (this.props.foodComponent) {
      return
    } else {
      if (prevState.stores !== stores) {
        storeCount(stores.length)
      }
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  getFavorites() {
    if (this.props.signedIn) {
      customerService.getAllFavoriteStores().then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          const { content } = res.data
          content.length > 0
            ? this.setState({ favoriteStores: favoriteStateMaker(content) })
            : this.setState({ favoriteStores: [] })
        }
      })
    }
  }

  favoriteHandler(id) {
    const { favoriteStores } = this.state
    if (!this.props.signedIn) {
      if (!this.props.foodComponent) {
        this.props.history.push(`${path}/customer/signin`, 'search')
      } else if (this.props.foodComponent) {
        this.props.history.push(`${path}/customer/signin`, 'food')
      }
      return
    }
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes ? this.deleteFavorite(storeId) : this.saveFavorite(id)
  }

  deleteFavorite(id) {
    customerService.deleteFavoriteStore(id).then((res) => {
      if (res === generalConstants.STATUS_OK) {
        this.getFavorites()
      }
    })
  }

  saveFavorite(id) {
    customerService
      .saveFavoriteStore(id)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.getFavorites()
        }
      })
      .catch((e) => console.log('e ---', e.response))
  }

  render() {
    const { searchQuery, classes, drawer, width } = this.props
    const { stores, loading } = this.state

    if (!searchQuery.address) return <Redirect to={`${path}/customer`} />
    return (
      <div style={{ minHeight: 600 }}>
        {stores?.length !== 0 ? (
          <div className='grow' style={{ marginTop: 30 }}>
            <Grid container spacing={2}>
              {stores.map(
                (store, index) =>
                  store !== [] && (
                    <Grid item lg={4} md={6} sm={drawer && width ? 12 : 6} xs={drawer && width ? 7 : 12} key={index}>
                      <IntersectionVisible onShow={() => index === stores.length - 4 && this.fetchData()}>
                        <StoreItem
                          search={true}
                          key={index}
                          data={store}
                          history={this.props.history}
                          onPress={this.favoriteHandler.bind(this)}
                          favorites={this.state.favoriteStores}
                          price={searchQuery.pricey}
                        />
                      </IntersectionVisible>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        ) : (
          !loading && (
            <div className={classes.noResultContainer}>
              <NoResult label='scrollComponent' />
            </div>
          )
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { search, customer } = state

  return {
    customOffset: search.searchResult.customOffset,
    searchResult: search.searchResult.stores,
    searchQuery: search.searchQuery,
    signedIn: customer.signedIn,
  }
}

const actionCreators = {
  doSearch: storeActions.customerStoreSearch,
  search: searchActions.search,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(ScrollComponent))))
