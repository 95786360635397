import React from 'react'

const handleDeleteButtonVisibility = (index, restaurant, deleteButton) => {
  if (index === 0) {
    if (restaurant.logo) {
      return deleteButton
    }
    return <></>
  } else {
    if (restaurant.background) {
      return deleteButton
    }
    return <></>
  }
}

export default handleDeleteButtonVisibility
