import axios from 'axios'
import { uiHeader, authCustomerHeader, merchantHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const orderService = {
  getAllOrders,
  getOrdersByStoreId,
  deleteOrder,
  createPaymentIntent,
  paymentConfirmation,
  saveOrder,
  orderPreview,
  orderComplete,
  updateOrder,
}

function getAllOrders() {
  return axios.get(`${API_URL}/user/order`, authCustomerHeader()).then((res) => {
    return res
  })
}
function getOrdersByStoreId({ storeId, page = 0, rowsPerPage = 15, startDate, endDate }) {
  return axios
    .get(
      `${API_URL}/store/${storeId}/order?page=${page}&size=${rowsPerPage}&start_date=${startDate}&end_date=${endDate}`,
      merchantHeader()
    )
    .then((res) => {
      return res
    })
}

function createPaymentIntent(orderId) {
  return axios.post(`${API_URL}/order/${orderId}/payment/create-intent`, null, authCustomerHeader()).then((res) => {
    return res
  })
}

function paymentConfirmation(orderId) {
  return axios.post(`${API_URL}/order/${orderId}/payment/confirmation`, null, authCustomerHeader()).then((res) => {
    return res
  })
}

function deleteOrder(orderId) {
  return axios.delete(`${API_URL}/order/${orderId}`, authCustomerHeader()).then((res) => {
    return res.status
  })
}

function saveOrder(order) {
  return axios.post(`${API_URL}/order`, order, authCustomerHeader()).then((res) => {
    return res
  })
}

function orderPreview(reqBody) {
  return axios.post(`${API_URL}/order/preview/check`, reqBody, uiHeader()).then((res) => {
    return res
  })
}

function orderComplete(orderId, body) {
  return axios.put(`${API_URL}/order/${orderId}`, body, authCustomerHeader()).then((res) => {
    return res.status
  })
}
function updateOrder(orderId, body) {
  return axios.put(`${API_URL}/order/${orderId}`, body, merchantHeader()).then((res) => {
    return res
  })
}
