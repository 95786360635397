export const merchantStoreContants = {
  GET_STORES_REQUEST: 'GET_STORES_REQUEST',
  GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
  GET_STORES_FAILURE: 'GET_STORES_FAILURE',

  // GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  // GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  // GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

  GET_EMPLOYEES_REQUEST: 'GET_EMPLOYEES_REQUEST',
  GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_FAILURE: 'GET_EMPLOYEES_FAILURE',

  DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

  CHANGE_SELECTED_STORE: 'CHANGE_SELECTED_STORE',
  CLEAR_STATE: 'CLEAR_STATE',

  UPDATE_STORE_REQUEST: 'UPDATE_STORE_REQUEST',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILURE: 'UPDATE_STORE_FAILURE',

  GETALL_SECTIONS_REQUEST: 'GETALL_SECTIONS_REQUEST',
  GETALL_SECTIONS_SUCCESS: 'GETALL_SECTIONS_SUCCESS',
  GETALL_SECTIONS_FAILURE: 'GETALL_SECTIONS_FAILURE',

  CREATE_SECTION_REQUEST: 'CREATE_SECTION_REQUEST',
  CREATE_SECTION_SUCCESS: 'CREATE_SECTION_SUCCESS',
  CREATE_SECTION_FAILURE: 'CREATE_SECTION_FAILURE',

  DELETE_SECTION_REQUEST: 'DELETE_SECTION_REQUEST',
  DELETE_SECTION_SUCCESS: 'DELETE_SECTION_SUCCESS',
  DELETE_SECTION_FAILURE: 'DELETE_SECTION_FAILURE',

  UPDATE_SECTION_REQUEST: 'UPDATE_SECTION_REQUEST',
  UPDATE_SECTION_SUCCESS: 'UPDATE_SECTION_SUCCESS',
  UPDATE_SECTION_FAILURE: 'UPDATE_SECTION_FAILURE',

  CREATE_MENU_REQUEST: 'CREATE_MENU_REQUEST',
  CREATE_MENU_SUCCESS: 'CREATE_MENU_SUCCESS',
  CREATE_MENU_FAILURE: 'CREATE_MENU_FAILURE',

  DELETE_MENU_REQUEST: 'DELETE_MENU_REQUEST',
  DELETE_MENU_SUCCESS: 'DELETE_MENU_SUCCESS',
  DELETE_MENU_FAILURE: 'DELETE_MENU_FAILURE',

  UPDATE_MENU_REQUEST: 'UPDATE_MENU_REQUEST',
  UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
  UPDATE_MENU_FAILURE: 'UPDATE_MENU_FAILURE',

  GET_BASIC_REQUEST: 'GET_BASIC_REQUEST',
  GET_BASIC_SUCCESS: 'GET_BASIC_SUCCESS',
  GET_BASIC_FAILURE: 'GET_BASIC_FAILURE',

  CHANGE_SELECTED_RESTAURANT: 'CHANGE_SELECTED_RESTAURANT',

  SAVE_MENU_IMAGE_REQUEST: 'SAVE_MENU_IMAGE_REQUEST',
  SAVE_MENU_IMAGE_SUCCESS: 'SAVE_MENU_IMAGE_SUCCESS',
  SAVE_MENU_IMAGE_FAILURE: 'SAVE_MENU_IMAGE_FAILURE',

  DELETE_IMAGE_REQUEST: 'DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'DELETE_IMAGE_FAILURE',

  CREATE_STORE_REQUEST: 'CREATE_STORE_REQUEST',
  CREATE_STORE_SUCCESS: 'CREATE_STORE_SUCCESS',
  CREATE_STORE_FAILURE: 'CREATE_STORE_FAILURE',

  DELETE_STORE_REQUEST: 'DELETE_STORE_REQUEST',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_FAILURE: 'DELETE_STORE_FAILURE',

  CHANGE_RESTAURANT_ADMIN_VIEW: 'CHANGE_RESTAURANT_ADMIN_VIEW',

  SAVE_LOGO_REQUEST: 'SAVE_LOGO_REQUEST',
  SAVE_LOGO_SUCCESS: 'SAVE_LOGO_SUCCESS',
  SAVE_LOGO_FAILURE: 'SAVE_LOGO_FAILURE',
  CLEAR_LOGO: 'CLEAR_LOGO',

  DELETE_LOGO_REQUEST: 'DELETE_LOGO_REQUEST',
  DELETE_LOGO_SUCCESS: 'DELETE_LOGO_SUCCESS',
  DELETE_LOGO_FAILURE: 'DELETE_LOGO_FAILURE',

  SAVE_BACKGROUND_REQUEST: 'SAVE_BACKGROUND_REQUEST',
  SAVE_BACKGROUND_SUCCESS: 'SAVE_BACKGROUND_SUCCESS',
  SAVE_BACKGROUND_FAILURE: 'SAVE_BACKGROUND_FAILURE',
  CLEAR_BACKGROUND: 'CLEAR_BACKGROUND',

  DELETE_BACKGROUND_REQUEST: 'DELETE_BACKGROUND_REQUEST',
  DELETE_BACKGROUND_SUCCESS: 'DELETE_BACKGROUND_SUCCESS',
  DELETE_BACKGROUND_FAILURE: 'DELETE_BACKGROUND_FAILURE',
}
