import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import { storeService } from '../../../../services'
import { GreenConnector } from '../../../utils/tools'
import Loading from '../../../common/loading'
import { generalConstants } from '../../../../constants'
import { valueConstants, steps, initialValuesState } from './Helpers'
import {
  zerothStepRequiredFieldsFilled,
  firstStepRequiredFieldsFilled,
  secondStepRequiredFieldsFilled,
  thirdStepRequiredFieldsFilled,
  fourthStepRequiredFieldsFilled,
  fifthStepRequiredFieldsFilled,
  tierControl,
  handleSubmitQuery,
} from './Helpers'

import BusinessDetails from './StepContent/BusinessDetails'
import WorkingHours from './StepContent/WorkingHours'
import Notifications from './StepContent/Notifications'
import DeliveryDetails from './StepContent/DeliveryDetails'
import PaymentDetails from './StepContent/PaymentDetails'
import BankInfo from './StepContent/BankInfo'
import SubmissionPart from './StepContent/SubmissionPart'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepper: {
    '& .MuiStepContent-root': {
      marginLeft: theme.spacing(2.5),
      borderLeft: '3px solid #bdbdbd',
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: theme.spacing(2.5),
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftWidth: 3,
    },
  },
  stepperLabel: {
    '& .MuiSvgIcon-root': {
      width: '2em',
      height: '2em',
    },
    '& .MuiStepLabel-labelContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      fontSize: '2rem',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '& .MuiStepLabel-label': {
      fontSize: '1rem',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 85,
    "&[add-button='true']": {
      marginRight: theme.spacing(),
    },
  },
}))

const StepperMain = () => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const topRef = useRef()
  const history = useHistory()

  const restaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)

  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [parsedAddress, setParsedAddress] = useState(null)
  const [error, setError] = useState(false)
  const [secondTierState, setSecondTier] = useState(false)
  const [thirdTierState, setThirdTier] = useState(false)
  const [tierError, setTierError] = useState(false)
  const [checkParsedAddress, setCheckParsedAddress] = useState(null)
  const [useStoreAddressForCheck, setUseStoreAddressForCheck] = useState(true)
  const [values, setValues] = useState(initialValuesState)

  useEffect(() => {
    let active = true
    if (active) {
      if (error && thirdStepRequiredFieldsFilled(values, secondTierState, thirdTierState)) {
        setError(false)
      }
      if (error && tierControl(values)) {
        setTierError(false)
      }
      if (error && secondStepRequiredFieldsFilled(values)) {
        setError(false)
      }
    }
    return () => {
      active = false
    }
  }, [values])

  const handleChange = (prop) => (e) => {
    if (prop === valueConstants.notificationType) {
      let val = e.target.value
      if (values.notificationType.includes(val)) {
        let prop2 =
          val === valueConstants.notificationPhone
            ? valueConstants.orderConfirmationPhone
            : valueConstants.orderConfirmationEmail
        setValues({
          ...values,
          [prop]: values.notificationType.filter((nt) => nt !== val),
          [prop2]: '',
        })
      } else {
        setValues({
          ...values,
          [prop]: [...values.notificationType, val],
        })
      }
    } else {
      setValues({
        ...values,
        [prop]: e.target.value,
      })
    }
  }

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setError(false)
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!zerothStepRequiredFieldsFilled(values, parsedAddress)) {
        setError(true)
        return
      } else {
        nextStep()
      }
    } else if (activeStep === 1) {
      if (!firstStepRequiredFieldsFilled(values.workingHours)) {
        setError(true)
        return
      } else {
        nextStep()
      }
    } else if (activeStep === 2) {
      if (!secondStepRequiredFieldsFilled(values)) {
        setError(true)
        return
      } else {
        nextStep()
      }
    } else if (activeStep === 3) {
      if (thirdStepRequiredFieldsFilled(values, secondTierState, thirdTierState) && tierControl(values)) {
        setTierError(false)
        nextStep()
      } else {
        if (!tierControl(values)) {
          return setTierError(true)
        } else {
          return setError(true)
        }
      }
    } else if (activeStep === 4) {
      if (!fourthStepRequiredFieldsFilled(values)) {
        setError(true)
        return
      } else {
        nextStep()
      }
    } else if (activeStep === 5) {
      if (!fifthStepRequiredFieldsFilled(values, checkParsedAddress)) {
        setError(true)
        return
      } else {
        nextStep()
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setError(false)
  }

  const handleReview = () => {
    handleScroll()
    setActiveStep(0)
  }

  const handleScroll = () => topRef.current.scrollIntoView({ behavior: 'smooth' })

  const handleSubmit = async () => {
    setLoading(true)

    let query = handleSubmitQuery(parsedAddress, checkParsedAddress, useStoreAddressForCheck, values)
    let id = restaurant.restaurantId

    const response = await storeService.create(id, query)
    if (response.status === generalConstants.STATUS_OK) {
      setLoading(false)
      history.push(`${path}/merchant/stores`)
    } else {
      console.log(response)
    }
    setLoading(false)
  }

  const stepContent = {
    0: (
      <BusinessDetails
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        error={error}
        setParsedAddress={setParsedAddress}
        parsedAddress={parsedAddress}
      />
    ),
    1: <WorkingHours values={values} setValues={setValues} error={error} />,
    2: <Notifications values={values} handleChange={handleChange} setValues={setValues} error={error} />,
    3: (
      <DeliveryDetails
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        secondTierState={secondTierState}
        setSecondTier={setSecondTier}
        thirdTierState={thirdTierState}
        setThirdTier={setThirdTier}
        error={error}
        tierError={tierError}
      />
    ),
    4: <PaymentDetails handleChange={handleChange} values={values} error={error} setValues={setValues} />,
    5: (
      <BankInfo
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        error={error}
        setCheckParsedAddress={setCheckParsedAddress}
        checkParsedAddress={checkParsedAddress}
        useStoreAddressForCheck={useStoreAddressForCheck}
        setUseStoreAddressForCheck={setUseStoreAddressForCheck}
        parsedAddress={parsedAddress}
      />
    ),
  }

  return (
    <Container className={classes.root} maxWidth='md'>
      <Loading open={loading} />
      <div ref={topRef} style={{ position: 'absolute', top: 0 }} />
      <Stepper
        activeStep={activeStep}
        orientation='vertical'
        className={classes.stepper}
        connector={<GreenConnector />}
      >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel className={classes.stepperLabel}>{label}</StepLabel>
            <StepContent className={classes.stepContent}>
              <div>{stepContent[index]}</div>
              <div className={classes.actionsContainer}>
                <div className={classes.buttonContainer}>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    {f({ id: 'back-label' })}
                  </Button>
                  <Button variant='contained' color='primary' onClick={handleNext} className={classes.button}>
                    {activeStep === steps.length - 1 ? f({ id: 'complete-label' }) : f({ id: 'next-label' })}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && <SubmissionPart handleReview={handleReview} handleSubmit={handleSubmit} />}
    </Container>
  )
}

StepperMain.propTypes = {
  handleScroll: PropTypes.func,
}

export default StepperMain
