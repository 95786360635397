import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import Footer from '../Footers'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AboutIcon from '@material-ui/icons/TouchApp'
import FavoriteIcon from '@material-ui/icons/Favorite'
import AddressIcon from '@material-ui/icons/LocationOn'
import PaymentIcon from '@material-ui/icons/Payment'
import InviteIcon from '@material-ui/icons/HowToReg'
import SignoutIcon from '@material-ui/icons/ExitToApp'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { setMainMenu } from '../../../actions/general.actions'
import userAvatarIcon from '../../../images/user_avatar.png'

import { bottomNavItems } from '../../../constants'
import { customerActions, orderActions } from '../../../actions'

import { isMobile } from 'react-device-detect'

const path = process.env.PUBLIC_URL
// const height = window.innerHeight

const styles = (theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: height,
    // maxHeight: height,
  },
  top: {
    // height: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    marginTop: theme.spacing(),
  },
  profileRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
  profileImage: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#f7f7f7',
  },
  buttons: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 55,
    textTransform: 'capitalize',
  },
  name: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    textTransform: 'capitalize',
  },
  placeholderImage: {
    width: 70,
    height: 70,
  },
  circleStar: {
    width: 15,
    height: 16,
    marginTop: 50,
    marginLeft: -20,
  },
  inviteFriends: {
    fontSize: 16,
    fontWeight: 500,
    color: '#333333',
  },
  get15Off: {
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 10,
    marginTop: 2,
  },
})

const MenuButton = withStyles(() => ({
  root: {
    justifyContent: 'left',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#000',
    height: 55,
    textTransform: 'capitalize',
  },
  startIcon: {
    marginRight: 30,
  },
}))(Button)

class Account extends React.Component {
  componentDidMount() {
    if (!isMobile) {
      this.goToProfile()
    } else this.props.setMainMenu(bottomNavItems.ACCOUNT)
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSignOut() {
    const { signout, clearCart, history } = this.props
    clearCart()
    signout()
    history.push(`${path}/customer/food`)
  }

  goToProfile = () => {
    this.props.history.push(`${path}/customer/account/profile`)
    return
  }

  render() {
    const { classes, customer, orderMenus } = this.props

    return (
      <div className={classes.mobileRoot}>
        <Divider light />
        <div className={classes.top}>
          <Button fullWidth onClick={() => this.props.history.push(`${path}/customer/account/profile`)}>
            <div className={clsx('centered-row', [classes.profileRow])}>
              <div className={classes.profileImage}>
                <img src={userAvatarIcon} height='70' width='70' alt='avatar' />
              </div>
              <Typography className={classes.name}>
                {customer.firstName} {customer.lastName}
              </Typography>
              <div className='grow' />
              <KeyboardArrowRightIcon />
            </div>
          </Button>
        </div>
        <Divider light />
        <div className={classes.content}>
          <div
            className={clsx('vertical-scrolling-wrapper-flexbox', [classes.buttons])}
            style={{ paddingBottom: orderMenus.length > 0 ? 120 : 60 }}
          >
            <MenuButton
              fullWidth
              startIcon={<FavoriteIcon />}
              onClick={() => this.props.history.push(`${path}/customer/account/favorite-restaurants`)}
            >
              {this.getLabel('favorites-label')}
            </MenuButton>
            <Divider light />
            <MenuButton
              fullWidth
              startIcon={<PaymentIcon />}
              onClick={() => this.props.history.push(`${path}/customer/account/payment-methods`)}
            >
              {this.getLabel('payment-label')}
            </MenuButton>
            <Divider light />
            <MenuButton
              fullWidth
              startIcon={<AddressIcon />}
              onClick={() => this.props.history.push(`${path}/customer/account/addresses`)}
            >
              {this.getLabel('addresses-label')}
            </MenuButton>
            <Divider light />
            {/* <MenuButton
              fullWidth
              startIcon={<PromotionIcon />}
              disabled
              // onClick={() => this.props.history.push(`${path}/customer/favorite-restaurants`)}
            >
              {this.getLabel('promotions-label')}
            </MenuButton>
            <Divider light /> */}
            {/* <MenuButton
              fullWidth
              startIcon={<NotificationIcon />}
              disabled
              // onClick={() => this.props.history.push(`${path}/customer/favorite-restaurants`)}
            >
              {this.getLabel('notifications-label')}
            </MenuButton>
            <Divider light /> */}
            <MenuButton
              fullWidth
              startIcon={<InviteIcon />}
              disabled
              // onClick={() => this.props.history.push(`${path}/customer/favorite-restaurants`)}
            >
              {this.getLabel('invite-friends')}
              {/* <div className='row'>
                <Typography className={classes.inviteFriends}>{this.getLabel('invite-friends')}</Typography>
                <Typography className={classes.get15Off}>{this.getLabel('get-15-off')}</Typography>
              </div> */}
            </MenuButton>
            {/* <Divider light />
            <MenuButton
              fullWidth
              startIcon={<GiftIcon />}
              disabled
              // onClick={() => this.props.history.push(`${path}/customer/favorite-restaurants`)}
            >
              {this.getLabel('gift-cards')}
            </MenuButton> */}
            {/* <Divider light />
            <MenuButton
              fullWidth
              startIcon={<DeliverIcon />}
              disabled
              onClick={() => this.props.history.push(`${path}/customer/favorite-restaurants`)}
            >
              {this.getLabel('deliver-with-tookfresh')}
            </MenuButton> */}
            <Divider light />
            <MenuButton fullWidth startIcon={<SignoutIcon />} onClick={() => this.handleSignOut()}>
              {this.getLabel('sign-out')}
            </MenuButton>
            <Divider light />
            <MenuButton
              fullWidth
              startIcon={<AboutIcon />}
              // disabled
              onClick={() => this.props.history.push(`${path}/customer/about-us`)}
            >
              {this.getLabel('about-label')}
            </MenuButton>
            <Divider light />
          </div>
        </div>
        <div className='sticky-bottom'>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customer, orders } = state
  return {
    mainProp: ownProps.match.params.main,
    secondProp: ownProps.match.params.second,
    customer: customer.customerInfo,
    signedIn: customer.signedIn,
    orderMenus: orders.cart.orderMenus,
  }
}

const actionCreators = {
  setMainMenu,
  signout: customerActions.signout,
  clearCart: orderActions.clearCart,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(Account))))
