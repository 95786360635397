import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { bottomLinks } from '../../../../constants/footer/web-footer'

const useStyles = makeStyles((theme) => ({
  bottomLink: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#fff',
    marginLeft: theme.spacing(3),
    "&[is-tablet='true']": {
      marginLeft: 0,
    },
    "&[is-last='true']": {
      marginTop: 15,
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
    textDecoration: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const path = process.env.PUBLIC_URL

const BottomLinks = ({ intl, tabletView, isTablet }) => {
  const classes = useStyles()
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <Grid
      item
      xs={12}
      md={9}
      className={tabletView ? classes.container : 'centered-row'}
      style={{ paddingTop: !tabletView && 40 }}
    >
      <div className='grow' />
      {bottomLinks.map((item, i) => (
        <Link key={i} to={`${path}${item.link}`} className={classes.link}>
          <Typography key={i} className={classes.bottomLink} is-tablet={isTablet} is-last={i === 3 ? 'true' : 'false'}>
            {getLabel(item.label)}
          </Typography>
        </Link>
      ))}
    </Grid>
  )
}

export default injectIntl(BottomLinks)
