import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textAlign: 'center',
    lineHeight: 1.3,
    fontSize: 30,
  },
  desc: {
    textAlign: 'center',
    fontSize: 16,
    paddingTop: theme.spacing(2),
  },
}))

const Description = (props) => {
  const classes = useStyles()
  return (
    <Grid className={classes.container}>
      <Typography variant='h5' className={classes.title}>
        <FormattedMessage id='pickup-delivery' />
      </Typography>
      <Typography variant='body2' className={classes.desc} color='textSecondary'>
        <FormattedMessage id='pickup-delivery-desc' />
      </Typography>
    </Grid>
  )
}

Description.propTypes = {
  // second: PropTypes.third,
}

export default React.memo(Description)
