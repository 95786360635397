import React from 'react'
import PropTypes from 'prop-types'
import StoreCardSmall from '../../common/StoreCardSmall'

const path = process.env.PUBLIC_URL

const MobileStoreItem = ({ data, category, onPress, favorites }) => {
  const isFavorite = favorites.some((favorite) => favorite.entityId === data.id)

  const location = {
    pathname: `${path}/customer/store/${data.id}`,
    state: { pathname: `${path}/customer/search`, category: category },
  }

  return <StoreCardSmall data={data} isFavorite={isFavorite} onPress={onPress} direction={location} big show />
}

MobileStoreItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MobileStoreItem
