import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { generalConstants } from '../../../constants'
import { Transition } from '../../utils/tools'
import PropTypes from 'prop-types'
import { orderActions } from '../../../actions'
import { attributeService } from '../../../services'
import { attributeMaker, menuAttributesFormatter } from '../../../helpers/attributes/attributes.js'
import { greenButtonStatusChecker } from '../../../helpers/attributes/button-status'
import { addToCheckedState } from '../../../helpers/attributes/multi-choice'
import { withRouter } from 'react-router'
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { freeCountChecker } from '../../../helpers/attributes/attribute-prices'
import MenuDialogTitle from './menu-item-dialog/MenuDialogTitle'
import MenuDialogActions from './menu-item-dialog/MenuDialogActions'
import MenuDialogContent from './menu-item-dialog/MenuDialogContent'
import { getRelatedComponent } from '../../../helpers/attributes/related-component'
import { Scrollbars } from 'react-custom-scrollbars'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Loading from '../../common/loading'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // paddingBottom: theme.spacing(7),
  },
  title: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
    fontSize: 20,
  },
  attributeTitle: {
    fontSize: 14,
  },
  attributeDescription: {
    fontSize: 10,
    color: '#c4c4c4',
  },
  required: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: 1.3,
  },
  desc: {
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#212121',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
  },
  category: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121',
  },
  dietary: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121',
  },
  price: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  qty: {
    fontSize: 11,
  },
  button: {
    textTransform: 'none',
    zIndex: 10,
  },
  disabledButton: {
    backgroundColor: '#c4c4c4',
    zIndex: 10,
  },
  instruction: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  special: {
    fontSize: 13,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#212121',
  },
  label: {
    fontSize: 13,
  },
  buttonContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  webButtonContainer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: 'pink',
  },
  reqiuredGrid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'center',
    height: 20,
    borderRadius: 10,
  },
  cart: {
    display: 'flex',
    flexDirection: 'row',
  },
  cartText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#000',
  },
  attributes: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    boxShadow: '0px 5px 3px 1px rgba(0, 0, 0, .2)',
  },
  addMenuDialog: {
    maxWidth: 450,
    minWidth: 450,
  },
  helperText: {
    display: 'flex',
    justifyContent: 'flex-end',
    userSelect: 'none',
  },
  helperTextRed: {
    display: 'flex',
    justifyContent: theme.palette.secondary.main,
    color: 'red',
    userSelect: 'none',
  },
  dietaryIcon: {
    width: 20,
    height: 20,
    marginTop: 4,
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
  },
}))

let _isMounted = false

const MenuItemDialog = ({ data, web = false, storeId, onCreateNewOrder, onClose, intl, open, storeOpened }) => {
  const classes = useStyles()

  const [quantity, setQuantity] = useState(1)
  const [specialInstructions, setSpecialInstructions] = useState('')
  const [attributes, setAttributes] = useState([])
  const [menuAttributes, setMenuAttributes] = useState([])
  const [buttonStatus, setButtonStatus] = useState(false)
  const [checked, setChecked] = useState({})
  const [attributePrice, setAttributePrice] = useState(0)
  const [showTitle, setShowTitle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)

  const store = useSelector(({ stores }) => stores.customerCurrentStore, shallowEqual)
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)

  const dispatch = useDispatch()
  const addMenu = (id, name, orderItem) => dispatch(orderActions.addMenuToOrder(id, name, orderItem))

  useEffect(() => {
    const init = async () => {
      _isMounted = true
      if (data) {
        await getMenuAttributes()
        // setPrice(data.price)
        // setQuantity(parseInt(data?.servingSize))
      }
      setReady(true)
    }
    init()
    return () => {
      _isMounted = false
      setReady(false)
    }
  }, [data])

  useEffect(() => {
    let price_ = 0
    menuAttributes.forEach((menuAttr) => {
      price_ += freeCountChecker(menuAttr)
    })
    setAttributePrice(price_)
  }, [menuAttributes])

  const getMenuAttributes = async () => {
    setLoading(true)
    await attributeService.menuAttributes(storeId.toString(), data?.id.toString()).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        // sort attributes to display hte ones that have required=true in first place
        const attributes = res.data.content.sort((a, b) => b.required - a.required)
        _isMounted && setAttributes(attributes)
      }
    })
    setLoading(false)
  }

  const handleAttributes = (attr, value) => {
    let newState = attributeMaker(menuAttributes, attr, value)
    let greenButtonDisableStatus = greenButtonStatusChecker(attributes, newState)
    let newCheckedState = addToCheckedState(attr, checked, value)
    setMenuAttributes([...newState])
    setButtonStatus(greenButtonDisableStatus)
    setChecked(newCheckedState)
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const addMenuToCart = () => {
    const { id, name, price, servingSize, servingType } = data
    const attributes_ = menuAttributesFormatter(menuAttributes)
    let orderItem = {
      menuId: id,
      name: name,
      price: Number(price) + Number(attributePrice),
      specialInstructions: specialInstructions,
      quantity: quantity,
      attributes: attributes_,
      servingSize,
      servingType,
    }

    if (!cart.storeId) {
      addMenu(store.id, store.nickname, orderItem)
    } else if (cart.storeId === store.id) {
      addMenu(store.id, store.nickname, orderItem)
    } else {
      onCreateNewOrder(orderItem)
    }
    setMenuAttributes([])
    setChecked({})
    setSpecialInstructions('')
    setButtonStatus(false)
    onClose()
  }

  const closeModal = () => {
    setMenuAttributes([])
    setChecked({})
    setSpecialInstructions('')
    setButtonStatus(false)
    onClose()
  }

  const handleInstruction = (value) => setSpecialInstructions(value)

  const incrementQty = () => setQuantity((prev) => prev + 1)

  const decrementQty = () => setQuantity((prev) => prev - 1)

  const toggleShowTitle = (value) => setShowTitle(value)

  const Component = getRelatedComponent(web)
  // style={{ width: web ? 560 : null}}
  if (!ready) return <Loading open={loading} />
  return (
    <div className={classes.root}>
      <Scrollbars style={{ height: '100%' }}>
        <Component
          fullWidth={!web ? true : null}
          maxWidth={web ? 'sm' : null}
          scroll='paper'
          // maxWidth={false}
          // className={classes.dialog}
          classes={{ paper: web ? classes.addMenuDialog : null }}
          open={open}
          onClose={closeModal}
          TransitionComponent={Transition}
          style={{ maxHeight: web ? '90%' : null }}
          keepMounted={false}
        >
          <DialogTitle
            style={{
              boxShadow: showTitle ? '0px 3px 2px 1px rgba(0, 0, 0, .1)' : null,
            }}
          >
            <MenuDialogTitle showTitle={showTitle} data={data} classes={classes} onClose={closeModal} web={web} />
          </DialogTitle>
          <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <MenuDialogContent
              data={data}
              classes={classes}
              attributes={attributes}
              menuAttributes={menuAttributes}
              web={web}
              handleAttributes={handleAttributes}
              checked={checked}
              handleInstruction={handleInstruction}
              quantity={quantity}
              incrementQty={incrementQty}
              decrementQty={decrementQty}
              toggleShowTitle={toggleShowTitle}
              getLabel={getLabel}
              specialInstructions={specialInstructions}
              servingSize={+data?.servingSize}
            />
          </DialogContent>
          <DialogActions>
            <MenuDialogActions
              data={data}
              classes={classes}
              buttonStatus={buttonStatus}
              addMenuToCart={addMenuToCart}
              getLabel={getLabel}
              quantity={quantity}
              attributePrice={attributePrice}
              attributes={attributes}
              storeOpened={storeOpened}
            />
          </DialogActions>
        </Component>
      </Scrollbars>
    </div>
  )
}

MenuItemDialog.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onCreateNewOrder: PropTypes.func,
}

export default injectIntl(withRouter(MenuItemDialog))
