import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { attributeChoosenChecker } from '../../../../helpers/attributes/attribute-choosen'

const AttributeTitle = ({ attribute, classes, menuAttributes, web }) => {
  const attributeChoosen = attributeChoosenChecker(attribute, menuAttributes)
  return (
    <Grid container direction='column'>
      <Grid item xs={12} container direction='row' justify='space-between' style={{ marginBottom: 5, marginTop: 10 }}>
        <Grid item xs={9}>
          <Typography
            variant='body1'
            component='h6'
            className={clsx(classes.title, classes.attributeTitle)}
            style={{ fontSize: web ? 18 : null }}
          >
            {attribute.name}
          </Typography>
        </Grid>
        <Grid
          xs={3}
          item
          className={clsx('centered-row', classes.reqiuredGrid)}
          style={{ backgroundColor: attributeChoosen ? '#69e781' : attribute.required ? '#000' : '#c4c4c4' }}
        >
          <Typography variant='body1' component='p' className={classes.required} style={{ fontSize: web ? 14 : null }}>
            {attribute.required ? 'Required' : 'Optional'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} direction='row' justify='flex-start'>
        <Typography
          variant='body1'
          component='h6'
          className={classes.attributeDescription}
          style={{ fontSize: web ? 14 : null }}
        >
          {attribute.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AttributeTitle
