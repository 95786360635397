import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Grow from '@material-ui/core/Fade'

import closeIcon from '../../../../images/close-icon.svg'

import { deliveryTypes, deliverySubTypes } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  typeMain: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchContainer: {
    width: 'fit-content',
    display: 'flex',
  },
  switch: {
    marginTop: -theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
      borderRadius: 20 / 2,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
      },
    },
  },
  type: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
    transition: theme.transitions.create(['color']),
    cursor: 'pointer',
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    touchAction: 'none',
    "&[selected-type='true']": {
      color: theme.palette.primary.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(),
    height: 62,
    "&[mobile-address-save='true']": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(),
    },
  },
  button: {
    maxWidth: 100,
    maxHeight: 40,
    textTransform: 'none',
    backgroundColor: '#fff',
    fontSize: 14,
    borderRadius: 0,
    color: '#c4c4c4',
    transition: theme.transitions.create(['color', 'background-color']),
    "&[mobile-address-save='true']": {
      maxWidth: 120,
    },
    '&.MuiButton-root': {
      lineHeight: 1.25,
    },
    '&:hover': {
      backgroundColor: 'rgba(105,231,129, 0.5)',
    },
    "&[button-selected='true']": {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    "&[button-name='deliveryDoor']": {
      border: '1px solid #c4c4c4',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "&[button-name='meetDoor']": {
      borderTop: '1px solid #c4c4c4',
      borderBottom: '1px solid #c4c4c4',
    },
    "&[button-name='meetOutside']": {
      border: '1px solid #c4c4c4',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  placeHolderTextField: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: '#707070',
      '&:not(:focus)': {
        cursor: 'pointer',
      },
      '&::placeholder': {
        fontSize: 14,
        fontWeight: 300,
        fontStyle: 'italic',
      },
    },
  },
  iconButton: {
    width: 30,
    height: 30,
  },
}))

const DeliveryType = ({
  handleSwitch,
  deliveryType,
  handleSubType,
  subType,
  instruction,
  clearInstruction,
  handleInstruction,
  setDeliveryType,
  mobileAddressSave,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.typeMain}>
        <div className={classes.switchContainer}>
          <Typography
            className={classes.type}
            selected-type={deliveryType === deliveryTypes.DELIVERY ? 'true' : 'false'}
            onClick={() => setDeliveryType(deliveryTypes.DELIVERY)}
          >
            {f({ id: 'delivery-label' })}
          </Typography>
          <Switch checked={deliveryType === deliveryTypes.TAKEOUT} className={classes.switch} onChange={handleSwitch} />
          <Typography
            className={classes.type}
            selected-type={deliveryType === deliveryTypes.TAKEOUT ? 'true' : 'false'}
            onClick={() => setDeliveryType(deliveryTypes.TAKEOUT)}
          >
            {f({ id: 'pick-up' })}
          </Typography>
        </div>
      </div>
      {deliveryType === deliveryTypes.DELIVERY && (
        <Grow in={deliveryType === deliveryTypes.DELIVERY}>
          <div className={classes.buttonsContainer} mobile-address-save={mobileAddressSave ? 'true' : 'false'}>
            <Button
              className={classes.button}
              disableRipple
              button-selected={subType === deliverySubTypes.DELIVER_DOOR ? 'true' : 'false'}
              button-name='deliveryDoor'
              onClick={() => handleSubType(deliverySubTypes.DELIVER_DOOR)}
              mobile-address-save={mobileAddressSave ? 'true' : 'false'}
            >
              {f({ id: 'deliver-door' })}
            </Button>
            <Button
              className={classes.button}
              disableRipple
              button-selected={subType === deliverySubTypes.MEET_DOOR ? 'true' : 'false'}
              button-name='meetDoor'
              onClick={() => handleSubType(deliverySubTypes.MEET_DOOR)}
              mobile-address-save={mobileAddressSave ? 'true' : 'false'}
            >
              {f({ id: 'meet-door' })}
            </Button>
            <Button
              className={classes.button}
              disableRipple
              button-selected={subType === deliverySubTypes.MEET_OUTSIDE ? 'true' : 'false'}
              button-name='meetOutside'
              onClick={() => handleSubType(deliverySubTypes.MEET_OUTSIDE)}
              mobile-address-save={mobileAddressSave ? 'true' : 'false'}
            >
              {f({ id: 'meet-outside' })}
            </Button>
          </div>
        </Grow>
      )}
      {deliveryType === deliveryTypes.DELIVERY && (
        <Grow in={deliveryType === deliveryTypes.DELIVERY}>
          {!mobileAddressSave ? (
            <TextField
              id='delivery-instruction'
              className={classes.placeHolderTextField}
              placeholder={f({ id: 'add-instruction-placeholder' })}
              value={instruction}
              onChange={handleInstruction}
              fullWidth
              content-type='placeholder'
              multiline
              rowsMax={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton className={classes.iconButton} onClick={clearInstruction}>
                      <img src={closeIcon} alt='close' height='14px' width='14px' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              size='small'
              variant='outlined'
              margin='normal'
              fullWidth
              placeholder={f({ id: 'add-instruction-placeholder' })}
              value={instruction}
              onChange={handleInstruction}
              multiline
              rows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton className={classes.iconButton} onClick={clearInstruction}>
                      <img src={closeIcon} alt='close' height='14px' width='14px' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grow>
      )}
    </div>
  )
}

DeliveryType.propTypes = {
  handleSwitch: PropTypes.func,
  deliveryType: PropTypes.string,
  handleSubType: PropTypes.func,
  subType: PropTypes.string,
  instruction: PropTypes.string,
  clearInstruction: PropTypes.func,
  handleInstruction: PropTypes.func,
  setDeliveryType: PropTypes.func,
  mobileAddressSave: PropTypes.bool,
}

export default DeliveryType
