import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import backIcon from '../../../images/black-back.svg'
import clsx from 'clsx'
import { customerService } from '../../../services'
import { generalConstants } from '../../../constants'
import Swipeout from 'rc-swipeout'
import 'rc-swipeout/assets/index.css'
import StoreItem from '../search/store_item'
import Dialog from '@material-ui/core/Dialog'
import NoResult from '../search/no_result'
import Loading from '../../common/loading'
import { isMobileOnly } from 'react-device-detect'
import IntersectionVisible from 'react-intersection-visible'
import MobileDialog from '../../common/mobile-dialog'
import { Transition } from '../../utils/tools'
import WebConfirmationDialog from '../../common/web-confirmation-dialog'
import StoreCardSmall from '../../common/StoreCardSmall'

const path = process.env.PUBLIC_URL
const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: height,
    // maxHeight: height,
  },
  container: {
    flexGrow: 1,
  },
  cardRoot: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    height: 120,
    position: 'relative',
    marginBottom: theme.spacing(),
    display: 'flex',
  },
  media: {
    width: '100%',
    height: 120,
    backgroundColor: '#f4f4f4',
    borderRadius: 6,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  mobileContent: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
    // marginTop: theme.spacing(),
  },
  rating: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    width: 40,
    height: 20,
    zIndex: 100,
  },
  rateIcon: {
    marginLeft: 5,
  },
  storeRating: {
    fontSize: 11,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    color: '#fff',
    marginLeft: 3,
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    backgroundColor: 'pink',
    alignItems: 'flex-start',
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  favorite: {
    height: 20,
    width: 20,
  },
  titleBlock: {
    justifyContent: 'space-between',
  },
  categoryBlock: {
    // paddingRight: theme.spacing(1),
  },
  deliveryBlock: {
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
  },
  category: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#333',
    opacity: 0.5,
  },
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    marginBottom: theme.spacing(2),
    height: 120,
  },
  headerContainer: {
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  noResultContainer: {
    marginTop: 15,
    width: '100%',
    height: 350,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mobileNoResult: {
    width: '100%',
    height: height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  desktopContent: {
    minHeight: generalConstants.minPageHeight,
  },
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
  deleteCardPaper: {
    width: '638px',
    height: '503px',
    padding: '20px 20px 34px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

class FavoriteRestaurants extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      favoriteStores: [],
      showDeleteDialog: false,
      id: null,
      loading: false,
      totalPages: 1,
      query: {
        page: 0,
        size: 12,
      },
    }
    this.deleteFavorite = this.deleteFavorite.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
  }

  componentDidMount() {
    this.getFavorites()
  }

  componentDidUpdate(prevProps) {
    const { signedIn, history } = this.props
    if (prevProps.signedIn !== signedIn) {
      if (!signedIn) {
        history.push(`${path}/customer/food`)
      }
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  getFavorites() {
    const { query, totalPages, favoriteStores } = this.state
    const { signedIn } = this.props
    if (totalPages > query.page && signedIn) {
      this.setState({ loading: true })
      customerService.getAllFavoriteStores(query).then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.setState({
            favoriteStores: query.page === 0 ? res.data.content : [...favoriteStores, ...res.data.content],
            query: { ...query, page: res.data.pageable.pageNumber + 1 },
            totalPages: res.data.totalPages,
            loading: false,
          })
        }
      })
    }
  }

  confirmDelete(id) {
    this.setState({ showDeleteDialog: true, id: id })
  }

  closeDialog() {
    this.setState({ showDeleteDialog: false })
  }

  deleteFavorite(id) {
    const { query } = this.state
    this.setState({ loading: true, query: { ...query, page: 0 }, totalPages: 1 })
    const { favoriteStores } = this.state
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes &&
      customerService.deleteFavoriteStore(storeId).then((res) => {
        if (res === generalConstants.STATUS_OK) {
          this.getFavorites()
          this.setState({ showDeleteDialog: false })
        }
      })
  }

  render() {
    const { classes } = this.props
    const { favoriteStores, showDeleteDialog, id, loading } = this.state

    return (
      <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
        <Loading open={loading} />
        {!isMobileOnly ? (
          <div className={clsx('grow desktop full-width', [classes.root])}>
            <Container maxWidth='lg'>
              <TopNavbar cartVisible={true} />
            </Container>
            <div className={classes.headerContainer}>
              <Container maxWidth='lg'>
                <Typography className={classes.headerText}>{this.getLabel('favorite-restaurants')}</Typography>
              </Container>
            </div>
            <div className={classes.container}>
              <Container maxWidth='lg' className={classes.desktopContent}>
                <Grid container className={classes.grid}>
                  {favoriteStores?.length > 0 ? (
                    <div className='grow' style={{ marginTop: 20 }}>
                      <Grid container spacing={2}>
                        {favoriteStores.map((favorite, index) => (
                          <Grid item lg={4} sm={6} md={6} xs={12} key={index}>
                            <IntersectionVisible
                              onShow={() => index === favoriteStores.length - 4 && this.getFavorites()}
                            >
                              <StoreItem
                                key={index}
                                data={favorite.store}
                                history={this.props.history}
                                onPress={this.confirmDelete.bind(this)}
                                favorites={this.state.favoriteStores}
                                price={2}
                              />
                            </IntersectionVisible>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ) : (
                    !loading && (
                      <div className={classes.noResultContainer}>
                        <NoResult label='favorites' />
                      </div>
                    )
                  )}
                </Grid>
              </Container>
            </div>
            <div className='grow' />
            <Footer />
          </div>
        ) : (
          <div className={clsx('mobile', classes.mobileRoot)}>
            <div className='mobile-top-bar'>
              <IconButton size='small' className={classes.back} onClick={() => this.props.history.goBack()}>
                <img src={backIcon} alt='Back' />
              </IconButton>
              <Typography variant='h6'>{this.getLabel('favorite-restaurants')}</Typography>
            </div>
            {/* <div className={clsx('vertical-scrolling-wrapper-flexbox', [classes.mobileContent])}> */}
            <div className={classes.mobileContent}>
              <Grid container className='vertical-scrolling-wrapper-flexbox'>
                {favoriteStores?.length > 0
                  ? favoriteStores.map((favorite, index) => {
                      const direction = `${path}/customer/store/${favorite.entityId}`
                      return (
                        <IntersectionVisible
                          key={index}
                          onShow={() => index === favoriteStores.length - 4 && this.getFavorites()}
                        >
                          <Swipeout
                            right={[
                              {
                                text: this.getLabel('remove-label'),
                                onPress: () => this.confirmDelete(favorite.entityId),
                                className: clsx('custom-class-2', [classes.dialog]),
                                style: { width: 120, borderRadius: 10, marginRight: 5 },
                              },
                            ]}
                          >
                            <StoreCardSmall data={favorite.store} favorite direction={direction} big />
                          </Swipeout>
                        </IntersectionVisible>
                      )
                    })
                  : !loading && (
                      <div className={classes.mobileNoResult}>
                        <NoResult label='favorites' />
                      </div>
                    )}
              </Grid>
            </div>
          </div>
        )}
        <Dialog
          classes={{ paper: classes.generalDialogRoot }}
          open={isMobileOnly && showDeleteDialog}
          onClose={this.closeDialog}
          TransitionComponent={Transition}
        >
          <MobileDialog
            close={this.closeDialog}
            closeIcon={false}
            header={this.getLabel('delete-favorite-alert-title')}
            text={this.getLabel('sure-to-delete-favorite')}
            function1={this.closeDialog}
            function2={this.deleteFavorite}
            id={id && id}
            sizeW={'135px'}
            sizeH={'200px'}
            img={'deleteFavoriteImage'}
            greenButton={false}
            button1={this.getLabel('cancel-label')}
            button2={this.getLabel('delete-label')}
          />
        </Dialog>
        <Dialog
          classes={{ paper: classes.deleteCardPaper }}
          open={showDeleteDialog}
          onClose={this.closeDialog}
          TransitionComponent={Transition}
        >
          <WebConfirmationDialog
            close={this.closeDialog}
            header={this.getLabel('delete-favorite-alert-title')}
            text={this.getLabel('sure-to-delete-favorite')}
            text2={false}
            function1={this.closeDialog}
            function2={this.deleteFavorite}
            img={'deleteFavoriteImage'}
            button1={this.getLabel('cancel-label')}
            button2={this.getLabel('delete-label')}
            id={id}
          />
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customer } = state
  return {
    signedIn: customer.signedIn,
    mainProp: ownProps.match.params.main,
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, null)(FavoriteRestaurants))))
