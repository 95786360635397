import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Textfield from '../Fields/TextField_'
import SelectAddress from './BusinessDetails/SelectAddress'
import PhoneField from '../Fields/PhoneField_'

import { valueConstants } from '../Helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}))

const BusinessDetails = ({ handleChange, values, setValues, error, setParsedAddress, parsedAddress }) => {
  const classes = useStyles()
  return (
    <Container maxWidth='md' className={classes.root}>
      <Grid container spacing={2}>
        <Textfield
          values={values}
          prop={valueConstants.storeName}
          required
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Textfield
          values={values}
          prop={valueConstants.storeNumber}
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Grid item xs={6}>
          <SelectAddress setParsedAddress={setParsedAddress} parsedAddress={parsedAddress} error={error} />
        </Grid>
        <Textfield
          values={values}
          prop={valueConstants.suiteNo}
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Textfield
          values={values}
          prop={valueConstants.storeEmail}
          required
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <PhoneField
          prop={valueConstants.storePhone}
          values={values}
          setValues={setValues}
          label='Store Phone*'
          error={error}
          gridVal={6}
        />
      </Grid>
    </Container>
  )
}

BusinessDetails.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
  setParsedAddress: PropTypes.func,
  parsedAddress: PropTypes.object,
}

export default BusinessDetails
