import { initialServiceHours } from '../../../constants'

export const steps = [
  'Personal Details',
  'Business Details',
  'Working Hours',
  'Menu Details',
  'Notifications',
  'Delivery Details',
  'Payment Details',
  'Bank Info',
]

export const zerothStepErrorsInitialState = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
}

export const labelConstants = {
  firstName: 'firstName-label',
  lastName: 'lastName-label',
  phoneNo: 'phone-number-label',
  email: 'email-label',
  businessRole: 'your-role-in-business',
  storeName: 'store-name',
  storeAddress: 'store-address',
  suiteNo: 'apt-suite',
  storePhone: 'store-phone',
  storeEmail: 'store-email',
  menuLink: 'your-catering-menu',
  orderConfirmationPhone: 'number-order-confirmation',
  orderConfirmationEmail: 'email-order-confirmation',
  orderPrepConfirmationPhone: 'number-prep-confirmation',
  firstTierDeliveryFee: 'delivery-fee-1',
  firstTierMinOrder: 'min-order-1',
  firstTierMaxDistance: 'max-distance-1',
  secondTierDeliveryFee: 'delivery-fee-2',
  secondTierMinOrder: 'min-order-2',
  secondTierMaxDistance: 'max-distance-2',
  thirdTierDeliveryFee: 'delivery-fee-3',
  thirdTierMinOrder: 'min-order-3',
  thirdTierMaxDistance: 'max-distance-3',
  legalBusinessName: 'legal-business-name',
  paymentContactName: 'fullname-label',
  paymentContactPhone: 'phone-number-label',
  paymentContactEmail: 'email-label',
  phoneNoStar: 'phone-no-star',
  check: 'check-label',
  directDeposit: 'direct-deposit-label',
  taxIdNumber: 'taxId-number',
  accountNumber: 'account-number',
  routingNumber: 'routing-number',
  confirmAccountNumber: 'account-number-confirm',
  maxDeliveryDistance: 'max-delivery-distance',
  minOrderPrice: 'min-order-price',
  storeNumber: 'store-number',
  textMessage: 'text-message',
}

export const valueConstants = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNo: 'phoneNo',
  email: 'email',
  businessRole: 'businessRole',
  storeName: 'storeName',
  storeAddress: 'storeAddress',
  suiteNo: 'suiteNo',
  storePhone: 'storePhone',
  storeEmail: 'storeEmail',
  menuLink: 'menuLink',
  notificationType: 'notificationType',
  orderConfirmationPhone: 'orderConfirmationPhone',
  orderConfirmationEmail: 'orderConfirmationEmail',
  orderPrepConfirmationPhone: 'orderPrepConfirmationPhone',
  firstTierDeliveryFee: 'firstTierDeliveryFee',
  firstTierMinOrder: 'firstTierMinOrder',
  firstTierMaxDistance: 'firstTierMaxDistance',
  secondTierDeliveryFee: 'secondTierDeliveryFee',
  secondTierMinOrder: 'secondTierMinOrder',
  secondTierMaxDistance: 'secondTierMaxDistance',
  thirdTierDeliveryFee: 'thirdTierDeliveryFee',
  thirdTierMinOrder: 'thirdTierMinOrder',
  thirdTierMaxDistance: 'thirdTierMaxDistance',
  deliveryBy: 'deliveryBy',
  legalBusinessName: 'legalBusinessName',
  paymentContactName: 'paymentContactName',
  paymentContactPhone: 'paymentContactPhone',
  paymentContactEmail: 'paymentContactEmail',
  tookfresh: 'SENPEX',
  ownBusiness: 'OWN_BUSINESS',
  check: 'CHECK',
  directDeposit: 'DIRECT_DEPOSIT',
  paymentMethod: 'paymentMethod',
  taxIdNumber: 'taxIdNumber',
  accountNumber: 'accountNumber',
  routingNumber: 'routingNumber',
  confirmAccountNumber: 'confirmAccountNumber',
  minOrderPrice: 'minOrderPrice',
  maxDeliveryDistance: 'maxDeliveryDistance',
  storeNumber: 'storeNumber',
  notificationPhone: 'PHONE',
  notificationEmail: 'EMAIL',
  notificationBoth: 'BOTH',
}

export const personalDetailsData = [
  {
    label: labelConstants.firstName,
    value: valueConstants.firstName,
    width: 6,
  },
  { label: labelConstants.lastName, value: valueConstants.lastName, width: 6 },
  { label: labelConstants.phoneNo, value: valueConstants.phoneNo, width: 6 },
  { label: labelConstants.email, value: valueConstants.email, width: 6 },
  {
    label: labelConstants.businessRole,
    value: valueConstants.businessRole,
    width: 12,
  },
]

export const businessDetailsData = [
  {
    label: labelConstants.storeName,
    value: valueConstants.storeName,
    width: 6,
  },
  {
    label: labelConstants.storeNumber,
    value: valueConstants.storeNumber,
    width: 6,
  },
  {
    label: labelConstants.storeAddress,
    value: valueConstants.storeAddress,
    width: 12,
  },
  { label: labelConstants.suiteNo, value: valueConstants.suiteNo, width: 6 },
  {
    label: labelConstants.storePhone,
    value: valueConstants.storePhone,
    width: 6,
  },
]

export const deliveryRole = [valueConstants.tookfresh, valueConstants.ownBusiness]

export const tfDefault = [
  {
    label: labelConstants.minOrderPrice,
    value: valueConstants.minOrderPrice,
  },
  {
    label: labelConstants.maxDeliveryDistance,
    value: valueConstants.maxDeliveryDistance,
  },
]

export const firstTier = [
  {
    label: labelConstants.firstTierDeliveryFee,
    value: valueConstants.firstTierDeliveryFee,
  },
  {
    label: labelConstants.firstTierMinOrder,
    value: valueConstants.firstTierMinOrder,
  },
  {
    label: labelConstants.firstTierMaxDistance,
    value: valueConstants.firstTierMaxDistance,
  },
]

export const secondTier = [
  {
    label: labelConstants.secondTierDeliveryFee,
    value: valueConstants.secondTierDeliveryFee,
  },
  {
    label: labelConstants.secondTierMinOrder,
    value: valueConstants.secondTierMinOrder,
  },
  {
    label: labelConstants.secondTierMaxDistance,
    value: valueConstants.secondTierMaxDistance,
  },
]

export const thirdTier = [
  {
    label: labelConstants.thirdTierDeliveryFee,
    value: valueConstants.thirdTierDeliveryFee,
  },
  {
    label: labelConstants.thirdTierMinOrder,
    value: valueConstants.thirdTierMinOrder,
  },
  {
    label: labelConstants.thirdTierMaxDistance,
    value: valueConstants.thirdTierMaxDistance,
  },
]

export const notificationValues = [valueConstants.notificationPhone, valueConstants.notificationEmail]

export const notificationConstants = {
  storePhone: 'Store Phone',
  storeEmail: 'Store Email',
  otherPhone: 'Other Phone',
  otherEmail: 'Other Email',
  both: 'BOTH',
  empty: '',
}

export const phoneConstants = [notificationConstants.storePhone, notificationConstants.otherPhone]
export const emailConstants = [notificationConstants.storeEmail, notificationConstants.otherEmail]

export const paymentDetailConstants = {
  myName: 'My name',
  myPhone: 'My phone',
  myEmail: 'My email',
  storePhone: 'Store phone',
  storeEmail: 'Store email',
  empty: '',
}

export const nameAreaConstants = ['My name', 'Other']

export const phoneAreaConstants = ['My phone', 'Store phone', 'Other']

export const emailAreaConstants = ['My email', 'Store email', 'Other']

export const directDepositFields = [
  {
    label: labelConstants.taxIdNumber,
    value: valueConstants.taxIdNumber,
  },
  {
    label: labelConstants.routingNumber,
    value: valueConstants.routingNumber,
  },
  {
    label: labelConstants.accountNumber,
    value: valueConstants.accountNumber,
  },
  {
    label: labelConstants.confirmAccountNumber,
    value: valueConstants.confirmAccountNumber,
  },
]

export const initialValues = {
  firstName: '',
  lastName: '',
  phoneNo: '1',
  email: '',
  businessRole: '',
  storeName: '',
  storeNumber: '',
  storeAddress: '',
  suiteNo: '',
  storePhone: '1',
  storeEmail: '',
  workingHours: initialServiceHours,
  menuLink: '',
  notificationType: [],
  orderConfirmationPhone: '',
  orderConfirmationEmail: '',
  deliveryBy: valueConstants.tookfresh,
  firstTierDeliveryFee: '',
  firstTierMinOrder: '',
  firstTierMaxDistance: '',
  secondTierDeliveryFee: '',
  secondTierMinOrder: '',
  secondTierMaxDistance: '',
  thirdTierDeliveryFee: '',
  thirdTierMinOrder: '',
  thirdTierMaxDistance: '',
  legalBusinessName: '',
  paymentContactName: '',
  paymentContactPhone: '1',
  paymentContactEmail: '',
  paymentMethod: valueConstants.directDeposit,
  taxIdNumber: '',
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  checkAddress: '',
  checkSuiteNo: '',
  minOrderPrice: '',
  maxDeliveryDistance: '',
  acceptSameDayOrder: false,
}

export const initialPdfState = {
  first: null,
  second: null,
  third: null,
}

export const initialLayersState = {
  second: false,
  third: false,
}
