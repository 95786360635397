/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import { searchActions, customerActions } from '../../../actions'
import { customerService } from '../../../services'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

import { deliveryTimes, generalConstants } from '../../../constants'
import { handleAddressQuery } from '../../../helpers/address/handleAddressQuery'

import AddressPopup from '../../common/addressPopup/addressPopup'
import HowPopover from './DeliveryDetails/HowPopover'
import WhenPopover from './DeliveryDetails/WhenPopover'
import WhenButton from './DeliveryDetails/WhenButton'
import HowButton from './DeliveryDetails/HowButton'

moment.locale('en')
const path = process.env.PUBLIC_URL

const rootHeight = 48

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    borderRadius: 7,
    backgroundColor: '#fff',
    paddingLeft: 5,
    height: rootHeight,
    "&[located-in='navbar']": {
      alignItems: 'center',
      height: 43,
    },
  },
  divider: {
    backgroundColor: '#c4c4c4',
    width: '1px',
    opacity: '50%',
  },
  addressPopupContainer: {
    flex: 1,
    height: rootHeight,
  },
}))

const DeliveryDetails = ({ fromStore, navbar, component, max_width }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const searchQuery = useSelector(({ search }) => search.searchQuery)
  const savedAddresses = useSelector(({ customer }) => customer.savedAddresses)

  const setWhen = (data) => dispatch(searchActions.setDeliveryTime(data))
  const setAddress = (data) => dispatch(searchActions.setAddress(data))
  const setWhenDetail = (data) => dispatch(searchActions.setDeliveryTimeDetail(data))
  const updateAddress = (data) => dispatch(customerActions.updateAddressFromAddressPopUp(data))

  const [deliveryTime, setDeliveryTime] = useState(null)
  const [showWhenPopover, setShowWhenPopover] = useState(false)
  const [showHowPopover, setShowHowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    let active = true
    if (active) {
      if (!deliveryTime && searchQuery) {
        let queryTime = searchQuery.deliveryTime
        setDeliveryTime(queryTime)
      }
    }
    return () => {
      active = false
    }
  }, [searchQuery, deliveryTime])

  const handleWhenClick = (event) => {
    setShowWhenPopover(true)
    setAnchorEl(event.currentTarget)
  }

  const handleWhenChange = (event) => {
    let value = event.target.value
    setDeliveryTime(value)
    if (value === deliveryTimes.ASAP) {
      setShowWhenPopover(false)
      setWhen(event.target.value)
      setWhenDetail(moment())
      fromStore || navbar ? null : history.push(`${path}/customer/search`)
    }
  }

  const closeWhenPopover = () => {
    setShowWhenPopover(false)
    setAnchorEl(null)
    setDeliveryTime(searchQuery.deliveryTime)
  }

  const handleWhenDetailChange = (date) => {
    setWhen(deliveryTimes.SCHEDULED)
    setWhenDetail(date)
    setShowWhenPopover(false)
    fromStore || navbar ? null : history.push(`${path}/customer/search`)
  }

  const openHowPopover = (e) => {
    setShowHowPopover(true)
    setAnchorEl(e.currentTarget)
  }

  const closeHowPopover = () => {
    setShowHowPopover(false)
    setAnchorEl(null)
  }

  const handleUpdateAddress = async (subType, inst) => {
    let address_ = savedAddresses.filter((item) => item.formattedAddress === searchQuery.address.formattedAddress)[0]
    let newAddress = {
      ...address_,
      deliveryOption: subType,
      deliveryInstruction: inst,
    }
    let addressQuery = handleAddressQuery(newAddress)
    await customerService
      .updateAddress(newAddress.id, newAddress)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          updateAddress(res.data)
          setAddress(addressQuery)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <div located-in={navbar ? 'navbar' : 'banner'} className={classes.root}>
      <WhenButton handleWhenClick={handleWhenClick} searchQuery={searchQuery} navbar={navbar} />
      <Divider orientation='vertical' flexItem className={!navbar ? classes.divider : null} />
      <div className={classes.addressPopupContainer} style={{ maxWidth: max_width }}>
        <AddressPopup deliveryDetails component={component} />
      </div>
      <Divider orientation='vertical' flexItem className={!navbar ? classes.divider : null} />
      <HowButton openHowPopover={openHowPopover} searchQuery={searchQuery} navbar={navbar} />
      <WhenPopover
        showWhenPopover={showWhenPopover}
        anchorEl={anchorEl}
        closeWhenPopover={closeWhenPopover}
        deliveryTime={deliveryTime}
        handleWhenChange={handleWhenChange}
        searchQuery={searchQuery}
        handleWhenDetailChange={handleWhenDetailChange}
      />
      <HowPopover
        showHowPopover={showHowPopover}
        anchorEl={anchorEl}
        closeHowPopover={closeHowPopover}
        handleUpdateAddress={handleUpdateAddress}
      />
    </div>
  )
}

DeliveryDetails.propTypes = {
  fromStore: PropTypes.bool,
  navbar: PropTypes.bool,
  component: PropTypes.string,
}

export default DeliveryDetails
