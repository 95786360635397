import React from 'react'
import { FormControl, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core'
import { GreenRadio } from '../../../utils/tools'

const OneSelect = ({ value, attribute, handleAttributes, menuAttributes }) => {
  return (
    <React.Fragment>
      <Grid item xs={10} container direction='row'>
        <FormControl component='fieldset'>
          <FormGroup>
            <FormControlLabel
              value={`${value.id}`}
              control={
                <GreenRadio
                  color='primary'
                  onChange={() => handleAttributes(attribute, value)}
                  checked={menuAttributes.some((attr) => attr.attributeValueIds.includes(value.id))}
                />
              }
              label={`${value.name}`}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item container xs={2} direction='row' alignItems='center' justify='center'>
        <Typography variant='body1'>{value.price !== 0 ? `$${value.price.toFixed(2)}` : null}</Typography>
      </Grid>
    </React.Fragment>
  )
}

export default OneSelect
