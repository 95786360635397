import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import TopNavbar from '../../top_navbar'
import Footer from '../../Footers'
import NoResult from '../../search/no_result'
import WebConfirmationDialog from '../../../common/web-confirmation-dialog'
import AddAddressDialog from './WebAddresses/AddAddressDialog'
import InnerAddressItem from './WebAddresses/InnerAddressItem'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { customerActions } from '../../../../actions'
import { customerService, searchService } from '../../../../services'
import { BlackButton, Transition } from '../../../utils/tools'
import { parseGoogleAPIAddress } from '../../../utils/common'
import { isSavedBefore } from '../../../../helpers/address/checkIfAddressSavedBefore'
import { generalConstants, initialAddressQuery } from '../../../../constants'

const path = process.env.PUBLIC_URL

const columnFlex = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
  },
  full: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    minHeight: generalConstants.minPageHeight,
  },
  grid: {
    ...columnFlex,
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    marginBottom: theme.spacing(2),
  },
  addButton: {
    textTransform: 'capitalize',
    height: 40,
  },
  addressContainer: {
    maxHeight: 40,
    width: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      border: `1px solid black`,
    },
    padding: theme.spacing(),
  },
  headerContainer: {
    ...columnFlex,
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  blackButtonContainer: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noResultContainer: {
    width: '100%',
    height: 350,
    display: 'flex',
    justifyContent: 'center',
  },
  innerAddressContainer: {
    minHeight: 300,
    paddingTop: 30,
  },
  warnDialog: {
    ...columnFlex,
    width: '638px',
    height: ' 503px',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: 24,
    alignItems: 'center',
  },
}))

const WebAddresses = () => {
  const classes = useStyles()
  const history = useHistory()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const addresses = useSelector(({ customer }) => customer.savedAddresses)
  const signedIn = useSelector(({ customer }) => customer.signedIn)

  const getAddresses = () => dispatch(customerActions.getAllSavedAddresses())

  const [showAddDialog, setShowAddDialog] = useState(false)
  const [warnDialog, setWarnDialog] = useState(false)
  const [newAddress, setNewAddress] = useState(initialAddressQuery)
  const [defaultAddressName, setDefaultAddressName] = useState('')

  useEffect(() => {
    let active = true
    if (active) {
      if (!signedIn) {
        history.push(`${path}/customer/food`)
      }
    }
    return () => {
      active = false
    }
  }, [signedIn])

  useEffect(() => {
    let active = true
    if (active) {
      getAddresses()
    }
    return () => {
      active = false
    }
  }, [])

  const handleDialogOpen = () => {
    addresses.length >= 10 ? setWarnDialog(true) : setShowAddDialog(true)
  }

  const handleDialogClose = () => {
    setShowAddDialog(false)
    setNewAddress(initialAddressQuery)
  }

  const handleAddClick = async () => {
    let [savedBefore, savedAddress] = isSavedBefore(addresses, newAddress.formattedAddress)
    if (savedBefore) {
      await customerService
        .updateAddress(savedAddress.id, newAddress)
        .then(() => getAddresses())
        .catch((err) => console.log(err))
    } else {
      await customerService
        .createAddress(newAddress)
        .then(() => getAddresses())
        .catch((err) => console.log(err))
    }
    setShowAddDialog(false)
    setNewAddress(initialAddressQuery)
  }

  const handleDeliveryAddress = (address) => {
    let parsedAddress = parseGoogleAPIAddress(address)
    if (parsedAddress) {
      let lat = parsedAddress.latitude
      let long = parsedAddress.longitude
      searchService
        .getTimeZoneOffset(lat, long)
        .then((response) => {
          let { rawOffset, dstOffset } = response.data
          setNewAddress({
            ...newAddress,
            country: parsedAddress.country,
            city: parsedAddress.city,
            state: parsedAddress.state,
            zipCode: parsedAddress.zipCode,
            addressLine1: parsedAddress.addressLine1,
            formattedAddress: parsedAddress.formattedAddress,
            latitude: parsedAddress.latitude,
            longitude: parsedAddress.longitude,
            name: newAddress.name && newAddress.name.length > 0 ? newAddress.name : defaultAddressName,
            timeZoneOffset: (rawOffset + dstOffset) / 60,
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const setAddressLine2 = (e) => {
    setNewAddress({
      ...newAddress,
      addressLine2: e.target.value,
    })
  }

  const clearAddressLine2 = () => {
    setNewAddress({
      ...newAddress,
      addressLine2: '',
    })
  }

  const setName = (e) => {
    setNewAddress({
      ...newAddress,
      name: e.target.value,
    })
  }

  const clearName = () => {
    setNewAddress({
      ...newAddress,
      name: '',
    })
  }

  const setInstruction = (e) => {
    setNewAddress({
      ...newAddress,
      deliveryInstruction: e.target.value,
    })
  }

  const clearInstruction = () => {
    setNewAddress({
      ...newAddress,
      deliveryInstruction: '',
    })
  }

  const setDeliveryOption = (prop) => {
    setNewAddress({
      ...newAddress,
      deliveryOption: prop,
    })
  }

  const closeWarnDialog = () => setWarnDialog(false)

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={true} />
      </Container>
      <div className={classes.headerContainer}>
        <Container maxWidth='lg' className={classes.header}>
          <Typography className={classes.headerText}>{f({ id: 'addresses-label' })}</Typography>
        </Container>
      </div>
      <Container maxWidth='lg' className={classes.full}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.blackButtonContainer}>
            <BlackButton
              disableRipple
              variant='contained'
              color='primary'
              className={classes.addButton}
              onClick={handleDialogOpen}
            >
              {f({ id: 'add-address' })}
            </BlackButton>
          </Grid>
          <Grid item xs={12} className={clsx('grow', [classes.innerAddressContainer])}>
            <Grid container spacing={2}>
              {addresses.length > 0 ? (
                addresses.map((address, index) => (
                  <Grid item sm={12} md={6} key={index}>
                    <InnerAddressItem data={address} history={history} />
                  </Grid>
                ))
              ) : (
                <div className={classes.noResultContainer}>
                  <NoResult label='address' />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className='grow' />
      <Footer />
      <AddAddressDialog
        showAddDialog={showAddDialog}
        handleDialogClose={handleDialogClose}
        handleAddClick={handleAddClick}
        setName={setName}
        newAddress={newAddress}
        handleDeliveryAddress={handleDeliveryAddress}
        setAddressLine2={setAddressLine2}
        handleSubType={setDeliveryOption}
        subTyp={newAddress.deliveryOption}
        instruction={newAddress.deliveryInstruction}
        handleInstruction={setInstruction}
        addresses={addresses}
        setNewAddress={setNewAddress}
        clearName={clearName}
        clearAddressLine2={clearAddressLine2}
        clearInstruction={clearInstruction}
        setDefaultAddressName={setDefaultAddressName}
      />
      <Dialog
        classes={{ paper: classes.warnDialog }}
        open={warnDialog}
        onClose={closeWarnDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closeWarnDialog}
          header={f({ id: 'too-many-addresses' })}
          text={f({ id: 'reached-max-address' })}
          text2={false}
          function1={closeWarnDialog}
          function2={false}
          img={'deleteAddressImage'}
          button1={f({ id: 'continue-label' })}
          button2={false}
          id={false}
        />
      </Dialog>
    </div>
  )
}

export default WebAddresses
