export const updateMenu = (sections, sectionId, menu) => {
  let updatedSectionMenu = sections.map((section) => {
    if (section.id !== +sectionId) {
      return section
    } else {
      let updatedMenu_ = section.menus.map((m) => {
        if (+m.id !== +menu.id) {
          return m
        } else {
          return { ...m, ...menu }
        }
      })
      section.menus = updatedMenu_
      return section
    }
  })

  return updatedSectionMenu
}

export const deleteMenu = (sections, sectionId, menuId) => {
  let relatedSection = sections.find((i) => i.id === sectionId)
  const index = sections.indexOf(relatedSection)
  const menuAfterDelete = relatedSection.menus.filter((menu) => menu.id !== menuId)
  sections[index].menus = menuAfterDelete
  return { sections }
}

export const updateSection = (sections, section) => {
  let updateSectionState = sections.map((s) => {
    if (s.id !== section.id) {
      return s
    } else {
      return { ...s, ...section }
    }
  })
  return { updateSectionState }
}

export const deleteMenuImage = (sections, sectionId, menuId, imageId) => {
  let relatedSection = sections.find((i) => i.id === +sectionId)
  const indexRelatedSection = sections.indexOf(relatedSection)
  let relatedMenu = relatedSection.menus.find((e) => e.id === +menuId)
  const indexRelatedMenu = relatedSection.menus.indexOf(relatedMenu)
  let newImagesProp = relatedMenu.images.filter((x) => x.id !== +imageId)
  relatedMenu.images = newImagesProp
  relatedSection.menus[indexRelatedMenu] = relatedMenu
  sections[indexRelatedSection] = relatedSection

  return sections
}
