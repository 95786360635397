import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, CardContent, Card } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import placeHolder from '../../../../../images/logo-bg-green.svg'

const imageWidth = 160

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '360px',
    height: imageWidth,
    backgroundColor: '#fff',
    borderRadius: 6,
    display: 'flex',
    border: '1.5 solid',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.15)',
    position: 'relative',
    userSelect: 'none',
  },
  boxRoot: {
    maxWidth: '360px',
    borderRadius: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
    maxWidth: '14rem',
  },
  details: {
    height: imageWidth,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  content: {
    height: imageWidth,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(),
  },
  button: {
    width: 41,
    height: 41,
    backgroundColor: '#fff',
    borderBottomRightRadius: 2,
    borderTopLeftRadius: 15,
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 40,
  },
  add: {
    width: 32,
    height: 32,
    borderRadius: 22,
    backgroundColor: '#c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContainer: {
    minWidth: 160,
    height: 160,
    // backgroundColor: '#F8F9FA',
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
}))

const MenuItem = ({ item, img }) => {
  const classes = useStyles()

  return (
    <Box className={classes.boxRoot} border={2} borderColor='white'>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography className={classes.title} noWrap>
              {item?.name}
            </Typography>
            <Typography variant='body2' color='textPrimary' component='p' className='elipsis'>
              {item?.description}
            </Typography>
            <div className='grow' />
            <Typography variant='body2' color='textSecondary' component='p'>
              ${item?.price}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.imageContainer}>
          <img src={img || placeHolder} alt='img' width='160px' height='160px' />
        </div>
        <div className={classes.button}>
          <div className={classes.add}>
            <AddIcon />
          </div>
        </div>
      </Card>
    </Box>
  )
}

export default MenuItem
