import React from 'react'
import { withRouter } from 'react-router-dom'
import { isMobile, isTablet } from 'react-device-detect'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { MerchantPrivateRoute } from '../../routes'
import MerchantHome from './MerchantHome'
import MerchantSignin from './authentication/Login'
import logoText from '../../images/logo_text_black.svg'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

const height = window.innerHeight

const useStyles = makeStyles(() => ({
  root: {
    height: height,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 200,
    fontSize: 20,
    fontWeight: 700,
    color: '#707070',
    textAlign: 'center',
  },
  logoContainer: {
    width: '100%',
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
}))

function MerchantMain() {
  let { path } = useRouteMatch()
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  if (isMobile && !isTablet)
    return (
      <Dialog fullScreen open={true}>
        <DialogTitle className={classes.logoContainer}>
          <img src={logoText} alt='TookFresh' height='45' className='mobile-logo' />
        </DialogTitle>
        <div className={classes.root}>{format({ id: 'mobile-not-supported' })}</div>
      </Dialog>
    )
  return (
    <Switch>
      <Route exact path={`${path}/signin`}>
        <MerchantSignin />
      </Route>
      <MerchantPrivateRoute path={path} component={MerchantHome} />
    </Switch>
  )
}

export default withRouter(MerchantMain)
