import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { GreenButton } from '../../../../utils/tools'
import { deliveryTypes, orderStatuses } from '../../../../../constants'
import withWidth from '@material-ui/core/withWidth'
import placeholder from '../../../../../images/image-placeholder.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import { IconButton } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { orderService } from '../../../../../services'
import { isEmpty } from 'lodash'
import { getImage } from '../../../../../helpers/get-image'
import background from '../../../../../images/restaurant-back.jpeg'

import { isTablet } from 'react-device-detect'
import DeliveryDetails from '../mobile-orders/mobile-order-detail/DeliveryDetails'

const path = process.env.PUBLIC_URL

const imageHeight = isTablet ? 60 : 66
const width = 316

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '250px',
  },
  contentContainer: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 250,
    height: 'auto',
    padding: 0,
    borderBottom: 'solid 1px #ededed',
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 25,
    marginBottom: 25,
  },
  rightPartContainer: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  orderHeaderContainer: {
    // height: 56.5,
    width: '97.5%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'solid 1px #ededed',
    marginLeft: 16.5,
  },
  orderSubheaderContainer: {
    // height: 21,
    width: '100%',
    margin: '0px',
    // paddingLeft: 16.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeaderText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'left',
    color: '#707070',
  },
  orderInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // paddingLeft: "16.5px",
    height: '100%',
  },
  orderTime: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.38,
    textAlign: 'left',
    color: '#8b8b8b',
  },
  orderDeliveryContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  deliveryImage: {
    width: 20,
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: 8,
  },
  deliveryTextContainer: {
    height: 18,
  },
  deliveryText: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: 1.38,
    textAlign: 'left',
    color: '#707070',
  },
  imageContainer: {
    minWidth: isTablet ? 290 : 316,
    height: isTablet ? 183.5 : 200,
    margin: 0,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  imageHoldings: {
    display: 'flex',
    position: 'relative',
    zIndex: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: imageHeight,
    // paddingLeft: 20,
    paddingRight: 10,
    width: width,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: imageHeight,
    // width: '20%',
    minWidth: imageHeight,
    justifyContent: 'center',
  },
  imageInfoContainer: {
    paddingRight: 10,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: imageHeight + 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  sectionNames: {
    fontFamily: 'Roboto',
    fontSize: isTablet ? '12px' : '14px',
    lineHeight: 1.07,
    letterSpacing: '0.3px',
    textAlign: 'left',
    color: '#ffffff',
    paddingBottom: 0,
    marginBottom: -5,
  },
  storeName: {
    fontFamily: 'Roboto',
    fontSize: isTablet ? '28px' : '32px',
    fontWeight: 'bold',
    lineHeight: 1.25,
    textAlign: 'left',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuPriceSection: {
    minHeight: 143.5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  menuSection: {
    minHeight: 143.5,
    width: '80%',
  },
  menuContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 40,
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  menuQuantityNameContainer: {
    width: '100%',
    minHeight: 19,
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 16,
  },
  menuQuantityContainer: {
    width: 16,
    height: 16,
    backgroundColor: '#69e781',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuQuantity: {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: 1.36,
    textAlign: 'center',
    color: '#ffffff',
  },
  menuNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 12.5,
  },
  menuName: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: 1.36,
    textAlign: 'left',
    color: '#000000',
  },
  detailContainer: {
    width: '42px',
    paddingLeft: 44.5,
    minHeight: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  detailText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#000000',
  },
  listItemText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#707070',
  },
  priceSectionContainer: {
    paddingTop: 28.5,
    minHeight: 143.5,
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  priceSection: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  taxContainer: {
    width: '100%',
    height: 19,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceSectionText: {
    fontFamily: 'Roboto',
    textAlign: 'left',
    color: '#000000',
  },
  totalContainer: {
    width: '100%',
    height: 21,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    height: 50,
    marginTop: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    height: 48,
    width: 111,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: isTablet ? '12px' : '16px',
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#ffffff',
  },
  rateContainer: {
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
  },
  rateButton: {
    marginRight: 0,
    textTransform: 'capitalize',
  },
})

class WebOrders extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      selectedIndex: '',
    }
  }

  componentDidUpdate(prevProps) {
    const { signedIn, history } = this.props
    if (prevProps.signedIn !== signedIn) {
      if (!signedIn) {
        history.push(`${path}/customer/signin`)
      } else return
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleCollapse(index) {
    const { selectedIndex } = this.state
    if (selectedIndex === index) {
      this.setState({ selectedIndex: '' })
    } else {
      this.setState({ selectedIndex: index })
    }
  }

  handleComplete = (id) => {
    const body = {
      status: 'DELIVERED',
      note: '...',
    }
    orderService.orderComplete(id, body).then((res) => {
      if (res === 200) {
        this.props.getOrders()
      }
    })
  }

  render() {
    const { classes, data, previous } = this.props
    const { selectedIndex } = this.state

    const restImage = getImage(data?.restaurant?.background, 'medium') || background

    return (
      <div className={classes.root}>
        <Container maxWidth='md' className={classes.contentContainer}>
          <Container className={classes.subContainer}>
            <div className={classes.imageContainer}>
              <img src={restImage} alt='background' width='100%' height='100%' style={{ filter: 'brightness(60%)' }} />

              <Grid
                xs={12}
                item
                className={classes.imageHoldings}
                style={{ marginTop: 122, position: 'absolute', bottom: 14 }}
              >
                <Grid item xs={3} className={classes.logoContainer}>
                  <img
                    src={getImage(data?.restaurant?.logo, 'big') || placeholder}
                    alt='logo'
                    width={imageHeight}
                    height={imageHeight}
                  />
                </Grid>
                <Grid item xs={9} className={classes.imageInfoContainer}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Typography className={classes.storeName}>{data.store.nickname}</Typography>
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Typography className={classes.sectionNames}>{data?.restaurant?.description}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.rightPartContainer}>
              <div className={classes.orderHeaderContainer} style={{ height: 85.5 }}>
                <div className={classes.orderSubheaderContainer} style={{ height: 50 }}>
                  <Typography className={classes.subHeaderText}>
                    {`Order ${data.status.name === orderStatuses.DELIVERED ? 'Completed' : 'Upcoming'}`}
                    {!previous && (
                      <span>
                        <IconButton
                          style={{ marginLeft: 10, width: 20, height: 20 }}
                          onClick={() => this.handleComplete(data.id)}
                        >
                          <DoneIcon style={{ color: '#69e781' }} />
                        </IconButton>
                      </span>
                    )}
                  </Typography>
                  {previous ? (
                    <div className={classes.rateContainer}>
                      <Button
                        disableRipple
                        disabled={data.ratingStatus}
                        className={classes.rateButton}
                        onClick={() => this.props.rate(data)}
                      >
                        Rate Your Order
                      </Button>
                    </div>
                  ) : null}
                </div>
                <Grid container className={classes.orderInfoContainer} style={{ height: 35.5 }}>
                  <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.orderTime}>
                      {previous
                        ? `${data?.deliveryDate} at ${data?.deliveryHour} Order Number ${data?.orderNumber}`
                        : `Order Number ${data?.orderNumber}`}
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {previous && data?.orderType === deliveryTypes.DELIVERY ? (
                      <DeliveryDetails order={data} web />
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              <div className={classes.menuPriceSection}>
                <div className={classes.menuSection}>
                  {data.orderDetails.map((order, index) => (
                    <div className={classes.menuContainer} key={index}>
                      <div className={classes.menuQuantityNameContainer}>
                        <div className={classes.menuQuantityContainer}>
                          <Typography className={classes.menuQuantity}>{order.quantity}</Typography>
                        </div>
                        <div className={classes.menuNameContainer}>
                          <Typography className={classes.menuName}>{order.orderMenu.name}</Typography>
                        </div>
                      </div>
                      {(!isEmpty(order?.attributes) || !!order?.orderMenu?.specialInstructions) && (
                        <>
                          <div onClick={() => this.handleCollapse(index)} className={classes.detailContainer}>
                            <Typography className={classes.detailText}>Detail</Typography>
                            {index === selectedIndex ? (
                              <ExpandMoreIcon style={{ marginBottom: 2 }} />
                            ) : (
                              <NavigateNextIcon style={{ marginBottom: 2 }} />
                            )}
                          </div>

                          <Collapse
                            in={index === selectedIndex}
                            timeout='auto'
                            unmountOnExit
                            style={{ paddingLeft: 28.5 }}
                          >
                            <List component='div' disablePadding>
                              {order?.attributes.map((attribute, index) => (
                                <ListItem
                                  key={index}
                                  style={{
                                    padding: '0px 0px 0px 16px',
                                    margin: 0,
                                  }}
                                >
                                  <ListItemText>
                                    <Typography className={classes.listItemText}>
                                      {attribute?.attributeValueName}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              ))}
                            </List>
                            {!!order?.orderMenu?.specialInstructions && (
                              <List component='div' disablePadding>
                                <ListItem className={classes.listItem}>
                                  <ListItemText>
                                    <Typography className={classes.detailText} noWrap>
                                      Special Instructions:
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                  {/* <ListItemText> */}
                                  <Typography className={classes.listItemText}>
                                    {order?.orderMenu?.specialInstructions}
                                  </Typography>
                                  {/* </ListItemText> */}
                                </ListItem>
                              </List>
                            )}
                          </Collapse>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className={classes.priceSectionContainer}>
                  <div className={classes.priceSection}>
                    <div className={classes.taxContainer}>
                      <Typography className={classes.priceSectionText} style={{ fontSize: 12 }}>
                        {this.getLabel('taxes-label')}
                      </Typography>
                      <Typography className={classes.priceSectionText} style={{ fontSize: 12 }}>
                        ${data.taxPrice}
                      </Typography>
                    </div>
                    <div className={classes.totalContainer}>
                      <Typography className={classes.priceSectionText} style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {this.getLabel('total-label')}
                      </Typography>
                      <Typography className={classes.priceSectionText} style={{ fontSize: 14, fontWeight: 'bold' }}>
                        ${data.totalPrice}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <GreenButton className={classes.button} onClick={() => this.props.handleOrderReceipt(data)}>
                      {this.getLabel('receipt-label')}
                    </GreenButton>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
        {/* <div className='sticky-bottom'>
          <Footer />
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { customer } = state

  return {
    signedIn: customer.signedIn,
    customer: customer.customerInfo,
    defaultAddress: customer.savedAddresses.filter((sa) => sa.default_ === true),
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, null)(withWidth()(WebOrders)))))
