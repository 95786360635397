import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import IntersectionVisible from 'react-intersection-visible'
import StoreItem from '../../store_item'
import MobileNoResult from '../../../../common/MobileNoResult'

const useStyles = makeStyles((theme) => ({
  item: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
}))

const SearchResult = ({ searchResult, fetchMoreData, favoriteHandler, favoriteStores }) => {
  const classes = useStyles()
  if (searchResult && searchResult?.length === 0) return <MobileNoResult />

  return (
    <>
      {searchResult?.map((store, index) => {
        return (
          <Grid item className={classes.item} key={index}>
            <IntersectionVisible onShow={() => index === searchResult?.length - 5 && fetchMoreData()}>
              <StoreItem data={store} onPress={favoriteHandler} favorites={favoriteStores} />
            </IntersectionVisible>
          </Grid>
        )
      })}
    </>
  )
}

SearchResult.propTypes = {
  searchResult: PropTypes.array,
  fetchMoreData: PropTypes.func,
  favoriteHandler: PropTypes.func,
  favoriteStores: PropTypes.array,
}

export default SearchResult
