import appstore from '../../images/apple-icon.svg'
import googleplay from '../../images/google-play1.svg'
import fb from '../../images/fb.svg'
import twttr from '../../images/twttr.svg'
import instagram from '../../images/instgrm.svg'

const path = process.env.PUBLIC_URL

const appStoresMenu = [
  {
    label: 'app-store',
    icon: appstore,
    alt: 'app store',
  },
  {
    label: 'google-play',
    icon: googleplay,
    alt: 'google play',
  },
]

const aboutTookfresh = [
  {
    label: 'about-tookfresh',
    link: '#',
    bold: true,
  },
  {
    label: 'read-blog',
    link: `${path}/customer/read-blog`,
    bold: false,
  },
  {
    label: 'buy-gift-card',
    link: `${path}/customer/buy-gift-card`,
    bold: false,
  },
  // {
  //   label: 'create-business-account',
  //   link: `${path}/customer/create-business-account`,
  //   bold: false,
  // },
  {
    label: 'add-your-restaurant',
    // link: `${path}/customer/add-your-restaurant`,
    link: '#',
    bold: false,
  },
  {
    label: 'signup-to-delivery',
    link: `${path}/customer/signup-to-delivery`,
    bold: false,
  },
]

const getHelps = [
  {
    label: 'get-help',
    link: '#',
    bold: true,
  },
  {
    label: 'read-faq',
    link: `${path}/customer/read-faq`,
    bold: false,
  },
  {
    label: 'view-cities',
    link: `${path}/customer/view-cities`,
    bold: false,
  },
  {
    label: 'view-countries',
    link: `${path}/customer/view-countries`,
    bold: false,
  },
  {
    label: 'restaurants-near-me',
    link: `${path}/customer/restaurants-near-me`,
    bold: false,
  },
  {
    label: 'support-center',
    link: `${path}/customer/support-center`,
    bold: false,
  },
]

const socialMedia = [
  {
    label: 'facebook',
    icon: fb,
    callback: null,
    alt: 'facebook',
  },
  {
    label: 'twitter',
    icon: twttr,
    callback: null,
    alt: 'twitter',
  },
  {
    label: 'instagram',
    icon: instagram,
    callback: null,
    alt: 'instagram',
  },
]

const bottomLinks = [
  {
    label: 'privacy-policy',
    link: `${path}/customer/privacy-policy`,
  },
  {
    label: 'terms-and-conditions',
    link: `${path}/customer/terms-and-conditions`,
  },
  {
    label: 'do-not-sell',
    link: '#',
  },
  {
    label: 'copyright-label',
    link: '#',
  },
]

export { appStoresMenu, aboutTookfresh, getHelps, socialMedia, bottomLinks }
