import { deliveryTypes, deliveryTimes, dateConstant } from '../../constants'
import moment from 'moment'

const getOCStatusQuery = (searchQuery, storeIds) => {
  let orderType = searchQuery?.deliveryType || deliveryTypes.DELIVERY
  const orderTime =
    searchQuery.deliveryTime === deliveryTimes.ASAP
      ? moment().format(dateConstant.dateFormat)
      : moment(searchQuery.deliveryTimeDetail).format(dateConstant.dateFormat)
  let query = {
    storeIds,
    orderType,
    orderTime,
  }
  return query
}

export { getOCStatusQuery }
