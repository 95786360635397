import { limits } from '../../constants'
import { valueConstants } from '../../components/businessRegister/businessConstants'
import { checkTierValues, confirmTierValues } from './edit-store'
import { filter } from 'lodash'
import { emailRegex, phoneRegex } from '../../components/utils/regex_collections'

const buttonStatus = (user, update, disable) => {
  let disableCondition
  if (update) {
    disableCondition = disable
  } else {
    disableCondition = user.firstMame === '' || user.lastName === '' || user.email === '' || !user.role
  }
  return disableCondition
}

const editEmployeeButtonStatus = (user, benchmark) => {
  let disableCondition_ = true
  if (Object.keys(user).length > 0 && benchmark) {
    disableCondition_ =
      user.firstName === benchmark.firstName && user.lastName === benchmark.lastName && user.email === benchmark.email
  }
  return disableCondition_
}

const checkAddButton = (attribute) => {
  if (
    !attribute.name ||
    !attribute.minimumCount ||
    !attribute.maximumCount ||
    !attribute.freeCount ||
    attribute.minimumCount > limits.MAXCOUNT ||
    attribute.minimumCount < limits.MINCOUNT ||
    attribute.maximumCount > limits.MAXCOUNT ||
    attribute.maximumCount < limits.MINCOUNT ||
    attribute.freeCount > limits.MAXCOUNT ||
    attribute.freeCount < limits.MINCOUNT
  ) {
    return true
  }
  return false
}

const checkUpdateButton = (editAttribute, attribute) => {
  const areValuesEqual = checkValues(editAttribute, attribute)
  const areValuesNull = checkNull(attribute)
  const isOutofLimits = checkLimits(attribute)
  if (areValuesEqual || areValuesNull || isOutofLimits) {
    return true
  }
  return false
}

const checkAddValueStatus = (attributeOptions) => {
  return !attributeOptions?.name || !attributeOptions?.price
}

const checkUpdateValueStatus = (attributeOptions, benchmark) => {
  //Here we cannot simply check if 2 objects are equal because price has different types
  //when user enters a number (e.g 1 === '1.00')
  let priceCondition = checkPriceCondition(attributeOptions, benchmark)
  if (
    attributeOptions.name === benchmark?.name &&
    attributeOptions.description === benchmark?.description &&
    priceCondition
  ) {
    return true
  }
  return false
}

const editButtonsDisableConditions = (
  subProp,
  store,
  bankInfo,
  storeInformation,
  tierValues,
  orderConfirmPhone,
  paymentContactPhone,
  phone,
  serviceHours
) => {
  const { nickname, minOrderPrice, maxDeliveryDistance, deliveryBy, notificationType, orderConfirmationEmail } = store

  const { email, address } = storeInformation

  const {
    taxIdNumber,
    ownerName,
    ownerBirthdate,
    accountNumber,
    routingNumber,
    paymentMethod,
    paymentContactEmail,
    paymentContactName,
  } = bankInfo

  if (subProp === 'store-details') {
    if (
      !nickname ||
      !phone ||
      !phoneRegex.test(phone) ||
      !email ||
      !emailRegex.test(email) ||
      !address.formattedAddress
    ) {
      return true
    }
    return false
  } else if (subProp === 'delivery-options') {
    if (!deliveryBy) {
      return true
    } else {
      if (deliveryBy === valueConstants.tookfresh) {
        if (!minOrderPrice || !maxDeliveryDistance) {
          return true
        }
        return false
      } else {
        let activeTiers = filter(tierValues, (tv) => tv.isActive === true)
        let order = activeTiers.length
        let activeTier = tierValues[order]
        let conditionSatisfied = checkTierValues(activeTier)
        let gradationSatisfied = order < 2 ? true : confirmTierValues(tierValues, order)
        if (!conditionSatisfied || !gradationSatisfied) {
          return true
        }
        return false
      }
    }
  } else if (subProp === 'bankinfo') {
    if (!paymentMethod) {
      return true
    } else {
      if (paymentMethod === valueConstants.directDeposit) {
        if (
          !taxIdNumber ||
          !ownerName ||
          !ownerBirthdate ||
          !accountNumber ||
          !routingNumber ||
          routingNumber?.length !== 11 ||
          accountNumber?.length !== 11 ||
          taxIdNumber?.length !== 9 ||
          ownerBirthdate?.length !== 10
        ) {
          return true
        }
        return false
      } else {
        if (!bankInfo.address.formattedAddress) {
          return true
        }
        return false
      }
    }
  } else if (subProp === 'notifications') {
    if (!notificationType || notificationType.length === 0) {
      return true
    } else {
      if (notificationType.length === 1) {
        if (notificationType[0] === 'PHONE' && (!orderConfirmPhone || !phoneRegex.test(orderConfirmPhone))) {
          return true
        } else if (
          notificationType[0] === 'EMAIL' &&
          (!orderConfirmationEmail || !emailRegex.test(orderConfirmationEmail))
        ) {
          return true
        }
        return false
      } else {
        if (
          !orderConfirmationEmail ||
          !emailRegex.test(orderConfirmationEmail) ||
          !orderConfirmPhone ||
          !phoneRegex.test(orderConfirmPhone)
        ) {
          return true
        }
        return false
      }
    }
  } else if (subProp === 'payment-contact-info') {
    if (
      !paymentContactPhone ||
      !phoneRegex.test(paymentContactPhone) ||
      !paymentContactEmail ||
      !emailRegex.test(paymentContactEmail) ||
      !paymentContactName
    ) {
      return true
    }
    return false
  } else if (subProp === 'working-hours') {
    let deliveryArr = filter(serviceHours.DELIVERY, (sh) => sh.length === 0)
    let takeoutArr = filter(serviceHours.TAKEOUT, (sh) => sh.length === 0)
    if (deliveryArr.length === 7 && takeoutArr.length === 7) {
      return true
    }
    return false
  }
}

export {
  buttonStatus,
  editEmployeeButtonStatus,
  checkAddButton,
  checkUpdateButton,
  checkAddValueStatus,
  checkUpdateValueStatus,
  editButtonsDisableConditions,
}

const checkPriceCondition = (attributeOptions, benchmark) => {
  if (!attributeOptions?.price) return true
  if (typeof attributeOptions?.price === 'number') {
    if (attributeOptions?.price === benchmark?.price) {
      return true
    }
  }
  if (typeof attributeOptions?.price === 'string') {
    if (benchmark?.price.toFixed(2) === attributeOptions?.price) {
      return true
    }
  }
  return false
}

const checkValues = (editAttribute, attribute) => {
  if (
    editAttribute?.minimumCount === +attribute.minimumCount &&
    editAttribute?.name === attribute.name &&
    editAttribute?.maximumCount === +attribute.maximumCount &&
    editAttribute?.freeCount === +attribute.freeCount &&
    editAttribute?.required === attribute.required &&
    editAttribute?.description === attribute.description
  ) {
    return true
  }
  return false
}

const checkNull = (attribute) => {
  if (
    attribute.name === '' ||
    attribute.minimumCount === '' ||
    attribute.maximumCount === '' ||
    attribute.freeCount === ''
  ) {
    return true
  }
  return false
}

const checkLimits = (attribute) => {
  if (
    attribute.minimumCount > limits.MAXCOUNT ||
    attribute.minimumCount < limits.MINCOUNT ||
    attribute.maximumCount > limits.MAXCOUNT ||
    attribute.maximumCount < limits.MINCOUNT ||
    attribute.freeCount > limits.MAXCOUNT ||
    attribute.freeCount < limits.MINCOUNT
  ) {
    return true
  }
  return false
}
