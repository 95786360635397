import React, { useState, Suspense, lazy } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination } from '@material-ui/core'
import { ordersHeader } from '../../../helpers/merchant/table-header'
import { rowsPerPageOptions } from '../../../constants'
import { ActionButtons } from './orders-table/ActionButtons'

const OrderDialog = lazy(() => import('./OrderDialog'))

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    margin: theme.spacing(0),
  },
  label: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {},
  cell: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '&[data-clickable=true]': {
      cursor: 'pointer',
      color: '#69e781',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}))

const StoresTable = ({ query, handleChangePage, handleChangeRowsPerPage, orders, changeOrderStatus }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  const [openDialog, setOpenDialog] = useState(false)
  const [target, setTarget] = useState(null)

  const onOpen = (e) => {
    setTarget(e.target.innerText)
    setOpenDialog(true)
  }

  const onClose = () => setOpenDialog(false)

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby='Stores' size='medium' aria-label='stores table'>
          <TableHead>
            <TableRow>
              {ordersHeader.map((header, i) => (
                <TableCell key={header.label} align='center'>
                  {format({ id: header.label })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.content?.map((order, i) => {
              return (
                <TableRow key={i} className={classes.row} hover>
                  <TableCell
                    className={classes.cell}
                    onClick={onOpen}
                    data-clickable={true}
                    align='center'
                    width={'10%'}
                  >
                    {order.orderId}
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'10%'}>
                    {moment(order.deliveryDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'15%'}>
                    {`${order.customerFirstName} ${order.customerLastName.charAt(0)}`}
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'10%'}>
                    {order.orderType}
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'20%'}>
                    {order.status.label}
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'15%'}>
                    <ActionButtons order={order} changeOrderStatus={changeOrderStatus} />
                  </TableCell>
                  <TableCell className={classes.cell} align='center' width={'20%'}>
                    {order.totalPayout.toFixed(2)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={orders?.totalElements || 0}
        rowsPerPage={query?.rowsPerPage || 15}
        page={query?.page || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Suspense fallback={<div></div>}>
        <OrderDialog data={orders?.content || []} onClose={onClose} openDialog={openDialog} target={target} />
      </Suspense>
    </Paper>
  )
}

export default StoresTable
