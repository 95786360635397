import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CountryCodeData from '../../constants/country_phone_list.json'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import { injectIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import axios from 'axios'
import GoogleAuth from './google_auth'
import FacebookAuth from './facebook_auth'
import { withRouter } from 'react-router-dom'
import { customerActions, alertActions } from '../../actions'
import { roles } from '../../constants'
import { GreenButton, TFTextField, Transition } from '../utils/tools'
import Divider from '@material-ui/core/Divider'
import InputMask from 'react-input-mask'
import clsx from 'clsx'
import { emailRegex, phoneRegex } from '../utils/regex_collections'
import { isMobile } from 'react-device-detect'
import MobileSignin from '../authentication/MobileSignin'
import { customerService } from '../../services'

const path = process.env.PUBLIC_URL

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootWOLogin: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
    marginBottom: theme.spacing(2),
  },
  titleMobile: {
    height: 60,
    fontSize: 25,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  subText: {
    fontSize: 12,
    height: 24,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.75,
    letterSpacing: 'normal',
    color: '#707070',
    marginBottom: theme.spacing(2),
  },
  signup: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#69e781',
  },
  codeField: {
    display: 'inline',
  },
  countryCode: {
    cursor: 'pointer',
  },
  link: {
    marginLeft: theme.spacing(),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  subLink: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  iconButton: {
    margin: theme.spacing(),
  },
  logo: {
    margin: theme.spacing(6),
  },
  signupButton: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'none',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    padding: 8,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    margin: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#c4c4c4',
  },
})

class Signup extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.codeInput = React.createRef()

    this.state = {
      firstName: '',
      lastName: '',
      code: '',
      phone: '',
      password: '',
      email: '',
      countryName: '',
      countryCode: '',
      currency: 'USD',
      userType: 'MEMBER',
      anchorEl: null,
      firstNameInvalid: true,
      lastNameInvalid: true,
      phoneInvalid: true,
      emailInvalid: true,
      passwordInvalid: true,
      showMobileSigninDialog: false,
    }

    this.getGeoInfo = this.getGeoInfo.bind(this)
    this.handleMobileSigninClose = this.handleMobileSigninClose.bind(this)
    this.handleSignin = this.handleSignin.bind(this)
    this.onSigninClick = this.onSigninClick.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.getGeoInfo()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getGeoInfo() {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data

        this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          code: data.country_calling_code,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  countryCodeSelected(event) {
    this.setState({ anchorEl: null })

    if (event.target.value) {
      this.setState({
        code: '+' + event.target.value,
      })
    }
  }

  checkEmail = async (email) => {
    const response = await customerService.checkEmail(email)
    return response.data
  }

  handleSubmit = async () => {
    let isEmailAvailable = await this.checkEmail(this.state.email)
    if (isEmailAvailable !== 'Ok!') return this.props.showError(isEmailAvailable.toString())
    let customer = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: `${this.state.code}-${this.state.phone}`,
      email: this.state.email,
      password: this.state.password,
      role: roles.WEB_USER,
    }

    this.props.signup(customer)
    this.props.history.push(`${path}/customer/signin`)
  }

  closeLoginDialog() {
    this.setState({ showLoginDialog: false })
  }

  handleMobileSigninClose = () => this.setState({ showMobileSigninDialog: false })

  handleSignin = () => {
    this.setState({ showMobileSigninDialog: false })
    this.props.history.push(`${path}/customer/food`)
  }

  onSigninClick = () => {
    isMobile ? this.setState({ showMobileSigninDialog: true }) : this.props.history.push(`${path}/customer/signin`)
  }

  render() {
    const { classes } = this.props
    const { firstName, lastName, code, phone, email, password, anchorEl, showMobileSigninDialog } = this.state

    return (
      <>
        <Grid container className={!isMobile ? classes.rootWOLogin : classes.root}>
          <Grid item xs={12}>
            <GoogleAuth />
            <FacebookAuth />
            <div className='centered-row' style={{ width: '100%' }}>
              <Divider className='grow' />
              <span className={classes.label}>{this.getLabel('or-continue')}</span>
              <Divider className='grow' />
            </div>
            {isMobile ? (
              <Typography className={clsx('mobile', [classes.titleMobile])} component='h1' variant='h5'>
                {this.getLabel('create-account')}
              </Typography>
            ) : (
              <Typography className={clsx('desktop', [classes.title])} component='h1' variant='h5'>
                {this.getLabel('create-account')}
              </Typography>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TFTextField
                  variant='outlined'
                  error={this.state.firstNameFocused && this.state.firstNameInvalid}
                  fullWidth
                  label={this.getLabel('first-name')}
                  value={firstName}
                  onChange={(event) =>
                    this.setState({
                      firstName: event.target.value,
                      firstNameFocused: true,
                      firstNameInvalid: !event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TFTextField
                  variant='outlined'
                  fullWidth
                  id='lastName'
                  label={this.getLabel('last-name')}
                  error={this.state.lastNameFocused && this.state.lastNameInvalid}
                  value={lastName}
                  onChange={(event) =>
                    this.setState({
                      lastName: event.target.value,
                      lastNameFocused: true,
                      lastNameInvalid: !event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TFTextField
                  className={classes.countryCode}
                  variant='outlined'
                  fullWidth
                  id='code'
                  label='Code'
                  autoComplete='code'
                  value={code}
                  onClick={(event) => this.setState({ anchorEl: event.target })}
                  inputProps={{ style: { cursor: 'pointer' } }}
                  inputRef={this.codeInput}
                />
                <Menu
                  id='country-code-menu'
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.countryCodeSelected.bind(this)}
                >
                  {CountryCodeData.map((data) => (
                    <MenuItem
                      key={data.dial_code + data.code}
                      value={data.dial_code}
                      onClick={this.countryCodeSelected.bind(this)}
                    >
                      {data.dial_code + data.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
              <Grid item xs={9}>
                <InputMask
                  mask='999 999 9999'
                  maskChar=' '
                  value={phone}
                  onChange={(event) =>
                    this.setState({
                      phone: event.target.value,
                      phoneFocused: true,
                      phoneInvalid: !phoneRegex.test(event.target.value),
                    })
                  }
                >
                  {() => (
                    <TFTextField
                      autoComplete='phone'
                      error={this.state.phoneFocused && this.state.phoneInvalid}
                      variant='outlined'
                      fullWidth
                      id='phone'
                      label={this.getLabel('phone-number')}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TFTextField
                  variant='outlined'
                  fullWidth
                  id='email'
                  label='Email'
                  name='email'
                  autoComplete='email'
                  value={email}
                  onChange={(event) =>
                    this.setState({
                      emailFocused: true,
                      emailInvalid: !emailRegex.test(event.target.value),
                      email: event.target.value,
                    })
                  }
                  error={this.state.emailInvalid && this.state.emailFocused}
                />
              </Grid>
              <Grid item xs={12}>
                <TFTextField
                  variant='outlined'
                  fullWidth
                  name='password'
                  placeholder={this.getLabel('password-eight-char')}
                  label='Password'
                  type='password'
                  error={this.state.passwordFocused && this.state.passwordInvalid}
                  id='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={(event) =>
                    this.setState({
                      password: event.target.value,
                      passwordFocused: true,
                      passwordInvalid: event.target.value.length < 8,
                    })
                  }
                />
              </Grid>
            </Grid>
            <GreenButton
              variant='contained'
              disabled={
                this.state.firstNameInvalid ||
                this.state.lastNameInvalid ||
                this.state.phoneInvalid ||
                this.state.emailInvalid ||
                this.state.passwordInvalid
              }
              className={classes.signupButton}
              onClick={this.handleSubmit}
            >
              {this.getLabel('create-account')}
            </GreenButton>
            <div style={{ textAlign: 'center' }}>
              <span className={classes.text}>{this.getLabel('already-have-account')}</span>
              <Link variant='body2' className={classes.link} onClick={this.onSigninClick}>
                {this.getLabel('sign-in')}
              </Link>
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <span className={classes.subText}>{this.getLabel('agree-text')}</span>
              <Link
                variant='body2'
                className={classes.subLink}
                onClick={() => {
                  this.props.history.push(`${path}/customer/terms-and-conditions`)
                }}
              >
                {this.getLabel('terms-of-use')}
              </Link>
              <span className={classes.subText}>{this.getLabel('and-label')}</span>
              <Link
                variant='body2'
                className={classes.subLink}
                onClick={() => {
                  this.props.history.push(`${path}/customer/privacy-policy`)
                }}
              >
                {this.getLabel('privacy-policy')}
              </Link>
            </div>
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={showMobileSigninDialog}
          onClose={this.handleMobileSigninClose}
          TransitionComponent={Transition}
        >
          <MobileSignin handleClose={this.handleMobileSigninClose} handleSignin={this.handleSignin} />
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showLogin: state.general.show_signin,
  }
}

const actionCreators = {
  signup: customerActions.signup,
  showError: alertActions.error,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(Signup))))
