import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setMainMenu } from '../../../actions/general.actions'
import { orderStatuses, bottomNavItems, generalConstants } from '../../../constants'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { orderService } from '../../../services'
import { ratingService } from '../../../services/rating.services'
import { Transition } from '../../utils/tools'
import RateOrders from './orders-main/RateOrders'
import Receipt from './orders-main/Receipt'
import Dialog from '@material-ui/core/Dialog'
import withWidth from '@material-ui/core/withWidth'
import { ratingCreator } from '../../../helpers/orders'
import Loading from '../../common/loading'
import useStyles from './styles'
import DesktopOrders from './orders-main/DesktopOrders'
import MobileOrders from './orders-main/MobileOrders'

const path = process.env.PUBLIC_URL

export const OrdersMain = ({ history }) => {
  const classes = useStyles()

  //Global states
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  //Local states
  const [mobileTabValue, setMobileTabValue] = useState(1)
  const [previousOrders, setPreviousOrders] = useState([])
  const [upcomingOrders, setUpcomingOrders] = useState([])
  const [showRatingDialog, setShowRatingDialog] = useState(false)
  const [showReceiptDialog, setShowReceiptDialog] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [questions, setQuestions] = useState([])
  const [verticalTabValue, setVerticalTabValue] = useState(0)
  const [relatedQuestions, setRelatedQuestions] = useState(null)
  const [ratings, setRatings] = useState({})
  const [loading, setLoading] = useState(false)

  //Actions
  const dispatch = useDispatch()
  const setMenu = (menu) => dispatch(setMainMenu(menu))

  useEffect(() => {
    !signedIn && history.push(`${path}/customer/food`)
  }, [signedIn])

  useEffect(() => {
    setMenu(bottomNavItems.ORDERS)
    getUserOrders()
  }, [])

  const getUserOrders = async () => {
    setLoading(true)
    const response = await orderService.getAllOrders()
    if (response.status === generalConstants.STATUS_OK) {
      const previous = response.data.filter((order) => order.status.name === orderStatuses.DELIVERED)
      const upcoming = response.data.filter((order) => order.status.name !== orderStatuses.DELIVERED)
      setPreviousOrders(previous)
      setUpcomingOrders(upcoming)
    } else {
      //handle error here
    }
    await getOrderQuestions()
    setLoading(false)
  }

  const getOrderQuestions = async () => {
    setLoading(true)
    const response = await ratingService.getOrderQuestions()
    if (response.status === generalConstants.STATUS_OK) {
      setQuestions(response.data.content)
    } else {
      //handle error here
    }
    setLoading(false)
  }

  const handleOrderRating = (order) => {
    let relatedQuestionss = questions
    let ratingss = ratingCreator(relatedQuestionss)
    setSelectedOrder(order)
    setRelatedQuestions(relatedQuestionss)
    setRatings(ratingss)
    setTimeout(() => {
      toggleRatingDialog()
    }, 100)
  }

  const handleOrderReceipt = (order) => {
    setSelectedOrder(order)
    toggleReceiptDialog()
  }

  const handleTabChange = (e, newValue) => setMobileTabValue(newValue)

  const handleVerticalTabChange = (e, newValue) => setVerticalTabValue(newValue)

  // const handleTabIndexChange = (i) => setMobileTabValue(i)

  const toggleRatingDialog = (i) => setShowRatingDialog((prev) => !prev)

  const toggleReceiptDialog = (i) => setShowReceiptDialog((prev) => !prev)

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
      <Loading open={loading} />
      {!isMobileOnly ? (
        <DesktopOrders
          loading={loading}
          verticalTabValue={verticalTabValue}
          upcomingOrders={upcomingOrders}
          previousOrders={previousOrders}
          handleVerticalTabChange={handleVerticalTabChange}
          handleOrderRating={handleOrderRating}
          getUserOrders={getUserOrders}
          handleOrderReceipt={handleOrderReceipt}
        />
      ) : (
        <MobileOrders
          mobileTabValue={mobileTabValue}
          handleTabChange={handleTabChange}
          upcomingOrders={upcomingOrders}
          previousOrders={previousOrders}
          handleOrderRating={handleOrderRating}
          handleOrderReceipt={handleOrderReceipt}
        />
      )}
      <Dialog
        fullScreen={isMobileOnly}
        maxWidth={isMobileOnly ? null : 'lg'}
        className={classes.dialog}
        open={showRatingDialog}
        onClose={toggleRatingDialog}
        TransitionComponent={Transition}
      >
        <RateOrders
          handleClose={toggleRatingDialog}
          order={selectedOrder}
          questions={relatedQuestions}
          rating={ratings}
          getOrders={getUserOrders}
        />
      </Dialog>
      <Dialog
        fullScreen={isMobileOnly}
        maxWidth={isMobileOnly ? null : 'lg'}
        open={showReceiptDialog}
        onClose={toggleReceiptDialog}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <Receipt handleClose={toggleReceiptDialog} order={selectedOrder} />
      </Dialog>
    </div>
  )
}

OrdersMain.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withWidth()(OrdersMain))
