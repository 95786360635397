import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { customerActions } from '../../actions/customer.actions'
import { customerService } from '../../services/customer.service'
import { GreenButton, Transition, TFTextField } from '../utils/tools'
import { emailRegex } from '../utils/regex_collections'
import ForgotPassword from './forgot_password'
import Dialog from '@material-ui/core/Dialog'
import clsx from 'clsx'
import GoogleAuth from './google_auth'
import FacebookAuth from './facebook_auth'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { isMobile } from 'react-device-detect'
import { customerConstants, generalConstants } from '../../constants'

const path = process.env.PUBLIC_URL

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootWOLogin: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "&[content-used='inModal']": {
      width: 'auto',
      minWidth: 400,
    },
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
    width: '100%',
    textAlign: 'center',
  },
  titleWOLogin: {
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
    marginTop: 20,
    width: '42%',
    "&[content-used='inModal']": {
      textAlign: 'center',
      width: '100%',
      fontSize: 23,
    },
  },
  titleMobile: {
    fontSize: 34,
    fontWeight: 'bold',
    lineHeight: 1.4,
    textAlign: 'center',
    color: '#0a1f44',
  },
  signin: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 406,
  },
  formMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWOLogin: {
    width: '42%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // marginLeft: '25%',
    height: 433,
    "&[content-used='inModal']": {
      textAlign: 'center',
      width: '94%',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#69e781',
  },
  link: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(),
    cursor: 'pointer',
  },
  iconButton: {
    margin: theme.spacing(),
  },
  logo: {
    margin: theme.spacing(6),
  },
  signinButton: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'capitalize',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    padding: 8,
    width: '100%',
    marginTop: theme.spacing(2),
  },
  forgotLink: {
    color: '#212121',
    marginTop: theme.spacing(),
    cursor: 'pointer',
  },
  label: {
    margin: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#c4c4c4',
  },
  signUpLink: {
    textAlign: 'center',
    width: '45%',
    marginTop: theme.spacing(),
  },
  signUpLinkMobile: {
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },
  signUpLinkWOLogin: {
    textAlign: 'center',
    width: '42%',
    // marginLeft: '25%',
    marginTop: -theme.spacing(2),
  },
  text: {
    width: '60%',
  },
  textMobile: {
    width: '100%',
  },
  error: {
    marginTop: 10,
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
})

class Signin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      emailInvalid: true,
      passwordInvalid: true,
      showPassword: false,
      showForgotPasswordDialog: false,
      error: null,
    }
    this.handleSignupClick = this.handleSignupClick.bind(this)
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSubmit = () => {
    const { email, password } = this.state
    const { history } = this.props
    if (email && password) {
      if (this.props.modal) {
        this.modalSignIn(email, password)
      } else if (history.location.state === 'fromFavourite' || history.location.state === 'fromStore') {
        this.props.signin(email, password)
        this.props.history.goBack()
      } else {
        this.props.signin(email, password)
        //componentDidUpdate should be fired
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { error, handleDialogClose, customer } = this.props
    if (JSON.stringify(customer) !== JSON.stringify(prevProps.customer)) {
      !error && handleDialogClose && handleDialogClose()
    }
  }

  modalSignIn = (email, pass) => {
    customerService.signin(email, pass).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(res.data))
          this.props.signinFromModal(res.data)
          this.props.getAllSavedAddresses()
          this.state.error && this.setState({ error: null })
        }
      },
      (error) => this.setState({ error: error.response.data.error_message.toString() })
    )
  }

  handleForgotPasswordClose = () => {
    this.setState({ showForgotPasswordDialog: false })
  }

  handleSignupClick = () => {
    const { history, handleDialogClose } = this.props
    const currentPath = history.location.pathname
    currentPath === '/customer/signup' ? handleDialogClose() : history.push(`${path}/customer/signup`)
  }

  render() {
    const { classes, login } = this.props
    const { email, password, showForgotPasswordDialog, showPassword, error } = this.state

    return (
      <div className={!login && !isMobile ? classes.rootWOLogin : classes.root} content-used={this.props.modal}>
        <Typography
          className={clsx('desktop', [login !== false ? classes.title : classes.titleWOLogin])}
          component='h1'
          variant='h5'
          content-used={this.props.modal}
        >
          {this.getLabel('signin-text')}
        </Typography>
        {error && <div className={classes.error}>{error}</div>}
        <Typography className={clsx('mobile', [classes.titleMobile])} component='h1' variant='h5'>
          {this.getLabel('mobile-signin-text')}
        </Typography>
        <div
          className={login !== false ? (isMobile ? classes.formMobile : classes.form) : classes.formWOLogin}
          content-used={this.props.modal}
        >
          <TFTextField
            variant='outlined'
            margin='normal'
            fullWidth
            id='email'
            label={this.getLabel('email-address')}
            value={email}
            autoComplete='email'
            onChange={(event) =>
              this.setState({
                emailFocused: true,
                emailInvalid: !emailRegex.test(event.target.value),
                email: event.target.value,
              })
            }
            error={this.state.emailInvalid && this.state.emailFocused}
          />
          <TFTextField
            variant='outlined'
            margin='normal'
            fullWidth
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            id='password'
            autoComplete='current-password'
            value={password}
            error={this.state.passwordFocused && this.state.passwordInvalid}
            onChange={(event) =>
              this.setState({
                password: event.target.value,
                passwordFocused: true,
                passwordInvalid: event.target.value.length < 8,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ marginRight: -10 }}>
                  <IconButton
                    disabled={password.length === 0}
                    aria-label='toggle password visibility'
                    onClick={() => this.setState({ showPassword: !showPassword })}
                    onMouseDown={() => this.setState({ showPassword: !showPassword })}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Link onClick={() => this.setState({ showForgotPasswordDialog: true })} className={classes.forgotLink}>
            {this.getLabel('forgot-password')}
          </Link>
          <GreenButton
            variant='contained'
            disabled={this.state.emailInvalid || this.state.passwordInvalid}
            onClick={this.handleSubmit}
            className={classes.signinButton}
          >
            {this.getLabel('sign-in')}
          </GreenButton>
          <div className='centered-row' style={{ width: '100%' }}>
            <Divider className='grow' />
            <span className={classes.label}>{this.getLabel('or-label')}</span>
            <Divider className='grow' />
          </div>
          <GoogleAuth style={{ width: '60%' }} />
          <FacebookAuth style={{ width: '60%' }} />
        </div>
        <div
          className={
            login !== false ? (isMobile ? classes.signUpLinkMobile : classes.signUpLink) : classes.signUpLinkWOLogin
          }
        >
          <span className={isMobile ? classes.textMobile : classes.text}>{this.getLabel('not-have-account')}</span>
          <Link variant='body2' className={classes.link} onClick={this.handleSignupClick}>
            {this.getLabel('create-account')}
          </Link>
        </div>
        <Dialog
          fullScreen
          open={showForgotPasswordDialog}
          onClose={this.handleForgotPasswordClose}
          TransitionComponent={Transition}
        >
          <ForgotPassword handleClose={this.handleForgotPasswordClose} />
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { customer } = state

  return {
    customer: customer.customerInfo,
    error: customer.error,
  }
}

const actionCreators = {
  signin: customerActions.signin,
  signout: customerActions.signout,
  signinFromModal: customerActions.signinFromModal,
  getAllSavedAddresses: customerActions.getAllSavedAddresses,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(Signin))))
