import { makeStyles } from '@material-ui/core'
import { width } from '../../../../../../helpers'
import { imageHeight } from '../../../../../../helpers/orders'

export default makeStyles((theme) => ({
  //ImageHeader styles
  container: {
    position: 'relative',
    overflow: 'hidden',
    height: 120,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  subContainer: {
    display: 'flex',
    position: 'relative',
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    height: imageHeight,
    paddingLeft: 20,
    paddingRight: 10,
    width: width,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: imageHeight,
    // width: '20%',
    minWidth: imageHeight,
    justifyContent: 'center',
  },
  storeInfoContainer: {
    paddingLeft: 20,
    paddingRight: 10,
    // width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: imageHeight,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  storeName: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 34,
    display: 'inline-block',
  },
  sectionNames: {
    color: 'white',
    fontSize: 16,
    display: 'inline-block',
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    filter: 'brightness(60%)',
  },
  //StatusDetail styles below
  orderStatus: {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
  },
  order: {
    fontSize: 16,
    color: '#707070',
    fontWeight: 'bold',
  },
  orderDeliveryDetail: {
    fontSize: 13,
    color: '#8b8b8b',
    paddingTop: 10,
    paddingBottom: 5,
  },
  iconBlock: {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  //PriceRow styles below
  total: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  receiptButton: {
    height: 50,
    width: 110,
    textTransform: 'none',
    color: 'white',
    borderRadius: 10,
  },
  //OrderDetails styles
  orderDetailRow: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    width: '100%',
  },
  orderDetailText: {
    fontSize: 14,
    lineHeight: 1.36,
  },
}))
