import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isMobile, isMobileOnly } from 'react-device-detect'

import { makeStyles } from '@material-ui/core'
import SigninDialog from './SigninDialog'
import NoMatch from '../no_match'
import Loading from '../../common/loading'
import MobileCampaigns from './CampaignsPage/MobileCampaigns'
import WebCampaigns from './CampaignsPage/WebCampaigns'

import { generalConstants } from '../../../constants'
import { searchService } from '../../../services'
import { customerActions } from '../../../actions'
import useRouter from '../../../helpers/useRouter'

const path = process.env.PUBLIC_URL

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

const height = window.innerHeight

const useStyles = makeStyles(() => ({
  root: {
    ...flexColumn,
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  mobileRoot: {
    ...flexColumn,
    width: '100%',
    height: height,
    maxHeight: height,
  },
}))

const CampaignPage = () => {
  const { history, match } = useRouter()
  const dispatch = useDispatch()
  const classes = useStyles()

  const saveFavorite = (id) => dispatch(customerActions.saveFavoriteStore(id))
  const deleteFavorite = (id) => dispatch(customerActions.deleteFavoriteStore(id))

  const campaigns = useSelector(({ campaigns }) => campaigns.data, shallowEqual)
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const { signedIn, favoriteStores, loading } = useSelector(({ customer }) => customer, shallowEqual)

  const [stores, setStores] = useState([])
  const [loading_, setLoading] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState(null)
  const [error, setError] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [hasMore, setHasmore] = useState(true)
  const [query, setQuery] = useState({
    page: 0,
    size: 12,
    latitude: null,
    longitude: null,
    promotionIds: null,
    customOffset: 0,
  })

  useEffect(() => {
    let active = true
    if (active) {
      setStores([])
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let active = true
    if (active) {
      if (campaigns && match) {
        let campaign = campaigns.filter((camp) => camp.id === +match.params.id)
        setCurrentCampaign(campaign[0])
      } else return
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, match])

  useEffect(() => {
    let active = true
    if (active) {
      if (match.params.id && searchQuery.address) {
        const { address } = searchQuery
        setQuery({
          ...query,
          latitude: address?.latitude,
          longitude: address?.longitude,
          promotionIds: +match.params.id,
        })
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, searchQuery])

  useEffect(() => {
    let active = true
    if (active) {
      if (query.promotionIds && query.latitude && query.longitude) {
        query.customOffset === 0 && getStores()
      } else return
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const getStores = async () => {
    if (hasMore) {
      setLoading(true)
      const response = await searchService.search(query)
      if (response.status === generalConstants.STATUS_OK) {
        let result = response.data
        setStores([...stores, ...result.stores])
        setQuery({
          ...query,
          customOffset: result.customOffset,
        })
        result.stores.length === 12 ? setHasmore(true) : setHasmore(false)
        setLoading(false)
      } else {
        setError(true)
        setLoading(false)
      }
    }
  }

  const favoriteHandler = (id) => {
    if (!signedIn) {
      if (isMobile) {
        setShowLogin(true)
      } else {
        const { id } = match.params
        history.push(`${path}/customer/signin`, `campaigns/${id}`)
      }
      return
    }
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  const handleLoginDialogClose = () => setShowLogin(false)

  const handleSignin = () => {
    const { id } = match.params
    setShowLogin(false)
    history.push(`${path}/customer/campaigns/${id}`)
  }

  if (!searchQuery.address) return <Redirect to={`${path}/customer`} />
  if (!loading && !loading_ && (error || stores.length === 0)) {
    return <NoMatch />
  } else {
    return (
      <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
        <Loading open={loading || loading_} />
        {!isMobileOnly ? (
          <WebCampaigns
            history={history}
            currentCampaign={currentCampaign}
            stores={stores}
            getStores={getStores}
            favoriteHandler={favoriteHandler}
            favoriteStores={favoriteStores}
            pricey={searchQuery?.pricey}
          />
        ) : (
          <MobileCampaigns
            history={history}
            currentCampaign={currentCampaign}
            stores={stores}
            getStores={getStores}
            favoriteHandler={favoriteHandler}
            favoriteStores={favoriteStores}
            pricey={searchQuery?.pricey}
          />
        )}
        <SigninDialog
          showLogin={showLogin}
          handleLoginDialogClose={handleLoginDialogClose}
          handleSignin={handleSignin}
        />
      </div>
    )
  }
}

export default CampaignPage
