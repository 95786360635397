import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { categoryActions, dietaryActions } from '../../../actions'
import queryString from 'query-string'
import { initialMenu } from '../../../constants'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import MenuTextFields from './common/MenuTextFields'
import Categories from './common/Categories'
import Dietaries from './common/Dietaries'
import ServingType from './common/ServingType'
import MenuSize from './common/MenuSize'
import MenuPrice from './common/MenuPrice'
import clsx from 'clsx'
import ActionButtons from './common/ActionButtons'
import { categoryChangeHandler, dietaryChangeHandler } from '../../../helpers/merchant/menu-section-item'
import { menuItemsDeepCheck } from '../../../helpers/merchant/deep-check'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  actionButtonsContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,
  },
}))

const EditMenuSectionItem = ({ history, intl, location }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  const values = useMemo(() => queryString.parse(location.search), [location.search])
  //Global states
  const categories = useSelector(({ categories }) => categories.data)
  const dietaries = useSelector(({ dietaries }) => dietaries.data)
  const sections = useSelector(({ merchantStores }) => merchantStores.sections)
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  //Local states
  const [sectionId, setSectionId] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [menuId, setMenuId] = useState('')
  const [menu, setMenu] = useState(initialMenu)
  const [menuBeforeUpdate, setMenuBeforeUpdate] = useState()
  const [loading, setLoading] = useState(false)
  //Actions
  const dispatch = useDispatch()
  const getCategories = () => dispatch(categoryActions.getAll())
  const getDietaries = () => dispatch(dietaryActions.getAll())
  const menuUpdate = async (storeId, sectionId, menuId, menu) =>
    dispatch(merchantStoreActions.updateMenu(storeId, sectionId, menuId, menu))

  useEffect(() => {
    const { sectionid, itemid } = values
    categories.length === 0 && getCategories()
    dietaries.length === 0 && getDietaries()
    if (sectionid && itemid) {
      let relatedSection = sections?.filter((s) => s.id === +sectionid)
      sectionChecker(relatedSection)
    } else {
      history.push(`${path}/merchant/menus`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sectionChecker = (section) => {
    if (section?.length > 0) {
      let menus = section[0].menus.filter((m) => m.id === +values.itemid)
      if (menus.length > 0) {
        initialStateMaker(section, menus)
      }
    } else {
      history.push(`${path}/merchant/menus`)
    }
  }

  const initialStateMaker = (sections, menus) => {
    const menu_ = menus[0]
    let newState = {
      ...menu,
      name: menu_.name,
      description: menu_.description,
      price: +menu_.price,
      servingSize: menu_.servingSize,
      servingType: menu_.servingType,
      dietaries: menu_.dietaries.map((d) => d.id),
      categories: menu_.categories.map((c) => c.id),
      // stores: [selectedStore.id],
    }
    setSectionId(values.sectionid)
    setSectionName(sections[0].name)
    setMenuId(values.itemid)
    setMenuBeforeUpdate(newState)
    setMenu(newState)
  }

  const updateMenuActions = async () => {
    const storeId = selectedStore.storeId
    const isMenuChanged = menuItemsDeepCheck(menu, menuBeforeUpdate)
    isMenuChanged && (await menuUpdate(storeId, sectionId, menuId, menu))
  }

  const onFormSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    await updateMenuActions()
    setLoading(false)
    history.goBack()
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    let newState = { ...menu, [name]: value }
    setMenu(newState)
  }

  const handleCategoryChange = (event) => {
    const newState = categoryChangeHandler(event, menu)
    setMenu(newState)
  }

  const handleDietaryChange = (event) => {
    const newState = dietaryChangeHandler(event, menu)
    setMenu(newState)
  }

  const handleTypeChange = (event) => {
    let val = event.target.value
    let newState = { ...menu, servingType: val }
    setMenu(newState)
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <div className='page-header' style={{ marginTop: 70 }}>
        <Typography variant='h4' style={{ margin: 10 }}>
          {getLabel('edit-food-under')} {sectionName}
        </Typography>
      </div>
      <form onSubmit={onFormSubmit}>
        <div className='row'>
          <Grid container spacing={2} className='full-width'>
            <MenuTextFields menu={menu} handleChange={handleChange} />
            <Categories categories={categories} menu={menu} handleChange={handleCategoryChange} />
            <Dietaries dietaries={dietaries} menu={menu} handleDietaryChange={handleDietaryChange} />
            {/* {merchantRole.includes(roles.REST_ADMIN) && (
              <Stores stores={stores} menu={menu} handleChange={handleChange} />
            )} */}
            <ServingType menu={menu} handleTypeChange={handleTypeChange} />
            <MenuSize menu={menu} handleChange={handleChange} />
            <MenuPrice menu={menu} handleChange={handleChange} />
          </Grid>
        </div>
        <div className={classes.actionButtonsContainer}>
          <ActionButtons menu={menu} />
        </div>
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

EditMenuSectionItem.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
}

export default withRouter(injectIntl(EditMenuSectionItem))
