import axios from 'axios'
import { merchantHeader, merchantImageHeader, uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const restaurantService = {
  create,
  getAll,
  saveLogo,
  deleteLogo,
  saveBackground,
  deleteBackground,
  basicIdentity,
  getImageData,
  registerRequest,
  allRestaurantRequests,
  updateRestaurant,
  verifyRegisterToken,
}

function create(name, desc) {
  return axios
    .post(
      `${API_URL}/restaurant`,
      {
        name: name,
        description: desc,
      },
      merchantImageHeader()
    )
    .then(handleResponse)
    .then((restaurant) => {
      return restaurant
    })
}
function updateRestaurant(data, id) {
  return axios.put(`${API_URL}/restaurant/${id}`, data, merchantHeader()).then((restaurant) => {
    return restaurant
  })
}

function getAll(page, size) {
  return axios.get(`${API_URL}/restaurant?page=${page}&size=${size}`, merchantHeader()).then((restaurants) => {
    return restaurants.data.content
  })
}

function registerRequest(data) {
  return axios
    .post(`${API_URL}/restaurant-request/request/ui`, data, uiHeader())
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

function verifyRegisterToken(token) {
  return axios
    .post(`${API_URL}/restaurant-request/verification/ui?token=${token}`, uiHeader())
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

function allRestaurantRequests(param) {
  return axios.get(`${API_URL}/restaurant/request?read=${param}`, uiHeader()).then((res) => {
    return res
  })
}

function basicIdentity(page = 0, size = 10) {
  return axios
    .get(`${API_URL}/restaurant/basic-identity?page=${page}&size=${size}`, merchantHeader())
    .then((restaurants) => {
      return restaurants.data.content
    })
}

function getImageData(id) {
  return axios.get(`${API_URL}/restaurant/${id}/basic-graphic`, merchantHeader()).then((data) => {
    return data
  })
}

function saveLogo(restaurantId, logo) {
  const data = new FormData()
  data.append('file', logo, 'logo.jpeg')

  return axios
    .post(`${API_URL}/restaurant/${restaurantId}/logo`, data, merchantImageHeader())
    .then(handleResponse)
    .then((res) => {
      return res
    })
}

// function saveLogo(restaurantId, logo) {
//   const data = new FormData()
//   data.append('file', logo)

//   return axios
//     .post(`${API_URL}/restaurant/${restaurantId}/logo/base64`, data, merchantImageHeader())
//     .then(handleResponse)
//     .then((res) => {
//       return res
//     })
// }

function deleteLogo(restaurantId) {
  return axios.delete(`${API_URL}/restaurant/${restaurantId}/logo`, merchantImageHeader()).then((res) => {
    return res.status
  })
}

function saveBackground(restaurantId, background) {
  const data = new FormData()
  data.append('file', background, 'image.png')

  return axios
    .post(`${API_URL}/restaurant/${restaurantId}/background`, data, merchantImageHeader())
    .then(handleResponse)
    .then((res) => {
      return res
    })
}

// function saveBackground(restaurantId, background) {
//   const data = new FormData()
//   console.log("🚀 ~ file: restaurant.service.js ~ line 98 ~ saveBackground ~ data", data)
//   data.append('file', background)

//   return axios
//     .post(`${API_URL}/restaurant/${restaurantId}/background/base64`, data, merchantImageHeader())
//     .then(handleResponse)
//     .then((res) => {
//       return res
//     })
// }

function deleteBackground(restaurantId) {
  return axios.delete(`${API_URL}/restaurant/${restaurantId}/background`, merchantImageHeader()).then((res) => {
    return res.status
  })
}

function handleResponse(response) {
  if (response.status !== 200) {
    const error = (response.data && response.data.message) || response.statusText
    return Promise.reject(error)
  }

  return response.data
}
