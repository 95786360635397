import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import { customerService, searchService } from '../../../services'
import { searchActions, customerActions, orderActions } from '../../../actions'
import { parseGoogleAPIAddress } from '../../utils/common'
import useRouter from '../../../helpers/useRouter'
import { handleAddressName } from '../../../helpers/address-header'
import { isSavedBefore } from '../../../helpers/address/checkIfAddressSavedBefore'
import { deliveryTypes, generalConstants } from '../../../constants'
import AddressSavePopover from './AddressSavePopover'
import SavedAddresses from './AddressPopupContent/SavedAddreses'
import Suggestions from './AddressPopupContent/Suggestions'
import InputArea from './AddressPopupContent/InputArea'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const useStyles = makeStyles((theme) => ({
  cardMain: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: theme.spacing(),
    position: 'relative',
    zIndex: 101,
    boxShadow: '0 0 10px #c4c4c4',
  },
  savedAddressesToggleButton: {
    width: '100%',
    height: 40,
    textTransform: 'capitalize',
    backgroundColor: '#69e781',
    borderRadius: 0,
    userSelect: 'none',
    fontSize: 16,
    color: 'white',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#69e781 !important',
    },
  },
}))

const path = process.env.PUBLIC_URL

let _isMounted = false

const AddressPopup = ({ welcomePage, deliveryDetails, component }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const placesRef = useRef()
  const popoverAnchor = useRef()
  const { history } = useRouter()

  const [currentAddress, setCurrentAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [addressArray, setAddressArray] = useState([])
  const [checked, setChecked] = useState(null)
  const [show, setShow] = useState(true)
  const [openSuggestions, setOpenSuggestions] = useState(false)
  const [openAddressPopover, setOpenAddressPopover] = useState(false)
  const [addressHeader, setAddressHeader] = useState('')
  const [defaultAddressName, setDefaultAddressName] = useState('')
  const [parsedAddress, setParsedAddress] = useState(null)
  const [savedAddress, setSavedAddress] = useState(null)
  const [savedBefore, setSavedBefore] = useState(false)

  const addressQuery = useSelector(({ search }) => search.searchQuery.address)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const cart = useSelector(({ orders }) => orders.cart)
  const customerSavedAddresses = useSelector(({ customer }) => customer.savedAddresses)

  const dispatch = useDispatch()
  const setAddress = (data) => dispatch(searchActions.setAddress(data))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))
  const addAddressToStore = (data) => dispatch(customerActions.createAddressFromAddressPopUp(data))
  const updateAddress = (data) => dispatch(customerActions.updateAddressFromAddressPopUp(data))

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      customerSavedAddresses && setAddressArray(customerSavedAddresses)
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSavedAddresses])

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      setAddressHeader(handleAddressName(addressArray))
      setDefaultAddressName(handleAddressName(addressArray))
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressArray])

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      if (addressQuery) {
        currentAddress !== addressQuery.formattedAddress && setCurrentAddress(addressQuery.formattedAddress)
      }
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressQuery])

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      signedIn && getAddresses()
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn])

  //Prevent suggestions to be cleared with clicking away
  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      if (placesRef) {
        placesRef.current.clearSuggestions = () => {}
        placesRef.current.handleInputOnBlur = () => {}
      } else {
        return
      }
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesRef])

  const getAddresses = async () => {
    signedIn &&
      (await customerService.getAllSavedAddresses().then(
        (res) => setAddressArray(res),
        (error) => console.log(error)
      ))
  }

  const closePopup = () => {
    setOpen(false)
    openSuggestions && setOpenSuggestions(false)
  }

  const closeAddressPopover = () => {
    setOpenAddressPopover(false)
    setCurrentAddress(parsedAddress.formattedAddress)
    setAddress(parsedAddress)
  }

  const showAddressPopover = () => {
    setTimeout(() => {
      setOpenAddressPopover(true)
    }, 200)
  }

  const handleRoute = () => {
    if (welcomePage) return
    setTimeout(() => {
      !history.location.pathname.includes('/customer/search') && history.push(`${path}/customer/search`)
    }, 400)
  }

  //If a already selected(checked) saved address clicked
  const hadleClickCheckedItem = () => {
    closePopup()
    refill()
  }

  //Works when user provide input in input area
  const handleChange = (address) => {
    address.length > 0 ? setOpenSuggestions(true) : setOpenSuggestions(false)
    setCurrentAddress(address)
  }

  //Works when user select an address from suggestions
  const handleSelect = async (address, placeId, suggestion) => {
    closePopup()
    let [savedBefore, savedAddress] = isSavedBefore(addressArray, address)
    setSavedBefore(savedBefore)
    setSavedAddress(savedAddress)
    geocodeByPlaceId(placeId)
      .then((results) => {
        let parsedAddress = parseGoogleAPIAddress(results[0])
        let lat = parsedAddress.latitude
        let long = parsedAddress.longitude
        searchService
          .getTimeZoneOffset(lat, long)
          .then((response) => {
            let { rawOffset, dstOffset } = response.data
            parsedAddress.formattedAddress = address
            parsedAddress.name = parsedAddress.city
            parsedAddress.deliveryOption = cart.subType
            parsedAddress.deliveryInstruction = cart.instructions
            parsedAddress.timeZoneOffset = (rawOffset + dstOffset) / 60
            setParsedAddress(parsedAddress)
            setCurrentAddress(parsedAddress.formattedAddress)
            if (!signedIn) {
              setAddress(parsedAddress)
              handleRoute()
            } else {
              showAddressPopover()
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => console.error('Error', error))
  }

  //Selecting an address from the list of saved addresses
  const handleCheck = (obj) => {
    let id = obj.id || null
    setChecked(id)
    setAddress(obj)
    if (obj.deliveryOption === '') {
      setUserDeliveryType(deliveryTypes.TAKEOUT)
      setUserDeliverySubTypes('')
    } else {
      setUserDeliveryType(deliveryTypes.DELIVERY)
      setUserDeliverySubTypes(obj.deliveryOption)
    }
    setUserInstructions(obj.deliveryInstruction)
    closePopup()
    !welcomePage && !history.location.pathname.includes('/customer/search') && history.push(`${path}/customer/search`)
  }

  //Disable browser autocomplete
  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = 'none'
    }
  }

  const clearInputField = () => setCurrentAddress('')

  const onClickHandler = () => {
    setOpen(true)
    if (!open) clearInputField()
  }

  //Fill input area with current address on clicking outside of the popup and input area
  const refill = () => {
    if (currentAddress !== parsedAddress?.formattedAddress) {
      addressQuery && setCurrentAddress(addressQuery.formattedAddress)
    } else return
  }

  //Restriction for PlacesAutocomplete
  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  }

  return (
    <>
      <ClickAwayListener onClickAway={closePopup}>
        <div>
          <PlacesAutocomplete
            value={currentAddress || ''}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
            ref={placesRef}
            highlightFirstSuggestion
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <ClickAwayListener onClickAway={refill}>
                <div>
                  <InputArea
                    onFocus={onFocus}
                    onClickHandler={onClickHandler}
                    component={component}
                    clearInputField={clearInputField}
                    popoverAnchor={popoverAnchor}
                    getInputProps={getInputProps}
                    welcomePage={welcomePage}
                    deliveryDetails={deliveryDetails}
                    currentAddress={currentAddress}
                  />
                  <Collapse in={open && currentAddress.length > 0}>
                    <Card elevation={24} className={classes.cardMain}>
                      {signedIn && addressArray.length > 0 && (
                        <Button
                          disableRipple
                          className={classes.savedAddressesToggleButton}
                          onClick={() => setShow(!show)}
                        >
                          {!show ? f({ id: 'show-saved-addresses' }) : f({ id: 'hide-saved-addresses' })}
                        </Button>
                      )}
                      <Suggestions
                        openSuggestions={openSuggestions}
                        suggestions={suggestions}
                        getSuggestionItemProps={getSuggestionItemProps}
                      />
                      {open && signedIn ? (
                        <SavedAddresses
                          addressArray={addressArray}
                          show={show}
                          checked={checked}
                          handleCheck={handleCheck}
                          hadleClickCheckedItem={hadleClickCheckedItem}
                        />
                      ) : null}
                    </Card>
                  </Collapse>
                </div>
              </ClickAwayListener>
            )}
          </PlacesAutocomplete>
        </div>
      </ClickAwayListener>
      <AddressSavePopover
        openAddressPopover={openAddressPopover}
        closeAddressPopover={closeAddressPopover}
        anchor={popoverAnchor.current}
        currentAddress={currentAddress}
        addressHeader={addressHeader}
        addressQuery={parsedAddress}
        addAddressToStore={addAddressToStore}
        setAddress={setAddress}
        welcomePage={welcomePage}
        savedAddress={savedAddress}
        savedBefore={savedBefore}
        setUserDeliverySubTypes={setUserDeliverySubTypes}
        setUserInstructions={setUserInstructions}
        setUserDeliveryType={setUserDeliveryType}
        setOpenAddressPopover={setOpenAddressPopover}
        updateAddress={updateAddress}
        defaultAddressName={defaultAddressName}
      />
    </>
  )
}

AddressPopup.propTypes = {
  welcomePage: PropTypes.bool,
  deliveryDetails: PropTypes.bool,
  component: PropTypes.string,
}

export default AddressPopup
