import { merchantHeader } from '../helpers'
import { merchantConstants } from '../constants'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const merchantService = {
  signin,
  signout,
  updateUser,
}

function signin(email, password) {
  return axios
    .post(
      `${API_URL}/restaurant/sign-in`,
      {
        email: email,
        password: password,
      },
      merchantHeader
    )
    .then((res) => {
      return res
    })
}

function signout() {
  localStorage.removeItem(merchantConstants.MERCHANT)
}

function updateUser(data) {
  return axios
    .put(`${API_URL}/user`, data, merchantHeader())
    .then((res) => res)
    .catch((err) => err)
}
