import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter, Redirect } from 'react-router-dom'
import { initialQuery } from '../../../constants'
import { customerActions } from '../../../actions'
import { storeService } from '../../../services'
import { Transition } from '../../utils/tools'
import Dialog from '@material-ui/core/Dialog'
import MobileSignin from '../../authentication/MobileSignin'
import { useDispatch, useSelector } from 'react-redux'
import MobilePage from './welcome-page/MobilePage'
import DesktopPage from './welcome-page/DesktopPage'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'left top',
    },
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
  },
}))

const WelcomePage = ({ history }) => {
  const classes = useStyles()
  //Global states
  const search = useSelector(({ search }) => search)
  //Local states
  const [locals, setLocals] = useState([])
  const [searchQuery, setSearchQuery] = useState(initialQuery)
  const [showMobileSigninDialog, setShowMobileSigninDialog] = useState(false)
  const mountedRef = useRef(false)

  const dispatch = useDispatch()
  const setGeolocation = (lat, long) => dispatch(customerActions.setGeolocation(lat, long))

  useEffect(() => {
    mountedRef.current = true
    getPosition().then((position) => {
      setGeolocation(position.coords.latitude, position.coords.longitude)
      if (mountedRef.current) {
        if (position.coords) {
          setSearchQuery({
            ...initialQuery,
            size: 6,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        }
      }
    })
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchQuery !== initialQuery) {
      !isMobileOnly && searchLocals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const getPosition = () => {
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
    // navigator.geolocation.getCurrentPosition((position) => {
    //     setGeolocation(position.coords.latitude, position.coords.longitude)
    //     if (position.coords) {
    //       setSearchQuery({
    //         ...initialQuery,
    //         size: 6,
    //         latitude: position.coords.latitude,
    //         longitude: position.coords.longitude,
    //       })
    //     }

    // })
  }

  const searchLocals = () => {
    storeService.customerStoreSearch(searchQuery).then((res) => {
      setLocals(res.data)
    })
  }

  const handleMobileSigninClose = () => {
    setShowMobileSigninDialog((prev) => !prev)
  }

  const handleSignin = () => {
    setShowMobileSigninDialog((prev) => !prev)
    handleRedirection()
  }

  const handleRedirection = () => {
    history.push(`${path}/customer/food`)
  }

  if (search.searchQuery.address) return <Redirect to={`${path}/customer/food`} />

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
      {!isMobileOnly ? (
        <DesktopPage locals={locals} />
      ) : (
        <MobilePage goToHome={handleRedirection} showDialog={handleMobileSigninClose} />
      )}
      <Dialog
        fullScreen
        open={showMobileSigninDialog}
        onClose={handleMobileSigninClose}
        TransitionComponent={Transition}
      >
        <MobileSignin handleClose={handleMobileSigninClose} handleSignin={handleSignin} goToHome={handleRedirection} />
      </Dialog>
    </div>
  )
}

WelcomePage.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(WelcomePage)
