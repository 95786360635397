import { generalConstants, merchantStoreContants } from '../constants'
import { alertActions } from './alert.actions'
import { menuService, restaurantService, storeService } from '../services'

export const merchantStoreActions = {
  getAllStores,
  getBusiness,
  clearState,
  setSelectedStore,
  updateStore,
  getEmployees,
  deleteEmployee,
  getAllSections,
  createSection,
  deleteSection,
  updateSection,
  createMenu,
  deleteMenu,
  updateMenu,
  setSelectedRestaurant,
  // getStoreBasedRoles,
  saveMenuImage,
  deleteMenuImage,
  changeRestaurantAdminView,
  // createStore,
  // deleteStore,
}

// function createStore(restaurantId, data) {
//   return (dispatch) => {
//     dispatch(request({ restaurantId }))

//     storeService
//       .create(restaurantId, data)
//       .then(
//         (store) => {
//           dispatch(success(store))
//         },
//         (error) => {
//           dispatch(failure(error.toString()))
//           dispatch(alertActions.error(error.toString()))
//         }
//       )
//       // .then(() => history.push(`${path}/merchant/stores`))
//   }

//   function request(restaurantId) {
//     return { type: merchantStoreContants.CREATE_STORE_REQUEST, restaurantId }
//   }
//   function success(store) {
//     return { type: merchantStoreContants.CREATE_STORE_SUCCESS, store }
//   }
//   function failure(error) {
//     return { type: merchantStoreContants.CREATE_STORE_FAILURE, error }
//   }
// }

// function deleteStore(restaurantId, storeId) {
//   return (dispatch) => {
//     dispatch(request({ storeId }))

//     storeService.deleteStore(restaurantId, storeId).then(
//       (res) => {
//         if (res === generalConstants.STATUS_OK) dispatch(success(storeId))
//       },
//       (error) => {
//         dispatch(failure(error.toString()))
//         dispatch(alertActions.error(error.toString()))
//       }
//     )
//   }

//   function request(storeId) {
//     return { type: merchantStoreContants.DELETE_STORE_REQUEST, storeId }
//   }
//   function success(storeId) {
//     return { type: merchantStoreContants.DELETE_STORE_SUCCESS, storeId }
//   }
//   function failure(error) {
//     return { type: merchantStoreContants.DELETE_STORE_FAILURE, error }
//   }
// }

function deleteMenuImage(storeId, sectionId, menuId, imageId) {
  return (dispatch) => {
    dispatch(request({ imageId }))

    menuService.deleteMenuImage(storeId, sectionId, menuId, imageId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) {
          dispatch(success(sectionId, menuId, imageId))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(imageId) {
    return { type: merchantStoreContants.DELETE_IMAGE_REQUEST, imageId }
  }
  function success(sectionId, menuId, imageId) {
    return { type: merchantStoreContants.DELETE_IMAGE_SUCCESS, sectionId, menuId, imageId }
  }
  function failure(error) {
    return { type: merchantStoreContants.DELETE_IMAGE_FAILURE, error }
  }
}

function saveMenuImage(storeId, sectionId, menuId, file) {
  return (dispatch) => {
    dispatch(request({ menuId }))

    menuService.saveMenuImage(storeId, sectionId, menuId, file).then(
      (res) => {
        dispatch(success(res.data, sectionId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(menuId) {
    return { type: merchantStoreContants.SAVE_MENU_IMAGE_REQUEST, menuId }
  }
  function success(menu, sectionId) {
    return { type: merchantStoreContants.SAVE_MENU_IMAGE_SUCCESS, menu, sectionId }
  }
  function failure(error) {
    return { type: merchantStoreContants.SAVE_MENU_IMAGE_FAILURE, error }
  }
}

function updateMenu(storeId, sectionId, menuId, data) {
  return (dispatch) => {
    dispatch(request({ menuId }))
    menuService.updateMenu(storeId, sectionId, menuId, data).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(success(sectionId, res.data))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
    // .then(() => history.push(`${path}/merchant/menus`, { refresh: true }))
  }

  function request(menuId) {
    return { type: merchantStoreContants.UPDATE_MENU_REQUEST, menuId }
  }
  function success(sectionId, menu) {
    return { type: merchantStoreContants.UPDATE_MENU_SUCCESS, sectionId, menu }
  }
  function failure(error) {
    return { type: merchantStoreContants.UPDATE_MENU_FAILURE, error }
  }
}

function deleteMenu(storeId, sectionId, menuId) {
  return (dispatch) => {
    dispatch(request({ menuId }))

    menuService.deleteMenu(storeId, sectionId, menuId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(sectionId, menuId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(menuId) {
    return { type: merchantStoreContants.DELETE_MENU_REQUEST, menuId }
  }
  function success(sectionId, menuId) {
    return { type: merchantStoreContants.DELETE_MENU_SUCCESS, sectionId, menuId }
  }
  function failure(error) {
    return { type: merchantStoreContants.DELETE_MENU_FAILURE, error }
  }
}

function createMenu(storeId, sectionId, data) {
  return (dispatch) => {
    dispatch(request({ data }))

    menuService.createMenu(storeId, sectionId, data).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data, sectionId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
    // .then(() => history.push(`${path}/merchant/menus`))
  }

  function request(menu) {
    return { type: merchantStoreContants.CREATE_MENU_REQUEST, menu }
  }
  function success(menu, sectionId) {
    return { type: merchantStoreContants.CREATE_MENU_SUCCESS, menu, sectionId }
  }
  function failure(error) {
    return { type: merchantStoreContants.CREATE_MENU_FAILURE, error }
  }
}

function getAllStores(page, size) {
  return (dispatch) => {
    dispatch(request())

    return storeService.getStores(page, size).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request() {
    return { type: merchantStoreContants.GET_STORES_REQUEST, payload: null }
  }
  function success(stores) {
    return { type: merchantStoreContants.GET_STORES_SUCCESS, stores }
  }
  function failure(error) {
    return { type: merchantStoreContants.GET_STORES_FAILURE, error }
  }
}
function getBusiness(page, size) {
  return (dispatch) => {
    dispatch(request())

    return restaurantService.basicIdentity(page, size).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request() {
    return { type: merchantStoreContants.GET_BASIC_REQUEST, payload: null }
  }
  function success(business) {
    return { type: merchantStoreContants.GET_BASIC_SUCCESS, business }
  }
  function failure(error) {
    return { type: merchantStoreContants.GET_BASIC_FAILURE, error }
  }
}

function createSection(storeId, data) {
  return (dispatch) => {
    dispatch(request({ storeId }))

    menuService.createSection(storeId, data).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(storeId) {
    return { type: merchantStoreContants.CREATE_SECTION_REQUEST, storeId }
  }
  function success(section) {
    return { type: merchantStoreContants.CREATE_SECTION_SUCCESS, section }
  }
  function failure(error) {
    return { type: merchantStoreContants.CREATE_SECTION_FAILURE, error }
  }
}

function deleteSection(storeId, sectionId) {
  return (dispatch) => {
    dispatch(request({ sectionId }))

    menuService.deleteSection(storeId, sectionId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(sectionId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(sectionId) {
    return { type: merchantStoreContants.DELETE_SECTION_REQUEST, sectionId }
  }
  function success(sectionId) {
    return { type: merchantStoreContants.DELETE_SECTION_SUCCESS, sectionId }
  }
  function failure(error) {
    return { type: merchantStoreContants.DELETE_SECTION_FAILURE, error }
  }
}

function updateSection(storeId, sectionId, data) {
  return (dispatch) => {
    dispatch(request({ sectionId }))

    menuService.updateSection(storeId, sectionId, data).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(sectionId) {
    return { type: merchantStoreContants.UPDATE_SECTION_REQUEST, sectionId }
  }
  function success(section) {
    return { type: merchantStoreContants.UPDATE_SECTION_SUCCESS, section }
  }
  function failure(error) {
    return { type: merchantStoreContants.UPDATE_SECTION_FAILURE, error }
  }
}

function getAllSections(storeId) {
  return (dispatch) => {
    dispatch(request({ storeId }))

    return storeService.getAllSections(storeId).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(storeId) {
    return { type: merchantStoreContants.GETALL_SECTIONS_REQUEST, storeId }
  }
  function success(sections) {
    return { type: merchantStoreContants.GETALL_SECTIONS_SUCCESS, sections }
  }
  function failure(error) {
    return { type: merchantStoreContants.GETALL_SECTIONS_FAILURE, error }
  }
}

function updateStore(restaurantId, data, storeId) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    storeService.update(restaurantId, data, storeId).then(
      (store) => {
        dispatch(success())
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
    // .then(() => history.push(`${path}/merchant/stores`))
  }

  function request(restaurantId) {
    return { type: merchantStoreContants.UPDATE_REQUEST, restaurantId }
  }
  function success() {
    return { type: merchantStoreContants.UPDATE_SUCCESS, payload: null }
  }
  function failure(error) {
    return { type: merchantStoreContants.UPDATE_FAILURE, error }
  }
}

// function getStoreBasedRoles(restaurantId) {
//   return (dispatch) => {
//     dispatch(request())

//     return storeService.getStoreBasedRoles(restaurantId).then(
//       (res) => {
//         dispatch(success(res))
//       },
//       (error) => {
//         dispatch(failure(error.toString()))
//         dispatch(alertActions.error(error.response.data.error_message.toString()))
//       }
//     )
//   }

//   function request() {
//     return { type: merchantStoreContants.GET_ROLES_REQUEST, payload: null }
//   }
//   function success(roles) {
//     return { type: merchantStoreContants.GET_ROLES_SUCCESS, roles }
//   }
//   function failure(error) {
//     return { type: merchantStoreContants.GET_ROLES_FAILURE, error }
//   }
// }

function getEmployees(storeId) {
  return (dispatch) => {
    dispatch(request())

    return storeService.getEmployeesByStore(storeId).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request() {
    return { type: merchantStoreContants.GET_EMPLOYEES_REQUEST, payload: null }
  }
  function success(employees) {
    return { type: merchantStoreContants.GET_EMPLOYEES_SUCCESS, employees }
  }
  function failure(error) {
    return { type: merchantStoreContants.GET_EMPLOYEES_FAILURE, error }
  }
}

function deleteEmployee(employeeId) {
  return (dispatch) => {
    dispatch(request())

    return storeService.deleteEmployee(employeeId).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(success(employeeId))
          dispatch(alertActions.success(res.data.toString()))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request() {
    return { type: merchantStoreContants.DELETE_EMPLOYEE_REQUEST, payload: null }
  }
  function success(employeeId) {
    return { type: merchantStoreContants.DELETE_EMPLOYEE_SUCCESS, employeeId }
  }
  function failure(error) {
    return { type: merchantStoreContants.DELETE_EMPLOYEE_FAILURE, error }
  }
}

function clearState() {
  return {
    type: merchantStoreContants.CLEAR_STATE,
  }
}

function setSelectedStore(store) {
  return {
    type: merchantStoreContants.CHANGE_SELECTED_STORE,
    store,
  }
}

function setSelectedRestaurant(restaurant) {
  return {
    type: merchantStoreContants.CHANGE_SELECTED_RESTAURANT,
    restaurant,
  }
}
function changeRestaurantAdminView(bool) {
  return {
    type: merchantStoreContants.CHANGE_RESTAURANT_ADMIN_VIEW,
    bool,
  }
}
