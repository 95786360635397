import React, { useState, useCallback, Suspense, lazy, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { useIntl } from 'react-intl'

import { getCroppedImg, getRotatedImage } from './helpers/canvasUtils'
import { styles } from './helpers/styles'
import { generalConstants } from '../../../../constants/general.constants'
import { alertActions } from '../../../../actions/alert.actions'
import { merchantRestaurantActions } from '../../../../actions/merchant.restaurant.actions'
import Spinner from '../spinner/spinner'
import handleButtonText from '../../../../helpers/imageUploadButtonText'
import handleDeleteButtonVisibility from '../../../../helpers/deleteButtonVisibility'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Backdrop from '@material-ui/core/Backdrop'

const useStyles = makeStyles(styles)

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const ImgDialog = lazy(() => import('./dialogs/ImgDialog'))
const StoreBackgroundSample = lazy(() => import('./sampleVisuals/storeBackgroundSample'))

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const UploadImage = ({ index }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const isLoading = useSelector(({ merchantStores }) => merchantStores.loading)
  const restaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)
  const id = restaurant?.restaurantId

  const error = () => dispatch(alertActions.error('Max file size 5mb'))
  const clear = () => dispatch(alertActions.clear())
  const deleteBackground = () => dispatch(merchantRestaurantActions.deleteBackground(id))
  const deleteLogo = () => dispatch(merchantRestaurantActions.deleteLogo(id))

  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    isLoading ? setLoading(true) : setLoading(false)
  }, [isLoading])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = async () => {
    setLoading(true)
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)
      const blobBin = atob(croppedImage.split(',')[1])
      const array = []
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
      const fileImage = URL.createObjectURL(file)
      setFile(file)
      openDialog(fileImage)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const openDialog = (data) => {
    setCroppedImage(data)
    setDialogOpen(true)
    setLoading(false)
  }

  const onClose = useCallback(() => {
    setImageSrc(null)
    setCroppedImage(null)
    setDialogOpen(false)
  }, [])

  const onDialogClose = () => {
    setDialogOpen(false)
  }

  const onDelete = () => {
    setImageSrc(null)
  }

  const onError = () => {
    error()
    setTimeout(() => clear(), 5000)
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.size > generalConstants.MAX_IMAGE_SIZE) {
        return onError()
      }
      let imageDataUrl = await readFile(file)

      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setImageSrc(imageDataUrl)
    }
  }

  const deleteImage = () => {
    index === 0 ? deleteLogo() : deleteBackground()
  }

  const deleteButton = (
    <>
      <label htmlFor='delete' data-content='delete' className={classes.uploadButton}>
        <DeleteForeverIcon className={classes.cloudUploadIcon} function='delete' />{' '}
        <Typography className={classes.buttonText}>
          {f({ id: handleButtonText('delete', index, restaurant) })}
        </Typography>
      </label>
      <button id='delete' type='submit' className={classes.input} onClick={deleteImage} />
    </>
  )

  return (
    <div style={{ height: !imageSrc ? 570 : 500 }} className={classes.root}>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={index === 0 ? 1 : 2 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography variant='overline' classes={{ root: classes.sliderLabel }}>
                {f({ id: 'zoom-label' })}
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby='Zoom'
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography variant='overline' classes={{ root: classes.sliderLabel }}>
                {f({ id: 'rotation-label' })}
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby='Rotation'
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <Button
              onClick={onDelete}
              variant='contained'
              color='primary'
              classes={{ root: classes.cropButton }}
              data-content='Delete'
              disableRipple
            >
              {f({ id: 'delete-label' })}
            </Button>
            <Button
              onClick={showCroppedImage}
              variant='contained'
              color='primary'
              classes={{ root: classes.cropButton }}
              data-content='Preview'
              disableRipple
            >
              {f({ id: 'preview-label' })}
            </Button>
          </div>
          <Suspense fallback={<div></div>}>
            <ImgDialog
              file={file}
              img={croppedImage}
              onClose={onClose}
              dialogOpen={dialogOpen}
              onDialogClose={onDialogClose}
              restaurantId={restaurant?.restaurantId}
              index={index}
            />
          </Suspense>
        </React.Fragment>
      ) : (
        <>
          <Suspense fallback={<div></div>}>
            <StoreBackgroundSample />
          </Suspense>
          <div className={classes.buttonMainContainer}>
            <div className={classes.fileUploadButtonContainer}>
              {handleDeleteButtonVisibility(index, restaurant, deleteButton)}
              <label htmlFor='file-upload' className={classes.uploadButton}>
                <CloudUploadIcon className={classes.cloudUploadIcon} />{' '}
                <Typography className={classes.buttonText}>
                  {f({ id: handleButtonText('upload', index, restaurant) })}
                </Typography>
              </label>
              <input id='file-upload' type='file' onChange={onFileChange} accept='image/*' className={classes.input} />
            </div>
          </div>
        </>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <Spinner />
      </Backdrop>
    </div>
  )
}

export default UploadImage
