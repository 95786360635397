import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { GreenButton, Transition } from '../../../utils/tools'
import verification from '../../../../images/verification-done.svg'
import { Dialog, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 315,
    height: 463,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 24,
  },
  greenButton: {
    margin: theme.spacing(3, 0, 2),
  },
  paymentTitle: {
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: -0.24,
    color: '#333333',
  },
  text: {
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#121212',
  },
}))

const DoneDialog = ({ intl, showDoneDialog, proceedToOrders }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Dialog
      open={showDoneDialog}
      onClose={proceedToOrders}
      TransitionComponent={Transition}
      PaperProps={{ style: { borderRadius: 24 } }}
      // keepMounted
    >
      <div className={classes.dialog}>
        <img src={verification} height='180' width='218' alt='Verification' style={{ marginTop: 20 }} />
        <Typography gutterBottom className={classes.paymentTitle}>
          {getLabel('payment-done')}
        </Typography>
        <Typography gutterBottom className={classes.text}>
          {getLabel('payment-confirmed')}
        </Typography>
        <GreenButton
          variant='contained'
          color='primary'
          fullWidth
          className={classes.greenButton}
          onClick={proceedToOrders}
        >
          {getLabel('continue-label')}
        </GreenButton>
      </div>
    </Dialog>
  )
}

DoneDialog.propTypes = {
  showDoneDialog: PropTypes.bool,
  proceedToOrders: PropTypes.func,
}

export default injectIntl(DoneDialog)
